
<script setup>
//#region Imports
import { ref, onBeforeMount, onUpdated, watch, reactive, getCurrentInstance, computed, nextTick  } from 'vue';
import axios from 'axios';
import { inject } from 'vue';
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import Notification from "@/components/common/SnackBar.vue";
import draggable from "vuedraggable";
//#endregion

//#region Reactive
const loginState = inject('loginState');
const store = inject('store');

const skillManagerState = reactive({
    loading: true,
    user_role: "User",
    skillMap: null,
    adminHierarchyView: [],
    adminCurrentHierarchy: null,
    adminCurrentHierarchy_id: null,
    activeSkillList: [],
    prioritizationMode: 'core',
    framework_items: [],
    frameworkClassifierType: null,
    selectedClassifierTypes: [],
    classifierTypes: [],
    reviewGroups: [],
    activeReviewGroup: null,
    editReviewGroup: null,
    currentReviewGroup: null,
    framework: null,
    data: [],
    hierarchy: null,
    filteredData: [],
    treeSelection: [],
    tabs: [
      "Skills Dashboard",
      "Skills Workspace",
      "Skills Framework Admin",
      "Market Insights"
    ],
    headers: [
          {
            text: 'Skill Name',
            value: 'name',
          },
          {
            text: 'Type',
            value: 'type',
          },
          {
            text: 'Client Override Name',
            value: 'client_name',
          },
          {
            text: 'Usage Count',
            value: 'id_count',
          }, 
          { 
            text: 'Actions', 
            value: 'actions', 
            sortable: false
          }
    ],
    skillLoading: true,
    hierarchies: [],
    activeHierarchy: null,
    filteredHierarchies: [],
    selectedSkillTag: null,
    selectedSkillCategories: [],
    notification: {
      text: "",
      type: "success"
    },
    extractSkillsDialog: {
      show: false,
      data: [],
      selected: [],
      loading: false,
      action: "extract",
      visible: [],
      framework_review_link_id: null
    },
    addSkillsDialog: {
      show: false,
      target: null,
      data: [],
      selected: [],
      loading: false,
      filter_text: "",
      filter_opts: []
    },
    reviewGroupHeaders: [
      {
        text: 'Review Group',
        align: 'start',
        value: 'name',
      },
      { text: 'Document Type', sortable: false, value: 'doc_type' },
      { text: 'Users', value: 'user_count' },
      { text: 'Jobs', value: 'doc_count' },
      { text: 'Creator', value: 'creator' },
      { text: 'Status', value: 'status' },
      { text: 'Date Created', value: 'date_created' },
      { text: 'Actions', sortable: false, value: 'actions' }
    ],
    createReviewGroupDialog: createDialogDefaultState(),
    createNewReviewGroupNodeId: null,
    existingHrNodes: [],
    activeView: "skill_priority",
    //activeView: "skill_proficiency",
    nodeHrs: [],
    reviewGroupLinks: [],
    nodeHrsSelected: [],
    addNewHrLinks: true,
    newHrGroup: {
      name: "",
      data: [],
      available: [],
      framework_review_link_id: -1,
      hrs: []
    },
    selectedGradingTypes: [],
    frameworkGradingType: [],
    allCategories: [],
    userList: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 20,
    saving: false,
    reviewGroupSearchText: "",
    commentsDialog: {
      show: false,
      data: [],
      skill: null,
      hr: null,
      newComment: baseComment()
    },
    uploadContentDialog: {
      show: false,
      data: [],
      loading:false
    },
    uploadFile: null,
    descriptorViewDialog: {
      show: false,
      data: [],
      skill: null
    },
});

let rerender = ref(0);
let rerenderTreeKey = ref(0);
let priorityContextMenu = ref(null);
//#endregion
 
//#region Trash
/*const useClassification = computed(() => loginState.user.settings.some(
    (s) => s.setting === 'tagging_classification_used' && s.value === "true"
))

const useCategorisation = computed(() => loginState.user.settings.some(
    (s) => s.setting === 'tagging_categorisation_used' && s.value === "true"
))

const useRatings = computed(() => loginState.user.settings.some(
    (s) => s.setting === 'tagging_rating_used' && s.value === "true"
))*/
//#endregion

//#region Computed

const filteredUserList = computed(() => {
  if(skillManagerState.createReviewGroupDialog.data.search === "" || !skillManagerState.createReviewGroupDialog.data.search){ return skillManagerState.userList }
  return skillManagerState.userList.filter(x => x.name && x.name.toLowerCase().includes(skillManagerState.createReviewGroupDialog.data.search?.toLowerCase()))
});

const addSkillFilteredList = computed(() => {
  let filteredByType = skillManagerState.addSkillsDialog.data.filter(x => skillManagerState.addSkillsDialog.filter_opts.includes(x.type_id) || !x.type_id);
  if(skillManagerState.addSkillsDialog.filter_text){
    return filteredByType.filter(x => x.value.toLowerCase().includes(skillManagerState.addSkillsDialog.filter_text.toLowerCase()))
  }
  return filteredByType;
});

const filteredSkillList = computed(() => {
  return skillManagerState.data.filter(x => skillManagerState.selectedClassifierTypes.includes(x.type_id) || !x.type_id)
});

const canEdit = computed(() => {
  return skillManagerState.user_role === "Admin" || skillManagerState.user_role === "HR";
})
//#endregion

//#region Methods

function baseComment() {
  return {
    framework_data_comment_id: null,
    parent: null,
    framework_data_id: null,
    framework_review_link_data_id: null,
    comment_text: ""
  }
}

const fallbackRecord = () => {
  return {
    framework_data_id: null,
    framework_id: skillManagerState.framework?.framework_id,
    framework_review_id: skillManagerState.activeReviewGroup,
    skill_id: null,
    tag_rating_id: null,
    tag_grading_type_value_id: null,
    node_id: null,
    active: 1,
    comments: []
  }
};

const skillExists2 = (skillId, hierarchyId) => {
  return skillManagerState.skillMap.get(hierarchyId)?.has(skillId) ?? false;
};

const getSkillData = (skillId, hierarchyId, property) => {
  let val = skillManagerState.skillMap.get(hierarchyId)?.get(skillId);
  let defaultVal = null;
  if(property === 'color')
  {
    defaultVal = '#e9e9e9';
  }
  return val ? val[property] : null;
};

const hasComments = (skill, hr) => {
  const record = hr.skills_data.find((sd) => sd.tag_value_id === skill.tag_value_id);
  return record && record.comments?.length > 0 ? true : false;
};

const filterPriorityList = (list) => {
  return list.data.filter(x => x.state !== 0 && skillManagerState.selectedClassifierTypes.includes(x.type_id) || !x.type_id)
}

const skillRatingExists = (skill, hr) => {
  if(hr.skills_data?.length === 0){
    return true;
  } else {
    let foundSkill = hr.skills_data.find(x => x.tag_value_id === skill.tag_value_id);
    if(!foundSkill) return true;
    return foundSkill.tag_rating_id;
  }
};

function setUpData(resp){
  skillManagerState.data = [];
  skillManagerState.skillLoading = false;
  skillManagerState.data = resp.data.skill_data;
  skillManagerState.tagData = resp.data.tag_data;
  skillManagerState.adminCurrentHierarchy_id = skillManagerState.adminHierarchyView.find(x => x.node_id === resp.data.reviewNode);
  skillManagerState.adminCurrentHierarchy = resp.data.hierarchies;
  skillManagerState.frameworkGradingType = skillManagerState.tagData.tagGradingTypes.find(x => x.tag_grading_type_id === skillManagerState.framework.tag_grading_type_id);
  skillManagerState.selectedGradingTypes = skillManagerState.frameworkGradingType.values.map(x => x.tag_grading_type_value_id);
  skillManagerState.selectedSkillTag = skillManagerState.tagData.tagTypes.find(x => x.tag_type_id === skillManagerState.framework.tag_type_id);
  skillManagerState.allCategories = skillManagerState.tagData.tagClassifierTypes.flatMap(item => item.values || []);
  skillManagerState.selectedSkillCategories = skillManagerState.tagData.tagClassifierTypes.flatMap(item => item.values || []).map(x => x.value);
  let valid = resp.data.hierarchies.map(x => x.hr_id);
  skillManagerState.hierarchy = store.state.hierarchies.hierarchies.find(x => x.ht_id === skillManagerState.framework.ht_id);
  skillManagerState.filteredHierarchies = skillManagerState.hierarchy.values.filter(x => valid.includes(x.value)).map(h => {
    let group = skillManagerState.reviewGroups.find(x => x.framework_review_id === skillManagerState.activeReviewGroup)
    let level = `hierarchy_node_id_level${group?.level+1}`;
    let source = resp.data.hierarchies.find(x => x.hr_id === h.value)
    return {
        hr_id: h.value,
        label: h.textValue,
        skills_data: source.data,
        node_id: h[level],
        docs: source.docs
      }
  });

  skillManagerState.newHrGroup = {
    name: "",
    data: [],
    available: [],
    framework_review_link_id: -1,
    hrs: []
  };

  showHierarchyGroupData(resp.data.group_links.data, resp.data.reviewNode);
  createSkillMap();
}

function showHierarchyGroupData(data, node){
  let hrList = data.reduce((acc, item) => {
    item.hrs.forEach(hr => {
      if (!acc.includes(hr.node_id)) {
        acc.push(hr.node_id);
      }
    });
    return acc;
  }, []);

  let availableNodes = findNodeWithChildren(skillManagerState.hierarchy.treePickerOptionsActive, node);
  availableNodes.children.forEach(x => {
    x.disabled = hrList.includes(x.node_id) ? 'disabled':'';
  })

  skillManagerState.nodeHrs = [JSON.parse(JSON.stringify(availableNodes))]
  skillManagerState.reviewGroupLinks = data;  
}

function updateReviewGroupStatus(status){
  let colour = '';
  switch(status){
    case skillManagerState.statuses[0]:
    case skillManagerState.statuses[1]:
      colour = '#fff5cc';
      break;
    case skillManagerState.statuses[2]:
      colour = '#eef7f9';
      break;
    case skillManagerState.statuses[3]:
      colour = '#f5f8d4';
      break;
  }

  let data = {
    framework_review_id: skillManagerState.currentReviewGroup.framework_review_id,
    status: status,
    status_id: skillManagerState.statuses[status]
  }

  axios.post("skills/updateReviewGroupState/", data)
    .then((resp) => {
      skillManagerState.currentReviewGroup.status = status;
      skillManagerState.currentReviewGroup.status_color = colour;
      triggerNotification("Status Updated: " + status, "success");
    })
    .catch(err => {
        console.log(err);
        triggerNotification("Errord: " + err, "error");
    });
}

function loadReviewGroup(id){
  resetCreateDialog();
  skillManagerState.activeReviewGroup = id.framework_review_id;
  skillManagerState.adminCurrentHierarchy_id = id;
  toggleNodeView({
    node_id: id.node_id,
    name: id.name
  })
}

function findNodeWithChildren(array, targetNodeId) {
  function search(nodes) {
    for (let node of nodes) {
      if (node.node_id === targetNodeId) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const found = search(node.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  return search(array);
}

function checkPermissions(){
  let userRole = "User";
  let adminRoles = ["RoleMapper Admin"];
  let hrRoles = ["Super User"];
  let settings = loginState.user.settings;
  let permission_groups = loginState.user.permission_groups;
  let hrRoleSetting = settings.find(x => x.setting === 'roleskill_workspace_hr');
  if(hrRoleSetting){ hrRoles = hrRoleSetting.value.split("|"); }  
  let adminRoleSetting = settings.find(x => x.setting === 'roleskill_workspace_admin');
  if(adminRoleSetting){ adminRoles = adminRoleSetting.value.split("|"); }
  if(permission_groups.some(x => hrRoles.includes(x))) { userRole = "HR"; }
  if(permission_groups.some(x => adminRoles.includes(x))) { userRole = "Admin"; }

  return userRole;
}

function getInstance() {
  skillManagerState.user_role = checkPermissions();
  initWorkspace();
}

function syncScreenData(item){
  let hrs = item.hrs.map(x => x.hr_id);
  skillManagerState.adminCurrentHierarchy.forEach(x => {
      if(hrs.includes(x.hr_id)){
        let skills = x.skills_data.map(z => z.tag_value_id);
        let toadd = item.data.filter(d => !skills.includes(d.tag_value_id));
        toadd.forEach(a => {
          let skill = skillManagerState.framework_items.find(d => d.tag_value_id === a.tag_value_id);
          assignSkill(skill, x);
        })
      }
    })
}

function setPriorities(e, item){
  let link = skillManagerState.reviewGroupLinks.find(x => x.framework_review_link_id === item.framework_review_link_id);
  let data = item.data.map((d,dIdx) => {
    return {
      ...d,
      priority: dIdx+1,
      framework_review_link_id: link.framework_review_link_id,
      tag_grading_type_value_id: d.tag_grading_type_value_id ? d.tag_grading_type_value_id : null
    }
  });
  link.data = data;
  if(e?.added){
    let removed = link.available.findIndex(x => x.tag_value_id === e.added.element.tag_value_id)
    if(removed > -1){
      link.available.splice(removed, 1);
    }

    syncScreenData(item);

  }

  axios.post("skills/savePrioritySkills/", data)
  .then((resp) => {
    link.data = resp.data;
    triggerNotification("Priority Skills Saved Successfully","success");
  })
  .catch(err => {
    console.log(err);
  });  
}

function docLoaded(){
    skillManagerState.loading = store.state.docs.loading;
    if(!skillManagerState.hierarchies){
    skillManagerState.hierarchies = store.state.hierarchies.hierarchies.map((h) => {
          switch (h.label.toLowerCase()) {
            case "geography":
            case "location":
              h.icon = "mdi-map-marker-outline";
              break;
            default:
              h.icon = "mdi-menu-down";
              break;
          }
          h.treePickerValue = null;
          return h;
      });
    }

}

function findNodeById(id, nodes) {
  for (const node of nodes) {
    if (node.node_id === id) {
      return node;
    }
    if (node.children && node.children.length > 0) {
      const result = findNodeById(id, node.children);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

function buildHierarchyDropdown(nodes, filter, filterList, path = "", result = [], leafDepth = 4, currentDepth = 1) {
  nodes.forEach(node => {

    let currentPath = path;
    if (node.children && node.children.length && currentDepth < leafDepth) {
      currentPath = path ? `${path} > ${node.name}` : node.name;
      let valid = filter ? node.children.some(x => filterList.includes(x.node_id)) : true;
      if(valid){
        buildHierarchyDropdown(node.children, filter, filterList, currentPath, result, leafDepth, currentDepth + 1);
      }
    } else {
      let parentPath = path;
      let lastHeader = result.length > 0 ? result[result.length - 1].header : null;

      if (parentPath && lastHeader !== parentPath && !result.find(x => x.header === path)) {
        result.push({ header: parentPath });
      }

      let valid = filter ? filterList.includes(node.node_id ) : true;
      if(valid){
        let rg = skillManagerState.reviewGroups.find(x => x.node_id === node.node_id);
        result.push({ 
          name: node.name,                         
          group: path,
          node_id: node.node_id, 
          status: rg ? rg.status : null, 
          status_color: rg ? rg.status_color : null
        });
      }
    }
  });

  return result;
}

function initHierarchies(){
  if(store.state.hierarchies.loading){
    setTimeout(() => {
      initHierarchies();
    }, 500);
    return;
  }
  
  rerenderTreeKey.value++;
  skillManagerState.activeHierarchy = [];
  skillManagerState.hierarchy = store.state.hierarchies.hierarchies.find(x => x.ht_id === skillManagerState.framework.ht_id);
  skillManagerState.reviewGroups.forEach(rg => {
    let node = findNodeById(rg.node_id, skillManagerState.hierarchy.treePickerOptionsActive);
    if(node){
      rg.doc_count = node.children.length;
    }
  })

  let clone = Object.assign([], skillManagerState.hierarchy.treePickerOptionsActive);
  let hrMap = skillManagerState.reviewGroups.map(x => x.node_id);
  let filterLists = skillManagerState.user_role === 'User';
  const hierarchyValues = buildHierarchyDropdown(clone, filterLists, hrMap, "", [], skillManagerState.hierarchy.linklevel-1);

  skillManagerState.activeHierarchy = clone;
  skillManagerState.activeHierarchy = skillManagerState.activeHierarchy.map(object => object);
  skillManagerState.adminHierarchyView = hierarchyValues;
}

function assignSkill(skill, hr){
  if(!hr.skills_data){
    hr.skills_data = [];
  }
  let index = hr.skills_data?.findIndex(sd => sd.tag_value_id === skill.tag_value_id);
  if(index === -1){
    hr.skills_data.push({...fallbackRecord(), tag_value_id: skill.tag_value_id, framework_item_id: skill.framework_item_id});
  }

  if(skillManagerState.data.findIndex(d => d.tag_value_id === skill.tag_value_id) === -1){
    skillManagerState.data.push(skill);
  }
  createSkillMap();
}

function initWorkspace(){
  skillManagerState.skillLoading = true;
  axios.get("skills/initWorkspace")
    .then((resp) => {
      setUpAdmin(resp.data);
      skillManagerState.skillLoading = false;
    })
    .catch(err => {
        console.log(err);
        skillManagerState.skillLoading = false;
    });
}

function setUpAdmin(resp){
  skillManagerState.framework = resp.framework.framework;
  skillManagerState.tagData = resp.framework.tag_data;
  skillManagerState.framework_items = resp.items;
  skillManagerState.userList = resp.users;
  skillManagerState.statuses = resp.statuses;
  skillManagerState.reviewGroups = resp.reviewGroups;
  skillManagerState.frameworkGradingType = resp.framework.frameworkGradingType;
  skillManagerState.frameworkClassifierType = resp.framework.frameworkClassifierType;//skillManagerState.tagData.tagGradingTypes.find(x => x.tag_grading_type_id === skillManagerState.framework.tag_grading_type_id);
  skillManagerState.selectedGradingTypes = skillManagerState.frameworkGradingType.values.map(x => x.tag_grading_type_value_id);
  skillManagerState.selectedClassifierTypes = skillManagerState.frameworkClassifierType.values.map(x => x.tag_classifier_type_value_id);
  skillManagerState.selectedSkillTag = skillManagerState.tagData.tagTypes.find(x => x.tag_type_id === skillManagerState.framework.tag_type_id);
  skillManagerState.classifierTypes = skillManagerState.tagData.tagClassifierTypes;
  initHierarchies();
}

function toggleNodeView(node){
  if(!node){ return; }
  skillManagerState.skillLoading = true;

  let data = { 
    node_id: node.node_id, 
    name: node.name
  }

  axios.get("skills/nodeView", { params: data })
    .then((resp) => {
      skillManagerState.adminCurrentHierarchy = resp.data.node_data;
      skillManagerState.reviewGroupLinks = resp.data.groups;
      skillManagerState.data = resp.data.activeItems;
      skillManagerState.skillLoading = false;
      skillManagerState.currentReviewGroup = resp.data.currentReviewGroup;
      createSkillMap();
    })
    .catch(err => {
        console.log(err);
        skillManagerState.skillLoading = false;
    });
}

function createSkillMap(){
  const map = new Map();
  skillManagerState.adminCurrentHierarchy.forEach((h) => {
    map.set(h.node_id, new Map(h.skills_data.map((s) => [s.tag_value_id, s])));
  });
  skillManagerState.skillMap = map;
}

function doSomething(rating_id, skill, hr){
  let index = hr.skills_data.findIndex(sd => sd.tag_value_id === skill.tag_value_id);
  let data = index !== -1 ? hr.skills_data[index] : {...fallbackRecord()};
  data = {
    ...data,
    tag_value_id: skill.tag_value_id,
    tag_rating_id: rating_id,
    node_id: hr.node_id,
    framework_review_id: skillManagerState.activeReviewGroup
  }

  axios.post("skills/saveSkillFrameworkData", data)
  .then((resp) => {
    index !== -1 ? hr.skills_data.splice(index, 1, resp.data.Data) : hr.skills_data.push(resp.data.Data);
    triggerNotification("Saved Successfully","success");
  })
  .catch(err => {
    console.log(err);
  });
}

function triggerNotification(msg, type){
  skillManagerState.notification = {
    text: msg,
    type: type
  };
}

function editReviewGroup(g, type){
  skillManagerState.createReviewGroupDialog.mode = type;
  skillManagerState.editReviewGroup = g;
  axios.get("skills/skillFrameworkReviewGroupDataGroups/" + g.framework_review_id)
  .then((resp) => {
    showHierarchyGroupData(resp.data.data, g.node_id || g.hr_node_id);
      
    if(skillManagerState.createReviewGroupDialog.mode === "edit"){
      skillManagerState.createReviewGroupDialog.show = true;
      skillManagerState.createReviewGroupDialog.data = {
        ...skillManagerState.createReviewGroupDialog.data,
        name: g.name,
        node_id: g.node_id || g.hr_node_id,
        framework_review_id: g.framework_review_id,
        users: resp.data.users
      }
    }
  })
  .catch(err => {
      console.log(err);
      skillManagerState.skillLoading = false;
  });
}

function initReviewGroup(){
  editReviewGroup(skillManagerState.currentReviewGroup, "edit");
}

function getSkillTypeFilterText(item, index) {
  if (item && index > 0) return "";
  let included = skillManagerState.frameworkClassifierType.values.filter((s) =>
    skillManagerState.selectedClassifierTypes.some((si) => s.tag_classifier_type_value_id === si)
  );
  if (included.length === skillManagerState.frameworkClassifierType.values.length)
    return included.length > 0 ? "All" : "None";

  if (included.length > 2) return `${included.length} items`;

  return included.reduce((prev, curr) => {
    return prev + (prev ? ", " : "") + curr.value;
  }, "");
}

function submitComment(){
  let data = {
    ...skillManagerState.commentsDialog.newComment,
    framework_review_id: skillManagerState.currentReviewGroup.framework_review_id
  };
  axios.post("skills/comment", data)
    .then((resp) => {
      triggerNotification("Comment Saved Successfully", "success");
      updateData(resp.data);
      skillManagerState.commentsDialog.newComment = {
        ...skillManagerState.commentsDialog.newComment,
        comment_text: "",
        parent: null
      }
      //skillManagerState.commentsDialog.newComment = baseComment();
    })
    .catch(err => {
      console.log(err);
    });  
}

function loadComments(skill, hr){

  switch(skillManagerState.activeView){
    case "skill_proficiency": {
      const record = hr.skills_data.find((sd) => sd.tag_value_id === skill.tag_value_id);
      skillManagerState.commentsDialog.data = record?.comments;
      skillManagerState.commentsDialog.skill = record;
      skillManagerState.commentsDialog.hr = hr;      
      skillManagerState.commentsDialog.newComment.framework_data_id = record.framework_data_id;
      break;
    }
    case "skill_priority": {
      const record = hr.data.find((sd) => sd.tag_value_id === skill.tag_value_id);
      skillManagerState.commentsDialog.data = record?.comments;      
      skillManagerState.commentsDialog.skill = record;
      skillManagerState.commentsDialog.newComment.framework_review_link_id = skill.framework_review_link_id;
      skillManagerState.commentsDialog.newComment.framework_review_link_data_id = skill.framework_review_link_data_id;
      break;
    }
  }
  skillManagerState.commentsDialog.show = true;
}

function updateData(data){

  switch(skillManagerState.activeView){
    case "skill_proficiency": {
      let target = skillManagerState.adminCurrentHierarchy.find(x => x.hr_id === skillManagerState.commentsDialog.hr.hr_id);
      let targetSkill = target.skills_data.find(x => x.framework_data_id === skillManagerState.commentsDialog.skill.framework_data_id);
      targetSkill.comments = data;
      skillManagerState.commentsDialog.data = data;
      break;
    }
    case "skill_priority": {
      let target = skillManagerState.reviewGroupLinks.find(x => x.framework_review_link_id === skillManagerState.commentsDialog.skill.framework_review_link_id);
      let targetSkill = target.data.find(x => x.framework_review_link_data_id === skillManagerState.commentsDialog.skill.framework_review_link_data_id);
      targetSkill.comments = data;
      skillManagerState.commentsDialog.data = data;
      break;
    }
  }


}

function getAllSkills(){
  let data = [];
  let vis = [];
  skillManagerState.adminCurrentHierarchy.forEach((h,idx) => {
    vis.push(idx);
    let docs = store.state.docs.docsList.filter(x => x.hierarchies.some(z => z.hr_id === h.hr_id) && x.tmpl_id === skillManagerState.framework.tmpl_id);
    data.push({
      name: h.name,
      docs: docs.map(x => { return {
        doc_id: x.doc_id,
        system_number: x.system_number,
        doc_status_text: x.doc_status_text,
        skills_extracted: h.docs.findIndex(h => h.doc_id === x.doc_id) > -1,
        doc_type: x.doc_type,
        doc_name: x.doc_name
      }})
    })    
  })

  skillManagerState.extractSkillsDialog = {
      data: data,
      show: true,
      selected: [],
      loading: false,
      action: "extract",
      visible: vis
  };
}

/*function getSkills(hry){
  let docs = store.state.docs.docsList.filter(x => x.hierarchies.some(h => h.hr_id === hry.hr_id) && x.tmpl_id === skillManagerState.framework.tmpl_id);
  let data = [];
  data.push({
    name: hry.label,
    docs: docs.map(x => { return {
      doc_id: x.doc_id,
      system_number: x.system_number,
      doc_status_text: x.doc_status_text,
      skills_extracted: hry.docs.findIndex(h => h.doc_id === x.doc_id) > -1,
      doc_type: x.doc_type,
      doc_name: x.doc_name
    }})
  })

  skillManagerState.extractSkillsDialog = {
      data: data,
      show: true,
      selected: [],
      loading: false,
      action: "extract"
  };
}*/

function getNewSkills(){
    let existing = skillManagerState.data.map(x => x.value);
    let available = skillManagerState.framework_items.filter(x => x.active && !existing.includes(x.value));
    skillManagerState.addSkillsDialog.data = available;
}

function addSkillsToGroup(item){
  skillManagerState.addSkillsDialog.selected = [];
  let available = skillManagerState.framework_items.map(d => {
    let skill = item.data.find(x => x.state !== 0 && x.tag_value_id === d.tag_value_id);
    let source = skillManagerState.selectedSkillTag.values.find(x => x.tag_value_id === d.tag_value_id);
    return {
      ...d,
      description: source?.description,
      exists: skill ? true : false,
      checked: false,
      color: skill ? skill.colour : null,
      tag_rating_id: skill ? skill.tag_rating_id: null,
      tag_grading_type_id: skill ? skill.tag_grading_type_id: null
    }
  });
  skillManagerState.addSkillsDialog.filter_opts = skillManagerState.frameworkClassifierType.values.map(x => x.tag_classifier_type_value_id);
  skillManagerState.addSkillsDialog.data = available;
  skillManagerState.addSkillsDialog.target = item;
  skillManagerState.addSkillsDialog.show = true;
}

function checkAllExtract(){
  skillManagerState.extractSkillsDialog.selected = [];
  skillManagerState.extractSkillsDialog.data.forEach(g => {
    g.docs.filter(d => !d.skills_extracted).forEach(d => {
      skillManagerState.extractSkillsDialog.selected.push(d.doc_id);
    })
  })
}

function confirmAddSkill(){
  if(skillManagerState.addSkillsDialog.target){
    let group = skillManagerState.reviewGroupLinks.find(x => x.framework_review_link_id === skillManagerState.addSkillsDialog.target.framework_review_link_id);
    let add = skillManagerState.addSkillsDialog.selected.map(s => {
      return {
        ...s,
        framework_review_link_data_id: null,
        framework_review_link_id: skillManagerState.addSkillsDialog.target.framework_review_link_id
      }
    })
    group.data = [
      ...group.data,
      ...add
    ];
    setPriorities(null, group);
    syncScreenData(group);
    skillManagerState.addSkillsDialog.show = false;
  } else {
    skillManagerState.data = [
      ...skillManagerState.addSkillsDialog.selected,
      ...skillManagerState.data
    ]

    skillManagerState.addSkillsDialog.show = false;
  }

}

function addNewSkill(){
  skillManagerState.addSkillsDialog = {
      show: true,
      selected: [],
      data:[],
      loading: true
  };
  skillManagerState.addSkillsDialog.filter_opts = skillManagerState.frameworkClassifierType.values.map(x => x.tag_classifier_type_value_id);
  getNewSkills();
  skillManagerState.addSkillsDialog.loading = false;
}

function getSkillsForDocs(){
    let data = {
      doc_ids: skillManagerState.extractSkillsDialog.selected,
      activeReviewGroupId: skillManagerState.currentReviewGroup.framework_review_id
    }

    skillManagerState.extractSkillsDialog.loading = true;

    axios.post("skills/getSkillsForDocs", data)
    .then((resp) => {
      skillManagerState.extractSkillsDialog.loading = false;
      setUpData(resp);
      skillManagerState.extractSkillsDialog.show = false;
      triggerNotification("Skills Extracted Successfully","success");
    })
    .catch(err => {
      console.log(err);
      skillManagerState.extractSkillsDialog.loading = false;
    });
}

function extractAllSKills(type){
  let data = [];
  let docList = skillManagerState.filteredHierarchies.flatMap(item => item.docs.map(doc => doc.doc_id));
  skillManagerState.reviewGroupLinks.forEach(item => {
    let hrs = item.hrs.map(x => x.hr_id);
    let docs = store.state.docs.docsList.filter(x => x.hierarchies.some(h => hrs.includes(h.hr_id)) && x.tmpl_id === skillManagerState.framework.tmpl_id);
    if((type === 'apply' && item.data.length > 0) || type === 'extract'){
        data.push({
        name: item.name,
        framework_review_link_id: item.framework_review_link_id,
        docs: docs.map(x => { return {
          doc_id: x.doc_id,
          system_number: x.system_number,
          doc_status_text: x.doc_status_text,
          skills_extracted: docList.includes(x.doc_id),
          doc_type: x.doc_type,
          doc_name: x.doc_name
        }})
      })
    }
  });

  skillManagerState.extractSkillsDialog = {
      data: data,
      show: true,
      selected: [],
      loading: false,
      action: type
  };
}

function deleteItem(skill, item){
  let target = item.skills_data.find(x => x.tag_value_id === skill.tag_value_id);
  if(target){
    axios.delete("skills/skillFrameworkData/" + target.framework_data_id)
    .then((resp) => {
      let idx = item.skills_data.indexOf(target);
      if(idx > -1){
        item.skills_data.splice(idx, 1);
      }
      triggerNotification("Skill Deleted Successfully","success");
    })
    .catch(err => {
      triggerNotification(err,"error");
    });  
  }
}

function deleteSkill(skill){

  let data = {
    ...skill,
    active: false
  }

  axios.post("skills/frameworkSkill", data)
    .then((resp) => {
      let idx = skillManagerState.data.findIndex(x => x.framework_item_id === skill.framework_item_id);
      if(idx > -1){
        skillManagerState.data.splice(idx, 1);
      }
      triggerNotification("Skill Deleted Successfully","success");
    })
    .catch(err => {
      triggerNotification(err,"error");
    });  
  //
}

function syncSkillCategories(newValue, skillItem, item) {
  item.hrs.forEach(h => {
    let hr = skillManagerState.adminCurrentHierarchy.find(x => x.hr_id === h.hr_id);
    if(hr){
      let skill = hr.skills_data.find(s => s.tag_value_id === skillItem.tag_value_id)
      if(!skill) return;
      skill.category = newValue.name;
      skill.category_id = newValue.tag_grading_type_value_id;
      skill.color = newValue.colour;
    }
  })  
}

function updateItemState(val, d){
  d.state = val;
  axios.post("skills/savePrioritySkills", [d])
  .then((resp) => {
    triggerNotification("Skill State Updated Successful","success");
    d.edit = false;
  })
  .catch(err => {
    triggerNotification(err,"error");
  });
}

function confirmCoreCategoryUpdate(val, d, item){
  let newValue = skillManagerState.frameworkGradingType.values.find(x => x.tag_grading_type_value_id === val);
  d.tag_grading_type_value_id = val;
  d.colour = newValue.colour;

  syncSkillCategories(newValue, d, item);

  axios.post("skills/savePrioritySkills", [d])
  .then((resp) => {
    triggerNotification("Skill Core Category Update Successful","success");
    d.edit = false;
  })
  .catch(err => {
    triggerNotification(err,"error");
  });
}

function applySkillsToDocs() {
  skillManagerState.saving = true;
  let allData = skillManagerState.extractSkillsDialog.data.flatMap(parent => 
    parent.docs.map(item => ({
      ...item,
      framework_review_link_id: parent.framework_review_link_id
    }))
  );

  let docData = skillManagerState.extractSkillsDialog.selected.map(d => {
    let item = store.state.docs.docsList.find(x => x.doc_id === d);
    let source = allData.find(x => x.doc_id === d);
    let hrItem = item.hierarchies.find(h => h.ht_id === skillManagerState.framework.ht_id);
    return {
      ...source,
      hr_id: hrItem?.hr_id
    }
  })

  let data = {
    framework_review_id: skillManagerState.currentReviewGroup.framework_review_id,    
    data: docData
  }

  axios.post("skills/applySkillsToDocs", data)
  .then((resp) => {
    triggerNotification("Skills Updated","success");
    skillManagerState.saving = false;
  })
  .catch(err => {
    triggerNotification(err,"error");
    skillManagerState.saving = false;
  });    
}

function createDialogDefaultState() {
  return {
    show: false,
    data: {
      name: "",
      hr_id: null,
      search: "",
      users: []
    },
    step: 1,
    mode: "create"
  }
}

function resetCreateDialog(){
  skillManagerState.createReviewGroupDialog = createDialogDefaultState();
}

function toggleUser(user){
  let idx = skillManagerState.createReviewGroupDialog.data.users.indexOf(user);
  idx > -1 ? skillManagerState.createReviewGroupDialog.data.users.splice(idx,1) : skillManagerState.createReviewGroupDialog.data.users.push(user);
}

function updateReviewGroup(item){
  let data = {
    framework_review_id: item.framework_review_id,
    node_id: item.node_id,
    name: item.name,
    users: item.users
  }


  axios.post("skills/skillFrameworkReviewGroups", data)
  .then((resp) => {
    triggerNotification("Updated","success");
    skillManagerState.createReviewGroupDialog.show = false;
    if(skillManagerState.currentReviewGroup.status === skillManagerState.statuses[0]){
      updateReviewGroupStatus('In Progress');
    }
  })
  .catch(err => {
    console.log(err);
  });
}

function loadDescriptors(d){
  let tagValue = skillManagerState.selectedSkillTag.values.find(x => x.tag_value_id === d.tag_value_id);
  let data = tagValue.ratings.length > 0 ? tagValue.ratings : skillManagerState.selectedSkillTag.ratings;
  skillManagerState.descriptorViewDialog = {
    data: data,
    show: true,
    skill: tagValue
  }
}

function processUpload(){
  skillManagerState.uploadContentDialog.loading = true;
  let formData = new FormData();
  formData.append("files[]", skillManagerState.uploadFile);
  formData.append("framework_review_id", skillManagerState.currentReviewGroup.framework_review_id);
  formData.append("node_id", skillManagerState.adminCurrentHierarchy_id.node_id);
  formData.append("node_name", skillManagerState.adminCurrentHierarchy_id.name);

  let options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  axios.post("skills/uploadContent/", formData, options)
  .then((resp) => {
    
    skillManagerState.uploadContentDialog.loading = false;
    skillManagerState.uploadContentDialog.show = false;
    //setUpAdmin(resp.data);
    //loadReviewGroup(skillManagerState.currentReviewGroup.framework_review_id);
    skillManagerState.adminCurrentHierarchy = resp.data.node_data;
    skillManagerState.reviewGroupLinks = resp.data.groups;
    skillManagerState.data = resp.data.activeItems;
    skillManagerState.skillLoading = false;
    skillManagerState.currentReviewGroup = resp.data.currentReviewGroup;
    triggerNotification("Data Upload Complete", "success");
  })
  .catch(err => {
      console.log(err);
      triggerNotification("Errord: " + err, "error");
      skillManagerState.uploadContentDialog.loading = false;
  });
}


//#endregion

//#region Component
onUpdated(() => {

})

onBeforeMount(() => {
    getInstance();
    watch(() => store.state.docs.loading, () => docLoaded());
})
//#endregion
</script>
<template>
  <v-card class="skillsWorkspace" flat>
    <v-card-text v-if="!skillManagerState.framework?.framework_id">
      No Framework Config!
    </v-card-text>
    <div v-else-if="skillManagerState.skillLoading">
        <div style="width:100vw; height:100vh">
          <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          style="top: 40%; left: 48%;"
        ></v-progress-circular>
        </div>
    </div>
    <div v-else>
      <v-card-text class="pa-0">
        <div class="stepper">            
          <div class="step" @click="skillManagerState.activeView = 'skill_priority'">
            <div :class="[ 'content', skillManagerState.activeView === 'skill_priority' ? 'active stepblue' : '' ]">
              <v-icon size="50px" color="#50B3CB">format_list_numbered</v-icon>
              <div class="steptitle">
                <h2>Skill Prioritization</h2>
              </div>
            </div>
          </div>
          <div class="step" @click="skillManagerState.activeView = 'skill_proficiency'">
            <div :class="[ 'content', skillManagerState.activeView === 'skill_proficiency' ? 'active stepyellow' : '' ]">
              <v-icon size="50px" color="#FFCB00">schema</v-icon>
              <div class="steptitle">
                <h2>Proficiency Distribution</h2>
              </div>
            </div>
          </div>
          <!--<div class="step" @click="skillManagerState.activeView = 'job_hierarchy'">
            <div :class="[ 'content', skillManagerState.activeView === 'job_hierarchy' ? 'active steppink' : '' ]">
              <v-icon size="50px" color="#EA3785">mdi-file-tree</v-icon>
              <div class="steptitle">
                <h2>Job Hierarchy Distribution</h2>
              </div>
            </div>
          </div>-->
          <!--<div class="step" @click="skillManagerState.activeView = 'job_hierarchy'">
            <div :class="[ 'content', skillManagerState.activeView === 'job_hierarchy' ? 'active steplime' : '' ]">
              <v-icon size="50px" color="#CEDC26">format_list_bulleted</v-icon>
              <div class="steptitle">
                <h2>Skill Proficiency Descriptors</h2>
              </div>
            </div>
          </div>-->
        </div>
      </v-card-text> 
      <v-card-text v-if="skillManagerState.user_role === 'User' && !skillManagerState.activeReviewGroup" class="pa-0">
        <div class="skillsWorkspaceHolder">
        <h1>Review Groups</h1>
        <v-row dense>
          <v-col cols="2">
            Users<br/>
            <v-select dense outlined :z-index="21"></v-select>
          </v-col>
          <v-col cols="2">
            Document Type<br/>
            <v-select dense outlined :z-index="21"></v-select>
          </v-col>
          <v-col cols="6"></v-col>
          <v-col cols="2">
            Search Groups & Docs<br/>
            <v-text-field dense outlined autocomplete="off" v-model="skillManagerState.reviewGroupSearchText"></v-text-field>
          </v-col>
        </v-row>
        <div style="overflow: hidden scroll; height: calc(100vh - 590px);">
          <v-data-table
            :headers="skillManagerState.reviewGroupHeaders"
            :items="skillManagerState.reviewGroups"
            class="elevation-1"
            hide-default-footer
            :page.sync="skillManagerState.page"
            :items-per-page="skillManagerState.itemsPerPage"
            @page-count="skillManagerState.pageCount = $event"
          >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-start">
              <v-btn icon @click="loadReviewGroup(item)"><v-icon>mdi-pencil</v-icon></v-btn>
              <!--<v-btn icon @click="editReviewGroup(item, 'edit')"><v-icon>mdi-cog</v-icon></v-btn>-->
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              small
              class="mx-1 status-chip chipFont"
              :color="item.status_color"
              label
            >
              {{ item.status }}
            </v-chip>
          </template>
          </v-data-table>
        </div>
        <div style="display:inline-flex; width:100%; position: relative; padding-top:40px">
          <!--<div style="position: absolute; left: 0;">
            <v-btn color="success" @click="showCreateDialog(true)">
            <v-icon class="mr-3">mdi-plus</v-icon>
            Create Review Group
          </v-btn>
          </div>-->
          <div style="position: absolute; left: 50%; transform: translateX(-50%);">
            <v-pagination
              v-model="skillManagerState.page"
              :length="skillManagerState.pageCount"
            ></v-pagination>
          </div>
        </div>
        </div>
      </v-card-text>  
      <v-card-text v-else-if="skillManagerState.user_role === 'HR' && !skillManagerState.adminCurrentHierarchy" class="pa-0">
        <div class="skillsWorkspaceHolder">
          <h1>HR Persona Review Groups</h1> {{ skillManagerState.user_role }}
          <v-autocomplete @change="toggleNodeView($event)" v-model="skillManagerState.adminCurrentHierarchy_id" :items="skillManagerState.adminHierarchyView" dense outlined hide-details style="z-index: 10" item-text="name" item-value="node_id" return-object>
            <template v-slot:item="data">
              {{ data.item.name }}                   
              <v-chip
                class="mx-1 status-chip chipFont"
                label
                :color="data.item.status_color"
                v-if="data.item.status"
                x-small
                style="top: 0px; left:15px;"
              >
                {{ data.item.status }}
              </v-chip>
            </template>
          </v-autocomplete>
        </div>
      </v-card-text>                   
      <v-card-text v-else class="pa-0">
        <div class="skillContentHolder">
          <div v-if="skillManagerState.activeView === 'skill_proficiency'">
            <v-row v-if="!skillManagerState.currentReviewGroup" dense>
              <v-col cols="12" style="display:inline-flex">
                <h1>Skill Proficiency Assignment</h1>
              </v-col>
            </v-row>
            <v-row v-if="skillManagerState.currentReviewGroup" dense>
              <v-col cols="4" style="display:inline-flex">
                <h1 style="margin-left: 10px;">Skill Proficiency Assignment</h1>
                <v-chip
                  class="mx-1 status-chip chipFont"
                  :color="skillManagerState.currentReviewGroup.status_color"
                  label
                  style="top: 5px; left:25px;"
                >
                  {{ skillManagerState.currentReviewGroup.status }}
                </v-chip>
              </v-col>
              <v-col cols="3">
              </v-col>
              <v-col v-if="skillManagerState.currentReviewGroup.status === skillManagerState.statuses[0] && skillManagerState.currentReviewGroup" cols="5" style="display: flex; justify-content: end; padding-top: 10px;">
                <v-btn small class="ml-2" @click="getAllSkills">Extract</v-btn>
                <v-btn small class="mx-2" @click="initReviewGroup()">Create Review Group</v-btn>
              </v-col>
              <v-col v-if="skillManagerState.currentReviewGroup.status !== skillManagerState.statuses[0] && skillManagerState.currentReviewGroup" cols="5" style="display: flex; justify-content: end; padding-top: 10px;">
                <v-btn v-if="skillManagerState.user_role !== 'User' && skillManagerState.currentReviewGroup.status !== skillManagerState.statuses[0]" small class="mx-2" @click="updateReviewGroupStatus(skillManagerState.statuses[0])">Move Back To {{ skillManagerState.statuses[0] }}</v-btn>
                <v-btn small class="mx-2" @click="updateReviewGroupStatus(skillManagerState.statuses[1])">Save As Draft</v-btn>
                <v-btn small class="mx-2" @click="updateReviewGroupStatus(skillManagerState.statuses[2])">Share For Review</v-btn>
                <v-btn small class="mx-2" @click="updateReviewGroupStatus(skillManagerState.statuses[3])">Approve</v-btn>
                <v-btn v-if="skillManagerState.currentReviewGroup.status === skillManagerState.statuses[3]" small class="mx-2" @click="extractAllSKills('apply')">Publish</v-btn>
              </v-col>
            </v-row>    
            <v-row dense style="padding-top: 28px;" v-if="!skillManagerState.adminCurrentHierarchy">
              <v-col>
                <h2>Select A Job Family To Begin</h2>
              </v-col>
            </v-row>            
            <v-row dense style="padding-bottom:5px">
              <v-col dense cols="2">
                Job Family<br/>
                <v-autocomplete @change="toggleNodeView($event)" v-model="skillManagerState.adminCurrentHierarchy_id" :items="skillManagerState.adminHierarchyView" dense outlined hide-details style="z-index: 10" item-text="name" item-value="node_id" return-object>
                  <template v-slot:item="data">
                    {{ data.item.name }}                   
                    <v-chip
                      class="mx-1 status-chip chipFont"
                      label
                      :color="data.item.status_color"
                      v-if="data.item.status"
                      x-small
                      style="top: 0px; left:15px;"
                    >
                      {{ data.item.status }}
                    </v-chip>
                  </template>
                  <template v-slot:selection="data">
                    <span>{{ data.item.name }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col dense cols="2">
                Job<br/>
                <v-select :items="['All']" value="All" dense outlined hide-details style="z-index: 10"></v-select>
              </v-col>
              <v-col dense cols="2">
                Business Area<br/>
                <v-select :items="['All']" value="All" dense outlined hide-details style="z-index: 10"></v-select>
              </v-col>
              <v-col dense cols="2">
                Skill Type<br/>
                <v-select v-model="skillManagerState.selectedClassifierTypes" :items="skillManagerState.frameworkClassifierType.values" multiple dense outlined hide-details style="z-index: 10" item-text="value" item-value="tag_classifier_type_value_id">
                  <template v-slot:selection="{ item, index }">
                    {{ getSkillTypeFilterText(item, index) }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="4" style="padding-left:15px">
                <div style="display: flex; align-items: center;">
                  <span class="mr-2">Core Category</span>
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="#aaa"
                      size="18px"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>Core Category</span>
                </v-tooltip>
                </div>
                  <div>
                    <div class="mr-4" v-for="item in skillManagerState.frameworkGradingType.values.slice().reverse()" :key="item.tag_grading_type_value_id" style="display:inline-flex; align-items:center; padding-top:6px">
                      <!--<v-checkbox v-model="skillManagerState.selectedGradingTypes" :value="item.tag_grading_type_value_id" small dense></v-checkbox>-->
                      <div class="legend" :style="{'background-color': item.colour}"></div>
                      <div style="padding-left:5px">{{ item.name }}</div>
                    </div>
                    <div class="mr-4" style="display:inline-flex; align-items:center; padding-top:3px">
                      <div class="legend" :style="{'background-color': '#ffffff'}"></div>
                      <div style="padding-left:5px">Unassigned</div>
                    </div>                    
                  </div>
              </v-col>               
              <!--<v-col dense cols="5" v-if="skillManagerState.currentReviewGroup">
                <div style="justify-content: flex-end; display: flex; align-content: center; align-items: center; height: 100%; padding-top: 20px;">
                  <v-btn dense small @click="addNewSkill">
                    <v-icon color="#AAAAAA" class="mr-2">mdi-plus-circle</v-icon> 
                    Add New Skill
                  </v-btn>
                  <v-btn v-if="1 === 0" small class="ml-2" @click="getAllSkills">Extract</v-btn>
                </div>
              </v-col>-->
            </v-row>
            <v-row v-if="skillManagerState.adminCurrentHierarchy" dense>
              <v-col cols="12">
                <div class="table-wrapper distribution-wrapper-admin">
                  <div class="table-scroll">
                    <table class="table table-bordered distributionTable" cellpadding="0" cellspacing="0">
                      <thead>
                        <tr>
                          <th class="locked-column intersect-column">
                            Skill  ({{ filteredSkillList.length }})
                            <v-btn style="left: 20px; bottom: 2px;" dense small @click="addNewSkill">
                              <v-icon color="#AAAAAA">mdi-plus-circle</v-icon> 
                              Add New Skill
                            </v-btn>
                          </th>
                          <th v-for="item in skillManagerState.adminCurrentHierarchy" :key="`hdr_${item.node_id}_${rerender}`">
                            <div style="display:inline">
                              <div class="headerText" :title="item.name">
                                {{item.name}}
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="skillManagerState.data.length > 0">
                        <tr v-for="(skill, idx) in filteredSkillList" :key="`${idx}_${rerender}`">
                          <td class="locked-column">
                            <div class="locked-col-text" style="display: inline-flex; width: 90%; align-items: center; justify-content: flex-start;" :title="`${skill.name} (${skill.type})`">
                              {{idx+1}} &nbsp; {{skill.value}}
                              <v-chip v-if="skill.type"
                                class="ma-2 pl-3 chipFont" small label
                              >
                              {{skill.type}}
                              </v-chip>
                            </div>
                            <v-menu
                              bottom
                              attach
                              right
                              min-width="270px"
                              :nudge-bottom="4"
                              :nudge-right="50"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div style="position:relative; float:right; right: 5px;">
                                  <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="#48A401" style="float: right;  ">mdi-dots-horizontal</v-icon>
                                </v-btn>
                                </div>

                              </template>

                              <v-list dense>
                                <v-list-item v-if="canEdit" @click="deleteSkill(skill)">
                                  <v-list-item-icon class="mr-3">
                                    <v-icon>mdi-delete</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>Delete Skill</v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="loadDescriptors(skill)">
                                  <v-list-item-icon class="mr-3">
                                    <v-icon>mdi-magnify</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>View Skill Descriptors</v-list-item-content>
                                </v-list-item>                                     
                              </v-list>
                            </v-menu>
                          </td> 
                          <td v-for="item in skillManagerState.adminCurrentHierarchy" :style="{ 'background-color': skillRatingExists(skill, item) ? getSkillData(skill.tag_value_id,item.node_id, 'color') : '#e9e9e9' }" :key="`${idx}_${item.hr_id}_${rerender}`">
                            <div v-if="skillExists2(skill.tag_value_id,item.node_id)" style="display: inline-flex;width: 100%;align-items: center;justify-content: space-between;">
                              <div style="width:110px;">
                                <v-select :disabled="!canEdit" dense :value="getSkillData(skill.tag_value_id,item.node_id, 'tag_rating_id')" @change="doSomething($event, skill, item)" style="width:110px; top:7px" :items="skillManagerState.selectedSkillTag.ratings" item-value="tag_type_rating_id" item-text="name">
                                  <template v-slot:selection="{ item }">
                                    <span
                                      class="grey--text text-caption"
                                      style="width:100px; text-align: center;"
                                    >
                                    {{ item.name  }}
                                    </span>
                                  </template>    
                                  <template v-slot:label>
                                    <span
                                      class="grey--text text-caption"
                                      style="width:100px; text-align: center;"
                                    >
                                    {{ skillRatingExists(skill, item) ? '' : 'Set proficiency' }} 
                                    </span>
                                  </template>                                                                        
                                </v-select>
                              </div>
                              <div style="width:20px;" v-if="hasComments(skill, item)">
                                <v-badge
                                  color="pink"
                                  offset-x="7px"
                                  offset-y="7px"
                                  dot
                                >
                                  <v-icon class="outlined" @click="loadComments(skill,item)">chat_bubble_outline</v-icon>
                                </v-badge>
                              </div>
                              <div style="float: right;right: 0px;position: relative;">
                              
                                <v-menu
                                  bottom
                                  attach
                                  right
                                  min-width="250px"
                                  :nudge-bottom="25"
                                  :nudge-right="25"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon>mdi-dots-horizontal</v-icon>
                                    </v-btn>
                                  </template>

                                  <v-list dense>
                                    <v-list-item v-if="canEdit" @click="deleteItem(skill, item)">
                                      <v-list-item-icon class="mr-3">
                                        <v-icon>mdi-delete</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>Remove Mapping</v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="loadDescriptors(skill)">
                                      <v-list-item-icon class="mr-3">
                                        <v-icon>mdi-magnify</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>View Skill Descriptors</v-list-item-content>
                                    </v-list-item>                                        
                                    <v-list-item @click="loadComments(skill, item)">
                                      <v-list-item-icon class="mr-3">
                                        <v-icon>mdi-comment-quote-outline</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>Comment</v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                            </div>
                            <div v-else>
                              <div @click="assignSkill(skill, item)" class="addSkill" style="color:#AAAAAA">
                                <v-icon class="mr-1" color="#AAAAAA">mdi-plus</v-icon> Add Skill
                              </div>
                            </div>
                          </td>         
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td class="locked-column">No Skills - Click To Extract</td>
                          <td :colspan="skillManagerState.filteredHierarchies.length"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </v-col>
              <!--<v-col cols="1">
                <div>Core Category</div>
                  <div>
                    <div class="mr-4" v-for="item in skillManagerState.frameworkGradingType.values.slice().reverse()" :key="item.tag_grading_type_value_id" style="display:inline-flex; align-items:center; padding-top:3px">
                      <v-checkbox v-model="skillManagerState.selectedGradingTypes" :value="item.tag_grading_type_value_id" small dense></v-checkbox>
                      <div :style="{ 'width':'15px', 'height':'15px', 'border-radius':'50%', 'border':'2px solid grey', 'background-color': item.colour}"></div>
                      <div style="padding-top:3px; padding-left:5px">{{ item.name }}</div>
                    </div>
                  </div>
              </v-col>-->
            </v-row>    
          </div>
          <div v-if="skillManagerState.activeView === 'skill_priority'">
            <v-row dense>
              <v-col cols="4" style="display:inline-flex">
                <h1>Prioritisation of Skills</h1>
                <v-chip
                  v-if="skillManagerState.currentReviewGroup"
                  class="mx-1 status-chip chipFont"
                  :color="skillManagerState.currentReviewGroup.status_color"
                  label
                  style="top: 5px; left:25px;"
                >
                  {{ skillManagerState.currentReviewGroup.status }}
                </v-chip>                
              </v-col>
              <v-col cols="3">
              </v-col>
              <v-col v-if="skillManagerState.currentReviewGroup && skillManagerState.currentReviewGroup?.status === skillManagerState.statuses[0]" cols="5" style="display: flex; justify-content: end; padding-top: 10px;">
                <v-btn small class="ml-2" @click="getAllSkills">Extract</v-btn>
                <v-btn small class="mx-2" @click="skillManagerState.uploadContentDialog.show = true">Upload Content</v-btn>
                <v-btn small class="mx-2" @click="initReviewGroup()">Create Review Group</v-btn>
              </v-col>               
              <v-col v-if="skillManagerState.currentReviewGroup && skillManagerState.currentReviewGroup?.status !== skillManagerState.statuses[0]" cols="5" style="display: flex; justify-content: end; padding-top: 10px;">
                <v-btn v-if="skillManagerState.user_role !== 'User' && skillManagerState.currentReviewGroup.status !== skillManagerState.statuses[0]" small class="mx-2" @click="updateReviewGroupStatus(skillManagerState.statuses[0])">Move Back To {{ skillManagerState.statuses[0] }}</v-btn>
                <v-btn small class="mx-2" @click="updateReviewGroupStatus(skillManagerState.statuses[1])">Save As Draft</v-btn>
                <v-btn small class="mx-2" @click="updateReviewGroupStatus(skillManagerState.statuses[2])">Share For Review</v-btn>
                <v-btn small class="mx-2" @click="updateReviewGroupStatus(skillManagerState.statuses[3])">Approve</v-btn>
                <v-btn v-if="skillManagerState.currentReviewGroup.status === skillManagerState.statuses[3]" small class="mx-2" @click="extractAllSKills('apply')">Publish</v-btn>
              </v-col>
            </v-row>
            <v-row dense v-if="!skillManagerState.adminCurrentHierarchy">
              <v-col>
                <h2>Select A Job Family To Begin</h2>
              </v-col>
            </v-row>
            <v-row dense style="height:78px;">
              <v-col cols="2">
                Job Family<br/>
                <v-autocomplete @change="toggleNodeView($event)" v-model="skillManagerState.adminCurrentHierarchy_id" :items="skillManagerState.adminHierarchyView" dense outlined hide-details style="z-index: 10" item-text="name" item-value="node_id" return-object>
                  <template v-slot:item="data">
                    {{ data.item.name }}                   
                    <v-chip
                      class="mx-1 status-chip chipFont"
                      label
                      :color="data.item.status_color"
                      v-if="data.item.status"
                      x-small
                      style="top: 0px; left:15px;"
                    >
                      {{ data.item.status }}
                    </v-chip>
                  </template>
                  <template v-slot:selection="data">
                    <span>{{ data.item.name }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                Job Profile<br/>
                <v-select dense outlined :items="['All']" value="All" :z-index="21"></v-select>
              </v-col>
              <v-col cols="2">
                Business Area<br/>
                <v-select dense outlined :items="['All']" value="All" :z-index="21"></v-select>
              </v-col>
              <v-col dense cols="2">
                Skill Type<br/>
                <v-select v-model="skillManagerState.selectedClassifierTypes" :items="skillManagerState.frameworkClassifierType.values" multiple dense outlined hide-details style="z-index: 10" item-text="value" item-value="tag_classifier_type_value_id">
                  <template v-slot:selection="{ item, index }">
                    {{ getSkillTypeFilterText(item, index) }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4" style="padding-left:15px">
                <div style="display: flex; align-items: center;">
                  <span class="mr-2">Core Category</span>
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="#aaa"
                      size="18px"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>Core Category</span>
                </v-tooltip>
                </div>
                  <div>
                    <div class="mr-4" v-for="item in skillManagerState.frameworkGradingType.values.slice().reverse()" :key="item.tag_grading_type_value_id" style="display:inline-flex; align-items:center; padding-top:6px">
                      <div class="legend" :style="{'background-color': item.colour}"></div>
                      <div style="padding-left:5px">{{ item.name }}</div>
                    </div>
                    <div class="mr-4" style="display:inline-flex; align-items:center; padding-top:3px">
                      <div class="legend" :style="{'background-color': '#ffffff'}"></div>
                      <div style="padding-left:5px">Unassigned</div>
                    </div>  
                  </div>
              </v-col>              
              <v-col v-if="1 === 0" cols="1" style="display: flex; justify-content: flex-end; align-items: center; align-content: center; flex-wrap: wrap;">
                <v-btn small class="mx-2" @click="extractAllSKills('extract')">Extract</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="skillManagerState.currentReviewGroup" dense>
              <v-col cols="12" class="pt-0">
                <div class="widthWrapper">
                  <div class="widthScroller">
                    <table class="table table-bordered prioritisationTable2" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr>
                        <th v-for="(item, gIdx) in skillManagerState.reviewGroupLinks" :key="`${gIdx}_${item.framework_review_link_id}`">
                          <div class="hdrLabel" :title="item.name">
                            <span>{{ item.name }}</span>  
                          </div>  
                        </th>                   
                      </tr>
                      <tr>
                        <th class="hdrDrop" v-for="(item, gIdx) in skillManagerState.reviewGroupLinks" :key="`${gIdx}_${item.framework_review_link_id}`">
                          <draggable
                            tag="div"
                            :disabled="!canEdit"
                            :list="item.data"
                            :group="`group`"
                            handle=".handle"
                            class="dragZoneHdr"
                            @change="setPriorities($event, item)"
                            min-height="200px"
                            >
                              <div v-for="(d, dIdx) in filterPriorityList(item)" :key="`${gIdx}_${dIdx}`" class="priorityItemHolder" >
                                <div class="rank">
                                  <span v-if="d.priority && d.state === 1">{{ d.priority }}</span>
                                  <span v-if="d.state === -1"><v-icon color="red">mdi-exclamation</v-icon></span>
                                </div>
                                <div class="dragSkill" :style="{ 'background-color':d.colour}">
                                  <div v-if="canEdit" class="handle">
                                <v-icon>drag_indicator</v-icon>
                              </div>
                              <div :class="['value', d.state === -1 ? 'remove' : '' ]">
                                <span style="padding-bottom:8px">{{ d.name }}</span><br/>
                                  <v-chip v-if="d.type" small label class="chipFont" style="padding:8px; margin-top: 8px;">
                                  {{d.type}}
                                  </v-chip></div>
                              <div class="actions">
                                <div style="width:20px;">
                                  <v-badge
                                    v-if="d.comments?.length > 0"
                                    color="pink"
                                    offset-x="7px"
                                    offset-y="7px"
                                    dot
                                  >
                                    <v-icon class="outlined" @click="loadComments(d, item)">chat_bubble_outline</v-icon>
                                  </v-badge>
                                </div>
                                <v-menu
                                  bottom
                                  absolute
                                  ref="priorityContextMenu"
                                  right
                                  v-model="d.edit"
                                  :close-on-content-click="true"
                                  min-width="250px"
                                  height="300px"
                                  :value="d.edit"
                                  :nudge-bottom="10"
                                  :nudge-right="10" tile
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="#AAAAAA" @click="d.edit = true;">mdi-dots-horizontal</v-icon>
                                  </template>

                                  <v-list tile>

                                    <v-menu :close-on-content-click="true" right offset-x
                                      :nudge-top="9"
                                      :nudge-right="0" 
                                      max-width="250px"  tile
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-list-item v-if="canEdit" v-on="on">
                                          <v-list-item-content>
                                            <v-list-item-title>Set Core Category</v-list-item-title>
                                          </v-list-item-content>
                                          <v-list-item-icon>
                                            <v-icon>mdi-chevron-right</v-icon>
                                          </v-list-item-icon>                                          
                                        </v-list-item>

                                      </template>
                                      <v-card class="flatTile">
                                        <v-card-text>
                                          <v-radio-group v-model="d.tag_grading_type_value_id">
                                            <v-radio
                                              v-for="type in skillManagerState.frameworkGradingType.values.slice().reverse()"
                                              :key="type.tag_grading_type_value_id"
                                              :label="type.name"
                                              :value="type.tag_grading_type_value_id"
                                              class="pt-0 pb-2 pr-2"
                                              @click="confirmCoreCategoryUpdate(type.tag_grading_type_value_id, d, item)"
                                            ></v-radio>
                                          </v-radio-group>
                                        </v-card-text>
                                      </v-card>
                                    </v-menu>                                      
                                    <v-list-item @click="loadDescriptors(d)">
                                      <v-list-item-content>
                                        <v-list-item-title>View Skill Descriptors</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item @click="loadComments(d, item)">
                                      <v-list-item-content>
                                        <v-list-item-title>Comment</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="d.state === 1" @click="updateItemState(-1, d)">
                                      <v-list-item-content>
                                        <v-list-item-title>Mark For Removal</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="canEdit && d.state === -1" @click="updateItemState(1, d)">
                                      <v-list-item-content>
                                        <v-list-item-title>Re-enable</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="canEdit" @click="updateItemState(0, d)">
                                      <v-list-item-content>
                                        <v-list-item-title>Delete</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!--<v-list-item v-if="canEdit" @click="removeFromPriorityList(d, item, 'remove')">
                                      <v-list-item-content>
                                        <v-list-item-title>Mark For Removal</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item> 
                                    <v-list-item v-if="canEdit" @click="removeFromPriorityList(d, item, 'core')">
                                      <v-list-item-content>
                                        <v-list-item-title>Move to Core Skills</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>-->
                                  </v-list>
                                </v-menu>
                              </div>
                                </div>


                            </div>
                          </draggable>
                        </th>
                      </tr>
                      <tr>
                        <th v-for="(item, gIdx) in skillManagerState.reviewGroupLinks" :key="`${gIdx}_${item.framework_review_link_id}`" class="roundedTh">
                          <div class="addSkillsLbl" :title="item.name">
                            <v-btn small class="mx-2" @click="addSkillsToGroup(item)" style="width:100%">
                              Add Skills
                            </v-btn>
                          </div>
                        </th> 
                      </tr>
                    </thead>
                  </table> 
                  <div v-if="1 === 0 && skillManagerState.currentReviewGroup" style="display:inline-flex;border-bottom: 1px solid #ddd; width:100%; position:relative; padding-top: 15px;">
                    <div :class="[ 'priorityNavTab', skillManagerState.prioritizationMode === 'core' ? 'prioritizationModeActive' : '']" @click="skillManagerState.prioritizationMode = 'core'">
                      <span class="mr-1">Skill Picker</span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="#aaa"
                          >
                            mdi-information
                          </v-icon>
                        </template>
                        <span>Core Skills by Level</span>
                      </v-tooltip>
                    </div>
                    <div :class="[ 'priorityNavTab', skillManagerState.prioritizationMode === 'recommend' ? 'prioritizationModeActive' : '']" @click="skillManagerState.prioritizationMode = 'recommend'">
                      <span class="mr-1">Recommended Skills</span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="#aaa"
                          >
                            mdi-information
                          </v-icon>
                        </template>
                        <span>Recommended Skills</span>
                      </v-tooltip>
                    </div>
                    <div :class="[ 'priorityNavTab', skillManagerState.prioritizationMode === 'remove' ? 'prioritizationModeActive' : '']" @click="skillManagerState.prioritizationMode = 'remove'">
                      <span class="mr-1">Marked For Removal</span>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="#aaa"
                          >
                            mdi-information
                          </v-icon>
                        </template>
                        <span>Marked For Removal</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <div v-if="1 === 0" class="verticalWrapper">
                    <div class="verticalScroller">
                      <table class="table table-bordered prioritisationTable2 subTable" cellpadding="0" cellspacing="0" style="width:100%">
                        <tbody>
                          <tr>
                            <td>
                              <v-row dense>
                                <draggable
                                  :disabled="!canEdit"
                                  :list="skillManagerState.data"
                                  :group="{ name: 'group', pull: 'clone', put: false }"
                                  :class="['dropZoneHdr', skillManagerState.prioritizationMode === 'core' ? 'available' : '', skillManagerState.prioritizationMode === 'recommend' ? 'recommended' : '', skillManagerState.prioritizationMode === 'remove' ? 'remove' : '']"
                                  handle=".handle"
                                  >
                                  <div
                                    v-for="(d, dIdx) in skillManagerState.data" 
                                    :key="`skill_${dIdx}`"
                                    class="dragSkill" :style="{ 'background-color':d.color}"
                                  >
                                    <div v-if="canEdit" class="handle">
                                      <v-icon>drag_indicator</v-icon>
                                    </div>                                  
                                    <div :class="['value', skillManagerState.prioritizationMode === 'remove' ? 'remove' : '' ]">
                                      {{ d.name }}
                                      <br/>
                                      <v-chip v-if="d.type" small label class="chipFont">
                                      {{d.type}}
                                      </v-chip>
                                    </div>
                                  </div>
                                    <!--<div v-for="(d, dIdx) in getActivePrioritizationList(item)" :key="`${idx}_${dIdx}`" class="dragSkill" :style="{ 'background-color':d.color}"  >
                                    <div v-if="canEdit" class="handle">
                                      <v-icon>drag_indicator</v-icon>
                                    </div>
                                    <div :class="['value', skillManagerState.prioritizationMode === 'remove' ? 'remove' : '' ]">{{ d.value }}</div>
                                    <div class="actions">
                                      <v-icon color="#AAAAAA">mdi-dots-horizontal</v-icon>
                                    </div>
                                  </div>-->
                                </draggable>
                              </v-row>
                            </td>

                          </tr>
                        </tbody>
                      </table> 
                    </div>
                  </div>   
                </div>
              </v-col>
            </v-row>              
          </div>
          <div v-if="skillManagerState.activeView === 'job_hierarchy'">
            <v-row dense>
              <v-col><h1>Job Hierarchy Distribution</h1></v-col>
            </v-row>   
          </div>
        </div>
      </v-card-text> 
    </div>
    <Notification :notification="skillManagerState.notification" />
    <v-dialog v-model="skillManagerState.commentsDialog.show" max-width="1200px" scrollable>
      <v-card>
        <v-card-title class="d-flex align-center">
          Comments
        </v-card-title>
        <v-card-text style="max-height:300px">
          <v-timeline
            align-top
            dense
          >
            <v-timeline-item v-for="(comment, cIdx) in skillManagerState.commentsDialog.data" :key="cIdx"
              :color="comment.background_colour" fill-dot :small="comment.level > 1"
            >
            <template v-slot:icon>
              <span :style="{ 'padding-top':'1px','padding-left':'1px', 'color': comment.text_colour }">{{comment.userInitials}}</span>
            </template>
              <v-row :class="[ comment.level === 1 ? 'pt-2' : '' ]">
                <v-col cols="3">
                  <strong>{{comment.name}}</strong> @ {{comment.comment_timestamp}}
                </v-col>
                <v-col cols="8">
                  <div :style="{ 'padding-left': comment.level === 0 ? '0px' : `${comment.level * 50}px`}">
                    {{comment.comment_text}}
                  </div>
                </v-col>
                <v-col cols="1">
                  <v-icon @click="skillManagerState.commentsDialog.newComment.parent = comment">reply</v-icon>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions v-if="skillManagerState.commentsDialog.newComment.parent">
          <v-row dense>
            <v-col cols="2" align="center">Replying To:</v-col>
            <v-col cols="9"><i>{{ skillManagerState.commentsDialog.newComment.parent.comment_text }}</i></v-col>
            <v-col cols="1"><v-icon @click="skillManagerState.commentsDialog.newComment.parent = null">close</v-icon></v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row dense style="display: inline-flex; align-items: baseline;">
            <v-col cols="2" align="center">Add A Comment:</v-col>
            <v-col cols="9"><v-text-field v-model="skillManagerState.commentsDialog.newComment.comment_text" dense outlined></v-text-field></v-col>
            <v-col cols="1"><v-btn @click="submitComment">Submit</v-btn></v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="skillManagerState.commentsDialog.show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="skillManagerState.extractSkillsDialog.show" max-width="1200px" scrollable>
      <v-card>
        <v-card-title v-if="skillManagerState.extractSkillsDialog.action === 'extract'" class="d-flex align-center">
          Extract Skills:
        </v-card-title>
        <v-card-title v-if="skillManagerState.extractSkillsDialog.action === 'apply'" class="d-flex align-center">
          Apply Skills:
        </v-card-title>
        <v-card-text>

          <v-btn small class="pa-3 mb-4" @click="checkAllExtract">Check All</v-btn>
          <v-expansion-panels accordion multiple v-model="skillManagerState.extractSkillsDialog.visible">
            <v-expansion-panel
              v-for="(g,gIdx) in skillManagerState.extractSkillsDialog.data" :key="gIdx"
            >
              <v-expansion-panel-header><div style="padding-left:15px">{{ g.name }}</div></v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="flow-font-small" v-for="d in g.docs" :key="d.doc_id">
                  <v-col cols="6">
                    <div style="display:flex; align-items: center; align-items: baseline;">
                      <v-checkbox v-model="skillManagerState.extractSkillsDialog.selected" 
                      :value="d.doc_id" 
                      :disabled="d.skills_extracted && skillManagerState.extractSkillsDialog.action === 'extract'"
                    ></v-checkbox>{{ d.system_number }} - {{ d.doc_name }}</div>
                  </v-col>
                  <v-col cols="2">
                    <v-chip
                      small
                      class="mx-1 status-chip chipFont"
                      label
                    >
                      {{ d.doc_type }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <v-chip
                      small
                      class="mx-1 status-chip chipFont"
                      label
                    >
                      {{ d.doc_status_text }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <div style="font-style:italic; color:green; padding-left:10px" v-if="d.skills_extracted && skillManagerState.extractSkillsDialog.action === 'extract'">Skills extracted</div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-4">
          <v-progress-circular
            :size="30"
            color="primary"
            indeterminate
            class="mr-2" v-if="skillManagerState.extractSkillsDialog.loading || skillManagerState.saving"
          ></v-progress-circular>
          <v-btn @click="skillManagerState.extractSkillsDialog.show = false">Cancel</v-btn>
          <v-btn v-if="skillManagerState.extractSkillsDialog.action === 'extract'" :disabled="skillManagerState.extractSkillsDialog.selected.length === 0 || skillManagerState.extractSkillsDialog.loading" color="primary" @click="getSkillsForDocs">Extract Skills</v-btn>
          <v-btn v-if="skillManagerState.extractSkillsDialog.action === 'apply'" 
          :disabled="skillManagerState.extractSkillsDialog.selected.length === 0 || skillManagerState.extractSkillsDialog.loading || skillManagerState.saving" 
          color="primary" @click="applySkillsToDocs">Apply Skills</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
    <v-dialog v-model="skillManagerState.createReviewGroupDialog.show" max-width="1500px" persistent scrollable>
      <v-card>
        <v-card-title class="d-flex align-center" >
          <div style="text-align: center; width:100%">{{ skillManagerState.createReviewGroupDialog.mode === 'create' ? 'Create' : 'Edit'}} Review Group</div>
        </v-card-title>
        <v-card-text>
          <div class="mb-3"><h2>Name your Group</h2></div>
                <div class="d-flex align-center mb-5">
                  <div class="mr-3">Give the group a name</div>
                  <div class="ml-3" style="width:500px">
                    <v-text-field dense  v-model="skillManagerState.createReviewGroupDialog.data.name" outlined hide-details label="Group name"></v-text-field>
                  </div>
                </div>
                <div class="mb-4"><h2>Choose Your Members</h2></div>
                <div class="d-flex align-center mb-5">
                  <div class="mr-3">Choose the members of this new group.</div>
                </div>
                <div class="d-flex align-center mb-5">
                  <div class="mr-3">Search Users</div>
                  <div class="ml-3" style="width:500px">
                    <v-text-field dense :value="skillManagerState.createReviewGroupDialog.data.search" v-model="skillManagerState.createReviewGroupDialog.data.search" outlined hide-details label="Search..." @click:clear="skillManagerState.createReviewGroupDialog.data.search = ''" clearable></v-text-field>
                  </div>
                </div>
                <div class="d-flex align-center mb-5">
                  <v-row dense>
                    <v-col cols="6">
                      <div style="max-height:300px; overflow: hidden scroll">

                        <v-list subheader>
                          <v-list-item
                            v-for="(user, uIdx) in filteredUserList"
                            :key="user.user_id"
                            :class="{'odd': uIdx % 2 === 0, 'even': uIdx % 2 !== 0}"
                          >
                        <v-avatar class="secondary-background" :color="$loginState.impersonating ? 'success darken-2' : ''" size="32">
                          <span class="body-2">{{ user.userInitials }}</span>
                        </v-avatar>

                          <v-list-item-content class="ml-3">
                            <v-list-item-title>{{ user.name }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-icon>
                            <v-checkbox :value="skillManagerState.createReviewGroupDialog.data.users.indexOf(user) > -1" @change="toggleUser(user)"></v-checkbox>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-chip v-for="(user, uIdx) in skillManagerState.createReviewGroupDialog.data.users"
                        class="ma-2 chipFont"
                        :key="uIdx"
                        close
                        color="grey"
                        label
                        text-color="white"
                        @click:close="toggleUser(user)"
                      >
                        {{user.name}}
                      </v-chip>
                    </v-col>
                  </v-row>
                </div>          
          <!--<v-stepper color="success" v-model="skillManagerState.createReviewGroupDialog.step" flat class="elevation-0">
            <v-stepper-header style="box-shadow: none !important">
              <v-stepper-step
                :complete="skillManagerState.createReviewGroupDialog.step > 1"
                step="1"
              >
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="skillManagerState.createReviewGroupDialog.step > 2"
                step="2"
              >
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <div class="mb-3"><h2>Name your Group</h2></div>
                <div class="d-flex align-center mb-5">
                  <div class="mr-3">Give the group a name</div>
                  <div class="ml-3" style="width:500px">
                    <v-text-field dense  v-model="skillManagerState.createReviewGroupDialog.data.name" outlined hide-details label="Group name"></v-text-field>
                  </div>
                </div>
                <div class="mb-4"><h2>Choose Your Members</h2></div>
                <div class="d-flex align-center mb-5">
                  <div class="mr-3">Choose the members of this new group.</div>
                </div>
                <div class="d-flex align-center mb-5">
                  <div class="mr-3">Search Users</div>
                  <div class="ml-3" style="width:500px">
                    <v-text-field dense :value="skillManagerState.createReviewGroupDialog.data.search" v-model="skillManagerState.createReviewGroupDialog.data.search" outlined hide-details label="Search..." @click:clear="skillManagerState.createReviewGroupDialog.data.search = ''" clearable></v-text-field>
                  </div>
                </div>
                <div class="d-flex align-center mb-5">
                  <v-row dense>
                    <v-col cols="6">
                      <div style="max-height:300px; overflow: hidden scroll">

                        <v-list subheader>
                          <v-list-item
                            v-for="(user, uIdx) in filteredUserList"
                            :key="user.user_id"
                            :class="{'odd': uIdx % 2 === 0, 'even': uIdx % 2 !== 0}"
                          >
                        <v-avatar class="secondary-background" :color="$loginState.impersonating ? 'success darken-2' : ''" size="32">
                          <span class="body-2">{{ user.userInitials }}</span>
                        </v-avatar>

                          <v-list-item-content class="ml-3">
                            <v-list-item-title>{{ user.name }}</v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-icon>
                            <v-checkbox :value="skillManagerState.createReviewGroupDialog.data.users.indexOf(user) > -1" @change="toggleUser(user)"></v-checkbox>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <v-chip v-for="(user, uIdx) in skillManagerState.createReviewGroupDialog.data.users"
                        class="ma-2"
                        :key="uIdx"
                        close
                        color="grey"
                        label
                        text-color="white"
                        @click:close="toggleUser(user)"
                      >
                        {{user.name}}
                      </v-chip>
                    </v-col>
                  </v-row>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <div class="mb-2 mt-2"><h1>Choose Job Families</h1></div>
                <div class="mb-2 mt-2">Choose which Job Families this group can review. </div>
                <v-treeview :items="skillManagerState.activeHierarchy" item-key="node_id" :key="rerenderTreeKey" return-object>
                  <template v-slot:label="{ item }">
                    <div @click="setActiveItem(item)" :style="{'color': item.active && !item.framework_review_id ? '#000000' : '#9f9b9b', 'background-color': skillManagerState.createReviewGroupDialog.data.hr_id?.node_id === item.node_id ? '#00cf3354' : ''}">
                      {{ item.name }} <span v-if="item.status" style="font-style: italic; font-size: 13px;" class="ml-3">{{ item.status }}</span>
                    </div>

                  </template>
                </v-treeview>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-row dense>
                  <v-col cols="5">
                    <div class="mb-2 mt-2"><h2>Select Job Titles</h2></div>
                  </v-col>
                  <v-col cols="7">
                    <div class="mb-2 mt-2"><h2>Enter Grouping Name</h2></div>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="5">
                    <div style="padding-top:15px">
                      <v-treeview item-disabled="disabled" selectable :items="skillManagerState.nodeHrs" v-model="skillManagerState.newHrGroup.hrs" item-key="node_id" return-object></v-treeview>
                    </div>
                  </v-col>
                  <v-col cols="7">
                    <div class="ml-3" style="width: 500px; display: inline-flex; justify-content: center; flex-wrap: nowrap; align-items: baseline;">
                      <v-text-field v-model="skillManagerState.newHrGroup.name" style="width:300px" class="mr-4" dense></v-text-field>
                      <v-btn :disabled="skillManagerState.newHrGroup.hrs.length === 0" small @click="createGroup">Create Hierarchy Group</v-btn><br/>
                    </div>
                    <table class="table table-bordered" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr v-for="(g, gIdx) in skillManagerState.reviewGroupLinks" :key="`${gIdx}_${g.framework_review_link_id}`" style="border:1px solid black">
                          <td style="position: relative; vertical-align: top; padding-bottom:15px">
                            <div class="headerDisplay">
                              {{ g.name }}
                            </div>
                          </td>
                          <td style="position: relative; vertical-align: top; padding-bottom:15px; padding-top: 3px;">
                            <div v-for="(h, hIdx) in g.hrs" :key="`${gIdx}_${g.framework_review_link_id}_${hIdx}`">{{h.name}}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>-->
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-4">
          <v-btn text color="success" class="mr-3" @click="resetCreateDialog()">Cancel</v-btn>
          <v-btn class="ml-3" color="success" v-if="skillManagerState.createReviewGroupDialog.mode === 'edit'" @click="updateReviewGroup(skillManagerState.createReviewGroupDialog.data)">Update & Close</v-btn>
          <!--<div v-if="skillManagerState.createReviewGroupDialog.step === 1">
            <v-btn class="ml-3" color="success" @click="skillManagerState.createReviewGroupDialog.step = skillManagerState.createReviewGroupDialog.mode === 'create' ? 2 : 3">Next</v-btn>
          </div>
          <div v-if="skillManagerState.createReviewGroupDialog.step === 2 && skillManagerState.createReviewGroupDialog.mode === 'create'">
            <v-btn class="ml-3" text color="success" @click="skillManagerState.createReviewGroupDialog.step = 1">Back</v-btn>
            <v-btn class="ml-3" color="success" :disabled="skillManagerState.createReviewGroupDialog.data.hr_id === null" @click="createReviewGroup()">Next</v-btn>
          </div>
          <div v-if="skillManagerState.createReviewGroupDialog.step === 3">
            <v-btn class="ml-3" text color="success" @click="skillManagerState.createReviewGroupDialog.step = skillManagerState.createReviewGroupDialog.mode === 'create' ? 2 : 1">Back</v-btn>
            <v-btn class="ml-3" color="success" v-if="skillManagerState.createReviewGroupDialog.mode === 'create'" @click="resetCreateDialog()">Finish & Close</v-btn>
            <v-btn class="ml-3" color="success" v-if="skillManagerState.createReviewGroupDialog.mode === 'edit'" @click="updateReviewGroup(skillManagerState.createReviewGroupDialog.data)">Update & Close</v-btn>
            <v-btn class="ml-3" color="success" v-if="skillManagerState.createReviewGroupDialog.mode === 'create'" @click="loadReviewGroup(skillManagerState.createReviewGroupDialog.data.hr_id.framework_review_id)">Continue & Edit</v-btn>
          </div>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="skillManagerState.addSkillsDialog.show" v-if="skillManagerState.addSkillsDialog.show" max-width="1200px" scrollable>
      <v-card>
        <v-card-title style="display: table-row !important;">
          <v-row dense>
            <v-col cols="2">
              <b>Add skills to:</b>
            </v-col>
            <v-col>
              <div v-for="hr in skillManagerState.addSkillsDialog.target?.hrs" :key="hr.hr_id">
                {{ hr.hierarchy_full_name }}<br/>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="skillManagerState.adminCurrentHierarchy" dense>
            <v-col>
              <div style="display: flex; align-items: center; font-size:14px; font-weight: 400;">
                <span class="mr-2">Core Category</span>
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="#aaa"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>Core Category</span>
                </v-tooltip>
                </div>              
                <div v-if="skillManagerState.adminCurrentHierarchy && skillManagerState.frameworkGradingType" style=" font-size:14px; font-weight: 400;">
                  <div class="mr-4" v-for="item in skillManagerState.frameworkGradingType.values.slice().reverse()" :key="item.tag_grading_type_value_id" style="display:inline-flex; align-items:center; padding-top:6px">
                    <div class="legend" :style="{'background-color': item.colour}"></div>
                    <div style="padding-left:5px">{{ item.name }}</div>
                  </div>
                  <div class="mr-4" style="display:inline-flex; align-items:center; padding-top:3px">
                    <div class="legend" :style="{'background-color': '#ffffff'}"></div>
                    <div style="padding-left:5px">Unassigned</div>
                  </div>  
                </div>
            </v-col>
          </v-row> 
          <v-row dense>
            <v-col cols="3">
              <v-select label="Skill Type" v-model="skillManagerState.addSkillsDialog.filter_opts" :items="skillManagerState.frameworkClassifierType.values" multiple dense outlined hide-details style="z-index: 10" item-text="value" item-value="tag_classifier_type_value_id">
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field clearable v-model="skillManagerState.addSkillsDialog.filter_text" placeholder="Search..." hide-details outlined dense></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-if="skillManagerState.adminCurrentHierarchy && skillManagerState.frameworkGradingType" style="padding-top: 5px; overflow: hidden;">
          <div v-if="skillManagerState.addSkillsDialog.loading">
            Loading.... Please Wait!
          </div>
          <div v-else-if="skillManagerState.addSkillsDialog.data.length ===0"  style="padding-top:10px">
            No More Skills To Show
          </div>
          <v-row v-else dense style="border: 1px solid #ccc; border-radius: 8px; max-height: 600px; overflow: scroll;">
            <v-col cols="6" v-for="item in addSkillFilteredList" :key="item.tag_value_id">
              <div style="display: flex;align-content: center;align-items: center; border-radius: 8px; padding-left: 8px; height: 46px; border:1px solid #ccc;" :style="{'background-color': item.color}">
                <div style="width:30px; margin-bottom:6px">
                  <v-checkbox :key="item.id"
                  v-model="skillManagerState.addSkillsDialog.selected"
                  v-if="!item.exists"
                  :label="item.name"
                  :value="item"
                  ></v-checkbox>
                </div>
                <div style="width:30px">
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="#aaa"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span>{{item.description}}</span>
                </v-tooltip>
                </div>                
                <div>{{ item.value }}</div>
                <div>
                  <v-chip v-if="item.type"
                    class="ma-2 pl-3 chipFont" small label
                  >
                    {{item.type}}
                  </v-chip>
                </div>
                <div v-if="skillManagerState.addSkillsDialog.selected.includes(item) || item.exists">
                  <v-select dense v-model="item.tag_rating_id" :value="item.tag_rating_id" style="width:110px; top:7px" :items="skillManagerState.selectedSkillTag.ratings" item-value="tag_type_rating_id" item-text="name">
                    <template v-slot:selection="{ item }">
                      <span
                        class="grey--text text-caption"
                        style="width:100px; text-align: center;"
                      >
                      {{ item.name  }}
                      </span>
                    </template>    
                    <template v-slot:label>
                      <span
                        class="grey--text text-caption"
                        style="width:100px; text-align: center;"
                      >
                      {{ item.tag_rating_id ? '' : 'Set proficiency' }} 
                      </span>
                    </template>                                                                        
                  </v-select>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="1 === 0">
          <div v-if="skillManagerState.addSkillsDialog.loading">
            Loading.... Please Wait!
          </div>
          <div v-else-if="skillManagerState.addSkillsDialog.data.length ===0">
            No More Skills To Show
          </div>
          <div v-else>
            <v-autocomplete multiple :items="skillManagerState.addSkillsDialog.data" v-model="skillManagerState.addSkillsDialog.selected" return-object item-text="name" item-value="id"></v-autocomplete>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-4">
          <v-btn @click="skillManagerState.addSkillsDialog.show = false">Cancel</v-btn>
          <v-btn :disabled="skillManagerState.addSkillsDialog.selected.length === 0" color="primary" @click="confirmAddSkill">Add Skills</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
    <v-dialog v-model="skillManagerState.descriptorViewDialog.show" max-width="1500px" scrollable>
      <v-card>
        <v-card-title class="d-flex align-center">
          View Descriptors: {{ skillManagerState.descriptorViewDialog.skill?.value }}
        </v-card-title>
        <v-card-text>
          <br/>
          Description:<br/>
          {{ skillManagerState.descriptorViewDialog.skill?.description }}<br/><br/>
          Proficiency Descriptors:<br/>
          <v-data-table
            :headers="[{ text: 'Proficiency Level', value: 'name' },{ text: 'Descriptor', value: 'description' }]"
            :items="skillManagerState.descriptorViewDialog.data"
            class="elevation-1"
            hide-default-footer
          ></v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-4">
          <v-btn @click="skillManagerState.descriptorViewDialog.show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 
    <v-dialog v-model="skillManagerState.uploadContentDialog.show" max-width="1500px" scrollable>
      <v-card>
        <v-card-title class="d-flex align-center">
          Upload Content
        </v-card-title>
        <v-card-text>
          <v-file-input v-model="skillManagerState.uploadFile" label="File input"></v-file-input> <v-btn :disabled="skillManagerState.uploadContentDialog.loading" @click="processUpload">Process....</v-btn>
          <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          v-if="skillManagerState.uploadContentDialog.loading"
          style="top: 40%; left: 48%;"
        ></v-progress-circular>
        </v-card-text>
        <v-card-actions class="d-flex justify-end px-6 pb-4">
          <v-btn @click="skillManagerState.uploadContentDialog.show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>      
  </v-card>  
</template>
<style lang="scss" scoped>

$skillColumnWidth: 400px;


.skillsWorkspace { 
  width: 100%;
  height: calc(100vh - 160px);
  max-height: calc(100vh - 160px); 
  background: #fefefe;  
  margin: 40px 0px 0px 0px;
}

.skillsWorkspaceHolder{ 
  border-radius:24px;
  padding: 32px 40px 32px 40px;
  gap: 32px;

  .noBorder {
    box-shadow:none;
  }
}

.headerIcon{
  position:relative;
  z-index:200
}

.addSkill {
  color:#AAAAAA;
  font-size:12px;
  cursor:pointer;
  text-align:start;
  display: flex;
  align-items: center;  
}

.addRating{
  color:green;
  font-size:12px;
}

.headerWrapper{
  width: 98%;
  height: calc(100vh - 160px);
  max-height: calc(100vh - 160px);
  overflow: scroll;
}

.subWrapper {
  background-color: green;
  width: 100%;
  overflow: hidden scroll;
  min-height: calc(100vh - 275px);
}

.prioritisation-wrapper {
  height: calc(100vh - 290px);
  max-height: calc(100vh - 290px);
  position: relative;
  width: 100%;
  overflow: hidden;
}

.distribution-wrapper {
  height: calc(100vh - 460px);
  max-height: calc(100vh - 460px);
  position: relative;
  width: 100%;
  overflow: hidden;
}

.table-scroll {
    overflow: auto;
    max-height: 100%;
    min-height: 100%;
    position: relative;
}

.distributionTable {
  width: 100%;
  border-collapse: collapse;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;   

  thead tr th {
    position: sticky;
    top: -1px;
    z-index: 1;
    border: 1px solid #EEEEEE;
    padding: 8px;
    min-width: 200px;
    max-width: 200px;
    max-height: 20px;
    height: 46px;
    line-height: 0;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0px 10px !important;
    background-color: #EEEEEE;
    color: black;
    z-index: 8;

    .headerText {
      max-width: 95%;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow-x: clip;
      padding: 3px 0px 2px 15px;
      text-align: center;
    }

    .headerIcon {
      float: right;
      right: 10px;    
      top: -8px;
    }
  }

  tr {
    width:100%;    
    height: 46px;
    td:not(.locked-column) {

      height: 46px;
      text-align:center;

      .fixed {
        min-width: 250px;
        max-width: 250px;    
        text-align:center;
      }

      background-color: white;
      min-width: 200px;
      max-width: 200px;
      word-wrap: break-word;
      border: 1px solid #ccc;
      padding: 0px 8px 0px 15px !important;
    }
    
  }

  .menuClass {
    z-index:25;
  }

  .locked-column {
  position: sticky;
  left: -1px;
  background-color: #fff;
  z-index: 2;
  border: 1px solid #ccc;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  height: 46px;
  min-width: 400px;
  max-width: 400px;
  padding-left: 25px;
  z-index:9;    
  padding: 0px 8px 0px 25px !important;
}

.locked-col-text {
  display: inline-flex;
  width: 85%;
  text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    overflow-x: clip;  
}

.intersect-column{
  position: sticky;
  top: -1px;
  left: -1px;
  z-index: 3;
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE;
  padding: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  color: #000;
  padding-left: 25px;  
  text-align: left;
  z-index:10;
}
}

.prioritisationTable {

  max-width:100%;
  border-spacing:10px;
  border-collapse:collapse;

  thead tr th {
    position: sticky;
    top: 0px;
    z-index: 1;
    min-width: 260px;
    max-width: 260px;
    max-height: 30px;
    height: 30px;
    line-height: 0;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #ffffff;
    color: black;
    z-index: 8;
    padding-bottom: 5px;
    padding-right: 10px;

    &.hdrDrop {
      top: 35px !important;
      max-height: 200px;
    }

    .headerText {
      max-width: 85%;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow-x: clip;
      padding: 15px 0px 2px 15px;
      text-align: center;
    }

    .headerIcon {
      float: right;
      right: 10px;
      top: -8px;
    }
  }

  tr {
    width:100%;    
    height: 30px;
    td:not(.locked-column) {
      height: 30px;
      text-align:center;

      .fixed {
        min-width: 250px;
        max-width: 250px;    
        text-align:center;
      }

      background-color: white;
      min-width: 300px;
      max-width: 300px;
      max-height: 30px;
      word-wrap: break-word;
      vertical-align: top;    
      padding: 0px 10px;
    }
    
  }
}

.groups{
  width: 100%;
  border-collapse: collapse;
}

.groups td, .groups th {
  border: 1px solid #ddd;
  padding: 8px;
}

.groups tr:nth-child(even){background-color: #f2f2f2;}

//.groups tr:hover {background-color: #ddd;}

.groups th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #949494;
  color: white;
}

.dragSkill{
  min-height: 40px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    left: -1px;
    background-color: #fff;
    z-index: 2;
    border: 1px solid #ccc;
    padding: 5px 0px 5px 0px;
    border-radius: 10px;    
    margin-top: 3px;
    max-width: 330px;
    margin-right: 3px;

    .handle {
      width: 25px;
      cursor: grab;
      display: inline-flex;
      border-radius: 5px;
      padding-left: 3px;

      span {
        padding-left: 12px;
        padding-top: 12px;
        font-family: DM Sans;
        font-size: 12px;
      }
    }

    .value {
      width: 90%;
      text-align: left;
      padding-left: 8px;
      font-family: DM Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 20.83px;
      letter-spacing: 0.5px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .value.remove {
      text-decoration: line-through;
    }

    .actions {
      width: 40px;
      display: inline-flex;
      justify-content: center;
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      align-content: center;
      align-items: baseline;
    }
}

.headerDisplay {
  top: -1px; 
  z-index: 1; 
  border: 1px solid #ccc; 
  padding: 8px;
  min-width: 260px;
  max-width: 260px;
  max-height: 30px;
  height: 30px;
  line-height: 0;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  background-color: #ccc;
  color: black;
  border-radius: 5px;
  align-content: center;
  vertical-align: top;
  position: relative;
  top: 0px;  
}


.dragZoneHdr{
  min-height: calc(100vh - 243px);
  max-height: calc(100vh - 243px);
  overflow: hidden scroll;
  background-color: #F7F9FB;
  //border-radius: 0px 0px 16px 16px;
  padding-top: 8px;
  margin-left: 1px;
  margin-right: 1px;
}

.dragZoneHdr:empty {
  color: #888;
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px;
  align-content: center;
  height: 100%;
  overflow:hidden;
  z-index: 6;
}

.dragZoneHdr:empty::before {
  content: "No Prioritised Skills To Display";
  color: #888;
}

.dropZoneHdr{
  height: 100%;
  background-color: #F7F9FB;
  border-radius: 0px 0px 8px 8px;
  padding: 0px 5px 0px 5px;  
}

.dropZoneHdr:empty {
  color: #888;
  border: 2px dashed #ccc;
  text-align: center;
  padding: 20px;
  align-content: center;
  height: 95%;
  overflow: hidden;
  margin-top: 2px;
}

.dropZoneHdr.available:empty::before {
  content: "No Available Items";
  color: #888;
}

.dropZoneHdr.recommended:empty::before {
  content: "No Recommended Items";
  color: #888;
}

.dropZoneHdr.remove:empty::before {
  content: "No Items To Remove";
  color: #888;
}

.legend {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.hdrLabel {
  padding: 25px 0px 0px 0px;
  border-radius: 16px 16px 0px 0px;
  background-color: #EEEEEE;
  height: 50px;
  span {
    width: 330px;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    overflow-x: clip;
    display: inline-block;
    align-content: center;
    font-size:14px;
    text-transform:uppercase;
  }
}

.addSkillsLbl{
  height: 52px;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 0px;
    border: 1px solid #EEEEEE;
    border-radius: 0px 0px 16px 16px;
    padding-right: 20px;
}

.v-menu__content {
  z-index: 1000 !important;
}

.navPill{
  width: 300px;
  height: 40px;
  border-radius: 24px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 20px 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: DM Serif Display;
  font-size: 20px;
  font-weight: 400;
  line-height: 27.42px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;

}

.skillContentHolder{
  border-radius:24px;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .08);
  padding: 32px 40px 0px 40px;
  margin-right: 40px;
  max-height: calc(100vh + 60px);
  min-height: calc(100vh - 290px);
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width:100%;
  padding-bottom:40px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-right: 20px;
}

.content {
  width: 421px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .08);
  background-color: #fff;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #50B3CB;
  color: #fff;
  border-color: #50B3CB;
  box-shadow: 0 2px 5px rgba(0, 98, 128, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.steptitle {
  color: #000000;
  padding-top: 3px;
  padding-left: 12px;
}

.steptitleLink{
  color: #48A401;
  text-decoration:underline;
}

.line {
  height: 2px;
  background-color: #ccc;
  flex-grow: 1;
  z-index: 0;
}

.stepblue {
  border: 4px solid #50B3CB;
}

.stepyellow {
  border: 4px solid #FFCB00;
}

.steppink{
  border: 4px solid #EA3785;
}

.steplime{
  border: 4px solid #CEDC26;
}

.active .content {


  border-radius: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, .08);
}


.line.active {
  background-color: #50B3CB;
}

.rank {
  color: #AAAAAA;
  font-size:12px;
  display:flex; 
  align-items: center;
  justify-content: center;
  width: 25px;
  padding-top: 5px;  
}

.priorityItemHolder {
  display:inline-flex;
  width:100%;
}








@import "@/assets/styles/vars";


.link-container {
  position: relative;
}

.edit-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.steps .edit-btn {
  top: -20px;
  right: -20px;
}

.add-btn {
  position: absolute;
  bottom: 0;
  right: -35px;
  z-index: 1;
}

.draggable {
  cursor: move;
}

.help-card {
  border-radius: 10px !important;
  min-width: 200px;
  .titlerow {
    min-height: 100px !important;
    .col {
      display: flex;
      align-items: center;
      padding: 29px;
    }
    h2 {
      font-size: 26px;
      line-height: normal;
      white-space: pre-wrap;
      word-break: normal;
    }
    p {
      margin-top: 10px;
      margin-bottom: 0;
      color: green;
      text-decoration: underline;
      font-size: 16px;
    }
  }
  .v-icon {
    opacity: 0.8;
  }
  &.theme--dark.hover {
    background-color: $secondary-background-dark;
  }
}

.v-card {
  border-radius: 20px !important;
}

.tile-color-orange {
  background-color: #fff5cc;
}

.tile-color-green {
  background-color: #F5F8D4;
}

.tile-color-blue {
  background-color: #EEF7F9;
}

.widthWrapper {
    max-width: 100%;
    overflow: scroll hidden;
    max-height: calc(100vh - 124px);
    height: calc(100vh - 124px);
}

.widthScroller {
  width: max-content;
  overflow: hidden;
  height: 100%;
}

/*.widthWrapper{
  max-width:100%;
  overflow: scroll hidden;
  max-height: calc(100vh - 124px);
  min-height: calc(100vh - 124px);
}

.widthScroller {
  width: max-content;
  overflow: hidden;
}*/

.verticalWrapper {
  /*max-width:100%;
  width:100%;
  overflow: scroll scroll;
  max-height: calc(100vh - 450px);
  min-height: calc(100vh - 450px);
  position:relative;
  display: inline;*/
}

.verticalScroller {
  width: 100%;
  overflow: scroll scroll;
  max-height: calc(100vh - 560px);
  min-height: calc(100vh - 560px);
}

.verticalScroller::-webkit-scrollbar {
  position: absolute;
  width: 10px;
  right: -10px; /* Move scrollbar outside */
}

.prioritisationTable2 {
  max-width:100%;
  border-spacing:10px;
  border-collapse:collapse;
  height:100%;

  thead tr th {
    top: 0px;
    z-index: 1;
    min-width: 350px;
    max-width: 350px;
    max-height: 30px;
    height: 30px;
    line-height: 0;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #ffffff;
    color: black;
    z-index: 9;
    padding-right: 10px;

    &.hdrDrop {
      top: 35px !important;
      max-height: 200px;
      border: 1px solid #F7F9FB;
    }

    .headerText {
      max-width: 85%;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow-x: clip;
      padding: 15px 0px 2px 15px;
      text-align: center;
    }

    .headerIcon {
      float: right;
      right: 10px;
      top: -8px;
    }
  }

  tbody {
    border: 1px solid #ddd;
    border-top: none;
  }

  tr {
    width:100%;    
    height: 30px;
    td {
      height: 30px;
      text-align:center;

      .fixed {
        min-width: 250px;
        max-width: 250px;    
        text-align:center;
      }

      background-color: white;
      min-width: 260px;
      max-width: 260px;
      max-height: 30px;
      word-wrap: break-word;
      vertical-align: top;    
      padding: 0px 10px 0px 0px;
    }
    
  }
}

/*.prioritisationTable2 {
  max-width:100%;
  border-spacing:10px;
  border-collapse:collapse;

  thead tr th {
    top: 0px;
    z-index: 1;
    min-width: 260px;
    max-width: 260px;
    max-height: 30px;
    height: 30px;
    line-height: 0;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #ffffff;
    color: black;
    z-index: 9;
    padding-right: 10px;

    &.hdrDrop {
      top: 35px !important;
      max-height: 200px;
    }

    .headerText {
      max-width: 85%;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow-x: clip;
      padding: 15px 0px 2px 15px;
      text-align: center;
    }

    .headerIcon {
      float: right;
      right: 10px;
      top: -8px;
    }
  }

  tbody {
    border: 1px solid #ddd;
    border-top: none;
  }

  tr {
    width:100%;    
    height: 30px;
    td {
      height: 30px;
      text-align:center;

      .fixed {
        min-width: 250px;
        max-width: 250px;    
        text-align:center;
      }

      background-color: white;
      min-width: 260px;
      max-width: 260px;
      max-height: 30px;
      word-wrap: break-word;
      vertical-align: top;    
      padding: 0px 10px 0px 0px;
    }
    
  }
}*/

.subTable{
  thead {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;

    tr th {
      padding-top: 1px;
    }
  }
}

.distribution-wrapper-admin{
  height: calc(100vh - 125px);
  max-height: calc(100vh - 125px);
  position: relative;
  width: 100%;
  overflow: hidden;
}

.odd {
    background-color: #FFFFFF;
  }

  .even {
    background-color: #F7F9FB;
  }
::v-deep{

  .v-select__selections{
    position:absolute;
  }

  th, tr, td, .v-input, span, div, strong {
    font-family: 'DM Sans', sans-serif  !important;
  }
}

.priorityNavTab {
  cursor: pointer;
  width: 240px;
  height: 56px;
  padding: 16px 20px 16px 20px;
  border-radius: 16px 16px 0px 0px;
  border: 1px solid #DDD;
  background-color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border-bottom: none;
  margin-right:8px;
  align-content: center;
}

.prioritizationModeActive {
  background-color: #fff !important;
  color: #48A401;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  position: relative;
  top: 1px;  
}

.chipFont{
  font-size: 12px;
}

.flatTile{
  border-radius:0px !important;
}
</style>