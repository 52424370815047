<template>
  <v-card>
    <v-card-title>
      <h4>
        {{ title }}
        <v-menu
          v-model="settings.show"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" title="Settings" class="ml-4"
              >mdi-cog-outline</v-icon
            >
          </template>
          <v-card>
            <v-card-title>Settings</v-card-title>
            <v-divider></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  ><v-select
                    label="View"
                    v-model="settings.view"
                    :items="settings.viewOptions"
                  ></v-select
                ></v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-switch
                    v-model="settings.ratingBar"
                    color="purple"
                    label="Proficiency as Bar"
                  ></v-switch>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-switch
                    v-model="settings.status"
                    color="purple"
                    label="Show Document Status"
                  ></v-switch>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action> Column Width</v-list-item-action>
                <v-list-item-content>
                  <v-slider
                    dense
                    hide-details
                    v-model="settings.cellWidth"
                    :tick-labels="settings.cellWidthLabels"
                    :min="1"
                    :max="3"
                    step="1"
                    ticks="always"
                    tick-size="4"
                  ></v-slider>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelSettings"> Cancel </v-btn>
              <v-btn color="primary" text @click="saveSettings"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </h4>
      <v-spacer></v-spacer
      ><v-icon v-if="withinDialog" @click="$emit('close')">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-container>
        <template
          v-if="
            (!documentIDs || documentIDs.length === 0) && !hierarchy_node_id
          "
        >
          <v-row
            ><v-col
              >Select a Job Profile and see all of it's Job titles, and their
              respective skills, in different Business Areas.</v-col
            ></v-row
          >
          <v-row
            ><v-col v-for="(h, hi) in hierarchyTypes" :key="'ht' + hi" cols="3">
              {{ h.label }}<br />
              <HierarchyTreePicker
                v-model="h.treePickerValue"
                :options="h.treePickerOptionsActive"
                placeholder="Please select"
                :hierarchyType="h"
                dense
                @change="onHrySelect(h, ...arguments)"
                @lostFocus="h.lostFocus = true"
                clearable
              >
              </HierarchyTreePicker></v-col
          ></v-row>
          <v-row
            v-if="
              tagTypes.length === 0 &&
              hierarchyTypes.some((h) => h.treePickerValue)
            "
            ><v-col
              ><h3>No documents found for selected criteria</h3></v-col
            ></v-row
          >
          <!-- <v-row v-for="(n, ni) in filteredNodes" :key="'n'+ ni">
            <v-col>{{ n.label }}</v-col>
            <v-col>{{ n.levelName }}</v-col>
            <v-col>{{ n.name }}</v-col>
          </v-row> -->
        </template>
        <template v-if="displayView === 'common'">
          <v-row>
            <v-col cols="2"
              ><h3 v-if="tagTypes.length === 1">
                {{ tagTypes[0].tag_type_name }}
              </h3></v-col
            >
            <v-col
              v-for="(t, ti) in templates"
              :key="'t_' + ti"
              style="overflow: auto; margin-left: 10px"
            >
              <v-row
                ><v-col
                  ><h4>
                    {{ t.tmpl_name }}{{ t.documents.length > 1 ? "s" : "" }} ({{
                      t.documents.length
                    }})
                  </h4></v-col
                ></v-row
              >
              <v-row
                class="flex-nowrap"
                style="overflow: hidden"
                :ref="'headerContainer' + ti"
              >
                <v-col
                  :class="'document-cell cell-header-' + cellWidth"
                  v-for="(d, di) in t.documents"
                  :key="'t_' + ti + 'dc1_' + di"
                  @click="openDocumentSummary($event, d)"
                  :title="d.reference"
                >
                  {{ d.doc_name
                  }}<DocStatus
                    v-if="showStatus"
                    :doc_id="d.doc_id"
                    style="margin-left: 10px"
                  ></DocStatus
                ></v-col>
              </v-row>
            </v-col>
            <v-col cols="2"></v-col>
          </v-row>
          <v-row v-for="(tt, tti) in tagTypes" :key="'tt_' + tti">
            <v-col
              cols="2"
              :style="{ height: availableHeightCommon, overflow: 'hidden' }"
              ref="rowContainer"
              @mousewheel="headerScroll"
            >
              <v-row v-if="tagTypes.length > 1" dense
                ><v-col class="section-header"
                  ><h3>{{ tt.tag_type_name }}</h3></v-col
                ></v-row
              >
              <v-row
                ><v-col class="cell">{{
                  tt.tags.length === 0
                    ? "No " + tt.tag_type_name + " assigned"
                    : ""
                }}</v-col></v-row
              >
              <v-row
                v-for="(ta, tai) in tt.tags"
                :key="'tt_' + tti + 'ta' + tai"
              >
                <v-col class="cell"
                  >{{ ta.value }}
                  <v-tooltip right v-if="ta.description">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon small v-bind="attrs" v-on="on"
                        >mdi-information</v-icon
                      ></template
                    >
                    <span>{{ ta.description }}</span>
                  </v-tooltip></v-col
                ></v-row
              >
            </v-col>
            <v-col
              :style="{
                height: availableHeightCommon,
                overflow: 'auto',
                marginLeft: '10px',
              }"
              v-for="(t, ti) in templates"
              :key="'t_' + ti"
              :ref="'scrollContainer' + ti"
              @scroll="setScroll('scrollContainer', ti)"
            >
              <v-row v-if="tagTypes.length > 1" dense
                ><v-col class="section-header"><h3></h3></v-col
              ></v-row>
              <v-row class="flex-nowrap">
                <v-col
                  :class="'pl-2 cell-' + cellWidth"
                  v-for="(d, di) in t.documents"
                  :key="'tt' + tti + 't' + ti + 'd' + di"
                >
                  <div
                    v-for="(dpa, dpai) in d.docPartAtts.filter(
                      (x) => x.hasEditPermission
                    )"
                    :key="'tt' + tti + 't' + ti + 'd' + di + 'dpa' + dpai"
                    style="cursor: pointer"
                    @click="addAttributeTag(dpa, null, d)"
                  >
                    Add {{ dpa.tpa_title }}
                    <v-icon color="grey lighten-2" title="Add to document"
                      >mdi-plus</v-icon
                    >
                  </div></v-col
                ></v-row
              >
              <v-row
                class="flex-nowrap"
                v-for="(ta, tai) in tt.tags"
                :key="'tt' + tti + 'ta' + tai + 't' + ti"
              >
                <v-col
                  :class="'cell-' + cellWidth"
                  style="text-align: center"
                  v-for="(d, di) in t.documents"
                  :key="'tt' + tti + 'ta' + tai + 't' + ti + 'd' + di"
                >
                  <div
                    v-if="d.commonTagTypes[tti].tags[tai].docTag"
                    style="
                      width: 100%;
                      height: 24px;
                      border: 1px solid lightgray;
                      border-radius: 5px;
                      cursor: pointer;
                    "
                    title="Click to Edit"
                    :style="{
                      backgroundColor:
                        d.commonTagTypes[tti].tags[tai].docTag.grading_colour,
                    }"
                    @click="
                      editAttributeTag(
                        d.commonTagTypes[tti].tags[tai].docTag,
                        d
                      )
                    "
                  >
                    <div class="float-left pl-2">
                      {{ d.commonTagTypes[tti].tags[tai].docTag.sequence }}
                    </div>
                    <!-- <v-icon small color="green"
                                >mdi-check-circle</v-icon
                              > -->
                    <template v-if="d.commonTagTypes[tti].maxRating">
                      <v-chip
                        v-if="
                          !showRatingBar &&
                          d.commonTagTypes[tti].tags[tai].docTag.rating_name
                        "
                        x-small
                        label
                        >{{
                          d.commonTagTypes[tti].tags[tai].docTag.rating_name
                        }}</v-chip
                      >
                      <div
                        v-if="showRatingBar"
                        style="
                          height: 24px;
                          float: right;
                          position: relative;
                          border: 3px solid transparent;
                        "
                      >
                        <div
                          v-for="i in d.commonTagTypes[tti].maxRating"
                          :key="'d' + i"
                          :style="{
                            height:
                              (i * 100) / d.commonTagTypes[tti].maxRating + '%',
                            backgroundColor:
                              d.commonTagTypes[tti].tags[tai].docTag
                                .rating_number >= i
                                ? 'green'
                                : null,
                            border:
                              d.commonTagTypes[tti].tags[tai].docTag
                                .rating_number < i
                                ? 'red solid 1px'
                                : null,
                            marginLeft: i > 1 ? '1px' : '6px',
                            top:
                              100 -
                              (i * 100) / d.commonTagTypes[tti].maxRating +
                              '%',
                          }"
                          :title="
                            d.commonTagTypes[tti].tags[tai].docTag.rating_name
                          "
                          style="width: 5px; float: left; position: relative"
                        ></div></div
                    ></template>
                  </div>
                  <template v-else>
                    <v-icon
                      color="grey lighten-2"
                      :title="'Add ' + dpa.tpa_title"
                      @click="addAttributeTag(dpa, ta, d)"
                      v-for="(dpa, dpai) in d.docPartAtts.filter(
                        (x) => x.hasEditPermission
                      )"
                      :key="'t' + ti + 'tt' + tti + 'd' + di + 'dpa' + dpai"
                      >mdi-plus</v-icon
                    ></template
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2">
              <template v-for="(gt, gti) in gradingTypes">
                <v-row dense :key="'gt' + gti">
                  <v-col
                    >{{ gt.name }}
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on"
                          >mdi-information</v-icon
                        ></template
                      >
                      <span>{{ gt.description }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(c, ci) in gt.values"
                  :key="'gt' + gti + 'cs' + ci"
                  dense
                >
                  <v-col style="height: 30px">
                    <template>
                      <svg
                        style="width: 20px; height: 25px"
                        :key="'cv' + ci"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="8"
                          :fill="c.colour"
                          stroke="gray"
                          strokeWidth="2"
                        /></svg
                      ><span
                        style="
                          font-size: 14px;
                          padding: 0 15px 0 7px;
                          vertical-align: top;
                        "
                        >{{ c.name }}</span
                      >
                    </template></v-col
                  >
                </v-row>
              </template>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col
              v-for="(t, ti) in templates"
              :key="'t_' + ti"
              :style="ti > 0 ? 'padding-left: 25px;' : ''"
              :cols="t.templateCols"
              ><v-row
                ><v-col
                  ><h4>
                    {{ t.tmpl_name }}{{ t.documents.length > 1 ? "s" : "" }} ({{
                      t.documents.length
                    }})
                  </h4></v-col
                ></v-row
              >
              <v-row class="flex-nowrap" style="overflow: auto">
                <!-- :cols="t.documentCols" -->
                <v-col
                  v-for="(d, di) in t.documents"
                  :key="'t_' + ti + 'd_' + di"
                  :class="'col-header-' + cellWidth"
                >
                  <v-row
                    ><v-col
                      @click="openDocumentSummary($event, d)"
                      class="document-cell"
                      :title="d.reference"
                      >{{ d.doc_name }}
                      <DocStatus
                        v-if="showStatus"
                        :doc_id="d.doc_id"
                        style="margin-left: 10px"
                      ></DocStatus></v-col
                  ></v-row>
                  <v-row>
                    <v-col
                      :style="{
                        maxHeight: availableHeight,
                        minHeight: availableHeight,
                        overflow: 'auto',
                        borderRight: 'solid #e1e1e1 1px',
                      }"
                    >
                      <template v-for="(dpa, dpai) in d.docPartAtts">
                        <v-row
                          dense
                          v-for="(ta, tai) in dpa.tags"
                          :key="
                            't_' + ti + 'd_' + di + 'dpa' + dpai + 'ta' + tai
                          "
                        >
                          <v-col
                            :style="{
                              backgroundColor: ta.grading_colour,
                              cursor: dpa.hasEditPermission ? 'pointer' : '',
                            }"
                            @click="
                              dpa.hasEditPermission
                                ? editAttributeTag(ta, d)
                                : null
                            "
                          >
                            <template v-if="showRatingBar && dpa.maxRating">
                              <div
                                :style="{
                                  float: 'left',
                                  width:
                                    'calc(100% - ' + dpa.maxRating * 9 + 'px)',
                                }"
                              >
                                {{ ta.value }}
                              </div>
                              <div
                                :style="{ width: dpa.maxRating * 9 + 'px' }"
                                style="
                                  height: 24px;
                                  float: right;
                                  position: relative;
                                  border: 3px solid transparent;
                                "
                              >
                                <div
                                  v-for="i in dpa.maxRating"
                                  :key="'d' + i"
                                  :style="{
                                    height: (i * 100) / dpa.maxRating + '%',
                                    backgroundColor:
                                      ta.rating_number >= i ? 'green' : null,
                                    border:
                                      ta.rating_number < i
                                        ? 'red solid 1px'
                                        : null,
                                    marginLeft: i > 1 ? '1px' : '6px',
                                    top: 100 - (i * 100) / dpa.maxRating + '%',
                                  }"
                                  :title="ta.rating_name"
                                  style="
                                    width: 5px;
                                    float: left;
                                    position: relative;
                                  "
                                ></div></div
                            ></template>
                            <template v-else
                              >{{ ta.value }}
                              <v-chip
                                v-if="!showRatingBar && ta.rating_name"
                                small
                                label
                                ><v-tooltip right v-if="ta.rating_description">
                                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">{{
                                      ta.rating_name
                                    }}</span></template
                                  >
                                  <span>{{
                                    ta.rating_description
                                  }}</span> </v-tooltip
                                ><span v-else>{{
                                  ta.rating_name
                                }}</span></v-chip
                              ></template
                            ></v-col
                          >
                        </v-row></template
                      >
                      <v-row
                        v-for="(dpa, dpai) in d.docPartAtts.filter(
                          (x) => x.hasEditPermission
                        )"
                        :key="'t_' + ti + 'd_' + di + 'dpa' + dpai"
                        ><v-col
                          style="cursor: pointer"
                          @click="addAttributeTag(dpa, null, d)"
                          >Add {{ dpa.tpa_title }}
                          <v-icon color="grey lighten-2" title="Add to document"
                            >mdi-plus</v-icon
                          ></v-col
                        ></v-row
                      >
                    </v-col>
                  </v-row></v-col
                ></v-row
              >
            </v-col>
            <v-col cols="2">
              <template v-for="(gt, gti) in gradingTypes">
                <v-row dense :key="'gt' + gti">
                  <v-col
                    >{{ gt.name }}
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on"
                          >mdi-information</v-icon
                        ></template
                      >
                      <span>{{ gt.description }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(c, ci) in gt.values"
                  :key="'gt' + gti + 'cs' + ci"
                  dense
                >
                  <v-col style="height: 30px">
                    <template>
                      <svg
                        style="width: 20px; height: 25px"
                        :key="'cv' + ci"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="10"
                          cy="10"
                          r="8"
                          :fill="c.colour"
                          stroke="gray"
                          strokeWidth="2"
                        /></svg
                      ><span
                        style="
                          font-size: 14px;
                          padding: 0 15px 0 7px;
                          vertical-align: top;
                        "
                        >{{ c.name }}</span
                      >
                    </template></v-col
                  >
                </v-row></template
              >
            </v-col>
          </v-row></template
        >
      </v-container>
    </v-card-text>
    <DocSummary
      :doc_id="documentSummary.doc_id"
      :trigger="documentSummary.trigger"
      @openDocument="$emit('openDocument', $event)"
      @copyDocument="$emit('copyDocument', $event)"
    ></DocSummary>
    <!-- <TagValueEdit
      :tag="tagValueForEdit"
      :show="editTagTrigger"
      @saved="tagSaved"
      @cancel="cancelEditTag"
    ></TagValueEdit> -->
    <TagValueAttribute
      :item="tagForEdit"
      @saved="attributeTagSaved"
      @removed="attributeTagRemoved"
      @cancel="cancelEditTag"
    ></TagValueAttribute>
  </v-card>
</template>
<script>
import axios from "axios";
// import ResponseHandler from "@/components/ResponseHandler";
// import { mapState } from "vuex";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import DocStatus from "@/components/common/DocStatus";
import DocSummary from "@/components/common/DocViewPopUp";
// import TagValueEdit from "@/components/document/TagValueEdit";
import TagValueAttribute from "@/components/document/TagValueAttribute";
import { mapState } from "vuex";
import utils from "@/components/document/view/utils.js";

export default {
  name: "TagCompare",
  components: {
    // ResponseHandler,
    HierarchyTreePicker,
    DocStatus,
    DocSummary,
    // TagValueEdit,
    TagValueAttribute,
  },
  props: {
    documentIDs: { type: Array, required: false },
    hierarchy_node_id: { type: Number, required: false },
    tag_type_id: { type: Number, required: false },
    withinDialog: { type: Boolean, required: false },
  },
  data: function () {
    return {
      templates: [],
      documents: [],
      tagTypes: [],
      gradingTypes: [],
      page: 0,
      subject: "",
      title: "",
      colSize: 2,
      response: null,
      hierarchyTypes: [],
      filteredNodes: [],
      documentSummary: {
        trigger: null,
        doc_id: null,
      },
      showRatingBar: false,
      showStatus: false,
      cellWidth: 2,
      settings: {
        show: false,
        view: null,
        viewOptions: [
          { value: "individual", text: "Individual Items" },
          { value: "common", text: "Common Items" },
        ],
        ratingBar: false,
        status: false,
        cellWidth: 2,
        cellWidthLabels: ["Narrow", "Medium", "Wide"],
      },
      displayView: "individual",
      docCount: 0,
      availableHeight: 0,
      availableHeightCommon: 0,
      tagForEdit: null,
    };
  },
  watch: {
    // show(val) {
    //   if (val) {
    //     let values = this.attribute.values;
    //     this.unclassified = false;
    //     if (!values.length && this.rawValues.length) {
    //       values = this.rawValues;
    //       this.unclassified = true;
    //     }
    //     values = JSON.parse(JSON.stringify(values));
    //     values.forEach((v) => (v.editMode = false));
    //     this.values = values;
    //     this.open = true;
    //   }
    // },
  },
  //   computed: {
  //     ...mapState({
  //       isLoading: (state) => state.docs.loading,
  //     }),
  //   },
  computed: {
    ...mapState({
      tagTypeDefs: (state) => state.hierarchies.tagTypes,
      tagGradingTypes: (state) => state.hierarchies.tagGradingTypes,
      tagPermissions: (state) => state.hierarchies.tagPermissions,
    }),
  },
  created() {
    this.displayView = "individual";
    this.settings.view = "individual";
    this.settings.ratingBar = false;
    this.settings.status = false;
    this.showRatingBar = false;
    this.showStatus = false;
    if (this.documentIDs?.length) {
      this.showRatingBar = true;
      this.displayView = "common";
      this.settings.view = "common";
      this.settings.ratingBar = true;
      this.showRatingBar = true;
      this.getDocumentTagsForDocs();
    } else if (this.hierarchy_node_id) {
      this.getDocumentTagsForHierarchyNode();
    } else {
      this.setup();
    }
  },
  methods: {
    setup() {
      this.hierarchyTypes = this.$store.state.hierarchies.hierarchies.map(
        (h) => {
          const local = { ...h, lostFocus: false };
          switch (local.label.toLowerCase()) {
            case "geography":
            case "location":
              h.icon = "mdi-map-marker-outline";
              break;
            default:
              h.icon = "mdi-menu-down";
              break;
          }
          return local;
        }
      );
      if (this.hierarchyTypes.length) {
        this.hierarchyTypes.forEach((h) => (h.treePickerValue = null));
      }
      this.setTitle();
    },
    setTitle() {
      let height = window.innerHeight - (this.withinDialog ? 300 : 260);
      if ((this.documentIDs ?? []).length === 0 && !this.hierarchy_node_id) {
        this.title = "Job Hierarchy Distribution";
        height -= 130;
      } else if (this.hierarchy_node_id && this.filteredNodes.length) {
        this.title = `Skills distribution for ${this.filteredNodes[0].levelName} ${this.filteredNodes[0].name}`;
      } else if (this.documentIDs?.length) {
        this.title = "Skills distribution for selected Documents";
      }
      this.availableHeight = height + "px";
      this.availableHeightCommon = height / this.tagTypes.length + "px";
    },
    onHrySelect() {
      this.getDocumentTagsForHierarchyNode(true);
    },
    getDocumentTagsForHierarchyNode(selected) {
      let request = {
        hierarchy_node_ids: [],
        tag_type_id: this.tag_type_id,
      };
      if (this.hierarchy_node_id && !selected) {
        request.hierarchy_node_ids.push(this.hierarchy_node_id);
      } else {
        this.hierarchyTypes
          .filter((ht) => ht.treePickerValue)
          .forEach((ht) => {
            let val = ht.values.find((x) => x.value === ht.treePickerValue);
            if (val) {
              request.hierarchy_node_ids.push(
                val["hierarchy_node_id_level" + ht.linklevel]
              );
            }
          });
      }
      if (!request.hierarchy_node_ids.length) {
        this.tagTypes = [];
        this.templates = [];
      } else {
        let possibleError = false;
        axios
          .post("document/getDocumentTags/", request)
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              if (resp.data.Data) {
                if (resp.data.Data) {
                  this.filteredNodes = resp.data.Data.nodes;
                  this.prepData(resp.data.Data);
                }
              }
            }
            this.setTitle();
            this.response = resp.data;
            this.isSaving = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isSaving = false;
          });
      }
    },
    setDocCount() {
      this.docCount = this.templates.reduce(
        (p, c) => p + c.documents.length,
        0
      );
    },
    getDocumentTagsForDocs() {
      if (this.documentIDs.length) {
        let request = {
          doc_ids: this.documentIDs,
        };
        let possibleError = false;
        axios
          .post("document/getDocumentTags/", request)
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              if (resp.data.Data) {
                if (resp.data.Data) {
                  this.prepData(resp.data.Data);
                }
              }
            }
            this.setTitle();
            this.response = resp.data;
            this.isSaving = false;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
            this.isSaving = false;
          });
      }
    },
    prepData(data) {
      let templates = data.templates;
      const colsAvailable = 10;
      this.docCount = templates.reduce((p, c) => p + c.documents.length, 0);
      templates.forEach((t) => {
        t.templateCols = Math.round(
          (colsAvailable * t.documents.length) / this.docCount
        );
        if (t.templateCols <= 2)
          t.templateCols = t.documents.length > 10 ? 4 : 2;
        if (t.templateCols <= 2)
          t.templateCols = t.documents.length > 2 ? 3 : 2;
      });
      while (
        templates.reduce((p, c) => p + c.templateCols, 0) > colsAvailable
      ) {
        const maxCols = templates.reduce(
          (p, c) => (c.templateCols > p ? c.templateCols : p),
          0
        );
        templates.find((t) => t.templateCols === maxCols).templateCols--;
      }
      // templates.forEach((t) => {
      //   let docs = t.documents;
      //   if (docs.length) {
      //     t.documents = docs.map((d) => {
      //       let dSecs = d.sections;
      //       d.commonSections = data.tagTypes.map((s) => {
      //         let dSec = dSecs?.find((x) => x.tag_type_id === s.tag_type_id);
      //         let sec = {
      //           tpa_id: s.tpa_id,
      //           tag_type_id: s.tag_type_id,
      //           tag_type_name: s.tag_type_name,
      //           maxRating: s.maxRating,
      //           def: dSec,
      //           tags: s.tags.map((st) => {
      //             return {
      //               tag_value_id: st.tag_value_id,
      //               value: st.value,
      //               val: dSec
      //                 ? dSec.tags.find(
      //                     (x) => x.tag_value_id === st.tag_value_id
      //                   )
      //                 : null,
      //             };
      //           }),
      //         };
      //         return sec;
      //       });
      //       return d;
      //     });
      //   }
      // });

      this.templates = templates;
      this.tagTypes = data.tagTypes;
      this.gradingTypes = this.tagGradingTypes.filter((x) =>
        data.gradingTypes.some((g) => g === x.tag_grading_type_id)
      );
      this.subject = data.tagTypes.map((x) => x.tag_type_name).join(" / ");
    },
    openDocumentSummary(event, item) {
      this.documentSummary.doc_id = item.doc_id;
      this.documentSummary.trigger = event;
    },
    saveSettings() {
      this.displayView = this.settings.view;
      this.showRatingBar = this.settings.ratingBar;
      this.cellWidth = this.settings.cellWidth;
      this.showStatus = this.settings.status;
      this.settings.show = false;
    },
    cancelSettings() {
      this.settings.view = this.displayView;
      this.settings.ratingBar = this.showRatingBar;
      this.settings.cellWidth = this.cellWidth;
      this.settings.status = this.showStatus;
      this.settings.show = false;
    },
    setScroll(container, index) {
      let top = this.$refs[container + index][0].scrollTop;
      let left = this.$refs[container + index][0].scrollLeft;
      this.$refs.rowContainer[0].scrollTop = top;
      this.templates.forEach((t, ti) => {
        if (ti === index)
          this.$refs["headerContainer" + ti][0].scrollLeft = left;
        else this.$refs[container + ti][0].scrollTop = top;
      });
    },
    headerScroll(element) {
      let top = this.$refs.rowContainer[0].scrollTop + element.deltaY;
      const container = "scrollContainer";
      this.$refs.rowContainer[0].scrollTop = top;
      this.templates.forEach((t, ti) => {
        // if (ti === index)
        //   this.$refs["headerContainer" + ti][0].scrollLeft = left;
        // else
        this.$refs[container + ti][0].scrollTop = top;
      });
    },
    addAttributeTag(dpa, tag, document) {
      let def = JSON.parse(
        JSON.stringify(
          this.tagTypeDefs.find((x) => x.tag_type_id === dpa.tag_type_id)
        )
      );
      def.values = def.values.filter((x) => x.active);
      def.ratings = def.ratings.filter((x) => x.active);
      let grading = this.tagGradingTypes.find(
        (x) => x.tag_grading_type_id === dpa.tag_grading_type_id
      );
      this.tagForEdit = {
        showRating: dpa.tag_type_capture_rating,
        showGrading: !!dpa.tag_grading_type_id,
        gradingLabel: grading?.name || "",
        tag_value: tag
          ? def.values.find((v) => v.tag_value_id === tag.tag_value_id)
          : null,
        dpa_tag_id: null,
        subjectType: dpa.tpa_title,
        tag_type_rating_id: null,
        tag_grading_type_value_id: null,
        ratings: def.ratings,
        gradings: grading?.values || [],
        tag_values: def.values.filter(
          (v) => !dpa.tags.some((t) => t.tag_value_id === v.tag_value_id)
        ),
        document: document,
        dpa: dpa,
        original: null,
        value_predefined: !!tag,
      };
    },
    editAttributeTag(tag, document) {
      let dpa = document.docPartAtts.find((x) => x.tpa_id === tag.tpa_id);
      let def = JSON.parse(
        JSON.stringify(
          this.tagTypeDefs.find((x) => x.tag_type_id === dpa.tag_type_id)
        )
      );
      def.values = def.values.filter((x) => x.active);
      def.ratings = def.ratings.filter((x) => x.active);
      let grading = this.tagGradingTypes.find(
        (x) => x.tag_grading_type_id === dpa.tag_grading_type_id
      );
      this.tagForEdit = {
        showRating: dpa.tag_type_capture_rating,
        showGrading: !!dpa.tag_grading_type_id,
        gradingLabel: grading?.name || "",
        tag_value: def.values.find((v) => v.tag_value_id === tag.tag_value_id),
        dpa_tag_id: tag.dpa_tag_id,
        subjectType: dpa.tpa_title,
        tag_type_rating_id: tag.tag_type_rating_id,
        tag_grading_type_value_id: tag.tag_grading_type_value_id,
        ratings: def.ratings,
        gradings: grading?.values || [],
        document: document,
        dpa: dpa,
        original: tag,
      };
    },
    cancelEditTag() {
      this.tagForEdit = null;
    },
    attributeTagRemoved(tag) {
      this.attributeTagSaved(tag, false, true);
    },
    async attributeTagSaved(tag, addAnother, deletedTag) {
      let dpa = this.tagForEdit.dpa;
      let ret = {
        tag_type_id: dpa.tag_type_id,
        dpa_id: dpa.dpa_id,
        dpa_tag_id: tag.dpa_tag_id,
        tag_value_id: tag.tag_value_id,
        tag_type_rating_id: tag.tag_type_rating_id || null,
        tag_grading_type_value_id: tag.tag_grading_type_value_id || null,
        notes: tag.notes || null,
        value: "",
        sequence: this.tagForEdit.original
          ? this.tagForEdit.original.sequence
          : dpa.tags.reduce((p, c) => (c.sequence > p ? c.sequence : p), 0) + 1,
        change: "",
      };
      if (!this.tagForEdit.original) {
        ret.change = "added";
        // add_remove = true;
      } else if (
        ret.sequence !== this.tagForEdit.original.sequence ||
        ret.tag_type_rating_id !==
          this.tagForEdit.original.tag_type_rating_id ||
        ret.tag_grading_type_value_id !==
          this.tagForEdit.original.tag_grading_type_value_id
      ) {
        ret.change = "updated";
        ret.dpa_tag_id = this.tagForEdit.original.dpa_tag_id;
        ret.notes = this.tagForEdit.original.notes;
      }

      let obj = {
        text: "",
        doc_part_id: dpa.dp_id,
        doc_part_type: dpa.dp_name,
        doc_id: this.tagForEdit.document.doc_id,
        system_number: this.tagForEdit.document.reference,
        tmpl_part_id: dpa.tp_id,
        notes: "",
        parent_dp_id: null,
        // is_essential: false,
        // quality: part.quality,
        // locked: part.locked,
        sequence: 1,
        attributes: [
          {
            tpa_id: dpa.tpa_id,
            tag_type_id: dpa.tag_type_id,
            text: "",
            dpa_id: dpa.dpa_id,
            currentTags: !deletedTag ? [ret] : [],
            deletedTags: deletedTag ? [tag] : [], //[{ dpa_tag_id: d.dpa_tag_id }],
          },
        ],
      };
      this.tagForEdit.original = null;
      let result = await utils.updateDocPart(obj);
      if (result.result.Status === "OK") {
        const sortTags = (a, b) =>
          a.value.toLowerCase() > b.value.toLowerCase()
            ? 1
            : a.value.toLowerCase() < b.value.toLowerCase()
            ? -1
            : 0;
        let item = result.result.Data.tagPart.attributes
          .find((a) => a.tpa_id === dpa.tpa_id)
          .tags.find((x) => x.tag_value_id === tag.tag_value_id);
        let tagType = this.tagTypes.find(
          (x) => x.tag_type_id === dpa.tag_type_id
        );
        if (
          item &&
          !tagType.tags.some((x) => x.tag_value_id === item.tag_value_id)
        ) {
          tagType.tags.push(item);
          tagType.tags.sort(sortTags);
          this.templates.forEach((t) => {
            t.documents.forEach((d) => {
              let dctt = d.commonTagTypes.find(
                (cs) => cs.tag_type_id === item.tag_type_id
              );
              dctt.tags.push({
                tag_value_id: item.tag_value_id,
                value: item.value,
                docTag:
                  this.tagForEdit.document.doc_id === d.doc_id ? item : null,
              });
              dctt.tags.sort(sortTags);
            });
          });
        } else if (item) {
          this.tagForEdit.document.commonTagTypes
            .find((cs) => cs.tag_type_id === dpa.tag_type_id)
            .tags.find((x) => x.tag_value_id === item.tag_value_id).docTag =
            item;
        } else {
          this.tagForEdit.document.commonTagTypes
            .find((cs) => cs.tag_type_id === dpa.tag_type_id)
            .tags.find((x) => x.tag_value_id === tag.tag_value_id).docTag =
            null;
        }
        let orig = dpa.tags.findIndex(
          (x) => x.tag_value_id === tag.tag_value_id
        );
        if (orig < 0 && !deletedTag) {
          dpa.tags.push(item);
          dpa.tags.sort(sortTags);
        } else if (!deletedTag) {
          dpa.tags.splice(orig, 1, item);
        } else {
          dpa.tags.splice(orig, 1);
        }
        if (addAnother) {
          this.addAttributeTag(dpa, null, this.tagForEdit.document);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/vars";
.document-cell {
  background-color: #eeeeee;
  border-right: solid #e1e1e1 1px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.cell-1,
.cell-header-1,
.col-header-1 {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}
.cell-2,
.cell-header-2,
.col-header-2 {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}
.cell-3,
.cell-header-3,
.col-header-3 {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}
.cell-1,
.cell-2,
.cell-3 {
  height: 32px;
  padding: 4px;
  border-right: solid #e1e1e1 1px;
}
.cell {
  height: 32px;
  padding: 4px;
}
.section-header {
  height: 40px;
}
/*
.cell-header-1,
.cell-header-2,
.cell-header-3 {
  height: 48px;
} */
</style>
