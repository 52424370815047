<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1 class="title">Page Permissions</h1>
      </v-col>
    </v-row>
    <v-alert
      type="warning"
      class="mt-6"
      v-if="!isLoading && (!permissionGroups || permissionGroups.length === 0)"
      >No permission groups for client - please set up in
      <router-link to="workflow">Workflow Admin</router-link></v-alert
    >
    <v-row v-else-if="!isLoading">
      <v-col>
        <v-card width="100%" class="pa-4">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="py-2"><h2>Section</h2></th>
                  <th class="py-2">
                    <h2>
                      Page
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-filter-menu
                          </v-icon>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(f, fi) in routeFilter.list"
                            :key="'f' + fi"
                            @click="filterRoutes(f)"
                          >
                            <v-list-item-title
                              ><b v-if="routeFilter.value === f">{{ f }}</b
                              ><span v-else>{{ f }}</span></v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </h2>
                  </th>
                  <th class="py-2" colspan="8">
                    <h2>Permission Groups</h2>
                  </th>
                </tr>
                <tr>
                  <th colspan="2"></th>
                  <th v-for="pg in permissionGroups" :key="pg.pg_id">
                    <v-chip small class="mb-2">{{
                      pg.permission_group_name
                    }}</v-chip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(r, ri) in routeFilter.routes" :key="'r' + ri">
                  <td>
                    {{ r.section }}
                  </td>
                  <td>
                    <v-icon v-if="r.icon" :class="r.icon">{{
                      r.icon.split(" ")[0]
                    }}</v-icon>
                    {{ r.label }}
                  </td>
                  <td v-for="pg in permissionGroups" :key="pg.pg_id">
                    <v-btn
                      icon
                      title="Disable"
                      v-if="r[`pg${pg.permission_group_id}_enabled`]"
                      @click="toggleRoute(r, pg.permission_group_id)"
                    >
                      <v-icon>mdi-check-circle-outline</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      v-else
                      title="Enable"
                      style="opacity: 0.2"
                      @click="toggleRoute(r, pg.permission_group_id)"
                    >
                      <v-icon>mdi-plus-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <Loading :isVisible="isLoading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ResponseHandler from "@/components/ResponseHandler";

export default {
  name: "vRouteAdmin",
  components: {
    ResponseHandler,
  },
  props: {},
  data: function () {
    return {
      isLoading: true,
      permissionGroups: [],
      clientRoutes: [],
      response: null,
      routeFilter: {
        list: ["All", "Client Visible", "RoleMapper Admin", "Module Pages"],
        value: "Client Visible",
        routes: [],
      },
    };
  },
  mounted() {},
  created() {
    this.init();
  },
  watch: {},
  computed: {
    ...mapState({
      newUi: (state) => state.theme.newUi,
      docsLoading: (state) => state.docs.loading,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary,
      hierarchiesLoading: (state) => state.hierarchies.loading,
      docTypes: (state) => state.hierarchies.docTypes,
    }),
  },
  methods: {
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    init() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("admin/routeAdminLoadData")
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;
            const permittedRoutes = data.permittedRoutes;
            const routes = data.routes;
            const clientSettings = this.$loginState.user.settings;
            const permissionGroups = data.permissionGroups;
            this.clientRoutes = this.$router
              .getRoutes()
              .filter(
                (r) =>
                  r.meta?.newUi && !r.meta?.disabled && !r.meta.landingPageId && !r.meta.internal
                  && (r.meta?.module ? clientSettings.find(x => x.setting === r.meta.module_setting && x.value === 'true') : true)
              )
              .map((r, ri) => {
                const route = {
                  label: r.meta?.text || r.name,
                  icon: r.meta?.icon,
                  section: r.meta?.section || "",
                  name: r.name,
                  path: r.path,
                  module: r.meta?.module || null,
                  sectionOrder: this.$router.menuSections.findIndex(
                    (s) => s.name === r.meta?.section
                  ),
                  order: ri,
                  pr_id: permittedRoutes.find((pr) => pr.route_name === r.name)
                    ?.pr_id,
                  reserved:
                    r.meta?.reserved ||
                    routes.find((pr) => pr.route_name === r.name)?.reserved,
                };
                permissionGroups.forEach((pg) => {
                  const pr = permittedRoutes.find(
                    (pr) =>
                      pr.route_name === r.name &&
                      pg.permission_group_id === pr.permission_group_id
                  );
                  route[`pg${pg.permission_group_id}_enabled`] = !!pr;
                });
                return route;
              })
              .sort((a, b) =>
                a.sectionOrder > b.sectionOrder
                  ? 1
                  : a.sectionOrder === b.sectionOrder
                  ? a.order > b.order
                    ? 1
                    : -1
                  : -1
              );
            this.permissionGroups = permissionGroups;
            this.filterRoutes("Client Visible");
          }
          this.response = resp.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    toggleRoute(route, pgId) {
      let pgprId = route[`pg${pgId}_enabled`];

      if (pgprId) {
        let possibleError = false;
        axios
          .post("admin/routeAdminRemoveRoute", {
            routeName: route.name,
            permissionGroupId: pgId,
          })
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              route[`pg${pgId}_enabled`] = false;
            }
            this.response = resp.data;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      } else {
        let possibleError = false;
        axios
          .post("admin/routeAdminAddRoute", {
            routeName: route.name,
            permissionGroupId: pgId,
          })
          .then((resp) => {
            possibleError = true;
            if (resp.data.Status === "OK") {
              route[`pg${pgId}_enabled`] = true;
            }
            this.response = resp.data;
          })
          .catch((err) => {
            if (possibleError) {
              alert("Code Error");
            } else if (err.response && err.response.status === 401) {
              this.$emit("sessionExpired", err);
            } else {
              alert(err.response ? err.response.data.message : err);
            }
            console.log(err);
          });
      }
    },
    filterRoutes(value) {
      this.routeFilter.value = value;
      switch (this.routeFilter.value) {
        case "All":
          this.routeFilter.routes.splice(0);
          this.routeFilter.routes = [...this.clientRoutes];
          break;
        case "Client Visible":
          this.routeFilter.routes.splice(0);
          this.routeFilter.routes = [
            ...this.clientRoutes.filter((x) => !x.reserved),
          ];
          break;
        case "RoleMapper Admin":
          this.routeFilter.routes.splice(0);
          this.routeFilter.routes = [
            ...this.clientRoutes.filter((x) => x.reserved),
          ];
          break;
        case "Module Pages":
          this.routeFilter.routes.splice(0);
          this.routeFilter.routes = [
            ...this.clientRoutes.filter((x) => x.reserved && x.module),
          ];
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
</style>