
<script setup>
import { ref, onBeforeMount, onUpdated, watch, reactive, getCurrentInstance, computed } from 'vue';
import { inject } from 'vue';
import SkillWorkspace from "@/components/skills/cSkillWorkspace";
import SkillAdmin from "@/components/skills/cSkillAdmin";
import AdminTags from '@/components/admin/cAdminTagsAlt.vue'
import LandingHolder from '@/views/vLandingHolder.vue';

const loginState = inject('loginState');
const store = inject('store');
const router = inject('router');

const skillManagerData = reactive({
    loading: true,
    tabs: [
      "Skills Dashboard",
      "Skills Workspace",
      "Skills Framework Admin",
      "Market Insights"
    ],
    skillTab: 0
})

function changeRoute(route){
  router.push(route);
}
</script>
<template>
  <v-card class="skillsManager" flat>
    <v-tabs class="skillsTabs" v-model="skillManagerData.skillTab" grow color="#48A401" background-color="transparent">
      <v-tab class="tab ma-0 pa-0">
        Skills Workspace
      </v-tab>
      <v-tab class="tab ma-0 pa-0">
        Skills Dashboard
      </v-tab>      
      <v-tab class="tab ma-0 pa-0">
        Skills Admin
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="skillManagerData.skillTab" style="">
      <v-tab-item class="tabItem workspace">
        <SkillWorkspace></SkillWorkspace> 
      </v-tab-item>   
      <v-tab-item class="tabItem dashboard">
        <LandingHolder config="skillsdashboard"></LandingHolder>
      </v-tab-item> 
      <v-tab-item class="tabItem admin">
        <AdminTags></AdminTags>       
      </v-tab-item> 
    </v-tabs-items>   
  </v-card>  
</template>
<style lang="scss" scoped>
.tabItem.workspace::v-deep {



  th, tr, td, .v-input, span, div {
    font-family: 'DM Sans', sans-serif  !important;
  }


  h1, h2, h3, h4, h5, h6{
    font-family: 'DM Serif Display', serif !important;
  }

  h1 {
    font-size: 28px;
    font-weight: 400;
    line-height: 38.39px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;
    padding-bottom:32px;
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 27.42px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    color: #000;
  }

  thead {
    background-color: #EEEEEE;
    border-radius: 8px;
    color: #000 !important;
  }

  thead th:first-child {
    border-top-left-radius: 8px;
  }

  thead th:last-child {
    border-top-right-radius: 8px;
  }

  tbody tr:nth-of-type(odd) {
      background-color: #FFFFFF;
  }

  tbody tr:nth-of-type(even) {
      background-color: #F7F9FB;
  }

  thead th td {
    color: #000 !important;
  }

  tbody td {
    padding: 10px;
  }

}

.skillsManager { 
  width: 100%;
  height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  padding: 0px 0px 0px 40px;
}

.skillsTabs {
  width: 50%;
  max-height: calc(100vh - 100px);
  padding: 0px 40px;
}

.tabItem.workspace {
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  overflow: scroll;
  padding: 0px 45px 0 40px;
}

.tabItem.admin {
  padding: 0px 45px 0 40px;
  overflow: scroll;
  padding-left: 5px;
}
</style>