<template>
    <div :style="{'width':'100%', 'min-height':'32px','padding': display === 'sidebyside' ? '10px 12px 0px 12px' : '','margin-bottom': display === 'sidebyside' ? '-10px' : ''}" v-if="item">
        <v-select
            v-if="item.restrict_to_lookup === true && !item.multi_select"
            dense
            style="margin-top:0px" min-height="20"
            v-model="item.text"
            :disabled="disable"
            :menu-props="{ offsetY:true }"
            single-line
            :value="item.text"
            hide-details
            color="transparent"
            item-text="text"
            item-value="value"
            :items="lookups"
            :multiple="lookup_type === 'multilookup'"
            @change="update"
        >
          <template slot="label">
            <span :style="{'width':'100%','color':'#ed8a8a','font-size': display === 'sidebysidefull' ? '11px' : ''}"><i>{{item.placeholder}}</i></span>
          </template>
          <template slot="selection" slot-scope="data">
            <span :style="{'font-size': display === 'sidebysidefull' ? '11px' : ''}" v-html="data.item.value"></span>
          </template>  
          <template slot="item" slot-scope="data">
            <span :style="{'font-size': display === 'sidebysidefull' ? '11px' : ''}">{{ data.item.text  }}</span>
          </template> 
      </v-select>    
      <v-select 
        v-if="item.multi_select"
        dense
        style="margin-top:0px" min-height="20"
        :items="lookups"
        :disabled="disable"
        single-line
        multiple
        v-model="selectedItems"
        @blur="doBlurUpdate"
        @change="selectedItemsChange"
      >
        <template slot="label">
            <span :style="{'width':'100%','color':'#ed8a8a','font-size': display === 'sidebysidefull' ? '11px' : ''}"><i>{{item.placeholder}}</i></span>
        </template>     
          <template slot="selection" slot-scope="data">
            <span :style="{'font-size': display === 'sidebysidefull' ? '10px' : ''}">{{ getSelectionText(data) }}</span>
          </template>   
        <template slot="item" slot-scope="data">
            <v-checkbox  v-model="data.attrs.inputValue"></v-checkbox><span :style="{'font-size': display === 'sidebysidefull' ? '10px' : '', 'padding-top' : '6px'}">{{ data.item.text  }}</span>
        </template> 
      </v-select>
      <v-combobox
        v-if="!item.restrict_to_lookup"
        dense
        style="margin-top:0px;font-size:11px;" min-height="20"
        single-line
        v-model="item.text"
        :disabled="disable"
        :value="item.text"
        item-text="text"
        item-value="value"
        :items="lookups"
        @change="update"
      >
      <template slot="label">
            <span :style="{'width':'100%','color':'#ed8a8a','font-size': display === 'sidebysidefull' ? '11px' : ''}"><i>{{item.placeholder}}</i></span>
          </template>
          <template slot="selection" slot-scope="data">
            <span :style="{'font-size': display === 'sidebysidefull' ? '10px' : '11px'}">{{ data.item.value }}</span>
          </template>  
          <template slot="item" slot-scope="data">
            <span :style="{'font-size': display === 'sidebysidefull' ? '10px' : '11px'}">{{ data.item.text }}</span>
          </template> 
        </v-combobox>
    </div>
</template>
    
<script>

export default {
    name: 'LookupInput',
    props: {
        value: Object,
        metadata: Object,
        disable: Boolean,
        active: Boolean,
        part: Object,
        displayMode: String,
    },  
    data: function() {
        return {
            lookup_type: "lookup",
            item: null,
            text: "",
            lookups: [],
            display: this.displayMode,
            selectedItems: [],
            selectedText: "",
            initialValue: ""
        }
    },
    components: {
    },
    watch: {
        value(val){
            this.init(val);
        },    
        displayMode(val){
            this.display = val;
        }
    },  
    mounted() {

    },
    created() {
        if(this.value){
            this.init(this.value);
        }
    },
    computed: {
    },
    methods: {
        getSelectionText(data){
            return `${data.item.value}${data.index < this.selectedItems.length - 1 ? (this.value.multi_select_separator ?? ",") : ''}`
        },
        selectedItemsChange(){
            this.selectedItems = this.lookups.filter(l => this.selectedItems.includes(l.value)).map(l => l.value);
            this.item.text = this.selectedItems.join(this.value.multi_select_separator ?? ",");
        },       
        doBlurUpdate(){
            if(this.item.text !== this.initialValue){
                this.update();
            }
        },
        update(){
            //
            this.item.isDirty = true;
            if(this.metadata.attributes.some(x => x.lookup_dependency_tpa_source === this.item.tpa_id)){
                let attrDetails = this.metadata.attributes.find(x => x.lookup_dependency_tpa_source === this.item.tpa_id);
                let sourcePart = this.part.attributes.find(x => x.tpa_id === attrDetails.tpa_id);
                if(sourcePart){
                    sourcePart.isDirty = true;
                    sourcePart.text = "";
                }
                /*
                if(sourcePart){
                    let sourcePartAttr = this.metadata.attributes.find(x => x.tpa_id === sourcePart.tpa_id);
                    console.log(sourcePartAttr);
                }*/
                this.$emit("sync", this.item, this.part);
                //this.$emit("update", this.item);
            } else {
                this.$emit("update", this.item);
            }
        },
        init(val){
            let attrData = this.metadata.attributes.find(x => x.tpa_id === val.tpa_id);
            let data
            if(val.isLookup){
                this.lookup_type = "lookup";
                this.item = val;
                let lookupData;
                if (attrData)
                    lookupData = this.metadata.lookup_list.find(x => x.key === attrData.lookup_key || x.lookup_key === attrData.lookup_key)
                else if (this.metadata.lookup_list.length)
                    lookupData = this.metadata.lookup_list[0];
                
                data = lookupData ? lookupData.children : [];

                if(val.multi_select){
                    this.lookup_type = "multilookup";
                    this.selectedItems = val.text.split(val.multi_select_separator).filter(x => x);
                    this.selectedText = val.text;
                    this.initialValue = val.text;
                }
            }
            else if(val.isDependentLookup){
                this.lookup_type = "dependent";
                this.item = val;
                let sourcePart = this.part.attributes.find(x => x.tpa_id === attrData.lookup_dependency_tpa_source);
                if(sourcePart){
                    let sourcePartAttr = this.metadata.attributes.find(x => x.tpa_id === sourcePart.tpa_id);
                    let lookupData = this.metadata.lookup_list.find(x => x.key === sourcePartAttr.lookup_key);
                    if(sourcePart.text !== ""){
                        let dependentList = lookupData.children.find(x => x.name === sourcePart.text);
                        data = dependentList.children;
                    }
                } 
                //let lookupData = this.metadata.lookup_list.find(x => x.key === attrData.lookup_key);
            }

            if (data) {
                this.lookups = data.map(l => {
                    return {
                        text: l.title !== null ? l.title : l.value,
                        value: l.value
                    } 
                }).sort((a, b) =>
                    a.text > b.text ? 1 : a.text < b.text ? -1 : 0
                );
            }
        }
    }
}
</script>
<style scoped lang="scss">
::v-deep .v-input__slot::before  { border-color: transparent !important; }

::v-deep .v-select__slot > label{
    width:100%;
}
</style>