<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn-toggle v-model="view" tile group dense>
          <v-btn v-if="currentTagType" value="tags">
            {{ this.currentTagType.tag_type_name }}
          </v-btn>
          <v-btn v-if="currentTagType && showRating" value="tagRatings">
            {{ this.currentTagTypeName }} Proficiencies
          </v-btn>
          <v-btn v-if="currentTagType && showRating" value="tagValueRatings">
            {{ this.currentTagTypeName }} Specific Proficiencies
          </v-btn><v-divider vertical></v-divider>
          <v-btn value="gradings">Gradings</v-btn>
          <v-btn value="classifiers">Classifiers</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        ><v-btn small class="float-right" @click="addTagType"
          >Add New Tag Type</v-btn
        ></v-col
      >
    </v-row>
    <v-card class="mainPanel mt-6" v-if="currentTagType">
      <v-card-text v-show="view === 'tags'">
        <v-row
          ><v-col
            ><h2>
              Manage {{ currentTagTypeName }} Descriptors

              <v-menu offset-y v-if="tagTypes.length > 1" z-index="301">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" right
                    >mdi-chevron-down</v-icon
                  >
                </template>

                <v-list dense>
                  <v-list-item
                    v-for="item in tagTypes"
                    :key="item.tag_type_name"
                    @click="setup(item)"
                  >
                    <v-list-item-title>
                      {{ item.tag_type_name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </h2></v-col
          ><v-col
            >
            <v-switch
              v-if="currentTagType"
              v-model="hideInactive"
              label="Hide Inactive"
              dense
              hide-details
            ></v-switch></v-col
          ><v-col
            ><v-btn small class="float-right" @click="addTagValue"
              >Add New {{ currentTagTypeName }}</v-btn
            ></v-col
          ></v-row
        >
        <v-row>
          <v-col>
            <rmTable
              :columns="tagValueColumns"
              :rows="tagValueData"
              :heightAdjustment="150"
              @rowClick="editTag"
              @rowAction="(action, row) => handleRowAction(action, row)"
            ></rmTable> </v-col
        ></v-row>
      </v-card-text>
      <v-card-text v-if="showRating" v-show="view === 'tagRatings'">
        <v-row
          ><v-col><h2>Manage Proficiency Descriptors</h2></v-col><v-col
            ><v-btn small @click="editTagTypeRating" class="float-right"
              >Add New Proficiency Level</v-btn
            ></v-col
          ></v-row
        >
        <v-row>
          <v-col>
            <rmTable
              :columns="tagRatingColumns"
              :rows="tagRatingData"
              :maxHeight="500"
              @rowClick="editTagTypeRating"
              @rowAction="(action, row) => handleRowAction(action, row)"
            ></rmTable>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="showRating" v-show="view === 'tagValueRatings'">
        <v-row
          ><v-col
            ><h2>
              Manage {{ currentTagTypeName }} Proficiency Descriptors
            </h2></v-col
          ><v-col
            >
            <v-switch
              v-if="currentTagType"
              v-model="hideInactive"
              label="Hide Inactive"
              dense
              hide-details
            ></v-switch
          ></v-col>
          <v-col></v-col
          ><v-col
            ><v-btn small class="float-right" @click="addTagValue"
              >Add New {{ currentTagTypeName }}</v-btn
            ></v-col
          ></v-row
        >
        <v-row>
          <v-col ref="tvrContainer">
            <rmTable
              :columns="tagValueRatingColumns"
              :rows="tagValueData"
              :heightAdjustment="150"
              container="tvrContainer"
              @rowAction="(action, row) => handleRowAction(action, row)"
              @saveCell="saveRatingDescription"
            ></rmTable> </v-col
        ></v-row>
      </v-card-text>
      <v-card-text v-show="view === 'gradings'">
        <v-row
          ><v-col
            >Gradings allow the categorisation of tags at the template part
            level. When the Grading Type is linked to the template part
            attribute, the tags applied will be grouped or coloured by the
            grading values.</v-col
          ><v-col cols="2"
            ><v-btn small class="float-right" @click="addGradingType"
              >Add New Grading</v-btn
            ></v-col
          ></v-row
        >
        <v-row v-if="currentTagGradingType"
          ><v-col
            ><h2>
              Manage {{ currentTagGradingType.name }}
              <v-menu offset-y v-if="tagGradingTypes.length > 1" z-index="301">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" right
                    >mdi-chevron-down</v-icon
                  >
                </template>

                <v-list dense>
                  <v-list-item
                    v-for="item in tagGradingTypes"
                    :key="item.name"
                    @click="setupGrading(item)"
                  >
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </h2></v-col
          ><v-col cols="2"
            ><v-btn small class="float-right" @click="editGradingValue(null)"
              >Add New {{ currentTagGradingType.name }}</v-btn
            ></v-col
          ></v-row
        >
        <v-row v-if="currentTagGradingType">
          <v-col>
            <rmTable
              :columns="gradingColumns"
              :rows="gradingData"
              :heightAdjustment="100"
              @rowClick="editGradingValue"
              @rowAction="(action, row) => handleRowAction(action, row)"
            ></rmTable> </v-col
        ></v-row>
      </v-card-text>
      <v-card-text v-show="view === 'classifiers'">
        <v-row
          ><v-col>Classifiers allow the grouping / labelling of tags.</v-col
          ><v-col cols="2"
            ><v-btn small class="float-right" @click="editClassifierType(null)"
              >Add New Classifier</v-btn
            ></v-col
          ></v-row
        >
        <v-row v-if="currentTagClassifierType"
          ><v-col
            ><h2>
              Manage {{ currentTagClassifierType.name }}
              <v-menu
                offset-y
                v-if="tagClassifierTypes.length > 1"
                z-index="301"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" right
                    >mdi-chevron-down</v-icon
                  >
                </template>

                <v-list dense>
                  <v-list-item
                    v-for="item in tagClassifierTypes"
                    :key="item.name"
                    @click="currentTagClassifierType = item"
                  >
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-icon
                small class="ml-2" :title="'Edit ' + currentTagClassifierType.name + ' Definition'"
                @click="editClassifierType(currentTagClassifierType)"
                >mdi-pencil</v-icon
              >
            </h2></v-col
          ><v-col cols="2"
            ><v-btn small class="float-right" @click="editClassifierValue(null)"
              >Add New {{ currentTagClassifierType.name }}</v-btn
            ></v-col
          ></v-row
        >
        <v-row v-if="currentTagClassifierType">
          <v-col>
            <rmTable
              :columns="classifierValueColumns"
              :rows="currentTagClassifierType.values"
              :heightAdjustment="100"
              @rowClick="editClassifierValue"
              @rowAction="(action, row) => handleRowAction(action, row)"
            ></rmTable> </v-col
        ></v-row>
      </v-card-text>
    </v-card>
    <TagValueEdit
      :tag="tagForEdit"
      :show="editTagTrigger"
      @saved="tagSaved"
      @cancel="cancelEditTag"
    ></TagValueEdit>
    <v-dialog v-model="addTagTypeDialog.show" persistent width="500">
      <v-card class="pt-5">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3>Add New Tag Type</h3>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              tagTypes.some(
                (x) =>
                  x.tag_type_name.toLowerCase() ===
                  addTagTypeDialog.tag_type_name.trim().toLowerCase()
              )
            "
          >
            <v-col cols="11">
              <v-alert type="error">Tage Type already exists</v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="addTagTypeDialog.tag_type_name"
                label="Tag Type"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="addTagTypeDialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="saveTagType"
            :disabled="
              !addTagTypeDialog.tag_type_name.trim() ||
              tagTypes.some(
                (x) =>
                  x.tag_type_name.toLowerCase() ===
                  addTagTypeDialog.tag_type_name.trim().toLowerCase()
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editTagTypeRatingDialog.show" persistent width="500">
      <v-card class="pt-5" v-if="currentTagType">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3>
                {{ editTagTypeRatingDialog.isNew ? "Add New" : "Edit" }} Tag
                Type Rating
              </h3>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              currentTagType.ratings.some(
                (x) =>
                  x.tag_type_rating_id !==
                    editTagTypeRatingDialog.tag_type_rating_id &&
                  x.name.toLowerCase() ===
                    editTagTypeRatingDialog.name.trim().toLowerCase()
              )
            "
          >
            <v-col cols="11">
              <v-alert type="error">Tage Type Rating already exists</v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editTagTypeRatingDialog.name"
                label="Rating"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editTagTypeRatingDialog.description"
                label="Description"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editTagTypeRatingDialog.sequence"
                label="Rating Number"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="editTagTypeRatingDialog.error_message">
            <v-col>
              <v-alert dense type="error"
                ><v-row
                  ><v-col class="grow">{{
                    editTagTypeRatingDialog.error_message
                  }}</v-col>
                  <v-col class="shrink"
                    ><v-btn @click="changeTagTypeRatingStatus(true)"
                      >Make Inactive Anyway</v-btn
                    ></v-col
                  ></v-row
                ></v-alert
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!editTagTypeRatingDialog.isNew"
            color="blue darken-1"
            text
            @click="changeTagTypeRatingStatus(false)"
            >{{
              editTagTypeRatingDialog.active ? "Make Inactive" : "Make Active"
            }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="editTagTypeRatingDialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="saveTagTypeRating"
            :disabled="
              !editTagTypeRatingDialog.name.trim() ||
              currentTagType.ratings.some(
                (x) =>
                  x.tag_type_rating_id !==
                    editTagTypeRatingDialog.tag_type_rating_id &&
                  x.name.toLowerCase() ===
                    editTagTypeRatingDialog.name.trim().toLowerCase()
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addGradingTypeDialog.show" persistent width="500">
      <v-card class="pt-5">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3>Add New Tag Grading Type</h3>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              tagGradingTypes.some(
                (x) =>
                  x.name.toLowerCase() ===
                  addGradingTypeDialog.name.trim().toLowerCase()
              )
            "
          >
            <v-col cols="11">
              <v-alert type="error">Grading Type already exists</v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="addGradingTypeDialog.name"
                label="Tag Grading Type"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="addGradingTypeDialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="saveGradingType"
            :disabled="
              !addGradingTypeDialog.name.trim() ||
              tagGradingTypes.some(
                (x) =>
                  x.name.toLowerCase() ===
                  addGradingTypeDialog.name.trim().toLowerCase()
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editGradingValueDialog.show" persistent width="500">
      <v-card class="pt-5" v-if="currentTagGradingType">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3>{{ editGradingValueDialog.tag_grading_type_value_id > 0 ? 'Edit' : 'Add New' }} {{ currentTagGradingType.name }}</h3>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              currentTagGradingType.values.some(
                (x) =>
                  x.tag_grading_type_value_id !==
                    editGradingValueDialog.tag_grading_type_value_id &&
                  x.name.toLowerCase() ===
                    editGradingValueDialog.name.trim().toLowerCase()
              )
            "
          >
            <v-col cols="11">
              <v-alert type="error">Grading Value already exists</v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editGradingValueDialog.name"
                label="Value"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editGradingValueDialog.description"
                label="Description"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editGradingValueDialog.sequence"
                label="Position"
                required
                type="number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editGradingValueDialog.colour"
                label="Colour"
                required
                outlined
            :append-icon="editGradingValueDialog.showColourPicker ? 'mdi-chevron-up' : 'mdi-chevron-down'" @click:append="editGradingValueDialog.showColourPicker = !editGradingValueDialog.showColourPicker"
              ></v-text-field>
              <v-color-picker v-show="editGradingValueDialog.showColourPicker"
                v-model="editGradingValueDialog.colour"
                hide-inputs
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="editGradingValueDialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="saveGradingValue"
            :disabled="
              !editGradingValueDialog.name.trim() ||
              currentTagGradingType.values.some(
                (x) =>
                  x.tag_grading_type_value_id !==
                    editGradingValueDialog.tag_grading_type_value_id &&
                  x.name.toLowerCase() ===
                    editGradingValueDialog.name.trim().toLowerCase()
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editItemDialog.show" persistent width="500">
      <v-card class="pt-5" v-if="editItemDialog.show">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3 v-if="editItemDialog.key_value <= 0">
                Add New {{ editItemDialog.type }}
              </h3>
              <h3 v-else>Edit {{ editItemDialog.type }}</h3>
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              editItemDialog.existingValues.some(
                (x) =>
                  x[editItemDialog.key_column] !== editItemDialog.key_value &&
                  (x.value ?? x.name).toLowerCase() ===
                    (editItemDialog.value ?? editItemDialog.name)
                      .trim()
                      .toLowerCase()
              )
            "
          >
            <v-col cols="11">
              <v-alert type="error"
                >{{ editItemDialog.type }} already exists</v-alert
              >
            </v-col>
          </v-row>
          <v-row dense v-if="editItemDialog.value !== undefined">
            <v-col cols="11">
              <v-text-field
                v-model="editItemDialog.value"
                label="Value"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="editItemDialog.name !== undefined">
            <v-col cols="11">
              <v-text-field
                v-model="editItemDialog.name"
                label="Name"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editItemDialog.description"
                label="Description"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editItemDialog.sequence"
                label="Position"
                required
                type="number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editItemDialog.colour"
                label="Colour"
                required
                outlined
            :append-icon="editItemDialog.showColourPicker ? 'mdi-chevron-up' : 'mdi-chevron-down'" @click:append="editItemDialog.showColourPicker = !editItemDialog.showColourPicker"
              ></v-text-field>
              <v-color-picker v-show="editItemDialog.showColourPicker"
                v-model="editItemDialog.colour"
                hide-inputs
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editItemDialog.show = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="saveEditItem"
            :disabled="
              !(editItemDialog.value ?? editItemDialog.name).trim() ||
              editItemDialog.existingValues.some(
                (x) =>
                  x[editItemDialog.key_column] !== editItemDialog.key_value &&
                  (x.value ?? x.name).toLowerCase() ===
                    (editItemDialog.value ?? editItemDialog.name)
                      .trim()
                      .toLowerCase()
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
  <script>
import { mapState } from "vuex";
import utils from "@/common/utils.js";
// import Pagination from "@/components/cPagination";
// import PageDescription from "@/components/cPageDescription";
import TagValueEdit from "../document/TagValueEdit.vue";
import rmTable from "@/components/common/Table";

export default {
  name: "adminHierarchy",
  components: {
    // Pagination,
    // PageDescription,
    TagValueEdit,
    rmTable,
  },
  data: function () {
    return {
      utils: utils,
      currentTagType: null,
      currentTagTypeName: null,
      currentTagGradingType: null,
      currentTagClassifierType: null,
      tagValueData: [],
      tagValueColumns: [],
      gradingColumns: [],
      gradingData: [],
      tagRatingColumns: [],
      tagRatingData: [],
      tagValueRatingColumns: [],
      classifierValueColumns: [
        {
          column: "value",
          text: "Value",
        },
        {
          column: "sequence",
          text: "Position",
          defaultSort: true,
        },
        {
          column: "description",
          text: "Description",
        },
        {
          column: "colour",
          text: "Colour",
        },
      ],
      tagForEdit: null,
      editTagTrigger: 0,
      allowAdd: false,
      allowEditTag: false,
      showRating: false,
      addTagTypeDialog: {
        show: false,
        tag_type_name: "",
      },
      editTagTypeRatingDialog: {
        show: false,
        name: "",
        description: "",
        sequence: "",
        tag_type_rating_id: null,
        active: 1,
        isNew: false,
        error_message: "",
      },
      hideInactive: true,
      addGradingTypeDialog: {
        show: false,
        name: "",
      },
      editGradingValueDialog: {
        show: false,
        name: "",
        description: "",
        sequence: "",
        colour: "",
        tag_grading_type_value_id: null,
        isNew: false,
        showColourPicker: false,
      },
      addClassifierTypeDialog: {
        show: false,
        name: "",
      },
      editItemDialog: {
        currentItem: null,
        type: "",
        existingValues: [],
        show: false,
        value: undefined,
        name: undefined,
        description: "",
        sequence: "",
        colour: "",
        key_value: -1,
        key_column: "",
        entity_type: "",
        f_key_value: -1,
        f_key_column: "",
        showColourPicker: false,
      },
      view: "tags",
    };
  },
  created: function () {
    if (this.tagGradingTypes.length) this.setupGrading(this.tagGradingTypes[0]);
    if (this.tagClassifierTypes.length)
      this.setupClassifier(this.tagClassifierTypes[0]);
    if (this.tagTypes.length) this.setup(this.tagTypes[0]);
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagGradingTypes: (state) => state.hierarchies.tagGradingTypes,
      tagClassifierTypes: (state) => state.hierarchies.tagClassifierTypes,
      tagPermissions: (state) => state.hierarchies.tagPermissions,
    }),
  },
  watch: {
    hierarchiesLoading(val) {
      if (val === false && this.tagClassifierTypes.length) {
        this.setupClassifier(this.tagClassifierTypes[0]);
      }
      if (val === false && this.tagGradingTypes.length) {
        this.setupGrading(this.tagGradingTypes[0]);
      }
      if (val === false && this.tagTypes.length) {
        this.setup(this.tagTypes[0]);
      }
    },
    hideInactive() {
      this.filterData();
    },
  },
  methods: {
    setupGrading(grading) {
      this.currentTagGradingType = grading;
      this.gradingColumns = [
        {
          column: "name",
          text: this.currentTagGradingType.name,
        },
        {
          column: "sequence",
          text: "Position",
          defaultSort: true,
        },
        {
          column: "description",
          text: "Description",
        },
        {
          column: "colour",
          text: "Colour",
        },
      ];
      this.setGradingData();
    },
    setGradingData() {
      this.gradingData = this.currentTagGradingType.values.filter((v) => v);
    },
    setupClassifier(classifier) {
      this.currentTagClassifierType = classifier;
    },
    setTagRatingData() {
      this.tagRatingData = (this.currentTagType.ratings || []).filter((r) => r);
    },
    setup(tagType) {
      let currentTagType = JSON.parse(JSON.stringify(tagType));
      let currentTagTypeName = currentTagType.tag_type_name;
      if (currentTagTypeName.substring(currentTagTypeName.length - 1) === "s") {
        currentTagTypeName = currentTagTypeName.substring(
          0,
          currentTagTypeName.length - 1
        );
      }
      this.currentTagTypeName = currentTagTypeName;
      this.allowAdd = this.tagPermissions.addTag;
      this.allowEditTag = this.tagPermissions.editTag;
      this.showRating = this.$loginState.user.settings.some(
        (s) => s.setting === "tagging_rating_used" && s.value !== "false"
      );

      let tagValueColumns = [];
      let tagRatingColumns = [];
      let tagValueRatingColumns = [];

      tagValueColumns.push({
        column: "value",
        text: this.currentTagTypeName,
        width: "15%",
        defaultSort: true,
      });
      tagValueRatingColumns.push({
        column: "value",
        text: this.currentTagTypeName,
        width: "15%",
        defaultSort: true,
        actions: [
          { name: "Edit", icon: "mdi-pencil", func: "editTag" },
          //   {
          //     name: "Comment",
          //     icon: "mdi-message-reply",
          //     func: "editTag",
          //   },
          { name: "Show History", icon: "mdi-history", func: "editTag" },
        ],
      });

      tagValueColumns.push({
        column: "description",
        text: "Descriptor",
        width: "35%",
      });

        this.tagClassifierTypes
          .sort((a, b) =>
            a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0
          )
          .forEach((c, ci) =>
            tagValueColumns.push({
              column: "value",
              columnObject: `cls_${ci}_object`,
              editable: false,
              index: ci,
              text: c.name,
              width: 40 / this.tagClassifierTypes.length + "%",
            })
          );
      tagValueColumns.push({
        column: "",
        text: "",
        width: "10%",
        actions: [
          { name: "Edit", icon: "mdi-pencil", func: "editTag" },
          //   {
          //     name: "Comment",
          //     icon: "mdi-message-reply",
          //     func: "editTag",
          //   },
          { name: "History", icon: "mdi-history", func: "editTag" },
        ],
      });
      this.tagValueColumns = tagValueColumns;
      if (this.showRating) {
        tagRatingColumns.push({
          column: "name",
          text: "Proficiency Level",
          width: "20%",
        });
        tagRatingColumns.push({
          column: "sequence",
          text: "#",
          width: "10%",
          defaultSort: true,
        });

        tagRatingColumns.push({
          column: "description",
          text: "Descriptor",
          width: "60%",
        });
        tagRatingColumns.push({
          column: "",
          width: "10%",
          text: "",
          actions: [
            { name: "Edit", icon: "mdi-pencil", func: "editTagTypeRating" },
            // {
            //   name: "Comment",
            //   icon: "mdi-message-reply",
            //   func: "editTagTypeRating",
            // },
            { name: "History", icon: "mdi-history", func: "editTagTypeRating" },
          ],
        });
      }
      this.tagRatingColumns = tagRatingColumns;
      if (this.showRating) {
        currentTagType.ratings.forEach((r, ri) => {
          tagValueRatingColumns.push({
            column: "description",
            columnObject: `rat_${ri}_object`,
            editable: true,
            index: ri,
            text: r.name,
            width: 85 / currentTagType.ratings.length + "%",
          });
        });
        this.tagValueRatingColumns = tagValueRatingColumns;
      }
      // build tag value / rating matrix
      currentTagType.values.forEach((v) => {
        this.prepTagValue(v, currentTagType);
      });
      this.currentTagType = currentTagType;
      this.setTagRatingData();
      this.filterData();
    },
    prepTagValue(tv, tagType) {
      if (this.showRating) {
        this.getValueRatings(tagType, tv).forEach((c, ci) => {
          tv[`rat_${ci}_object`] = c;
        });
		}
		this.tagClassifierTypes.forEach((ct, cti) => {
			let tc = tv.classifiers.find(c => c.tag_classifier_type_id === ct.tag_classifier_type_id);
			tv[`cls_${cti}_object`] = tc || null;
		});
    },
    addTagType() {
      this.addTagTypeDialog.show = true;
    },
    saveTagType() {
      if (this.addTagTypeDialog.tag_type_name.trim().length) {
        let data = {
          entity_type: "tag_type",
          status_change: false,
          tag_type_id: -1,
          tag_type_name: this.addTagTypeDialog.tag_type_name,
        };

        this.$store.dispatch("hierarchies/saveItem", data).then((resp) => {
          if (resp.data.Status === "OK") {
            this.currentTagType = JSON.parse(
              JSON.stringify(
                this.tagTypes.find(
                  (x) => x.tag_type_id === resp.data.Data[0][0].tag_type_id
                )
              )
            );
            this.addTagTypeDialog.show = false;
            this.addTagValue();
          }
          this.response = resp.data;
          this.isLoading = false;
        }),
          (error) => {
            console.error(error);
            this.isLoading = false;
          };
      }
    },
    handleRowAction(action, row) {
      this[action.func](row);
    },
    editTagTypeRating(item) {
      this.editTagTypeRatingDialog.tag_type_rating_id =
        item?.tag_type_rating_id || -1;
      this.editTagTypeRatingDialog.description = item?.description || "";
      this.editTagTypeRatingDialog.name = item?.name || "";
      this.editTagTypeRatingDialog.sequence =
        item?.sequence || this.currentTagType.ratings.length + 1;
      this.editTagTypeRatingDialog.active = item?.active ?? 1;
      this.editTagTypeRatingDialog.error_message = "";
      this.editTagTypeRatingDialog.isNew =
        this.editTagTypeRatingDialog.tag_type_rating_id < 1;
      this.editTagTypeRatingDialog.show = true;
    },
    saveTagTypeRating() {
      if (this.editTagTypeRatingDialog.name.trim().length) {
        let data = {
          entity_type: "tag_type_rating",
          status_change: false,
          tag_type_id: this.currentTagType.tag_type_id,
          tag_type_rating_id: this.editTagTypeRatingDialog.tag_type_rating_id,
          name: this.editTagTypeRatingDialog.name,
          description: this.editTagTypeRatingDialog.description,
          sequence: this.editTagTypeRatingDialog.sequence,
        };

        this.$store.dispatch("hierarchies/saveItem", data).then((resp) => {
          if (resp.data.Status === "OK") {
            let respData = resp.data.Data[0][0];
            let currentRating = this.currentTagType.ratings.find(
              (x) => x.tag_type_rating_id === respData.tag_type_rating_id
            );
            if (currentRating) {
              currentRating.name = respData.name;
              currentRating.description = respData.description;
              currentRating.sequence = respData.sequence;
              this.setTagRatingData();
            }
            this.editTagTypeRatingDialog.show = false;
          }
          this.response = resp.data;
          this.isLoading = false;
        }),
          (error) => {
            console.error(error);
            this.isLoading = false;
          };
      }
    },
    changeTagTypeRatingStatus(ignore_validation) {
      let data = {
        entity_type: "tag_type_rating",
        status_change: true,
        tag_type_id: this.currentTagType.tag_type_id,
        tag_type_rating_id: this.editTagTypeRatingDialog.tag_type_rating_id,
        active: this.editTagTypeRatingDialog.active === 1 ? 0 : 1,
        ignore_validation: ignore_validation ? 1 : 0,
      };
      this.$store.dispatch("hierarchies/saveItem", data).then((resp) => {
        if (
          resp.data.Status === "OK" &&
          resp.data.Data.length &&
          resp.data.Data[0].length
        ) {
          let respData = resp.data.Data[0][0];
          let currentRating = this.currentTagType.ratings.find(
            (x) => x.tag_type_rating_id === respData.tag_type_rating_id
          );
          if (Number(respData.valid) === 1) {
            currentRating.active = respData.active;
            this.editTagTypeRatingDialog.show = false;
            this.setTagRatingData();
          } else {
            this.editTagTypeRatingDialog.error_message = `This rating is currently applied to ${
              respData.used_count
            } document${Number(respData.used_count) === 1 ? "" : "s"}.`;
          }
        }
        this.response = resp.data;
        this.isLoading = false;
      }),
        (error) => {
          console.error(error);
          this.isLoading = false;
        };
    },
    editTag(tag) {
      this.tagForEdit = tag;
      this.editTagTrigger++;
    },
    addTagValue() {
      this.tagForEdit = {
        tag_value_id: -1,
        value: "",
        tag_type_id: this.currentTagType.tag_type_id,
      };
      this.editTagTrigger++;
    },
    cancelEditTag() {
      this.tagForEdit = null;
    },
    tagSaved(updated) {
      // get latest
      let tagValue = this.tagTypes
        .find((tt) => tt.tag_type_id === updated.tag_type_id)
        ?.values.find((v) => v.tag_value_id === updated.tag_value_id);
      if (tagValue) {
        tagValue = JSON.parse(JSON.stringify(tagValue));
        this.prepTagValue(tagValue, this.currentTagType);
        let index = this.currentTagType.values.findIndex(
          (tv) => tv.tag_value_id === tagValue.tag_value_id
        );
        if (index >= 0) {
          this.currentTagType.values.splice(index, 1, tagValue);
        } else {
          this.currentTagType.values.push(tagValue);
        }
        this.filterData();
      }
      this.tagForEdit = null;
    },
    filterData() {
      let raw = this.currentTagType.values;
      if (this.hideInactive) {
        raw = raw.filter((x) => x.active);
      }
      this.tagValueData = raw;
    },
    addGradingType() {
      this.addGradingTypeDialog.show = true;
    },
    saveGradingType() {
      if (this.addGradingTypeDialog.name.trim().length) {
        let data = {
          transaction: "TagGradingType",
          tag_grading_type_id: -1,
          name: this.addGradingTypeDialog.name,
          description: this.addGradingTypeDialog.description,
        };

        this.$store.dispatch("hierarchies/saveTagData", data).then((resp) => {
          if (resp.data.Status === "OK") {
            this.currentTagGradingType = this.tagGradingTypes.find(
              (x) =>
                x.tag_grading_type_id ===
                resp.data.Data[0][0].tag_grading_type_id
            );
            this.addGradingTypeDialog.show = false;
            this.editGradingValue();
          }
          this.response = resp.data;
          this.isLoading = false;
        }),
          (error) => {
            console.error(error);
            this.isLoading = false;
          };
      }
    },
    editGradingValue(value) {
      this.editGradingValueDialog.name = value?.name ?? "";
      this.editGradingValueDialog.description = value?.description ?? "";
      this.editGradingValueDialog.colour = value?.colour ?? "#ffffff";
      this.editGradingValueDialog.tag_grading_type_value_id =
        value?.tag_grading_type_value_id ?? -1;
      this.editGradingValueDialog.sequence = value?.sequence
        ? value.sequence
        : this.currentTagGradingType.values
        ? this.currentTagGradingType.values.reduce(
            (p, c) => (c.sequence > p ? c.sequence + 1 : p),
            1
          )
        : 1;
      this.editGradingValueDialog.show = true;
    },
    cancelGradingValueEdit() {
      this.editGradingValueDialog.show = false;
    },
    saveGradingValue() {
      if (this.editGradingValueDialog.name.trim().length) {
        let data = {
          transaction: "TagGradingTypeValue",
          tag_grading_type_id: this.currentTagGradingType.tag_grading_type_id,
          tag_grading_type_value_id:
            this.editGradingValueDialog.tag_grading_type_value_id,
          name: this.editGradingValueDialog.name,
          description: this.editGradingValueDialog.description,
          sequence: this.editGradingValueDialog.sequence,
          colour: this.editGradingValueDialog.colour,
        };

        this.$store.dispatch("hierarchies/saveTagData", data).then((resp) => {
          if (resp.data.Status === "OK") {
            const respData = resp.data.Data[0][0];
            let value = this.currentTagGradingType.values.find(
              (x) =>
                x.tag_grading_type_value_id ===
                respData.tag_grading_type_value_id
            );
            if (value) {
              value.name = respData.name;
              value.description = respData.description;
              value.sequence = respData.sequence;
              this.setGradingData();
            }
            this.editGradingValueDialog.show = false;
          }
          this.response = resp.data;
          this.isLoading = false;
        }),
          (error) => {
            console.error(error);
            this.isLoading = false;
          };
      }
    },
    editClassifierType(item) {
      this.editItemDialog.currentItem = null;
      this.editItemDialog.type = "Classifier";
      this.editItemDialog.existingValues = this.tagClassifierTypes;
      this.editItemDialog.name = item?.name ?? "";
      this.editItemDialog.value = undefined;
      this.editItemDialog.description = item?.description ?? "";
      this.editItemDialog.colour = item?.colour ?? "#ffffff";
      this.editItemDialog.entity_type = "tag_classifier_type";
      this.editItemDialog.key_column = "tag_classifier_type_id";
      this.editItemDialog.key_value = item?.tag_classifier_type_id ?? -1;
      this.editItemDialog.f_key_column = null;
      this.editItemDialog.f_key_value = null;
      this.editItemDialog.sequence = item?.sequence
        ? item.sequence
        : this.tagClassifierTypes
        ? this.tagClassifierTypes.reduce(
            (p, c) => (c.sequence >= p ? c.sequence + 1 : p),
            1
          )
        : 1;
      this.editItemDialog.show = true;
    },
    cancelEditItem() {
      this.editItemDialog.show = false;
    },
    editClassifierValue(item) {
      this.editItemDialog.currentItem = this.currentTagClassifierType;
      this.editItemDialog.type = this.currentTagClassifierType.name;
      this.editItemDialog.existingValues = this.currentTagClassifierType.values;
      this.editItemDialog.value = item?.value ?? "";
      this.editItemDialog.name = undefined;
      this.editItemDialog.description = item?.description ?? "";
      this.editItemDialog.colour = item?.colour ?? "#ffffff";
      this.editItemDialog.entity_type = "tag_classifier_type_value";
      this.editItemDialog.key_column = "tag_classifier_type_value_id";
      this.editItemDialog.key_value = item?.tag_classifier_type_value_id ?? -1;
      this.editItemDialog.f_key_column = "tag_classifier_type_id";
      this.editItemDialog.f_key_value =
        this.currentTagClassifierType.tag_classifier_type_id;
      this.editItemDialog.sequence = item?.sequence
        ? item.sequence
        : this.currentTagClassifierType.values
        ? this.currentTagClassifierType.values.reduce(
            (p, c) => (c.sequence >= p ? c.sequence + 1 : p),
            1
          )
        : 1;
      this.editItemDialog.show = true;
    },
    saveEditItem() {
      if (
        this.editItemDialog.value?.trim()?.length ||
        this.editItemDialog.name?.trim()?.length
      ) {
        let data = {
          entity_type: this.editItemDialog.entity_type,
          status_change: false,
          description: this.editItemDialog.description,
          sequence: this.editItemDialog.sequence,
          colour: this.editItemDialog.colour,
        };
        if (this.editItemDialog.value !== undefined) {
          data.value = this.editItemDialog.value;
        }
        if (this.editItemDialog.name !== undefined) {
          data.name = this.editItemDialog.name;
        }
        data[this.editItemDialog.key_column] = this.editItemDialog.key_value;
        if (this.editItemDialog.f_key_column !== undefined) {
          data[this.editItemDialog.f_key_column] =
            this.editItemDialog.f_key_value;
        }

        this.$store.dispatch("hierarchies/saveItem", data).then((resp) => {
          if (resp.data.Status === "OK") {
            const respData = resp.data.Data[0][0];
            let value = this.editItemDialog.existingValues.find(
              (x) => x[this.editItemDialog.key_column] === respData.key_value
            );
            if (value) {
              if (this.editItemDialog.value !== undefined)
                value.value = respData.value;
              if (this.editItemDialog.name !== undefined)
                value.value = respData.name;
              value.description = respData.description;
              value.sequence = respData.sequence;
              value.colour = respData.colour;
            } else {
              this.editItemDialog.existingValues.push(respData);
            }
            if (this.editItemDialog.currentItem?.values) {
              this.editItemDialog.currentItem.values =
                this.editItemDialog.currentItem.values.filter((v) => v);
            }
            this.editItemDialog.show = false;
          }
          this.response = resp.data;
          this.isLoading = false;
        }),
          (error) => {
            console.error(error);
            this.isLoading = false;
          };
      }
    },
    getValueRatings(tagType, tag) {
      return tagType.ratings.map((r) => {
        let override = tag.ratings.find(
          (x) => x.tag_type_rating_id === r.tag_type_rating_id
        );
        return {
          tag_value_rating_id: override?.tag_value_rating_id || null,
          tag_type_rating_id: r.tag_type_rating_id,
          tag_value_id: tag.tag_value_id,
          name: r.name,
          description: override?.description || r.description,
          sequence: r.sequence,
          active: r.active,
          edit: false,
        };
      });
    },
    editValueRating(rating) {
      if (rating.edit) this.cancelRatingDescription(rating);
      else {
        rating.edit = true;
        rating.original = rating.description;
      }
    },
    saveRatingDescription(item) {
      item.entity_type = "tag_value_rating";

      this.$store.dispatch("hierarchies/saveItem", item).then((resp) => {
        if (resp.data.Status === "OK") {
          let respData = resp.data.Data[0][0];
          if (!item.tag_value_rating_id)
            item.tag_value_rating_id = respData.tag_value_rating_id;
          item.edit = false;
        }
        this.response = resp.data;
      }),
        (error) => {
          console.error(error);
          this.isLoading = false;
        };
    },
    cancelRatingDescription(rating) {
      rating.edit = false;
      rating.description = rating.original;
    },
    getTHStyle(headerColumns, index) {
      return {
        borderTopLeftRadius: index === 0 ? "20px 20px" : null,
        borderTopRightRadius:
          index === headerColumns.length - 1 ? "20px 20px" : null,
        width: headerColumns[index].width,
      };
    },
  },
};
</script>
  
  <style scoped lang="scss">
::v-deep .v-data-table tbody td {
  cursor: pointer !important;
}
.inactive-row {
  background-color: #ebe8e8;
}
div.admin-cell,
td.admin-cell,
span.admin-cell {
  font-family: sans-serif;
  font-weight: 400 !important;
  border-bottom: none;
  font-size: 14px;
}
div.admin-cell-title,
span.admin-cell-title {
  font-weight: 800 !important;
}
th.th {
  border: 0px gray #eeeeee;
  background-color: #eeeeee !important;
  font-weight: bold;
  text-transform: uppercase;
}
</style>