<template>
  <div :id="id">
    <v-menu
      offset-y
      v-model="tisMenu"
      :close-on-content-click="false"
      :attach="attach ? `#${id}` : false"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          outlined
          hide-details
          dense
          label="Time In Status"
          placeholder="Select..."
          readonly
          :value="appliedFiltersText"
          :rounded="rounded"
          :class="`tisSelect ${roundish ? 'roundish' : ''}`"
          v-on="on"
          @click="tisMenu = true"
          v-on:keyup.enter="tisMenu = true"
          @blur="handleBlur"
        >
          <template slot="append">
            <v-icon v-if="appliedFilters && appliedFilters.length" @click="clearFilter" title="Clear"
              >mdi-close</v-icon
            >
            <v-icon title="Expanded">{{
              tisMenu ? "mdi-menu-up" : "mdi-menu-down"
            }}</v-icon>
          </template>
        </v-text-field>
      </template>
      <v-card class="tisMenu">
        <v-card-text class="pt-10">
          <v-row dense v-for="f in availableFilters" :key="f.status">
            <v-col style="max-width: 100px;" class="d-flex align-center"><v-label>{{ f.status }}</v-label></v-col>
            <v-col>
              <v-range-slider
                hide-details
                v-model="f.selected"
                :color="f.colour"
                :max="f.max">
                <template v-slot:thumb-label="props">
                  {{ props.value }}
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utils from "@/common/utils.js";

export default {
  name: "cTimeInStatusFilter",
  components: {},
  props: {
    value: { type: Array },
    items: { type: Array },
    rounded: { type: Boolean },
    roundish: { type: Boolean },
    attach: { type: Boolean, default: true }
  },
  data: function () {
    return {
      personTab: 0,
      utils: utils,
      tisMenu: false,
      id: "timeInStatusFilter" + utils.makeid(8),
      availableFilters: [],
      updateModelTimeout: null
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.docs.loading,
      docStatuses: (state) => state.docs.allDocStatuses,
    }),
    appliedFilters() {
      return this.availableFilters
        .filter(f => f.selected[0] > 0 || f.selected[1] < f.max)
        .map(f => {
          return {
            status: f.status,
            min: f.selected[0],
            max: f.selected[1],
            maxMax: f.max,
          }
        });
    },
    appliedFiltersText() {
      if (this.appliedFilters.length === 0)
        return "";
      else if (this.appliedFilters.length > 1)
        return `${this.appliedFilters.length} Selected`;
      else {
        const filter = this.appliedFilters[0];
        if (filter.min > 0 && filter.max < filter.maxMax)
          return `${filter.status}: ${filter.min} - ${filter.max}`;
        else if (filter.min > 0)
          return `${filter.status} Over ${filter.min - 1} Days`;
        else
          return `${filter.status} Under ${filter.max + 1} Days`;
      }
    }
  },
  watch: {
    appliedFilters(val) {
      if (this.updateModelTimeout)
        clearTimeout(this.updateModelTimeout);

      this.updateModelTimeout = setTimeout(() => this.$emit('input', val), 500);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.availableFilters = this.docStatuses
        .filter(s => s.show && !s.status.startsWith('Part ') && !s.final_status)
        .map(s => {
          const max = this.items.reduce((acc, curr) => acc = curr[`dis_${s.status}`] > acc ? curr[`dis_${s.status}`] : acc, 0)
          return {
            ...s,
            max: max,
            selected: [0, max],
          }
        })
        .filter(f => f.max !== 0);
    },
    clearFilter() {
      this.availableFilters.forEach(f => {
        f.selected = [0, f.max];
      });
      this.$emit('input', []);
    },
    handleBlur() {
      if(!this.attach){
        return;
      }
      setTimeout(() => {
        const menuContainer = document.getElementById(this.id);
        if (menuContainer && !menuContainer.contains(document.activeElement)) {
          this.tisMenu = false;
        }
      }, 50);
    }
  },
};
</script>

<style scoped lang="scss">
.tisSelect::v-deep {
  input {
    cursor: pointer;
  }
}

.tisChip.theme--light {
  background-color: #f4f9fd !important;
  .v-icon {
    color: #7d8592;
  }
}

.tisMenu {
  min-height: 150px;
  overflow: hidden;
  .v-chip-group {
    min-height: 30px;
  }
  ::v-deep .v-tabs-slider-wrapper {
    left: auto !important;
    right: 0;
  }
}
</style>