<template>
  <div class="createNewDialog">
    <v-card>
      <v-toolbar fixed dense flat color="transparent">
        <v-toolbar-title
          >Create {{ showUploadButton ? "or Upload" : "" }} New
          {{
            targetDocProvided
              ? targetDoc
              : docNames.length === 1
              ? docType
              : "Document"
          }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <!-- <v-btn @click="clearFilters">Clear Selection</v-btn> -->
        <v-btn
          large
          icon
          class="btn-background ml-2"
          @click="closeAddDocument"
          title="Close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text dense>
        <v-row style="height: calc(90vh - 180px)">
          <v-col cols="4" style="height: 100%; overflow-y: auto">
            <v-form ref="createDocForm">
              <v-col cols="12" v-if="docNames.length > 1 && !targetDocProvided">
                <v-row>
                  <v-col cols="11" class="expand">
                    <v-select
                      dense
                      outlined
                      class="roundish"
                      :return-object="false"
                      :items="docNames"
                      label="Document Type *"
                      item-text="docType"
                      item-value="docType"
                      v-model="docType"
                      :menu-props="{
                        'offset-y': true,
                      }"
                      hide-details
                    ></v-select>
                  </v-col>

                  <v-col cols="1"> </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="showLanguageSelection">
                <v-row>
                  <v-col cols="11" class="expand">
                    <v-select
                      class="roundish"
                      dense
                      outlined
                      v-model="selectedTranslateLang"
                      :items="utils.translateOptions"
                      label="Target Language"
                      :menu-props="{ 'offset-y': true }"
                      hide-details
                    >
                      <template slot="item" slot-scope="item">
                        <div
                          v-if="item.item.value !== 'en'"
                          class="d-flex align-center"
                        >
                          <country-flag
                            :country="item.item.flagCode"
                            size="normal"
                            class="ma-0"
                          />
                          <span style="padding-left: 20px">{{
                            item.item.text
                          }}</span>
                        </div>
                        <div
                          v-if="item.item.value == 'en'"
                          class="d-flex align-center"
                        >
                          <img
                            class="ma-0"
                            style="margin-left: 12px !important"
                            src="../assets/Anglosphere.png"
                            width="28px"
                            height="20px"
                          /><span style="padding-left: 32px">{{
                            item.item.text
                          }}</span>
                        </div>
                      </template>
                      <template v-slot:selection="item">
                        <div
                          v-if="item.item.value !== 'en'"
                          class="d-flex align-center"
                        >
                          <country-flag
                            :country="item.item.flagCode"
                            size="normal"
                            class="ma-0"
                            style="max-height: 36px"
                          /><span style="padding-left: 20px">{{
                            item.item.text
                          }}</span>
                        </div>
                        <div
                          v-if="item.item.value == 'en'"
                          class="d-flex align-center"
                        >
                          <img
                            src="../assets/Anglosphere.png"
                            width="28px"
                            height="20px"
                            class="mr-0 ml-2"
                          />
                          <span style="padding-left: 20px">{{
                            item.item.text
                          }}</span>
                        </div>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="1"> </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                v-for="(item, index) in currentFields"
                :key="index"
                style="height: 65px"
              >
                <v-row dense>
                  <v-col cols="11">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      class="roundish"
                      :label="item.label"
                      v-model="item.value"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-tooltip top v-if="item.tooltip !== null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="createTooltip" v-bind="attrs" v-on="on">
                          mdi-help-circle-outline</v-icon
                        >
                      </template>
                      <div>{{ item.tooltip }}</div>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="expand"
                v-for="(h, hi) in applicableHierarchyTypes"
                :key="'h' + hi"
              >
                <v-row dense>
                  <v-col cols="11">
                    <HierarchyTreePicker
                      v-if="useTreePicker"
                      v-model="h.treePickerValue"
                      :label="`${h.label} *`"
                      :options="h.treePickerOptionsActive"
                      placeholder="Please select"
                      :hierarchyType="h"
                      :init="open"
                      :error="h.lostFocus && !h.treePickerValue"
                      :hint="
                        h.lostFocus && !h.treePickerValue ? 'Required' : ''
                      "
                      dense
                      @change="onHrySelect(h, ...arguments)"
                      @nodeAdded="nodeAdded(h, ...arguments)"
                      @lostFocus="h.lostFocus = true"
                    >
                    </HierarchyTreePicker>
                    <v-autocomplete
                      v-else-if="!h.selectNew"
                      dense
                      outlined
                      clearable
                      class="roundish"
                      auto-select-first
                      :ref="'hcb' + hi"
                      :return-object="false"
                      :items="h.values"
                      item-value="text"
                      item-text="text"
                      :label="`${h.label} *`"
                      v-model="h.hierarchy_text"
                      @focus="focusHTID = hi"
                      @keyup="searchText($event, h)"
                      @keyup.enter.exact="pickValue(h)"
                      @keyup.ctrl.enter="pickValue(h, true)"
                      :filter="utils.comboFilterPicker"
                      @change="onHrySelect"
                      hide-details
                      :append-icon="h.icon"
                      :rules="[rules.required]"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content style="max-width: 800px">
                          <v-list-item-title>
                            {{ item.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:prepend-item>
                        <div
                          v-if="h.showNewPrompt"
                          style="padding: 0px 0px 10px 10px; font-size: 14px"
                        >
                          <div v-if="canAddHierarchy !== 0">
                            Press <kbd>Ctrl</kbd> + <kbd>Enter</kbd> to create a
                            new {{ h.label }} <mark>{{ h.searchText }}</mark> or
                            <kbd>Enter</kbd> to pick the highlighted item
                          </div>
                          <div v-else>
                            Press <kbd>Enter</kbd> to pick the highlighted item
                          </div>
                        </div>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-if="canAddHierarchy !== 0">
                              No matching results. Press
                              <kbd>Ctrl</kbd> + <kbd>enter</kbd> key to create
                              the new
                              {{ h.label }}
                            </v-list-item-title>
                            <v-list-item-title v-else>
                              {{ h.not_in_list_message }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <v-row
                      dense
                      v-else
                      style="
                        border: solid #1976d2 2px;
                        margin: 2px;
                        border-radius: 4px;
                      "
                    >
                      <v-col cols="6"
                        ><h4
                          style="
                            text-transform: initial;
                            padding-bottom: 10px;
                            padding-top: 5px;
                          "
                        >
                          New {{ h.label }}: <mark>{{ h.hierarchy_text }}</mark>
                        </h4>
                        Please select the hierarchy elements if you can or press
                        <v-btn elevation="2" x-small @click="cancelNew(h)"
                          >Cancel</v-btn
                        >
                        to pick an existing {{ h.label }} <br /><br />
                        Note: If any levels are left empty the new
                        {{ h.label }} will be saved as "Unclassified"
                      </v-col>
                      <v-col cols="5">
                        <v-row
                          dense
                          v-for="(l, li) in h.levels"
                          :key="'h' + hi + 'l' + li"
                        >
                          <v-col cols="1"></v-col>
                          <v-col cols="11">
                            <v-text-field
                              :label="'Level ' + (li + 1)"
                              dense
                              outlined
                              readonly
                              v-if="li === h.levels.length - 1"
                              v-model="h.hierarchy_text"
                              hide-details
                            ></v-text-field>
                            <v-select
                              v-else
                              dense
                              outlined
                              v-model="l.selected"
                              return-object
                              :label="'Level ' + (li + 1)"
                              :items="l.items"
                              item-text="text"
                              item-value="text"
                              @change="classifyHierarchy(h, l)"
                              hide-details
                            >
                            </v-select>
                          </v-col> </v-row
                      ></v-col>
                      <v-col cols="1"
                        ><v-icon
                          class="float-right"
                          @click="cancelNew(h)"
                          style="color: #1976d2"
                          >mdi-close</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1">
                    <v-tooltip top v-if="h.tooltip !== null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="createTooltip" v-bind="attrs" v-on="on">
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <span>{{ h.tooltip }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="expand" v-if="!hideRecruiter">
                <v-row>
                  <v-col cols="11" class="pb-0">
                    <v-combobox
                      v-if="!recruiterRestrict && canAddRecruiter !== 0"
                      dense
                      outlined
                      clearable
                      class="roundish"
                      :return-object="false"
                      :items="recruiters"
                      :label="recruiterAltLabel"
                      item-text="text"
                      item-value="text"
                      @change="buildFilters"
                      v-model="recruiter"
                      :filter="utils.comboFilterPicker"
                      append-icon="mdi-account-outline"
                      :rules="[rules.required]"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content style="max-width: 800px">
                          <v-list-item-title>
                            {{ item.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              No matching results. Press
                              <kbd>enter</kbd> to link to a new
                              {{ recruiterAltLabel }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                    <v-autocomplete
                      v-else
                      dense
                      outlined
                      clearable
                      class="roundish"
                      :return-object="false"
                      :items="recruiters"
                      :label="recruiterAltLabel"
                      item-text="text"
                      item-value="text"
                      @change="buildFilters"
                      v-model="recruiter"
                      :filter="utils.comboFilterPicker"
                      append-icon="mdi-account-outline"
                      :rules="[rules.required]"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content style="max-width: 800px">
                          <v-list-item-title>
                            {{ item.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                class="expand"
                v-for="(c, ci) in docClassifiers"
                :key="'c' + ci"
              >
                <v-row>
                  <v-col cols="11">
                    <v-combobox
                      dense
                      outlined
                      clearable
                      class="roundish"
                      :return-object="true"
                      :items="c.values"
                      :label="c.name"
                      item-text="value"
                      item-value="cv_id"
                      v-model="c.selected"
                      :filter="utils.comboFilterPicker"
                      hide-details
                      :rules="[rules.required]"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content style="max-width: 800px">
                          <v-list-item-title>
                            {{ item.value }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              No matching results.
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                v-if="multiLifecycles.length && !this.isActionSelected"
              >
                <v-radio-group
                  v-model="selectedActionId"
                  row
                  v-if="multiLifecycles.length > 2"
                  @change="setSelected"
                >
                  <v-radio
                    v-for="(l, li) in multiLifecycles"
                    :key="'l' + li"
                    :label="l.lifecycle_selection_text || 'Default'"
                    :value="l.lifecycle_action_id"
                  ></v-radio>
                </v-radio-group>
                <v-switch
                  v-else
                  v-model="selectedActionNonDefault"
                  inset
                  :label="multiLifecycles[1].lifecycle_selection_text"
                  @change="setSelected"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                v-if="
                  selectedTemplate &&
                  !selectedTemplate.usesWorkflow &&
                  showReqField &&
                  showReqForDocType
                "
              >
                <v-row>
                  <v-col cols="11">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      class="roundish"
                      v-model="requisition_number"
                      :rules="reqIdRequired ? [rules.required] : []"
                      :label="`Requisition ID ${reqIdRequired ? '*' : ''}`"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1"></v-col>
                </v-row>
              </v-col>
              <!-- <v-col
                cols="12"
                v-if="selectedAction && selectedAction.data_capture_method"
              >
                <v-row>
                  <v-col cols="11">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      class="roundish"
                      v-model="selectedAction.data_capture_value"
                      :label="selectedAction.data_capture_label"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1"
                    ><v-tooltip left v-if="selectedAction.data_capture_tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"> mdi-info </v-icon>
                      </template>
                      <span>{{ selectedAction.data_capture_tooltip }}</span>
                    </v-tooltip></v-col
                  >
                </v-row>
              </v-col> -->
              <template v-if="selectedAction && distinctDataCaptures.length">
                <v-col
                  cols="12"
                  v-for="dc in distinctDataCaptures"
                  :key="'dc' + dc.action_available_id"
                >
                  <v-row v-if="dc.method === 'requisition'">
                    <v-col cols="11" class="pb-0">
                      <v-text-field
                        dense
                        outlined
                        clearable
                        class="roundish"
                        v-model="dc.value"
                        :label="`${dc.input_label} ${
                          dc.data_capture_mandatory ? '*' : ''
                        }`"
                        :rules="dc.validationRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="pb-0"
                      ><v-tooltip left v-if="dc.tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"> mdi-info </v-icon>
                        </template>
                        <span>{{ dc.tooltip }}</span>
                      </v-tooltip></v-col
                    >
                  </v-row>
                  <v-row v-else-if="dc.method === 'form'">
                    <v-col cols="11" class="pb-0">
                      <template>
                        <v-text-field
                          v-for="ip in dc.inputs"
                          :key="
                            'dc' +
                            dc.action_available_id +
                            '_' +
                            ip.check_type_id
                          "
                          dense
                          outlined
                          clearable
                          class="roundish"
                          v-model="ip.value"
                          :label="`${ip.question_text} ${
                            dc.data_capture_mandatory ? '*' : ''
                          }`"
                          :rules="ip.validationRules"
                        ></v-text-field>
                      </template>
                    </v-col>
                    <v-col cols="1" class="pb-0"
                      ><v-tooltip left v-if="dc.tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"> mdi-info </v-icon>
                        </template>
                        <span>{{ dc.tooltip }}</span>
                      </v-tooltip></v-col
                    >
                  </v-row>
                  <v-row v-else-if="dc.method === 'review_group'">
                    <v-col>
                      <AssignReviewGroup
                        :docType="docType"
                        :action="dc"
                        viewType="dense_contained"
                        @change="dc.value = $event"
                      >
                      </AssignReviewGroup>
                    </v-col>
                    <v-col cols="1"> </v-col>
                  </v-row>
                  <v-row v-else-if="dc.method === 'hierarchy'">
                    <v-col>
                      <DataCaptureHierarchy
                        :documentHierarchies="applicableHierarchyTypes"
                        :action="selectedAction"
                        @change="dc.value = $event"
                      >
                      </DataCaptureHierarchy>
                    </v-col>
                    <v-col cols="1"> </v-col>
                  </v-row>
                </v-col>
              </template>
              <v-col dense cols="12" v-if="showInheritSkills">
                <v-row dense>
                  <v-col dense cols="11" style="display:flex; align-items: center;"><v-switch dense @change="toggleSkillInheritence($event)"></v-switch><span style="padding-top:5px; padding-left:10px">Inherit Skills From Framework?</span></v-col>
                </v-row>      
              </v-col>
            </v-form>
          </v-col>
          <v-col cols="8">
            <DocsWithPartsNew
              v-if="filters"
              :fullScreen="false"
              :enablePreview="true"
              :clickToPreview="true"
              :pageSize="20"
              :height="resultsHeight"
              :showHeaderCount="true"
              :showFooter="false"
              :showFilters="false"
              :filters="filters"
              density="normal"
              @sessionExpired="sessionExpired"
              @selectDocument="selectedDocument = $event"
              @deSelectDocument="selectedDocument = null"
              @openDocument="openDocument"
              @copyDocument="copyDocument"
              @documentChangedHandler="documentChangedHandler"
            ></DocsWithPartsNew>
          </v-col>
        </v-row>
        <div class="ml-1 mt-6">Fields marked * are required.</div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="!createExternal"
          large
          class="roundish ml-2 px-5"
          color="primarylight"
          @click="createDocument(false)"
          >Create New Document</v-btn
        >
        <FileUploader
          class="ml-2"
          v-if="showUploadButton"
          :fileUploadData="fileUploadFormData"
          :validationFunction="isCreateAllowed"
          :uploadedCallback="filesUploadedCallback"
          :useTranslation="useTranslation"
          multiple="false"
        />
        <v-spacer />
        <v-btn
          v-if="selectedDocument"
          large
          class="roundish px-5"
          color="primarylight"
          @click="copyDocument(selectedDocument)"
          >Copy Document</v-btn
        >
      </v-card-actions>
    </v-card>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <Loading :isVisible="storeDataLoading || isLoading" />
  </div>
</template>

<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import FileUploader from "../components/cFileUpload.vue";
import utils from "@/common/utils.js";
import DocsWithPartsNew from "@/components/cDocumentsWithPartsNew";
import { mapState } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import AssignReviewGroup from "@/components/cAssignReviewGroup.vue";
import DataCaptureHierarchy from "@/components/cDataCaptureHierarchy.vue";
import CountryFlag from "vue-country-flag";

export default {
  name: "DocumentAdd",
  components: {
    ResponseHandler,
    FileUploader,
    DocsWithPartsNew,
    HierarchyTreePicker,
    AssignReviewGroup,
    DataCaptureHierarchy,
    CountryFlag,
  },
  props: {
    open: null,
    targetDoc: String,
  },
  data: function () {
    return {
      useTranslation: false,
      createExternal: false,
      creationDisabled: true,
      canAddHierarchy: -1,
      canAddRecruiter: -1,
      hierarchies: [],
      fileUploadFormData: {},
      locations: [],
      costcentres: [],
      recruiters: [],
      managers: [],
      jobtypes: [],
      docClassifiers: [],
      jobtype: "",
      location: "",
      costcentre: "",
      recruiter: "",
      response: null,
      document: null,
      focusHTID: null,
      isLoading: false,
      utils: utils,
      docNames: ["Job Advert"],
      docType: null,
      filters: null,
      selectedDocument: null,
      recruiterAltLabel: "Recruiter",
      defaultDocType: "",
      requisition_number: "",
      review_group_id: null,
      useTreePicker: false,
      targetDocType: "",
      selectedTemplate: null,
      selectedAction: null,
      selectedHierarchies: [],
      selectedActionId: null,
      selectedActionNonDefault: false,
      multiLifecycles: [],
      createExtraFields: [],
      currentFields: [],
      show: false,
      previewHierarchies: [],
      isActionSelected: false,
      hideRecruiter: false,
      matchAllDocTypes: false,
      recruiterRestrict: false,
      selectedTranslateLang: "en",
      rules: {
        required: (value) => !!value || "Required",
      },
      applicableHierarchyTypes: [],
      showInheritSkills: false,
      useInheritSkills: false
    };
  },
  created() {},
  updated() {},
  computed: {
    ...mapState({
      config: (state) => state.settings.config,
    }),
    skillsModule(){
      return this.$loginState.user.settings.some((s) => s.setting === 'roleskill_module' && s.value === "true") && 
             this.$loginState.user.settings.some((s) => s.setting === 'roleskill_createdialog_inheritskills' && s.value === "true");
    },   
    targetDocProvided() {
      return this.targetDoc !== undefined && this.targetDoc !== "";
    },
    resultsHeight() {
      if (this.$vuetify.breakpoint.smAndDown) return "calc(90vh - 320px)";
      else if (this.$vuetify.breakpoint.mdAndDown) return "calc(90vh - 260px)";
      else return "calc(90vh - 160px)";
    },
    ...mapState({
      storeDataLoading: (state) =>
        state.hierarchies.loading || state.docs.loading,
      docClassifierRules: (state) => state.hierarchies.docClassifierRules,
      docStatusSummary: (state) => state.docs.docStatusSummary,
    }),
    showUploadButton() {
      return (
        this.createExternal ||
        this.$loginState.user.settings.some(
          (s) =>
            s.setting === "createdialog_showuploadforall" && s.value !== "false"
        )
      );
    },
    showLanguageSelection() {
      return this.config.settings.some(
        (s) =>
          s.setting === "createdialog_showlanguageselection" &&
          s.value !== "false"
      );
    },
    showReqField() {
      return this.config.settings.some(
        (s) => s.setting === "ManualReqEntry" && s.value === "true"
      );
    },
    showReqForDocType() {
      let setting = this.$loginState.user.settings.find(
        (s) => s.setting === "createdialog_req_id_doctype"
      );
      if (setting !== undefined) {
        let docTypes = setting.value.split("|");
        return docTypes.indexOf(this.docType) > -1;
      } else {
        return false;
      }
    },
    reqIdRequired() {
      return this.config.settings.some(
        (s) =>
          s.setting === "createdialog_req_id_required" && s.value === "true"
      );
    },
    distinctDataCaptures() {
      if (
        !this.selectedAction ||
        !this.selectedAction.dataCaptures ||
        !this.selectedAction.dataCaptures.length
      )
        return [];

      return this.selectedAction.dataCaptures
        .filter((dc) =>
          ["requisition", "form", "review_group", "hierarchy"].includes(
            dc.method
          )
        )
        .filter(
          (dc, i, a) =>
            dc.method !== "hierarchy" &&
            a.findIndex(
              (dco) => dco.data_capture_type_id === dc.data_capture_type_id
            ) === i
        )
        .map((dc) => {
          dc.validationRules = [];
          if (dc.data_capture_mandatory) {
            dc.validationRules.push(this.rules.required);
          }
          if (dc.format_regex) {
            const regex = new RegExp(dc.format_regex);
            dc.validationRules.push(
              (v) =>
                !v || regex.test(v) || dc.format_helptext || "Invalid format"
            );
          }
          if (dc.inputs?.length) {
            dc.inputs.forEach((ip) => {
              ip.validationRules = [];
              if (dc.data_capture_mandatory) {
                ip.validationRules.push(this.rules.required);
              }
              if (ip.format_regex) {
                const regex = new RegExp(ip.format_regex);
                ip.validationRules.push(
                  (v) =>
                    !v ||
                    regex.test(v) ||
                    ip.format_helptext ||
                    "Invalid format"
                );
              }
            });
          }
          return dc;
        });
    },
  },
  watch: {
    open: {
      immediate: true,
      handler(val) {
        if (val && !this.storeDataLoading) {
          this.clearFilters();
          this.setData();
          this.changeDocType();
        }
      },
    },
    storeDataLoading(val) {
      if (!val) {
        this.clearFilters();
        this.setData();
        this.changeDocType();
      }
    },
    docType() {
      this.changeDocType();
    },
  },
  methods: {
    toggleSkillInheritence(e){
      this.useInheritSkills = e;
    },
    filterAdditionalFields(docType) {
      this.currentFields =
        this.createExtraFields.find((x) => x.template_name === docType)
          ?.fields || [];
      //alert(this.currentFields.length);
    },
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    openDocument(item) {
      this.$emit("openDocument", item);
    },
    copyDocument(item, tmpl_id) {
      this.closeAddDocument();
      this.$emit("copyDocument", item, tmpl_id);
    },
    documentChangedHandler(item) {
      this.$emit("documentChangedHandler", item);
    },
    onHrySelect(ht) {
      ht.newLeafNodeAdded = false;

      this.isActionSelected = this.applicableHierarchyTypes.some(
        (h) => h.treePickerValue === -999
      );

      this.setSelected();
      this.checkCriteriaRules();
      this.buildFilters();

      if(this.skillsModule && ht.label.toLowerCase().includes('job')){

        let data = { 
          hr_id: ht.treePickerValue, 
          tmpl_id: this.selectedTemplate.tmpl_id
        }

        axios.get("skills/checkSkillData", { params: data })    
        .then((resp) => {
          this.showInheritSkills = resp.data;
        })
        .catch(err => {
            console.log(err);
        });
      }
    },
    nodeAdded(ht, newNode) {
      this.isActionSelected = true;
      ht.newLeafNodeAdded = true;
      ht.treePickerValue = newNode.hierarchy_node.hr_id;

      this.setSelected();
      this.checkCriteriaRules();
      this.buildFilters();
    },
    checkCriteriaRules() {
      if (this.applicableHierarchyTypes.some((h) => !h.hierarchy_text)) return;

      const hSelected = {};
      this.hierarchies.forEach((h) => {
        let sel = h.values.find(
          (v) => v.text.toLowerCase() === h.hierarchy_text.toLowerCase()
        );
        if (sel) {
          sel.level1 = sel.level1?.toLowerCase();
          sel.level2 = sel.level2?.toLowerCase();
          sel.level3 = sel.level3?.toLowerCase();
          sel.level4 = sel.level4?.toLowerCase();
          sel.level5 = sel.level5?.toLowerCase();
          sel.level6 = sel.level6?.toLowerCase();
        }
        hSelected[h.ht_id] = sel;
      });

      this.docClassifierRules.some((dc) => {
        let allCriteriaMet = true;
        dc.criteria.forEach((dcc) => {
          if (allCriteriaMet) {
            const sel = hSelected[dcc.ht_id] || {};
            if (
              (dcc.h_level1 !== null &&
                sel.level1 !== dcc.h_level1.toLowerCase()) ||
              (dcc.h_level2 !== null &&
                sel.level2 !== dcc.h_level2.toLowerCase()) ||
              (dcc.h_level3 !== null &&
                sel.level3 !== dcc.h_level3.toLowerCase()) ||
              (dcc.h_level4 !== null &&
                sel.level4 !== dcc.h_level4.toLowerCase()) ||
              (dcc.h_level5 !== null &&
                sel.level5 !== dcc.h_level5.toLowerCase()) ||
              (dcc.h_level6 !== null &&
                sel.level6 !== dcc.h_level6.toLowerCase())
            )
              allCriteriaMet = false;
          }
        });
        if (allCriteriaMet) {
          const classifier = this.docClassifiers.find(
            (c) => c.ct_id === dc.ct_id
          );
          if (classifier && !classifier.selected) {
            const val = classifier.values.find((v) => v.cv_id === dc.cv_id);
            if (val) {
              this.response = {
                Message: `${classifier.name}: ${val.value} selected.${
                  dc.comments ? " Justification: " : ""
                }${dc.comments}`,
                Data: null,
                Status: "OK",
                RowsUpdated: 1,
              };
              classifier.selected = val;
            }
          }
        }
        return allCriteriaMet;
      });
    },
    buildFilters() {
      let sourceFilters = this.applicableHierarchyTypes.filter(
        (x) => this.previewHierarchies.indexOf(x.ht_name) > -1
      );
      let hfilter = this.useTreePicker
        ? sourceFilters
            .filter((ht) => ht.treePickerValue)
            .map((ht) => {
              return {
                ht_id: ht.ht_id,
                selected: [{ id: `${ht.treePickerValue}` }],
              };
            })
        : sourceFilters
            .filter((ht) => ht.hierarchy_text)
            .map((ht) => {
              let sel = sourceFilters
                .find((h) => h.ht_id === ht.ht_id)
                .values.find(
                  (v) =>
                    v.text.toLowerCase() === ht.hierarchy_text.toLowerCase()
                );
              return {
                ht_id: ht.ht_id,
                selected: sel ? [{ id: `${sel.value}` }] : [],
              };
            });
      let recFilter =
        this.recruiter &&
        !this.hideRecruiter &&
        this.recruiters.some((x) => x.text === this.recruiter)
          ? this.recruiter
          : "";
      if (!hfilter.length && !recFilter) this.filters = null;
      else {
        let docStatuses = this.docStatusSummary.filter(
          (s) => s.status !== "Deleted"
        );

        let docStatusSetting = this.$loginState.user.settings.find(
          (s) => s.setting === "createdialog_doc_statuses"
        );
        if (docStatusSetting) {
          let list = JSON.parse(docStatusSetting.value);
          docStatuses = this.docStatusSummary.filter((s) =>
            list.includes(s.status)
          );
        }

        this.filters = {
          docStatusIncluded: docStatuses,
          canCopyTo: this.docType,
          hierarchyFilters: hfilter,
          peopleIncluded:
            recFilter && !this.hideRecruiter
              ? [{ column: "recruiter", values: [recFilter] }]
              : [],
        };

        switch (
          this.$loginState.user.settings.find(
            (s) => s.setting === "createdialog_doc_type_matching"
          )?.value
        ) {
          case "single":
            this.filters.docTypeIncluded = [{ docType: this.docType }];
            this.filters.canCopyTo = null;
            break;
          case "all":
            this.filters.docTypeIncluded = null;
            this.filters.canCopyTo = null;
            break;
          case "can_copy_to":
          default:
            this.filters.docTypeIncluded = null;
            this.filters.canCopyTo = this.docType;
            break;
        }
      }
      this.selectedDocument = null;
    },
    isCreateAllowed() {
      this.setSelected();

      this.applicableHierarchyTypes.forEach((h) => {
        h.lostFocus = true;
      });

      if (!this.$refs.createDocForm.validate()) return false;

      let reqIdRequired =
        this.showReqField && !this.selectedTemplate?.usesWorkflow
          ? this.reqIdRequired
            ? true
            : false
          : false;

      let dataCaptures = this.distinctDataCaptures || [];
      let isValid = (value, mandatory, format_regex) => {
        const regex = format_regex ? new RegExp(format_regex) : null;
        if ((!value && mandatory) || (value && regex && !regex.test(value))) {
          return false;
        } else {
          return true;
        }
      };
      let dataCapturesValid = true;
      dataCaptures
        .filter((dc) => !dc.questionnaire_id)
        .forEach((dc) => {
          if (dc.inputs.length) {
            dc.inputs.forEach((ip) => {
              if (
                !isValid(ip.value, dc.data_capture_mandatory, ip.format_regex)
              )
                dataCapturesValid = false;
            });
          } else {
            if (!isValid(dc.value, dc.data_capture_mandatory, dc.format_regex))
              dataCapturesValid = false;
          }
        });

      let creationDisabled =
        this.applicableHierarchyTypes.filter((h) =>
          this.useTreePicker ? !!h.treePickerValue : h.hierarchy_text
        ).length !== this.applicableHierarchyTypes.length ||
        (!this.recruiter && !this.hideRecruiter) ||
        (reqIdRequired ? !this.requisition_number : false) ||
        !dataCapturesValid;

      if (!creationDisabled) {
        this.fileUploadFormData = {
          hr_ids: [],
          hierarchies: [],
          doc_id: [],
          doc_type: this.docType,
          createFiles: true,
          action: null,
          parseDoc: false,
          selectdTranslateLang: this.selectedTranslateLang,
          docClassifiers: [],
        };
        this.applicableHierarchyTypes.forEach((dh) => {
          if (dh.hierarchy_text) {
            let rootHierarchy = this.applicableHierarchyTypes.filter(
              (rh) => rh.ht_id == dh.ht_id
            );
            if (rootHierarchy) {
              let matchedHierarchy = rootHierarchy[0].values.find(
                (h) => h.text == dh.hierarchy_text
              );
              if (matchedHierarchy) {
                this.fileUploadFormData.hr_ids.push(matchedHierarchy.value);
              }
            }
          } else {
            if (dh.treePickerValue) {
              this.fileUploadFormData.hr_ids.push(dh.treePickerValue);
            }
          }
        });

        if (this.docType == "External File") {
          this.fileUploadFormData.createFiles = false;
          this.fileUploadFormData.parseDoc = false;
        }

        let data = this.checkNew();
        if (data) {
          this.fileUploadFormData.action = data.action;
          this.fileUploadFormData.hierarchies = data.hierarchies;
          this.fileUploadFormData.recruiter = data.recruiter;
          this.fileUploadFormData.extraFields = data.extraFields;
          if (data.dataCaptures) {
            this.fileUploadFormData.dataCaptures = data.dataCaptures;
          }
          if (data.docClassifiers && data.docClassifiers.length) {
            this.fileUploadFormData.docClassifiers = data.docClassifiers;
          }
        }

        this.fileUploadFormData = JSON.parse(
          JSON.stringify(this.fileUploadFormData)
        );
      }

      return !creationDisabled;
    },
    changeDocType() {
      this.selectedTemplate =
        this.$loginState.user.createDocumentDialogOptions.find(
          (a) => a.tmpl_name === this.docType
        );
      this.applicableHierarchyTypes = this.hierarchies.filter(
        (x) =>
          !this.selectedTemplate.hiddenHierarchyTypes.some((h) => h === x.ht_id)
      );
      this.selectedTranslateLang = "en";
      this.selectedActionNonDefault = false;
      this.multiLifecycles = [];
      if (this.selectedTemplate) {
        if (
          this.selectedTemplate.lifecycles.length > 1 &&
          this.selectedTemplate.lifecycles.some(
            (l) =>
              l.lifecycle_id !==
              this.selectedTemplate.lifecycles[0].lifecycle_id
          )
        ) {
          this.selectedTemplate.lifecycles.forEach((l) => {
            if (
              !this.multiLifecycles.some(
                (m) => m.lifecycle_id === l.lifecycle_id
              )
            )
              this.multiLifecycles.push(l);
          });
        }
        if (this.selectedTemplate.lifecycles.length === 1) {
          this.selectedActionId =
            this.selectedTemplate.lifecycles[0].lifecycle_action_id;
        } else if (
          this.selectedTemplate.lifecycles.length > 1 &&
          this.selectedTemplate.lifecycles.some((x) => x.isDefault)
        ) {
          let ls = this.selectedTemplate.lifecycles.sort((a, b) =>
            a.isDefault < b.isDefault
              ? 1
              : a.isDefault > b.isDefault
              ? -1
              : a.ht_id_can_add_node.length > b.ht_id_can_add_node.length
              ? 1
              : a.ht_id_can_add_node.length < b.ht_id_can_add_node.length
              ? -1
              : 0
          );
          this.selectedActionId = ls[0].lifecycle_action_id;
        } else {
          this.selectedActionId = null;
        }
        let htTrigger = this.selectedTemplate.lifecyclesHT.length
          ? this.selectedTemplate.lifecyclesHT[0]
          : null;
        this.applicableHierarchyTypes.forEach((ht) => {
          let trigger = ht.values.findIndex((v) => v.isActionTrigger);
          if (trigger >= 0) {
            ht.values.splice(trigger, 1);
          }
          trigger = ht.treePickerOptionsActive.findIndex(
            (v) => v.isActionTrigger
          );
          if (trigger >= 0) {
            ht.treePickerOptionsActive.splice(trigger, 1);
          }
          if (htTrigger && ht.ht_id === htTrigger.ht_id_empty_trigger) {
            ht.values.unshift({
              hr_id: -999,
              text: htTrigger.lifecycle_selection_text,
              isActionTrigger: true,
            });
            ht.treePickerOptionsActive.unshift({
              id: -999,
              label: htTrigger.lifecycle_selection_text,
              isActionTrigger: true,
            });
          }
          // check for possibility of allow add leaf node
          ht.allowAddLeafNode = false;
          if (this.selectedActionId) {
            let selLifeCyle = this.selectedTemplate.lifecycles.find(
              (x) => x.lifecycle_action_id === this.selectedActionId
            );
            let lifecycleActions = selLifeCyle
              ? this.selectedTemplate.lifecycles.filter(
                  (x) => x.lifecycle_id === selLifeCyle.lifecycle_id
                )
              : [];
            ht.allowAddLeafNode =
              lifecycleActions.length &&
              lifecycleActions.some((a) =>
                a.ht_id_can_add_node.some((n) => n === ht.ht_id)
              );
          }
        });
      }

      this.toggleDocTypeSelection();

      this.hideRecruiterField();

      this.setSelected();
    },
    toggleDocTypeSelection() {
      this.filterAdditionalFields(this.docType);
      this.createExternal = this.docType === "External File" ? true : false;
      this.buildFilters();
    },
    filesUploadedCallback(data) {
      this.$store.dispatch("files/reload");
      let responseData = data[0];
      if (responseData.newDocuments && responseData.newDocuments.length > 1) {
        //doc already created
        this.isLoading = false;
        this.clearFilters();
        this.$emit(
          "created",
          responseData.newDocuments[responseData.newDocuments.length - 1],
          true
        );
      } else {
        this.isLoading = false;
        this.createDocument(data);
      }
    },
    hideRecruiterField() {
      let hideRecruiterField = this.$loginState.user.settings.find(
        (s) => s.setting === "createdialog_hide_recruiter_field"
      );
      if (this.docType && hideRecruiterField) {
        let docTypes = hideRecruiterField.value.split("|").map((a) => {
          return a;
        });
        this.hideRecruiter = docTypes.some(
          (x) => x.toLowerCase() === this.docType.toLowerCase()
        );
      } else {
        this.hideRecruiter = false;
      }

      if (this.hideRecruiter && this.recruiter) {
        this.recruiter = "";
        this.onHrySelect();
      }
    },
    setData() {
      if (
        !this.$store.state.hierarchies.loading &&
        this.$store.state.hierarchies.hierarchies.length
      ) {
        this.$refs.createDocForm?.resetValidation();
        this.useTreePicker = this.config.settings.some(
          (s) => s.setting === "hierarchy_tree_picker" && s.value === "true"
        );
        this.hierarchies = this.$store.state.hierarchies.hierarchies.map(
          (h) => {
            const local = { ...h, lostFocus: false };
            switch (local.label.toLowerCase()) {
              case "geography":
              case "location":
                h.icon = "mdi-map-marker-outline";
                break;
              default:
                h.icon = "mdi-menu-down";
                break;
            }
            return local;
          }
        );

        let altLabel = this.$loginState.user.settings.find(
          (s) => s.setting === "RecruiterAltLabel"
        );
        if (altLabel !== undefined && altLabel.value !== " ") {
          this.recruiterAltLabel = altLabel.value;
        }

        this.recruiterRestrict = !!this.$loginState.user.settings.find(
          (s) =>
            s.setting === "recruiter_restrict_to_users" && s.value === "true"
        );

        let defaultDocType = this.$loginState.user.settings.find(
          (s) => s.setting === "DefaultCreationDocType"
        );
        if (defaultDocType !== undefined && defaultDocType.value !== " ") {
          this.defaultDocType = defaultDocType.value;
        }

        let translateSetting = this.$loginState.user.settings.find(
          (s) => s.setting === "UseTranslation"
        );
        if (translateSetting) {
          this.useTranslation = translateSetting.value == "true" ? true : false;
          this.useTranslation = JSON.parse(JSON.stringify(this.useTranslation));
        } else {
          this.useTranslation = false;
        }

        let previewHierarchies = this.$loginState.user.settings.find(
          (s) => s.setting === "createdialog_preview_hierarchy_filters"
        );
        if (previewHierarchies) {
          this.previewHierarchies = JSON.parse(previewHierarchies.value);
        } else {
          this.previewHierarchies =
            this.$store.state.hierarchies.hierarchies.map((h) => {
              return h.ht_name;
            });
        }

        this.recruiters = JSON.parse(
          JSON.stringify(this.$store.state.hierarchies.recruiters)
        );
        this.managers = JSON.parse(
          JSON.stringify(this.$store.state.hierarchies.managers)
        );
        this.docNames = this.$loginState.user.createDocumentDialogOptions
          .sort((a, b) => a.tmpl_id - b.tmpl_id)
          .map((dt) => dt.tmpl_name);

        this.docClassifiers = this.$store.state.hierarchies.docClassifiers.map(
          (c) => {
            return {
              ct_id: c.ct_id,
              name: c.name,
              selected: null,
              values: c.values,
            };
          }
        );

        if (!this.docType || !this.docNames.includes(this.docType)) {
          this.docType = this.docNames[0];
        }

        if (this.targetDocProvided) {
          this.docType = this.targetDoc;
        } else if (this.defaultDocType !== "") {
          let idx = this.docNames.indexOf(this.defaultDocType);
          if (idx > -1) {
            this.docType = this.docNames[idx];
          }
        }

        this.canAddHierarchy = this.$store.state.hierarchies.canAddHierarchy;
        this.canAddRecruiter = this.$store.state.hierarchies.canAddRecruiter;
      } else if (this.hierarchies.length) {
        this.hierarchies.forEach((h) => (h.treePickerValue = null));
      }

      this.hideRecruiterField();

      if (
        !this.$store.state.docs.loading &&
        this.$store.state.docs.createExtraFields.length &&
        !this.createExtraFields.length
      ) {
        this.createExtraFields = this.$store.state.docs.createExtraFields;
        this.filterAdditionalFields(this.docType);
      }
    },
    closeAddDocument() {
      this.creationDisabled = true;
      this.$emit("closed");
    },
    clearFilters() {
      this.applicableHierarchyTypes.forEach((dh) => {
        dh.hierarchy_text = null;
        if (dh.treePickerValue) {
          dh.treePickerValue = null;
        }
      });
      this.docClassifiers.forEach((dc) => (dc.selected = null));
      this.recruiter = null;
      this.creationDisabled = true;
      this.selectedDocument = null;
      this.selectedTemplate = null;
      this.docType = null;
      if (this.currentFields.length > 0) {
        this.currentFields.forEach((ef) => {
          ef.value = null;
        });
      }
      this.$loginState.user.createDocumentDialogOptions.forEach((o) => {
        o.lifecycles.forEach((ls) => {
          ls.dataCaptures?.forEach((dc) => {
            dc.value = null;
            dc.inputs.forEach((ip) => {
              ip.value = null;
            });
          });
        });
      });
      this.requisition_number = "";
      this.buildFilters();
    },
    linkFileToDoc(docData) {
      this.isLoading = true;
      let data = {
        doc_id: docData.doc_id,
        file_id: docData.file_data[0].file_id,
      };

      axios
        .post("file/linkFileToDoc/", data)
        .then((resp) => {
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    createDocument(uploaded) {
      if (!this.isCreateAllowed()) {
        return false;
      }

      let data = this.checkNew();
      if (!data) return;

      this.isLoading = true;
      axios
        .post("document/createDocument/", data)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            if (uploaded && uploaded.length && uploaded[0].file_id) {
              //todo link doc
              resp.data.Data.file_data = uploaded;
              this.linkFileToDoc(resp.data.Data);
            }
            this.clearFilters();
            this.creationDisabled = true;
            if (data.hierarchies.some((x) => x.isNew))
              this.$store.dispatch("hierarchies/refresh");
            this.$emit("created", resp.data.Data);
          } else {
            alert(resp.data.Message);
          }
          this.response = resp.data;
          console.log(resp);
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
        });
    },
    setSelected() {
      this.selectedAction = null;
      this.selectedHierarchies = null;
      if (!this.selectedTemplate) return;
      this.selectedHierarchies = this.applicableHierarchyTypes.map((h) => {
        let hr = null;
        if (this.useTreePicker) {
          hr = { value: h.treePickerValue };
        } else {
          hr = h.values.find(
            (v) =>
              v.text.toLowerCase() === (h.hierarchy_text || "").toLowerCase() &&
              v.value
          );
        }
        let ret = {
          ht_id: h.ht_id,
          ht_name: h.label.replace("Select ", ""),
          hr_id: hr ? hr.value : h.hierarchy_text,
          isNew: hr ? false : true,
          wasMissing: h.missing,
          hrAdded: !!h.newLeafNodeAdded,
        };
        if (ret.isNew && !h.levels.some((l) => !l.selected)) {
          let missing = false;
          h.levels.forEach((l) => {
            ret["level" + l.index] = l.selected.text;
            if (!l.selected.text) missing = true;
          });
          if (missing) {
            h.levels.forEach((l) => {
              ret["level" + l.index] = null;
            });
          }
        }
        return ret;
      });

      if (this.selectedTemplate.lifecyclesHT.length) {
        let htTrigger = this.selectedHierarchies.find(
          (h) =>
            h.ht_id ===
            this.selectedTemplate.lifecyclesHT[0].ht_id_empty_trigger
        );
        if (htTrigger.hrAdded) {
          this.selectedAction = this.selectedTemplate.lifecyclesHT[0];
          htTrigger.hr_id =
            this.currentFields && this.currentFields.length
              ? this.currentFields[0].value
              : "No " + htTrigger.ht_name; // TODO - link to template_lifecycle so is flexible
          htTrigger[
            "level" +
              this.hierarchies.find((x) => x.ht_id === htTrigger.ht_id)
                .linklevel
          ] = htTrigger.hr_id;
          htTrigger.isNew = true;
        } else {
          this.selectedAction = this.selectedTemplate.lifecyclesHT.find(
            (x) =>
              x.ht_id_empty_trigger === htTrigger.ht_id &&
              htTrigger.hr_id === -999
          );
        }
      }
      if (!this.selectedAction && this.selectedTemplate.lifecycles.length) {
        if (
          this.selectedTemplate.lifecycles.some(
            (x) => x.ht_id_can_add_node.length
          ) &&
          this.selectedHierarchies.some((h) => h.isNew && h.hr_id)
        ) {
          this.selectedAction = this.selectedTemplate.lifecycles.find(
            (x) => x.ht_id_can_add_node.length
          );
        } else if (this.multiLifecycles.length === 2) {
          this.selectedAction =
            this.multiLifecycles[this.selectedActionNonDefault ? 1 : 0];
        } else {
          this.selectedAction = this.selectedTemplate.lifecycles.find(
            (x) => x.lifecycle_action_id === this.selectedActionId
          );
        }
      }
    },
    checkNew() {
      if (!this.selectedTemplate) return;
      if (this.selectedTemplate.usesWorkflow && !this.selectedAction) return;
      let data = {
        recruiter: this.hideRecruiter
          ? this.$loginState.user.name
          : this.recruiter,
        docType: this.docType,
        language: this.selectedTranslateLang,
        hierarchies: this.selectedHierarchies,
        docClassifiers: this.docClassifiers.map((c) => {
          return {
            ct_id: c.ct_id,
            cv_id: c.selected?.cv_id,
          };
        }),
        action: this.selectedAction,
        inheritSkills: this.useInheritSkills
      };

      if (
        !this.selectedTemplate.usesWorkflow &&
        this.requisition_number !== ""
      ) {
        data.requisition_numbers = this.requisition_number;
      }

      if (this.currentFields.length > 0) {
        data.extraFields = this.currentFields;
      }

      if (
        this.selectedTemplate.usesWorkflow &&
        this.distinctDataCaptures.length
      ) {
        data.dataCaptures = this.distinctDataCaptures.map((x) => x);
        data.dataCaptures.forEach((dc) => {
          if (dc.method === "requisition") {
            dc.requisitions = [
              {
                isNew: true,
                requisition_number: dc.value,
              },
            ];
          }
        });
        // if (this.selectedAction.data_capture_method === "requisition") {
        //   data.data_capture_value = {
        //     requisitions: [
        //       {
        //         isNew: true,
        //         requisition_number: this.selectedAction.data_capture_value,
        //       },
        //     ],
        //   };
        // } else {
        //   data.data_capture_value = this.selectedAction.data_capture_value;
        // }
      }

      return data;
    },
    classifyHierarchy(hierarchy, level) {
      if (level.index === hierarchy.levels[hierarchy.levels.length - 1].index) {
        return;
      }
      let penultimateIndex =
        hierarchy.levels[hierarchy.levels.length - 1].index;
      let doneN = false;
      hierarchy.levels.forEach((l) => {
        if (l.index > level.index) {
          l.items.splice(0);
          if (!doneN) {
            l.items.push(...level.selected.items);
            if (l.index === penultimateIndex) {
              let newItem = {
                text: hierarchy.hierarchy_text,
                level: penultimateIndex,
                items: [],
              };
              l.items.push(newItem);
              hierarchy.levels[hierarchy.levels.length - 1].selected = newItem;
            }

            doneN = true;
          }
        }
      });
    },
    pickValue(hrchy, isnew) {
      let ctl = this.$refs["hcb" + this.focusHTID][0],
        h = this.applicableHierarchyTypes[this.focusHTID];
      ctl.isMenuActive = false;
      if (
        this.canAddHierarchy !== 0 &&
        (isnew || !ctl.filteredItems.length) &&
        hrchy.searchText
      ) {
        h.hierarchy_text = hrchy.searchText;
        if (this.canAddHierarchy === 1) {
          hrchy.selectNew = true;
        } else {
          hrchy.values.push({ text: hrchy.searchText });
        }
      } else {
        ctl.lazySearch = h.hierarchy_text;
      }
      hrchy.showNewPrompt = false;
    },
    searchText($event, hrchy) {
      let ctl = this.$refs["hcb" + this.focusHTID][0];
      if ([13, 37, 38, 39, 40].indexOf($event.keyCode) >= 0) return;
      if (ctl.lazySearch) {
        if ($event.keyCode !== 13) hrchy.searchText = ctl.lazySearch.trim();
        let match = ctl.filteredItems.find(
          (f) => f.text.toLowerCase() === ctl.lazySearch.toLowerCase()
        );
        if (match) {
          ctl.selectItem(match);
          hrchy.hierarchy_text = match.text;
          hrchy.hr_id = match.hr_id;
          hrchy.showNewPrompt = false;
        } else {
          hrchy.showNewPrompt =
            ctl.filteredItems.length &&
            (!hrchy.hierarchy_text ||
              hrchy.hierarchy_text.toLowerCase() !==
                hrchy.searchText.toLowerCase());
        }
      } else {
        hrchy.hierarchy_text = "";
        hrchy.showNewPrompt = false;
      }
    },
    cancelNew(h) {
      h.selectNew = false;
      h.searchText = "";
      h.showNewPrompt = false;
      this.creationDisabled = true;
    },
  },
};
</script>
<style scoped lang="scss">
.createTooltip {
  top: 7px;
  left: 10px;
  cursor: pointer;
}

.createNewDialog {
  > .v-card {
    height: calc(90vh - 5px);
    padding: 15px;
    > .v-card__text {
      height: calc(90vh - 140px);
    }
  }
}
.v-toolbar {
  border: 0 !important;
  .v-toolbar__title {
    font-size: 1.375rem;
  }
}
.expand {
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
}
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.dragHandle {
  color: blue;
  cursor: grab;
  /* margin-left: 30%; */
}

.hierarchy-level {
  border: solid 1px #004d40;
  border-radius: 5px;
  display: block;
  height: 24px;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 5px;
  //   font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

::v-deep .v-text-field__details {
  margin-bottom: 0 !important;
}
</style>