
<script setup>
//#region Imports
import { ref, onBeforeMount, onUpdated, watch, reactive, getCurrentInstance, computed } from 'vue';
import { inject } from 'vue';
import axios from 'axios';
import Notification from "@/components/common/SnackBar.vue";
//#endregion

//#region Reactive
const loginState = inject('loginState');
const store = inject('store');

const skillAdminTab = ref(0);

const skillAdminData = reactive({
    loading: true,
    data: [],
    framework: null,
    frameworkItems: [],
    frameworkData: [],
    framework_providers: [],
    selectedProvider: 0,
    headers: [
      { text: 'Skill', value: 'name' },
      { text: 'Core Category', value: 'category' },
      { text: 'Type', value: 'type', width:"250px" },
      { text: 'Active', value: 'active', sortable: false, width:"150px" },
      { text: '', value: 'actions', sortable: false, width:"50px" }
    ],
    notification: {
      text: "",
      type: "success"
    },
    search: '',
    selectedTagType: null
})
//#endregion

//#region Computed
const itemTypes = computed(() => {
  return [...new Set(skillAdminData.frameworkItems.map(x => { return x.type }))]
});

const gradingType = computed(() => {
  let item = skillAdminData.frameworkData.tagGradingTypes.find(x => x.tag_grading_type_id === skillAdminData.framework.tag_grading_type_id);
  if(!item) return [];
  return item.values;
})
//#endregion



//#region Methods

function calcTags(provider_id){
  skillAdminData.selectedProvider = skillAdminData.framework_providers.find(x => x.framework_provider_id === provider_id);
}

function getFramework(){
  skillAdminData.skillLoading = true;
  axios.get("skills/framework")
  .then((resp) => {
    skillAdminData.framework = resp.data.framework;
    skillAdminData.frameworkItems = resp.data.items;
    skillAdminData.frameworkData = resp.data.tag_data;
    skillAdminData.framework_providers = resp.data.framework_providers;
    if(resp.data.framework.tag_type_id){
      skillAdminData.selectedTagType = resp.data.tag_data.tagTypes.find(x => x.tag_type_id ===  resp.data.framework.tag_type_id)
    }
    if(resp.data.framework.framework_provider_id){
      calcTags(resp.data.framework.framework_provider_id);
    }
  })
  .catch(err => {
      console.log(err);
      skillAdminData.skillLoading = false;
  });
}

function getAllSkills() {
  skillAdminData.skillLoading = true;
    axios.get("skills/getAllSkills/")
    .then((resp) => {
      skillAdminData.data = resp.data.skill_data;
    })
    .catch(err => {
        console.log(err);
        skillAdminData.skillLoading = false;
    });
}

function getInstance() {
  getFramework();
  //getAllSkills();
}

function triggerNotification(msg, type){
  skillAdminData.notification = {
    text: msg,
    type: type
  };
}

function toggleSkill(e, item){
  let data = {
    ...item,
    active: e
  }
  axios.post("skills/frameworkSkill", data)
  .then((resp) => {
    triggerNotification("Skill Updated Successfully","success");
  })
  .catch(err => {
    triggerNotification(err,"error");
  });  
}

function edit(item){
  console.log(item);
}

function changeTagType(e){
  skillAdminData.selectedTagType = skillAdminData.frameworkData.tagTypes.find(x => x.tag_type_id === e);
}

function changeCategory(e, item){
  let data = {
    ...item,
    mapping: e
  }
  axios.post("skills/mapFrameworkSkill", data)
  .then((resp) => {
    triggerNotification("Skill Updated Successfully","success");
  })
  .catch(err => {
    triggerNotification(err,"error");
  });  
}

function saveFrameworkConfig(){
  axios.post("skills/saveFrameworkConfig", skillAdminData.framework)
  .then((resp) => {
    skillAdminData.framework = resp.data;
    triggerNotification("Skill Framework Config Updated Successfully","success");
  })
  .catch(err => {
    triggerNotification(err,"error");
  });  
}

function syncTags(){

  let data = {
    ...skillAdminData.framework,
    framework_provider_id: skillAdminData.selectedProvider.framework_provider_id
  }

  axios.post("skills/syncTags", data)
  .then((resp) => {
    triggerNotification("Skill Framework Config Synced Successfully","success");
  })
  .catch(err => {
    triggerNotification(err,"error");
  });  
}

//#endregion



//#region Component
onUpdated(() => {

})

onBeforeMount(() => {
    getInstance();
    //watch(() => store.state.docs.loading, () => docLoaded());
})
//#endregion
</script>
<template>
  <div>
    <v-tabs style="padding-top:15px; width:50%" v-model="skillAdminTab" grow color="#48A401" background-color="transparent">
      <v-tab class="tab ma-0 pa-0">
        Skill Framework Config
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="skillAdminTab" style="">
      <v-tab-item class="tabItem pa-4" style="padding-top:15px">
        <v-row dense v-if="skillAdminData.framework">
          <v-col cols="3">
            Tag Type
          </v-col>
          <v-col cols="9">
            <v-select clearable @change="changeTagType($event)" v-model="skillAdminData.framework.tag_type_id" :items="skillAdminData.frameworkData.tagTypes" item-value="tag_type_id" item-text="tag_type_name"></v-select>
          </v-col>
        </v-row>
        <v-row dense v-if="skillAdminData.framework">
          <v-col cols="3">
            Tag Grading Type
          </v-col>
          <v-col cols="9">
            <v-select clearable v-model="skillAdminData.framework.tag_grading_type_id" :items="skillAdminData.frameworkData.tagGradingTypes" item-value="tag_grading_type_id" item-text="name"></v-select>
          </v-col>
        </v-row>
        <v-row dense v-if="skillAdminData.framework">
          <v-col cols="3">
            Template Type
          </v-col>
          <v-col cols="9">
            <v-select clearable v-model="skillAdminData.framework.tmpl_id" :items="store.state.docs.docTypeSummary" item-value="tmpl_id" item-text="docType"></v-select>
          </v-col>
        </v-row>
        <v-row dense v-if="skillAdminData.framework">
          <v-col cols="3">
            Hierarchy Type
          </v-col>
          <v-col cols="9">
            <v-select clearable v-model="skillAdminData.framework.ht_id" :items="store.state.hierarchies.hierarchies" item-value="ht_id" item-text="label"></v-select>
          </v-col>
        </v-row>
        <v-row dense v-if="skillAdminData.framework">
          <v-col cols="3">
            Tag Classifier Type
          </v-col>
          <v-col cols="9">
            <v-select clearable v-model="skillAdminData.framework.tag_classifier_type_id" :items="skillAdminData.frameworkData.tagClassifierTypes" item-value="tag_classifier_type_id" item-text="name"></v-select>
          </v-col>
        </v-row>
        <v-row dense v-if="skillAdminData.framework && skillAdminData.framework_providers">
          <v-col cols="3">
            Skill Framework Provider
          </v-col>
          <v-col cols="9">
            <v-select @change="calcTags($event)" clearable v-model="skillAdminData.framework.framework_provider_id" :items="skillAdminData.framework_providers" item-value="framework_provider_id" item-text="framework_name"></v-select>
          </v-col>
        </v-row>
        <v-row v-if="skillAdminData.selectedTagType && skillAdminData.selectedProvider" dense>
          <v-col cols="12">
            <h4>Sync Skill Tags with Framework Provider</h4>
          </v-col>
          <v-col cols="12">
            {{ skillAdminData.selectedTagType.values.length }} tag(s) in Selected Tag Type<br/>
            {{ skillAdminData.selectedTagType.values.filter(x => x.inferred_from).length }} tag(s) inferred from a Framework Provider
            <br/>
            <span v-if="skillAdminData.selectedProvider">
              <hr/>              
              {{ skillAdminData.selectedProvider.framework_items.length }} tag(s) in Selected Framework Provider<br/>
              {{ skillAdminData.selectedProvider.framework_items.filter(x => !skillAdminData.selectedTagType.values.map(v => { return v.value}).includes(x.Name || x.Description)).length }} Framework Items not in Selected Skill Tag List
            </span>
          </v-col> 
          <v-col cols="12">
            <v-btn @click="syncTags" :disabled="skillAdminData.selectedProvider === 0">Sync Tags With Skill Framework</v-btn>
          </v-col>       
        </v-row>
        <v-row>
        <v-col cols="11">
        </v-col> 
        <v-col cols="1">
          <v-btn color="primary" @click="saveFrameworkConfig">Save</v-btn>
        </v-col> 
        </v-row> 
      </v-tab-item> 
    </v-tabs-items>
    <Notification :notification="skillAdminData.notification" />
  </div>
</template>
<style lang="scss" scoped>

</style>