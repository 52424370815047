<template>
  <div>
    <v-dialog v-model="editedTag.show" persistent width="500">
      <v-card class="pt-5" v-if="tagType">
        <v-card-text>
          <v-row dense>
            <v-col>
              <h3>
                {{
                  isNew
                    ? "Add New"
                    : editedTag.active
                    ? "Edit"
                    : "Inactive"
                }} {{ tagTypeName }}
              </h3>
            </v-col>
          </v-row>
          <v-row dense v-if="!isNew && editedTag.active">
            <v-col>
              <v-alert dense type="warning"
                >NOTE: Updates to the {{ tagTypeName }} name will affect any documents where
                the {{ tagTypeName }} is present</v-alert
              >
            </v-col>
          </v-row>
          <v-row
            dense
            v-if="
              tagType.values.some(
                (x) =>
                  x.value.toLowerCase() ===
                    editedTag.value.trim().toLowerCase() &&
                  x.tag_value_id !== editedTag.tag_value_id
              )
            "
          >
            <v-col>
              <v-alert type="error">{{ tagTypeName}} already exists</v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                v-model="editedTag.value"
                outlined
                :label="tagTypeName + ' Name'"
                required
                :readonly="!editedTag.active"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-textarea
                v-model="editedTag.description"
                outlined
                label="Description"
                :readonly="!editedTag.active"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row
            dense
            v-for="(ct, cti) in editedTag.classifierTypes"
            :key="'ct_' + cti"
          >
            <v-col cols="11" v-if="allowEditTag">
              <v-text-field
                v-if="ct.classifierValueNew"
                v-model="ct.classifierValueNewName"
                :label="'New ' + ct.name + ' Name'"
                required
                outlined
                append-icon="mdi-close-circle-outline"
                @click:append="ct.classifierValueNew = false"
              ></v-text-field>
              <v-select
                v-else
                v-model="ct.tag_classifier_type_value_id"
                outlined
                :label="'Select ' + ct.name"
                clearable
                :items="ct.values"
                item-text="value"
                item-value="tag_classifier_type_value_id"
                append-outer-icon="mdi-plus-circle-outline"
                @click:append-outer="ct.classifierValueNew = true"
              ></v-select>
            </v-col>
            <v-col v-else>{{ ct.name }}: {{ ct.value }}</v-col>
          </v-row>
          <v-row dense v-if="editedTag.error_message">
            <v-col>
              <v-alert dense type="error"
                ><v-row
                  ><v-col class="grow">{{ editedTag.error_message }}</v-col>
                  <v-col class="shrink"
                    ><v-btn @click="changeTagStatus(true)"
                      >Make Inactive Anyway</v-btn
                    ></v-col
                  ></v-row
                ></v-alert
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!isNew"
            color="blue darken-1"
            text
            @click="changeTagStatus(false)"
            >{{ editedTag.active ? "Make Inactive" : "Make Active" }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
          <v-btn
            v-if="editedTag.active"
            color="blue darken-1"
            text
            @click="saveTag"
            :disabled="
              !editedTag.value.trim() ||
              tagType.values.some(
                (x) =>
                  x.value.toLowerCase() ===
                    editedTag.value.trim().toLowerCase() &&
                  x.tag_value_id !== editedTag.tag_value_id
              )
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </div>
</template>
  
  <script>
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
// import utils from "@/common/utils.js";
import { mapState } from "vuex";

export default {
  name: "TagAttribute",
  components: {
    ResponseHandler,
  },
  props: {
    tag: Object,
    show: Number,
  },
  data: function () {
    return {
      response: null,
      editedTag: {
        show: false,
        value: null,
        description: "",
        tag_value_id: null,
        active: 1,
        error_message: "",
        inferred_from: "",
        classifierTypes: [],
      },
      allowAdd: false,
      allowEditTag: false,
      //   showRating: false,
      usesNotes: false,
      isLoading: false,
      isNew: false,
      tagType: null,
      tagTypeName: "",
    };
  },
  watch: {
    show(val) {
      if (val) this.editTag();
    },
  },
  computed: {
    ...mapState({
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagClassifierTypes: (state) => state.hierarchies.tagClassifierTypes,
      tagPermissions: (state) => state.hierarchies.tagPermissions,
    }),
  },
  created() {},
  methods: {
    setup() {
      this.original = JSON.parse(JSON.stringify(this.tag));
      this.allowAdd = this.tagPermissions.addTag;
      this.allowEditTag = this.tagPermissions.editTag;
      this.usesNotes = this.$loginState.user.settings.some(
        (s) => s.setting === "tagging_notes_used" && s.value !== "false"
      );
    },
    editTag() {
      this.setup();
      let tagDef = this.tagTypes
        .find((x) => x.tag_type_id === this.tag.tag_type_id)
        ?.values.find((x) => x.tag_value_id === this.tag.tag_value_id);
      this.isNew = !tagDef;
      if (this.isNew) {
        tagDef = this.tag;
        tagDef.active = 1;
      }
      this.tagType = this.tagTypes.find(
        (x) => x.tag_type_id === tagDef.tag_type_id
		);
		this.tagTypeName = this.tagType.tag_type_name;
		if (this.tagTypeName.substring(this.tagTypeName.length - 1) === "s")
			this.tagTypeName = this.tagTypeName.substring(0, this.tagTypeName.length - 1);

      this.editedTag.show = true;
      this.editedTag.tag_value_id = tagDef.tag_value_id || -1;
      this.editedTag.value = tagDef.value ?? "";
      this.editedTag.description = tagDef.description ?? "";
      this.editedTag.active = tagDef.active ?? 1;
      this.editedTag.error_message = "";
      this.editedTag.inferred_from = tagDef.inferred_from;
      this.editedTag.classifierTypes = this.tagClassifierTypes.map((ct) => {
        let ctv = tagDef.classifiers.find(
          (c) => c.tag_classifier_type_id === ct.tag_classifier_type_id
        );
        return {
          tag_classifier_type_id: ct.tag_classifier_type_id,
          description: ct.description,
          name: ct.name,
          active: ct.active,
          sequence: ct.sequence,
          values: ct.values,
          tag_classifier_type_value_id: ctv?.tag_classifier_type_value_id,
          tag_value_classifier_value_id: ctv?.tag_value_classifier_value_id,
          classifierValueNew: ct.values.length === 0,
          classifierValueNewName: "",
        };
      });
    },
    cancel() {
      this.editedTag.show = false;
      this.$emit("cancel");
    },
    saveTag() {
      let data = {
        entity_type: "tag_value",
        status_change: false,
        tag_type_id: this.tag.tag_type_id,
        tag_value_id: this.editedTag.tag_value_id,
        value: this.editedTag.value,
        description: this.editedTag.description,
        inferred_from: this.editedTag.inferred_from || null,
        classifiers: this.editedTag.classifierTypes.map((ct) => {
          return {
            tag_classifier_type_id: ct.tag_classifier_type_id,
            tag_classifier_type_value_id: ct.tag_classifier_type_value_id,
            tag_value_classifier_value_id: ct.tag_value_classifier_value_id,
            tag_classifier_type_value: ct.classifierValueNewName.trim(),
          };
        }),
      };
      this.$store.dispatch("hierarchies/saveItem", data).then((resp) => {
        if (resp.data.Status === "OK" && resp.data.Data.item) {
          this.$emit("saved", resp.data.Data.item);
          this.editedTag.show = false;
        }
        this.response = resp.data;
        this.isLoading = false;
      }),
        (error) => {
          console.error(error);
          this.isLoading = false;
        };
    },
    changeTagStatus(ignore_validation = false) {
      let data = {
        entity_type: "tag_value",
        status_change: true,
        tag_type_id: this.tag.tag_type_id,
        tag_value_id: this.editedTag.tag_value_id,
        active: this.editedTag.active === 1 ? 0 : 1,
        ignore_validation: ignore_validation ? 1 : 0,
      };
      this.$store.dispatch("hierarchies/saveItem", data).then((resp) => {
        if (resp.data.Status === "OK" && resp.data.Data.item) {
          if (Number(resp.data.Data.valid) === 1) {
            this.$emit("saved", resp.data.Data.item);
            this.editedTag.show = false;
          } else {
            this.editedTag.error_message = `This tag is currently applied to ${
              resp.data.Data.used_count
            } document${Number(resp.data.Data.used_count) === 1 ? "" : "s"}.`;
          }
        }
        this.response = resp.data;
        this.isLoading = false;
      }),
        (error) => {
          console.error(error);
          this.isLoading = false;
        };
    },
  },
};
</script>
  <style scoped>
</style>
  