import { render, staticRenderFns } from "./vRouteAdmin.vue?vue&type=template&id=4ac40c5c&scoped=true"
import script from "./vRouteAdmin.vue?vue&type=script&lang=js"
export * from "./vRouteAdmin.vue?vue&type=script&lang=js"
import style0 from "./vRouteAdmin.vue?vue&type=style&index=0&id=4ac40c5c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac40c5c",
  null
  
)

export default component.exports