<template>
    <div>
        <div style="padding-left:15px; width:100%">
            <span>We've found similar documents to this one.</span><br/>
            <span>Select one or more to compare and use instead.</span><br/>
            <div style="display:inline-flex; width:100%; padding:20px 0px;">
                <span class="mr-5">Filters</span>
                <v-menu
                v-model="similarityFilterMenu"
                :close-on-content-click="false"
                :nudge-width="250"
                offset-x
                offset-y
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="mr-2">mdi-filter</v-icon>
                </template>
                <v-card class="pa-2">
                    Match Types:
                    <v-checkbox v-model="similarityTypeSelect" label="Similar Content, Different Name" value="similar_content_different_name"></v-checkbox>
                    <v-checkbox v-model="similarityTypeSelect" label="Similar Content, Same Name" value="similar_content_same_name"></v-checkbox>                      
                    <v-checkbox v-model="similarityTypeSelect" label="Different Content, Same Name" value="different_content_same_name"></v-checkbox>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click="similarityFilterMenu = false">
                        Close
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-menu>
            </div>
            <v-card raised style="height:290px; width: calc(100% - 10px); padding: 0px;">
                <v-card-text tile scrollable style="max-height: 275px; overflow: hidden scroll; padding: 0px;">
                    <div v-for="(match,idx) in getSimilarityDocs" :key="idx" class="similarityRow">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="similarySelect" >
                                    <v-checkbox v-model="docMatches" :value="match.doc_id">
                                        <template v-slot:label>
                                            <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" v-on="on" v-bind="attrs">{{ match.doc_name }}</div>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </template>
                            <span>{{match.doc_name}}</span>
                        </v-tooltip>
                        <div class="similarityScore">
                            <v-progress-circular :color="getMatchColour(match.match_pct_content)" :width="3" :size="30" :rotate="90" :value="match.match_pct_content">
                                <span>{{Number(match.match_pct_content).toFixed(0)}}</span>
                            </v-progress-circular>
                            <!--<v-chip :color="getMatchColour(match.match_pct_content)" x-small><span>{{Number(match.match_pct_content).toFixed(0)}}</span></v-chip>-->
                        </div>                        
                        <div class="similarityActions">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-on="on" v-bind="attrs">mdi-dots-horizontal</v-icon>
                                </template>
                                <v-card>
                                    <v-card-text style="cursor:pointer" @click="quickView(match, $event)">Quick View</v-card-text>
                                </v-card>
                            </v-menu>                          
                        </div>                        
                    </div>
                </v-card-text>
            </v-card>
            <v-btn color="primary" :disabled="docMatches.length === 0" outlined class="mr-2 mt-4" @click="initCompare">
            Compare Documents
            </v-btn>


        </div>
    </div>
</template>
<script>

export default {
  name: "DocumentSimilarityPanel",
  components: {
  },
  props: {
    matches: Array
  },
  data: function () {
    return {
        similarityTypeSelect: [
            "similar_content_different_name",
            "similar_content_same_name",     
            "different_content_same_name"
        ],
        similarityFilterMenu: false,
        docMatches: []
    };
  },
  watch: {
    getSimilarityDocs() {
        const docIds = this.getSimilarityDocs.map(d => d.doc_id);
        this.docMatches = this.docMatches.filter(d => docIds.includes(d));
    }
  },
  computed: {
    getSimilarityDocs(){
      if(this.matches.length === 0) return [];
      else {
        return this.matches.filter(obj => this.similarityTypeSelect.some(prop => obj[prop] === true))
      }
    },  
  },
  created() {
  },
  methods: {
    getMatchColour(val){
      let number = Number(val);
      if(number <= 25){
        return "red !important";
      } else if(number >= 75){
        return "green !important";
      } else {
        return "orange !important";
      }
    },
    initCompare() {
        this.$emit("initCompare", this.docMatches);
    },
    quickView(match, $event){
        this.$emit("quickView", match.doc_id, $event);
    }
  
  },
};
</script>
<style scoped lang="scss">

.similarityRow {
    height: 50px;
    width: 100%;
    display: inline-flex;
    border: 1px solid #DDDDDD;
    border-right: none;
    align-items: center;
    padding-bottom: 5px;


    .similarySelect {
        width: 75%;
        padding:0px 8px;
    }

    .similarityScore {
        width: 15%;
        padding-top:5px;
        padding-left:5px
    }

    .similarityActions {
        width: 10%;
        padding-top:5px;
    }
}
</style>
  