<template>
  <div class="d-flex align-center">
    <v-text-field
      v-model="search"
      ref="searchField"
      hide-details
      solo
      dense
      flat
      label="Search"
      prepend-inner-icon="mdi-magnify"
      @blur="closeIfEmpty"
      @input="doSearch"
      @keyup.esc="$emit('close')"
      class="align-center"
    >
      <template v-slot:append>
        <v-progress-circular
          size="15"
          style="top: 4px"
          color="green"
          indeterminate
          aria-label="Search in progress"
          v-if="searchInProgress"
        ></v-progress-circular>
        <v-icon v-else aria-label="Clear/Close Search" @click="$emit('close')">mdi-close</v-icon>
      </template>
    </v-text-field>

    <v-dialog
      v-model="showMenu"
      fullscreen
      persistent
      no-click-animation
      hide-overlay
      :retain-focus="false"
      content-class="resultsDialog elevation-3"
      @click:outside="clickOutside"
    >
      <v-card>
        <v-card-text v-if="haveResults" dense>
          <div class="tabHolder">
            <v-tabs>
              <v-tab v-for="(ef, efi) in resultsCategories" :key="efi"
                @click="tabResults = ef.docs">
                {{ ef.name }} ({{ ef.docs.length }})
              </v-tab>
            </v-tabs>

            <v-divider />
          </div>

          <DocsWithPartsNew
            v-if="tabResults"
            :fullScreen="false"
            :pageSize="20"
            :height="resultsHeight"
            :showFooter="false"
            :showFilters="false"
            :filters="filters"
            :specifiedResults="tabResults"
            :enablePreview="true"
            density="normal"
            @sessionExpired="sessionExpired"
            @openDocument="openDocument"
            @copyDocument="copyDocument"
            @documentChangedHandler="documentChangedHandler"
          ></DocsWithPartsNew>

        </v-card-text>
        <div v-else class="d-flex align-center justify-center pa-4">
          <h2 class="subtitle-1">No documents found</h2>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import utils from "@/common/utils.js";
import DocsWithPartsNew from "@/components/cDocumentsWithPartsNew";

export default {
  name: "HeaderSearch",
  components: {
    DocsWithPartsNew,
  },
  props: {},
  data: function () {
    return {
      search: "",
      showMenu: false,
      docStatusIncluded: [],
      searchTimeout: null,
      filters: null,
      tabResults: null,
      resultsCategories: [],
      haveResults: false,
      searchInProgress: false,
    };
  },
  created() {},
  updated() {},
  watch: {
    // showMenu(val) {
    //   if (!val) this.$emit("close");
    // }
  },
  mounted() {
    this.focusInput();

    if (this.$loginState.readOnly) {
        this.docStatusIncluded.push(
          this.docStatusSummary.find(
            (s) => s.status.toLowerCase() === "approved"
          ) || this.docStatusSummary[0]
        );
      } else {
        this.docStatusIncluded = [...this.docStatusSummary.filter(s => s.show)];
      }
  },
  computed: {
    resultsHeight() {
      if (this.$vuetify.breakpoint.mdAndDown)
        return "calc(100vh - 130px)";
      else
        return "calc(100vh - 130px)";
    },
    ...mapState({
      isLoading: (state) => state.docs.loading,
      docsList: (state) => state.docs.docsList,
      docStatusSummary: (state) => state.docs.docStatusSummary,
      docTypeSummary: (state) => state.docs.docTypeSummary.filter((t) => t.showInAllJobs),
      config: (state) => state.settings.config,
    }),
    useOpenSearch() {
      return (
        this.config.settings.some(
          (s) => s.setting === "alljobs_use_opensearch" && s.value === "true"
        )
      );
    },
    openSearchMinScore() {
      const setting = this.config.settings.find(
        (s) => s.setting === "alljobs_opensearch_score"
      );
      return parseFloat(setting?.value) || 5.0;
    },
  },
  methods: {
    doSearch() {
      if (!this.search)
        return;

      //throttle so as not to run search too frequently
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(() => this.runSearch(), 500);
    },
    focusInput() {
      if (this.$refs.searchField && this.$refs.searchField.$refs.input)
        this.$refs.searchField.$refs.input.focus();
    },
    sessionExpired(err) {
      this.$emit("sessionExpired", err);
    },
    openDocument(item) {
      this.$emit("close");
      this.$emit("openDocument", item);
    },
    copyDocument(item, tmpl_id) {
      this.$emit("close");
      this.$emit("copyDocument", item, tmpl_id);
    },
    documentChangedHandler(item) {
      this.$emit("documentChangedHandler", item);
    },
    async runSearch() {
      this.filters = {
        filterText: (this.search || "").trim().toLowerCase(),
        docStatusIncluded: this.docStatusIncluded,
        docTypeIncluded: this.docTypeSummary,
        useOpenSearch: this.useOpenSearch,
        openSearchMinScore: this.openSearchMinScore,
      }
      this.searchInProgress = true;
      const results = await this.$store.getters["docs/filter"](this.filters);
      this.haveResults = results.length !== 0;
      this.buildResultsCategories(results);
      this.searchInProgress = false;
      this.showMenu = true;

      setTimeout(() => this.focusInput(), 100);
    },
    buildResultsCategories(results) {
      this.resultsCategories.splice(0);

      if (!results || !results.length)
        return;

      this.tabResults = results;
      this.resultsCategories.push({ name: "All Jobs", docs: results, filters: { ...this.filters } });
      const myJobs = results.filter(d => d.myJob);
      if (myJobs.length)
        this.resultsCategories.push({ name: "My Jobs", docs: myJobs, filters: { ...this.filters, myJobs: true } });

      const categories = {};
      results.forEach(doc => {
        doc.hierarchies.forEach(hr => {
          const topLevel = hr.hierarchy6 || hr.hierarchy5 || hr.hierarchy4 || hr.hierarchy3 || hr.hierarchy2 || hr.hierarchy1;
          if (hr.ht_id && topLevel) {
            const key = `${hr.ht_id}_${topLevel}`;
            const cat = categories[key] || {
              ht_id: hr.ht_id,
              selected: [],
              text: topLevel,
              docs: [],
            };
            cat.docs.push(doc);
            if (!cat.selected.includes(hr.hr_id))
              cat.selected.push(hr.hr_id);
            categories[key] = cat;
          }
        });
      });

      const sortedHrIds = Object.keys(categories)
        .filter(c => categories[c].docs.length !== results.length)
        .sort(function(a,b) { return categories[b].docs.length - categories[a].docs.length; });
      //Add top few buckets
      for (let i = 0; i < 10 && i < sortedHrIds.length; i++) {
        const topHry = categories[sortedHrIds[i]]
        const hfilter = {
            ht_id: topHry.ht_id,
            selected: topHry.selected.map(s => {
              return { id: `${s}` };
            })
          };
        this.resultsCategories.push({ name: topHry.text, docs: topHry.docs, filters: { ...this.filters, hierarchyFilters: [ hfilter ]} });
      }
    },
    closeIfEmpty() {
      if (!this.search) {
        this.$emit("close");
        return;
      }
    },
    clickOutside(e) {
      if (e.target !== this.$refs.searchField.$refs.input) {
        this.$emit("close");
      }
    }
  },
};
</script>
<style scoped lang="scss">
::v-deep .resultsDialog {
  margin-top: 48px !important;
  > .v-card {
    padding: 5px 0 !important
  }
  .v-toolbar {
    border: 0 !important;
    .v-toolbar__title {
      font-size: 1.375rem;
    }
  }
  .tabHolder {
    margin-right: 70px;
    .v-tab {
      text-transform: none;
      letter-spacing: normal;
      font-size: 16px;
    }
  }
  .filters {
    height: 15px;
    button {
      z-index: 5;
      position: fixed;
      top: 78px;
      right: 40px;
    }
  }
}

::v-deep mark {
    color: inherit;
    border-radius: 5px;
    background-color: rgba(#0372ee,.3);
}
</style>