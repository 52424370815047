<template>
  <div>
    <v-row
      v-if="attribute.tag_viewer === 'chips'"
      dense
      @keydown="keyDown"
      tabindex="0"
      @blur="lostFocus"
      ref="editContainer"
    >
      <v-col @click="editMode = editable">
        <v-row v-for="(tg, tgi) in tag_gradings" :key="'tg' + tgi">
          <v-col cols="2" v-if="!tg.hide_name">{{ tg.name }}</v-col>
          <v-col
            cols="2"
            v-if="!tg.tags.length && !editMode"
            :class="attribute.placeholder ? 'placeholder' : 'placeholder-empty'"
            >{{ attribute.placeholder || "|" }}</v-col
          >
          <v-col cols="3" v-if="editMode">
            <v-combobox
              v-model="selectedTags"
              :items="definition.values"
              item-value="tag_value_id"
              item-text="value"
              :label="'Select ' + definition.tag_type_name + '...'"
              hint="type to search"
              :search-input.sync="search"
              multiple
              dense
              outlined
              flat
              small-chips
              hide-details
              persistent-hint
              hide-selected
              :hide-no-data="!search"
              @change="checkNew(tg)"
              @keydown="keyDown"
              :filter="tagListSearch"
              @blur="lostFocus"
            >
              <template v-slot:no-data>
                <v-list-item v-if="allowAdd">
                  <span class="subheading">Hit ENTER to create </span>
                  <v-chip label small>{{ search }}</v-chip>
                  <span class="subheading"> or ESCAPE to remove text</span>
                </v-list-item>
                <v-list-item v-else>
                  <span class="subheading"
                    >No matching items found. Hit ESCAPE to remove text</span
                  >
                </v-list-item>
              </template>
              <template v-slot:selection="{}"> </template>
              <template v-slot:item="{ item }">
                <v-chip dark label small>{{ tagListText(item) }}</v-chip>
              </template>
            </v-combobox></v-col
          >
          <v-col
            v-on:drop="moveTag($event, null, tg)"
            v-on:dragover="allowDrop($event)"
          >
            <v-chip
              small
              label
              class="mr-2 px-2 mb-2"
              tabindex="0"
              v-for="(t, ti) in tg.tags"
              :key="'tg' + tgi + 't_' + ti"
              :title="
                editMode && allowEditTag
                  ? 'Click to edit ' + definition.tag_type_name + ' details'
                  : null
              "
              @blur="lostFocus"
              @click="editMode && allowEditTag ? editTagValue(t) : null"
              v-on:drop="moveTag($event, t, tg)"
              v-on:dragover="allowDrop($event)"
            >
              <v-icon
                v-if="editMode"
                small
                @click.stop="removeItem(t, tg)"
                title="Click to Remove"
                class="pr-2"
                >mdi-delete</v-icon
              >
              <span
                class="pr-2 drag-item"
                v-on:dragstart="dragStart($event, t)"
                v-on:dragend="dragEnd($event)"
                :draggable="editable"
                :style="{
                  cursor: editable
                    ? dragItem === t
                      ? 'grabbing'
                      : 'grab'
                    : 'default',
                }"
                >{{ t.value }}</span
              >
              <div
                v-if="showRating && !editMode"
                style="
                  height: 100%;
                  float: right;
                  position: relative;
                  border: 3px solid transparent;
                "
              >
                <div
                  v-for="i in definition.maxRating"
                  :key="'d' + i"
                  :style="{
                    height: (i * 100) / definition.maxRating + '%',
                    backgroundColor: t.rating_number >= i ? 'green' : null,
                    border: t.rating_number < i ? 'red solid 1px' : null,
                    marginLeft: i > 1 ? '1px' : '6px',
                    top: 100 - (i * 100) / definition.maxRating + '%',
                  }"
                  style="width: 5px; float: left; position: relative"
                ></div>
              </div>
              <v-menu v-if="showRating && editMode">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    @click.stop
                    v-bind="attrs"
                    v-on="on"
                    title="Click to change Rating"
                    style="
                      height: 100%;
                      float: right;
                      position: relative;
                      border: 3px solid transparent;
                    "
                  >
                    <div
                      v-for="i in definition.maxRating"
                      :key="'d' + i"
                      :style="{
                        height: (i * 100) / definition.maxRating + '%',
                        backgroundColor:
                          (t.rating_number || 0) >= i ? 'green' : null,
                        border:
                          (t.rating_number || 0) < i ? 'red solid 1px' : null,
                        marginLeft: i > 1 ? '1px' : '6px',
                        top: 100 - (i * 100) / definition.maxRating + '%',
                      }"
                      style="width: 5px; float: left; position: relative"
                    ></div>
                  </div>
                </template>
                <v-list ref="menuContainer">
                  <v-list-item
                    v-if="t.tag_type_rating_id"
                    dense
                    @click="updateRating(t, null)"
                    ><v-list-item-icon
                      ><v-icon>mdi-layers-remove</v-icon></v-list-item-icon
                    >
                    <v-list-item-title
                      >Remove Rating</v-list-item-title
                    ></v-list-item
                  >
                  <v-list-item
                    dense
                    v-for="r in definition.ratings"
                    :key="r.tag_type_rating_id"
                    @click="updateRating(t, r)"
                    :style="
                      r.tag_type_rating_id === t.tag_type_rating_id
                        ? 'backgroundColor: #e7f2f6;'
                        : null
                    "
                  >
                    <v-list-item-title>{{
                      r.sequence + ":   " + r.name
                    }}</v-list-item-title
                    ><v-list-item-icon v-if="r.description">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }"
                          ><v-icon v-bind="attrs" v-on="on" class="float-right"
                            >mdi-information-outline</v-icon
                          >
                        </template>
                        <span>{{ r.description }}</span>
                      </v-tooltip></v-list-item-icon
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-chip></v-col
          ><v-col cols="1" v-if="editMode && tg.tags.length > 1">
            <v-icon
              title="Remove all tags"
              @click="removeAll(tg)"
              class="float-right"
              >mdi-delete</v-icon
            ></v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <template v-else-if="attribute.tag_viewer === 'grid'">
      <v-card-title>
        <h3>{{ attribute.title }}</h3>
        <v-spacer></v-spacer>
        <v-btn
          color="grey lighten-2"
          small
          depressed
          v-if="editable"
          @click="addAttributeTag"
          ><v-icon>mdi-plus</v-icon>Add {{ attribute.title }}</v-btn
        >
      </v-card-title>
      <v-card-title class="pt-0"> </v-card-title>
      <v-row v-if="tag_classifiers.length">
        <v-col cols="6" v-for="(tc, tci) in tag_classifiers" :key="'tcc' + tci">
          <v-row v-if="tag_classifiers.length > 1"
            ><v-col cols="5"
              >{{ tc.name }}
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on"
                    >mdi-information</v-icon
                  ></template
                >
                <span
                  >{{
                    tc.description ||
                    tc.name +
                      "s that have been applied to the linked " +
                      subjectType
                  }}s</span
                >
              </v-tooltip></v-col
            ><v-col cols="6"
              ><v-select
                outlined
                v-model="tc.filter"
                :label="tc.name + ' filter'"
                dense
                clearable
                hide-details
                :items="tc.values"
                :item-text="'name'"
                :item-value="'name'"
              ></v-select></v-col
          ></v-row>
          <v-row>
            <v-col><div :id="'tcc' + tci" style="height: 100px"></div></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-if="tag_classifiers.length === 1" cols="3"
          >{{ tag_classifiers[0].name }}
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              ></template
            >
            <span
              >{{
                tag_classifiers[0].description ||
                tag_classifiers[0].name +
                  "s that have been applied to the linked " +
                  subjectType
              }}s</span
            >
          </v-tooltip></v-col
        >
        <v-col v-if="showGrading && tag_gradings.length > 1"
          >{{ gradingLabel }}
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              ></template
            >
            <span>{{ gradingTypeDescription }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row
        dense
        v-if="
          (showGrading && tag_gradings.length > 1) ||
          tag_classifiers.length === 1
        "
      >
        <v-col v-if="tag_classifiers.length === 1" cols="3"
          ><v-select
            outlined
            v-model="tag_classifiers[0].filter"
            :label="tag_classifiers[0].name + ' filter'"
            dense
            clearable
            hide-details
            :items="tag_classifiers[0].values"
            :item-text="'name'"
            :item-value="'name'"
          ></v-select
        ></v-col>
        <v-col v-if="showGrading && tag_gradings.length > 1">
          <template v-for="(c, ci) in tag_gradings">
            <svg
              style="width: 20px; height: 25px"
              :key="'cv' + ci"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10"
                r="8"
                :fill="c.colour"
                stroke="gray"
                strokeWidth="2"
              /></svg
            ><span
              :key="'cs' + ci"
              style="
                font-size: 14px;
                padding: 0 15px 0 7px;
                vertical-align: top;
              "
              >{{ c.name }}</span
            >
          </template></v-col
        >
      </v-row>
      <v-row dense ref="editContainer">
        <v-col>
          <v-simple-table dense fixed-header height="400">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    style="
                      border: 0px gray #eeeeee;
                      background-color: #eeeeee;
                      font-weight: bold;
                    "
                    :style="
                      hi === 0
                        ? 'border-top-left-radius: 20px 20px;'
                        : hi === listColumns.length - 1
                        ? 'border-top-right-radius: 20px 20px;'
                        : ''
                    "
                    class="text-left"
                    v-for="(h, hi) in listColumns"
                    :key="h.value"
                  >
                    {{ h.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-on:drop="moveTagPosition($event, item)"
                  v-on:dragover="allowDrop($event)"
                  v-for="(item, i) in filterBy()"
                  :key="'r' + i"
                >
                  <td
                    v-for="col in listColumns"
                    :key="'r' + i + col.value"
                    :style="{
                      backgroundColor: showGrading ? item.grading_colour : '',
                    }"
                  >
                    <v-icon
                      v-if="
                        col.value === 'drag-handle' &&
                        !tag_classifiers.some((x) => x.filter)
                      "
                      color="gray"
                      v-on:dragstart="dragStart($event, item)"
                      v-on:dragend="dragEnd($event)"
                      :draggable="editable"
                      :style="{
                        cursor: dragItem === item ? 'grabbing' : 'grab',
                      }"
                      >mdi-drag</v-icon
                    >
                    <v-icon
                      v-else-if="col.value === 'edit'"
                      color="green"
                      @click="editAttributeTag(item)"
                      >mdi-pencil</v-icon
                    >
                    <v-icon
                      v-else-if="col.value === 'delete'"
                      color="green"
                      @click.stop="attributeTagRemoved(item)"
                      >mdi-close</v-icon
                    >
                    <span>{{ item[col.value] }}</span>
                    <template
                      v-if="
                        col.value === 'value' &&
                        item.classifiers.filter(
                          (x) => x.value && x.showClassifier
                        )
                      "
                      ><br />
                      <v-chip
                        label
                        x-small
                        color="gray lighten-5"
                        v-for="(c, ci) in item.classifiers.filter(
                          (x) => x.value && x.showClassifier
                        )"
                        :key="'cl' + ci"
                        >{{
                          (tag_classifiers.length > 1 ? c.name + " - " : "") +
                          c.value
                        }}</v-chip
                      ></template
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table></v-col
        >
      </v-row>
    </template>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>

    <Loading :isVisible="isLoading" />
    <TagValueEdit
      :tag="tagValueForEdit"
      :show="editTagTrigger"
      @saved="tagSaved"
      @cancel="cancelEditTag"
    ></TagValueEdit>
    <TagValueAttribute
      :item="tagForEdit"
      @saved="attributeTagSaved"
      @removed="attributeTagRemoved"
      @cancel="cancelEditTag"
    ></TagValueAttribute>
  </div>
</template>
  
  <script>
// import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
import chartUtils from "@/common/chartUtils.js";
import { mapState } from "vuex";
import TagValueEdit from "./TagValueEdit.vue";
import TagValueAttribute from "./TagValueAttribute.vue";

const colours = [
  "#2d99ff", // Blue
  "#2cd9c5", // Green
  "#FF4069", // Red
  "#FF9020", // Orange
  "#FFED6F", // Yellow
  "#BC80BD", // Purple
  "#8DD3C7", // Turquoise
  "#CCEBC5", // Pale Blue
  "#FFFFB3", // Pale Yellow
  "#BEBADA", // Lavender
  "#FCCDE5", // Pink
  "#D9D9D9", // Grey,
];
export default {
  name: "TagAttributeDetail",
  components: {
    ResponseHandler,
    TagValueEdit,
    TagValueAttribute,
  },
  props: {
    attribute: Object,
    editable: Boolean,
  },
  data: function () {
    return {
      response: null,
      tagValueForEdit: null,
      editTagTrigger: 0,
      tagForEdit: null,
      allowAdd: false,
      allowEditTag: false,
      showRating: false,
      showGrading: false,
      gradingLabel: "",
      usesNotes: false,
      isLoading: false,
      //   utils: utils,
      definition: null,
      search: "",
      selectedTags: [],
      tag_gradings: [],
      tag_classifiers: [],
      editMode: false,
      dragItem: null,
      listColumns: [],
      listData: [],
      subjectType: "",
      gradingTypeDescription: "",
      refreshCharts: false,
    };
  },
  watch: {
    editMode(val) {
      if (val && this.$refs.editContainer) {
        this.$refs.editContainer.focus();
      }
    },
    "attribute.tags"(val) {
      if (val && this.selectedTags.some((x) => !x.dpa_tag_id)) {
        this.updateNewTagIds();
      }
    },
  },
  computed: {
    ...mapState({
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagGradingTypes: (state) => state.hierarchies.tagGradingTypes,
      tagPermissions: (state) => state.hierarchies.tagPermissions,
    }),
  },
  created() {
    let def = JSON.parse(
      JSON.stringify(
        this.tagTypes.find((x) => x.tag_type_id === this.attribute.tag_type_id)
      )
    );
    def.values = def.values.filter((x) => x.active);
    def.ratings = def.ratings.filter((x) => x.active);
    this.definition = def;
    this.selectedTags = JSON.parse(JSON.stringify(this.attribute.tags));
    this.tag_gradings = JSON.parse(JSON.stringify(this.attribute.tag_gradings));
    this.allowAdd = this.tagPermissions.addTag;
    this.allowEditTag = this.tagPermissions.editTag;
    this.showRating =
      this.attribute.tag_type_capture_rating > 0 &&
      this.definition.maxRating > 0 &&
      this.$loginState.user.settings.some(
        (s) => s.setting === "tagging_rating_used" && s.value !== "false"
      );
    this.showGrading = !!this.attribute.tag_grading_type_id;
    this.gradingLabel = this.showGrading
      ? this.tagGradingTypes.find(
          (x) => x.tag_grading_type_id === this.attribute.tag_grading_type_id
        )?.name
      : "";
    this.usesNotes = this.$loginState.user.settings.some(
      (s) => s.setting === "tagging_notes_used" && s.value !== "false"
    );
    this.setCats();
    let subjectType = this.attribute.title;
    if (subjectType.lastIndexOf("s") === subjectType.length - 1) {
      subjectType = subjectType.substring(0, subjectType.length - 1);
    }
    this.subjectType = subjectType;
    this.gradingTypeDescription = this.tagGradingTypes.find(
      (x) => x.tag_grading_type_id === this.attribute.tag_grading_type_id
    )?.description;

    if (this.editable)
      this.listColumns.push({
        value: "drag-handle",
        text: subjectType.toUpperCase(),
      });
    this.listColumns.push({
      value: "sequence",
      text: this.editable ? "" : subjectType.toUpperCase(),
    });
    this.listColumns.push({
      value: "value",
      text: "",
    });
    if (this.showRating) {
      this.listColumns.push({
        value: "rating",
        text: "PROFICIENCY",
      });
      this.listColumns.push({
        value: "rating_description",
        text: "DESCRIPTOR",
      });
    } else {
      this.listColumns.push({
        value: "value_description",
        text: "DESCRIPTOR",
      });
    }
    if (this.editable)
      this.listColumns.push({
        value: "edit",
        text: "",
      });
    if (this.editable)
      this.listColumns.push({
        value: "delete",
        text: "",
      });
  },
  updated() {
    this.$nextTick(() => {
      if (this.refreshCharts) this.showCharts();
      this.refreshCharts = false;
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.tag_classifiers.forEach((c) => {
        if (c.chartDef.loaded) chartUtils.setChartSize(c.chartDef);
      });
    });
  },
  methods: {
    updateNewTagIds() {
      this.selectedTags
        .filter((x) => !x.dpa_tag_id)
        .forEach((t) => {
          t.dpa_tag_id = this.attribute.tags.find(
            (v) => v.tag_value_id === t.tag_value_id
          )?.dpa_tag_id;
        });
    },
    setCats() {
      let tag_classifiers = [];
      this.selectedTags.forEach((t) => {
        t.classifiers
          .filter((c) => c.showClassifier)
          .forEach((c) => {
            let tc = tag_classifiers.find(
              (x) => x.tag_classifier_type_id === c.tag_classifier_type_id
            );
            if (!tc) {
              tc = {
                tag_classifier_type_id: c.tag_classifier_type_id,
                active: c.active,
                name: c.name,
                description: c.description,
                sequence: c.sequence,
                filter: "",
                values: [],
              };
              tag_classifiers.push(tc);
            }
            let val = c.classifier_type_value_value || "";
            let dim = tc.values.find((x) => x.name === val);
            if (!dim) {
              dim = {
                name: val,
                count: 0,
                empty: false,
                colour: c.classifier_type_value_colour,
              };
              tc.values.push(dim);
            }
            dim.count++;
          });
      });
      tag_classifiers.forEach((tc) => {
        if (tc.values.length === 1 && !tc.values[0].name) {
          tc.values = [];
          tc.filter = "";
        } else {
          let un = tc.values.find((v) => v.name === "");
          if (un) {
            un.name = `No ${tc.name}`;
            un.empty = true;
            un.colour = "#cccccc";
          }
        }
      });
      tag_classifiers = tag_classifiers
        .filter((c) => c.values.length)
        .sort((a, b) =>
          a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0
        )
        .map((c, ci) => {
          c.chartDef = {
            chartId: "tcc" + ci,
            vm: this,
            chartData: null,
            measure: "count",
            dimension: "name",
            loaded: false,
            hideLegend: false,
            hideLabels: true,
            element: null,
            centralTitle: "",
            diameter: 90,
            width: 300,
            chart: null,
            bars: null,
            dimensionColours: [],
            colours: c.values.map((l, li) => l.colour || colours[li]),
          };
          return c;
        });
      this.tag_classifiers = tag_classifiers;
      this.refreshCharts = true;
    },
    editTagValue(tag) {
      this.tagValueForEdit = tag;
      this.editTagTrigger++;
    },
    cancelEditValueTag() {
      this.tagValueForEdit = null;
    },
    addAttributeTag() {
      this.tagForEdit = {
        show: false,
        showRating: this.showRating,
        showGrading: this.showGrading,
        gradingLabel: this.gradingLabel,
        tag_value: null,
        dpa_tag_id: null,
        subjectType: this.subjectType,
        tag_type_rating_id: null,
        tag_grading_type_value_id: null,
        ratings: this.definition.ratings,
        gradings: this.tag_gradings.filter((x) => x.tag_grading_type_value_id),
        tag_values: this.definition.values.filter(
          (v) =>
            !this.selectedTags.some((t) => t.tag_value_id === v.tag_value_id)
        ),
      };
    },
    editAttributeTag(tag) {
      this.updateNewTagIds();
      this.tagForEdit = {
        show: false,
        showRating: this.showRating,
        showGrading: this.showGrading,
        gradingLabel: this.gradingLabel,
        tag_value: this.definition.values.find(
          (v) => v.tag_value_id === tag.tag_value_id
        ),
        dpa_tag_id: tag.dpa_tag_id,
        subjectType: this.subjectType,
        tag_type_rating_id: tag.tag_type_rating_id,
        tag_grading_type_value_id: tag.tag_grading_type_value_id,
        ratings: this.definition.ratings,
        gradings: this.tag_gradings.filter((x) => x.tag_grading_type_value_id),
      };
    },
    cancelEditTag() {
      this.tagForEdit = null;
    },
    attributeTagSaved(attributeTag, addAnother) {
      if (this.attribute.tag_viewer === "grid") {
        this.updateNewTagIds();
        let target = this.selectedTags.find(
          (t) => t.dpa_tag_id === attributeTag.dpa_tag_id
        );
        let tag = this.definition.values.find(
          (v) => v.tag_value_id === attributeTag.tag_value_id
        );
        if (!target) {
          target = JSON.parse(JSON.stringify(attributeTag));
          target.value = tag.value;
          target.classifiers = tag.classifiers;
          target.value_description = tag.description;
          target.sequence =
            this.selectedTags[this.selectedTags.length - 1].sequence + 1;
          this.selectedTags.push(target);
        }
        target.tag_type_rating_id = attributeTag.tag_type_rating_id;
        target.tag_grading_type_value_id =
          attributeTag.tag_grading_type_value_id;
        let rat =
          tag.ratings.find(
            (r) => r.tag_type_rating_id === attributeTag.tag_type_rating_id
          ) ||
          this.definition.ratings.find(
            (r) => r.tag_type_rating_id === attributeTag.tag_type_rating_id
          );
        if (rat) {
          target.rating = rat.name;
          target.rating_number = rat.sequence;
          target.rating_description = rat.description;
        }
        let grad = this.tag_gradings.find(
          (r) =>
            r.tag_grading_type_value_id ===
            attributeTag.tag_grading_type_value_id
        );
        if (grad) {
          target.grading = grad.name;
          target.grading_colour = grad.colour;
          //target.grading_description = grad.description;
        }
        this.save();
        this.setCats();
      }
      this.tagForEdit = null;
      if (addAnother) this.addAttributeTag();
    },
    attributeTagRemoved(attributeTag) {
      if (this.attribute.tag_viewer === "grid") {
        let target = this.selectedTags.findIndex(
          (t) => t.dpa_tag_id === attributeTag.dpa_tag_id
        );
        if (target >= 0) {
          this.selectedTags.splice(target, 1);
          this.selectedTags
            .filter((t, ti) => ti >= target)
            .forEach((t) => t.sequence--);
        }
        this.save();
        this.setCats();
      }
    },
    tagSaved(updated) {
      let tag = this.selectedTags.find(
        (x) => x.tag_value_id === updated.tag_value_id
      );
      if (tag) {
        tag.value = updated.value;
        this.tag_gradings.some((g) =>
          g.tags.some((t) => {
            if (t.tag_value_id === updated.tag_value_id) {
              t.value = updated.value;
              return true;
            } else {
              return false;
            }
          })
        );
      }
      this.$refs.editContainer.focus();
      this.save(updated);
      this.tagForEdit = null;
      this.tagValueForEdit = null;
    },
    tagListSearch(item, queryText) {
      const text = `${item.value} ${item.classifiers
        .filter((c) => c.value)
        .map((c) => c.value)
        .join(" ")}`.toLowerCase();
      let search = (queryText || "")
        .toLowerCase()
        .replace(/,/g, " ")
        .split(" ")
        .filter((x) => x.length)
        .map((x) => x);
      return (
        !queryText ||
        queryText.length === 0 ||
        search.every((s) => text.indexOf(s) >= 0)
      );
    },
    tagListText(item) {
      return (
        item.value +
        (item.classifiers.length
          ? " / " + item.classifiers.map((c) => c.value).join(" / ")
          : "")
      );
    },
    updateRating(item, rating) {
      item.tag_type_rating_id = rating?.tag_type_rating_id;
      item.rating = rating?.name;
      item.rating_number = rating?.sequence;
      this.save();
    },
    checkNew(grading) {
      let newTag = this.selectedTags.find((t) => t.tag_value_id === undefined);
      if (!this.allowAdd && newTag) {
        this.selectedTags = this.selectedTags.filter((t) => t.tag_value_id);
        this.search = "";
        return;
      }
      this.selectedTags = this.selectedTags.map((t) => {
        if (t.tag_value_id !== undefined) {
          return t;
        } else {
          let val = t.toLowerCase();
          let existing = this.definition.values.find(
            (cv) => cv.value.toLowerCase() === val
          );
          let newVal = {
            tag_value_id: existing
              ? existing.tag_value_id
              : (this.definition.values.filter((x) => x.tag_value_id < 0)
                  .length +
                  1) *
                -1,
            value: existing ? existing.value : t,
            tag_type_id: this.attribute.tag_type_id,
          };
          if (!existing) this.definition.values.push(newVal);
          return newVal;
        }
      });
      let addedTag = this.selectedTags.find(
        (x) =>
          !this.attribute.tags.some((o) => o.tag_value_id === x.tag_value_id)
      );
      addedTag.tag_grading_type_value_id = grading.tag_grading_type_value_id;
      this.tag_gradings
        .find(
          (g) =>
            g.tag_grading_type_value_id === grading.tag_grading_type_value_id
        )
        .tags.push(addedTag);
      this.save();
    },
    keyDown(event) {
      if (event.key === "Escape") {
        this.$emit("cancel");
        this.editMode = false;
      }
    },
    lostFocus(event) {
      if (
        !this.tagValueForEdit &&
        event.relatedTarget &&
        !this.$refs.editContainer.contains(event.relatedTarget) &&
        (!this.$refs.menuContainer?.length ||
          !this.$refs.menuContainer.some((x) =>
            x.$el.contains(event.relatedTarget)
          ))
      ) {
        this.editMode = false;
      }
    },
    removeAll(grading) {
      grading.tags.forEach((t) => {
        const ind = this.selectedTags.findIndex(
          (x) => x.tag_value_id === t.tag_value_id
        );
        this.selectedTags.splice(ind, 1);
      });
      grading.tags.splice(0, grading.tags.length);
      this.save();
    },
    removeItem(tag, grading) {
      this.selectedTags = this.selectedTags.filter(
        (x) => x.tag_value_id !== tag.tag_value_id
      );
      grading.tags = grading.tags.filter(
        (x) => x.tag_value_id !== tag.tag_value_id
      );
      this.save();
    },
    save(updatedTag) {
      //compare with original
      let currentTags = [];
      let add_remove = false;
      if (this.attribute.tag_viewer === "grid") {
        this.selectedTags.forEach((t, ti) => {
          let ret = {
            tag_type_id: this.attribute.tag_type_id,
            dpa_id: this.attribute.dpa_id,
            dpa_tag_id: t.dpa_tag_id,
            tag_value_id: t.tag_value_id,
            tag_type_rating_id: t.tag_type_rating_id || null,
            tag_grading_type_value_id: t.tag_grading_type_value_id || null,
            notes: t.notes || null,
            value: t.value,
            sequence: ti + 1,
            change: "",
          };
          let original = this.attribute.tags.find(
            (x) => x.tag_value_id === t.tag_value_id
          );
          if (!original) {
            ret.change = "added";
            add_remove = true;
          } else if (
            ret.sequence !== original.sequence ||
            ret.tag_type_rating_id !== original.tag_type_rating_id ||
            ret.tag_grading_type_value_id !== original.tag_grading_type_value_id
          ) {
            ret.change = "updated";
            ret.dpa_tag_id = original.dpa_tag_id;
            ret.notes = original.notes;
          } else if (
            updatedTag?.tag_value_id === t.tag_value_id &&
            updatedTag?.value !== t.value
          ) {
            ret.change = "updated";
            ret.value = updatedTag.value;
          }
          currentTags.push(ret);
        });
      } else {
        let seq = 0;
        this.tag_gradings.forEach((g) => {
          g.tags.forEach((t) => {
            seq++;
            let ret = {
              tag_type_id: this.attribute.tag_type_id,
              dpa_id: this.attribute.dpa_id,
              dpa_tag_id: t.dpa_tag_id,
              tag_value_id: t.tag_value_id,
              tag_type_rating_id: t.tag_type_rating_id || null,
              tag_grading_type_value_id: t.tag_grading_type_value_id || null,
              notes: t.notes || null,
              value: t.value,
              sequence: seq,
              change: "",
            };
            let original = this.attribute.tags.find(
              (x) => x.tag_value_id === t.tag_value_id
            );
            if (!original) ret.change = "added";
            else if (
              ret.sequence !== original.sequence ||
              ret.tag_type_rating_id !== original.tag_type_rating_id ||
              ret.tag_grading_type_value_id !==
                original.tag_grading_type_value_id
            ) {
              ret.change = "updated";
              ret.dpa_tag_id = original.dpa_tag_id;
              ret.notes = original.notes;
            } else if (
              updatedTag?.tag_value_id === t.tag_value_id &&
              updatedTag?.value !== t.value
            ) {
              ret.change = "updated";
              ret.value = updatedTag.value;
            }
            currentTags.push(ret);
          });
        });
      }
      let data = {
        tpa_id: this.attribute.tpa_id,
        dpa_id: this.attribute.dpa_id,
        deletedTags: this.attribute.tags.filter(
          (o) =>
            !this.selectedTags.some((c) => o.tag_value_id === c.tag_value_id)
        ),
        currentTags: currentTags,
      };
      this.search = "";
      this.$emit("changed", data);
      if (add_remove || data.deletedTags.length) this.refreshCharts = true;
      this.$refs.editContainer.focus();
    },
    // setDragCursor: function (value) {
    //   const html = document.getElementsByTagName("html").item(0);
    //   html.classList.toggle("grabbing", value);
    // },
    dragStart: function (event, item) {
      //   this.setDragCursor(true);
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      this.dragItem = item;
    },
    dragEnd: function () {
      //   this.setDragCursor(false);
    },
    allowDrop: function (event) {
      if (!this.dragItem || this.dragItem.tpa_id !== this.attribute.tpa_id)
        event.preventDefault();
    },
    moveTag: function (event, targetTag, newGrading) {
      if (!newGrading || !this.dragItem) {
        event.preventDefault();
      } else {
        const prevGrading = this.tag_gradings.find(
          (g) =>
            g.tag_grading_type_value_id ===
            this.dragItem.tag_grading_type_value_id
        );
        const targetIndex = targetTag
          ? newGrading.tags.findIndex(
              (x) => x.tag_value_id === targetTag.tag_value_id
            )
          : prevGrading.tag_grading_type_value_id ===
            newGrading.tag_grading_type_value_id
          ? newGrading.tags.length - 1
          : newGrading.tags.length;
        const oldIndex = prevGrading.tags.findIndex(
          (x) => x.tag_value_id === this.dragItem.tag_value_id
        );
        if (
          oldIndex !== targetIndex ||
          prevGrading.tag_grading_type_value_id !==
            newGrading.tag_grading_type_value_id
        ) {
          this.dragItem.tag_grading_type_value_id =
            newGrading.tag_grading_type_value_id;
          prevGrading.tags.splice(oldIndex, 1);
          newGrading.tags.splice(targetIndex, 0, this.dragItem);
          this.save();
        }
        event.stopPropagation();
        this.dragItem = null;
      }
    },
    moveTagPosition: function (event, targetTag) {
      if (!this.dragItem) {
        event.preventDefault();
      } else {
        const targetIndex = this.selectedTags.findIndex(
          (x) => x.tag_value_id === targetTag.tag_value_id
        );
        let oldIndex = this.selectedTags.findIndex(
          (x) => x.tag_value_id === this.dragItem.tag_value_id
        );
        if (oldIndex !== targetIndex) {
          this.selectedTags.splice(oldIndex, 1);
          this.selectedTags.splice(targetIndex, 0, this.dragItem);
          this.selectedTags.forEach((t, ti) => (t.sequence = ti + 1));
          this.save();
        }
        event.stopPropagation();
        this.dragItem = null;
      }
    },
    showCharts() {
      if (this.attribute.tag_viewer === "grid") {
        this.tag_classifiers.forEach((c) => {
          this.initialisePie(c);
        });
      }
    },
    initialisePie(classifier) {
      let chart = classifier.chartDef;
      chart.element = document.getElementById(chart.chartId);
      chart.chartData = classifier.values.map((v) => {
        return {
          name: `${v.name} (${v.count})`,
          count: v.count,
        };
      });
      chart.centralTitle = `${this.selectedTags.length} ${this.attribute.title}`;
      chartUtils.drawChart(chart);
      if (!chart.loaded)
        window.addEventListener("resize", () => chartUtils.setChartSize(chart));
      chart.loaded = true;
    },
    filterBy() {
      let filters = this.tag_classifiers
        .filter((x) => x.filter)
        .map((x) => {
          let value = x.values.find((v) => v.name === x.filter);
          return {
            tag_classifier_type_id: x.tag_classifier_type_id,
            value: value.empty ? null : value.name,
          };
        });
      return this.selectedTags.filter((t) =>
        filters.every((f) =>
          t.classifiers.some(
            (c) =>
              c.tag_classifier_type_id === f.tag_classifier_type_id &&
              c.classifier_type_value_value === f.value
          )
        )
      );
    },
  },
};
</script>
  <style scoped>
.v-list-item__action {
  margin: 3px 0;
}
.v-label {
  font-size: 0.9rem;
}
div.cellClick {
  width: 100%;
  cursor: pointer;
}
.placeholder {
  color: #ed8a8a;
  cursor: pointer;
  font-style: italic;
  font-weight: 300 !important;
}
.placeholder-empty {
  color: transparent;
  cursor: pointer;
}
.drag-item {
  cursor: grab;
  /* border: solid white 2px;
  border-radius: 5px;
  margin: -2px; */
}
.drag-item:hover {
  background-color: gray;
  color: white;
}
.grabbing * {
  cursor: grabbing;
}
</style>
  