<template>
  <div :class="!childPartLevel ? 'docContent' : null">
    <div
      :class="[
        'viewPartHolder',
        display === 'sidebysidefull' ? 'sbs' : '',
      ]"
      v-for="(item, idx) in docParts"
      :key="'new_' + idx"
    >
      <draggable
        :list="item.parts"
        :handle="'.dragHandle'"
        ghost-class="ghost"
        :move="canMove"
        :group="{ name: `${data.document.doc_id}_pt` }"
        :disabled="!item.permissions_list.canEdit"
        @start="draggedParent = null"
        @end="dragging = false"
        @change="
          (e) => {
            return movePart(e, item);
          }
        "
      >
        <div
          class="viewPartRow"
          :style="cssVars"
          v-for="(part, pKey) in item.parts"
          :key="idx + '_' + pKey + '_' + rerender"
          :id="`container_${part.doc_part_id}`"
          :ref="`container_${part.doc_part_id}`"
        >
          <div
            :id="`viewcontainer_${data.document.doc_id}_${item.type
              .replace(/\s+/g, '')
              .replace(/'+/g, '')}`"
            :class="[ 'viewPartItem',
              item.tmpl_part_metadata.contentPart ? 'contentPart' : 'tmplPart',
              getHighlightStyle(item, part),
              highlightedDocParts.includes(part.doc_part_id)
                ? 'highlightedScorePart'
                : '',
            ]"
            @dragover.prevent
            @dragenter="highlightDragTarget(item, part)"
            @drop.stop.prevent="dropItem($event, item, part, parent)"
            @mouseover="part.hover = true"
            @mouseout="part.hover = false"
          >
            <div class="viewPartRowGutter">
              <v-btn
                icon
                small
                v-if="display === 'sidebysidefull' && pKey === 0"
                :style="`float: right; padding-bottom:10px; ${
                  part.hover ? '' : 'visibility: hidden'
                }`"
                color="grey lighten-2"
                title="Sync Position"
                @click="syncPositions(item)"
              >
                <v-icon small>mdi-sync</v-icon>
              </v-btn>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-if="
                      !item.tmpl_part_metadata.contentPart &&
                      item.tmpl_part_metadata.why_text &&
                      hintsVisible &&
                      pKey === 0
                    "
                    class="locked"
                    color="grey lighten-2"
                    @click="showHint(item, true)"
                    small
                    style="padding-left: 3px"
                    >mdi-lightbulb-outline</v-icon
                  >
                </template>
                <span>View Insights</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="
                      !item.tmpl_part_metadata.contentPart &&
                      item.tmpl_part_metadata.how_to_text &&
                      hintsVisible &&
                      pKey === 0
                    "
                    class="locked"
                    v-bind="attrs"
                    v-on="on"
                    color="grey lighten-2"
                    small
                    style="padding-left: 3px"
                    @click="showHint(item, false)"
                    >mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>Guidance</span>
              </v-tooltip>
              <v-tooltip
                right
                v-if="
                  !item.tmpl_part_metadata.contentPart &&
                  (item.type === 'Working Pattern' ||
                    flexPartId === item.tmpl_part_id) &&
                  item.permissions_list.canEdit
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="green lighten-2"
                    small
                    @click="rFlex()"
                    style="cursor: pointer; font-size: 1.5em; top: -4px"
                    >mdi-electron-framework</v-icon
                  >
                </template>
                <span>Run Flex Assessment</span>
              </v-tooltip>
              <v-tooltip
                right
                v-if="
                  !item.tmpl_part_metadata.contentPart &&
                  data.document.state.canEditDocParts &&
                  item.hasDeletedParts &&
                  item.tmpl_part_metadata.multi_row &&
                  pKey === 0
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="showDeletedPartHistory(item)"
                    color="grey lighten-2"
                    small
                    style="padding-left: 3px"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-history</v-icon
                  >
                </template>
                <span>{{ "View deleted " + item.type + " rows" }}</span>
              </v-tooltip>
            </div>
            <div
              :class="['viewPartRowContent',
                item.tmpl_part_metadata.contentPart
                  ? 'contentPart'
                  : 'tmplPart',
                getCssStyles(item.tmpl_part_metadata.layout),
              ]"
            >
              <v-icon v-if="item.tmpl_part_metadata.moveable" class="dragHandle"
                >mdi-drag-variant</v-icon
              >
              <v-row dense>
                <v-col
                  dense
                  v-for="(attr, aIdx) in part.attributes"
                  :key="idx + '_' + pKey + '_' + aIdx"
                  :cols="getCols(attr.cols)"
                  style=""
                >
                  <ViewControl
                    v-if="item.tmpl_part_metadata.contentPart"
                    :value="attr"
                    :metadata="item.tmpl_part_metadata"
                    :permissions="part.permissions_list"
                    :disabled="
                      !item.permissions_list.canEdit ||
                      part.locked ||
                      attr.locked
                    "
                    @update="
                      (data) => updateContentPart(data, attr, item, part)
                    "
                  />
                  <div v-else-if="attr.restrict_to_htid">
                    <div
                      v-if="
                        !item.permissions_list.canEdit ||
                        part.locked ||
                        attr.locked
                      "
                    >
                      {{ attr.hierarchyText }}
                    </div>
                    <div
                      v-else-if="!attr.editing"
                      tabindex="0"
                      @focus="attr.editing = true"
                      style="width: 100%;"
                    >
                      {{ attr.hierarchyText || "&nbsp;" }}
                    </div>
                    <HierarchyTreePicker
                      v-else
                      ref="hierarchyTreePicker"
                      v-model="attr.hierarchyTreePickerValue"
                      borderless
                      :label="attr.hierarchy.label"
                      :hint="attr.hierarchy.hint"
                      :options="attr.hierarchy.treePickerOptionsToUse"
                      :placeholder="attr.hierarchy.label"
                      :error="attr.hierarchy.error"
                      :textValue="attr.hierarchyText"
                      :readonly="false"
                      :clearable="true"
                      :dense="true"
                      :hierarchyType="attr.hierarchy"
                      :autofocus="true"
                      @change="(data) => syncFromHierarchy(data, attr)"
                      @nodeAdded="(data) => syncFromNewHierarchy(data, attr)"
                      @lostFocus="attr.editing = false"
                    >
                    </HierarchyTreePicker>
                  </div>
                  <div v-else-if="attr.restrict_to_date">
                    <DatePicker
                      v-model="attr.text"
                      css_class="borderless"
                      :disabled="
                        !item.permissions_list.canEdit ||
                        part.locked ||
                        attr.locked
                      "
                      hideBorder
                      attach
                      placeholder="Select Date...."
                      @input="
                        (data) =>
                          updateDocPart({ text: data }, attr, item, part)
                      "
                    ></DatePicker>
                  </div>
                  <LookupInput
                    v-else-if="attr.isLookup || attr.isDependentLookup"
                    :value="attr"
                    :ref="idx + '_' + pKey + '_' + aIdx"
                    :metadata="item.tmpl_part_metadata"
                    :disable="
                      !item.permissions_list.canEdit ||
                      part.locked ||
                      attr.locked
                    "
                    :active="item.tmpl_part_metadata.active"
                    :part="part"
                    :displayMode="display"
                    @update="(data) => updateDocPart(data, attr, item, part)"
                    @sync="
                      (data, parts) =>
                        syncDocPartAttr(data, parts, attr, part, item)
                    "
                  ></LookupInput>
                  <TagAttribute
                    v-else-if="attr.tag_type_id"
                    :attribute="attr"
                    :editable="data.document.state.canEditDocParts && !attr.locked && !part.locked"
                    @changed="updateDocPart($event, attr, item, part, true)"
                  ></TagAttribute>
                  <TextInput
                    v-else
                    :value="attr"
                    :ref="idx + '_' + pKey + '_' + aIdx"
                    :metadata="item.tmpl_part_metadata"
                    :multiRow="multiRowPart(item)"
                    :wordChecks="item.wordChecks"
                    @focus="focus(item, attr, part)"
                    @blur="blur(item, attr, part)"
                    :doubleClickEdit="false"
                    :edit="attr.editing"
                    :disable="
                      !item.permissions_list.canEdit ||
                      part.locked ||
                      attr.locked
                    "
                    :active="item.tmpl_part_metadata.active"
                    :spellCheck="
                      spellcheckEnabled &&
                      item.permissions_list.canEdit &&
                      (!item.tmpl_part_metadata.exclusions || !item.tmpl_part_metadata.exclusions.spell_check)
                    "
                    :spellCheckOnLoad="spellCheckOnLoad"
                    :spellCheckLanguageCode="
                      data.document.spell_check_language_code
                    "
                    :controlOffset="textControlOffset"
                    :textClass="
                      display === 'sidebysidefull'
                        ? 'comparePartTextFull'
                        : item.tmpl_part_metadata.layout
                    "
                    :displayMode="display"
                    :displayActions="aIdx === part.attributes.length - 1"
                    @updateEssential="
                      (data) => updateEssential(data, part, item)
                    "
                    @confirm="(data) => updateDocPart(data, attr, item, part)"
                    @languageIssue="
                      (data) => openLanguageMenu(data, item, part, attr)
                    "
                  ></TextInput>
                  <!--<div v-else>
                                            <table id="table" width="100%" class="groupTable" cellspacing="0" cellpadding="0" :style="getTableCss(part.attributes[0].content_metadata.css_styles)">
                                                <tr v-for="(row,rIdx) in part.attributes[0].content_metadata.rows" :key="rIdx" :style="getTableCss(row.css_styles)">
                                                    <td v-for="(cell,cIdx) in row.cells" :key="cIdx" :colspan="cell.col_span" :class="cell.selected ? 'selected' : ''" :rowspan="cell.row_span">
                                                        <span v-if="cell.content_type === 'static'" v-html="cell.value"></span>
                                                        <div v-else-if="cell.content_type === 'tmplPart'">
                                                            <div v-if="!cell.cell_content.parts[0].attributes[0].isLookup && !cell.cell_content.parts[0].attributes[0].isDependentLookup">
                                                                <TextInput 
                                                                    :value="cell.cell_content.parts[0].attributes[0]"
                                                                    :ref="idx + '_' + pKey + '_' + aIdx"
                                                                    :disable="cell.cell_content.parts[0].attributes[0].locked"
                                                                    :metadata="cell.cell_content.tmpl_part_metadata"
                                                                    :active="true"
                                                                    :wordChecks="cell.cell_content.wordChecks || []"
                                                                ></TextInput>
                                                            </div>
                                                            <div v-else-if="cell.cell_content.parts[0].attributes[0].isLookup || cell.cell_content.parts[0].attributes[0].isDependentLookup">
                                                                <LookupInput
                                                                    :value="cell.cell_content.parts[0].attributes[0]"
                                                                    :ref="idx + '_' + pKey + '_' + aIdx"
                                                                    :metadata="cell.cell_content.tmpl_part_metadata"
                                                                    :disable="cell.cell_content.parts[0].attributes[0].locked"
                                                                    :active="true"
                                                                    :part="cell.cell_content.dependentParts"
                                                                ></LookupInput>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>-->
                </v-col>
              </v-row>
            </div>
            <div class="viewPartRowActions">
              <!---HOVER ACTIONS-->
              <div
                style="
                  display: inline-flex;
                  position: absolute;
                  right: 115px;
                  top: -10px;
                "
                v-if="
                  data.document.state.canEditDocParts &&
                  item.permissions_list.canEdit &&
                  useAiImprovements &&
                  improvementParts.includes(part.doc_part_id) &&
                  !part.locked &&
                  part.text
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attr }">
                    <v-btn
                      icon
                      v-bind="attr"
                      v-on="on"
                      @click="doAiFunction(part, item, 'improve', $event)"
                      :class="
                        'userPilotImprovements' + item.type.replace(/\s+/g, '')
                      "
                      :style="`${part.hover ? '' : 'visibility: hidden'}`"
                    >
                      <v-icon color="green">mdi-auto-fix</v-icon>
                    </v-btn>
                  </template>
                  <span>Generate AI Improvements</span>
                </v-tooltip>
              </div>
              <div
                style="
                  display: inline-flex;
                  position: absolute;
                  right: 115px;
                  top: -10px;
                "
                v-else-if="
                  data.document.state.canEditDocParts &&
                  item.permissions_list.canEdit &&
                  useOpenAISuggestions &&
                  item.tmpl_part_metadata.suggestion_part &&
                  !part.locked &&
                  (!part.text || !useAiImprovements)
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attr }">
                    <v-btn
                      icon
                      v-bind="attr"
                      v-on="on"
                      @click="doAiFunction(part, item, 'suggestions', $event)"
                      :class="
                        'userPilotSuggestions' + item.type.replace(/\s+/g, '')
                      "
                      :style="`${part.hover ? '' : 'visibility: hidden'}`"
                    >
                      <v-icon color="green">mdi-auto-fix</v-icon>
                    </v-btn>
                  </template>
                  <span>Generate AI Suggestions</span>
                </v-tooltip>
              </div>
              <div
                style="
                  display: inline-flex;
                  position: absolute;
                  right: 27px;
                  top: 0px;
                "
              >
                <v-tooltip
                  v-if="!item.permissions_list.canEdit || part.locked"
                  right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="locked"
                      color="grey lighten-2"
                      :style="`padding-left:3px; ${
                        part.hover ? '' : 'visibility: hidden'
                      }`"
                      small
                      style=""
                      >mdi-lock</v-icon
                    >
                  </template>
                  <span>{{
                    item.permissions_list.disableReason || "Content Locked"
                  }}</span>
                </v-tooltip>
              </div>
              <!-- END HOVER ACTIONS-->
              <div
                style="
                  display: inline-flex;
                  position: absolute;
                  right: 69px;
                  top: -5px;
                "
                v-if="
                  !part.contentPart && item.childPartTypes?.length && part.doc_part_id > 0
                "
              >
                <v-badge
                  color="grey lighten-1"
                  overlap
                  v-if="!part.expanded"
                  :class="[
                    part.totalChildren &&
                    part.childPartTypes.some((cpt) =>
                      cpt.parts.some((x) =>
                        highlightedDocParts.includes(x.doc_part_id)
                      )
                    )
                      ? 'highlightedScorePart'
                      : '',
                  ]"
                  :value="part.totalChildren"
                  :content="part.totalChildren"
                  dense
                >
                  <v-btn
                    icon
                    small
                    style="float: right"
                    title="Show sub-parts"
                    @click="expandPart(part, item)"
                    :color="!part.totalChildren ? 'red' : ''"
                  >
                    <v-icon small>mdi-chevron-down</v-icon>
                  </v-btn>
                </v-badge>
                <div v-if="part.expanded" style="padding-right: 6px">
                  <v-icon small @click="shrinkPart(part)"
                    >mdi-chevron-up</v-icon
                  >
                </div>
              </div>
              <div
                style="
                  display: inline-flex;
                  position: absolute;
                  right: 40px;
                  top: -5px;
                "
                v-if="!item.tmpl_part_metadata.contentPart"
              >
                <v-menu bottom left attach>
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn
                      v-if="
                        !readOnly &&
                        ((!item.tmpl_part_metadata.tp_locked && !part.locked) ||
                          (data.document.state.canEditDocParts && part.canAdd))
                      "
                      icon
                      small
                      class="userPilotLCRowActions"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="grey lighten-2">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-if="
                        data.document.state.canEditDocParts &&
                        part.canAdd &&
                        item.permissions_list.canEdit
                      "
                      @click="addRow(part, item)"
                    >
                      <v-list-item-icon style="margin-right: 10px">
                        <v-icon>mdi-plus</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Add new</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        data.partsHistory.length &&
                        data.document.lifecycle_id &&
                        data.document.state.docPartCommentAction
                      "
                      @click="showPartHistory(part, item, true)"
                    >
                      <v-list-item-icon style="margin-right: 10px">
                        <v-icon>mdi-comment-text-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{
                        data.document.state.docPartCommentAction.name
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        allowSummarise &&
                        part.text &&
                        data.document.state.canEditDocParts &&
                        !(item.tmpl_part_metadata.tp_locked || part.locked)
                      "
                      :class="
                        'userPilotSummarise' + item.type.replace(/\s+/g, '')
                      "
                      @click="doAiFunction(part, item, 'summarise', $event)"
                      ><v-list-item-icon style="margin-right: 10px">
                        <v-icon>mdi-arrow-collapse-vertical</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Summarise</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="data.partsHistory.length"
                      @click="showPartHistory(part, item)"
                    >
                      <v-list-item-icon style="margin-right: 10px">
                        <v-icon>mdi-history</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>History</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        data.document.state.canEditDocParts &&
                        item.tmpl_part_metadata.allow_edit_dialog &&
                        !part.locked
                      "
                      @click="showNotes(item, part)"
                    >
                      <v-list-item-icon style="margin-right: 10px">
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Edit Notes</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="part.canLock"
                      @click="toggleLockRow(part, item)"
                    >
                      <v-list-item-icon style="margin-right: 10px">
                        <v-icon>{{
                          part.locked ? "mdi-lock-open" : "mdi-lock"
                        }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ part.locked ? "Un-Lock" : "Lock" }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="
                        data.document.state.canEditDocParts &&
                        part.canDelete &&
                        item.permissions_list.canEdit &&
                        !part.locked
                      "
                      @click="deleteRow(part, item, parent)"
                    >
                      <v-list-item-icon style="margin-right: 10px">
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div
                style="
                  display: inline-flex;
                  position: absolute;
                  right: 7px;
                  top: 0px;
                "
              >
                <v-tooltip
                  bottom
                  v-if="
                    data.document.state.canEditDocParts &&
                    part.notes &&
                    item.tmpl_part_metadata.allow_edit_dialog
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="notesIcon"
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 18px; color: #7d93b4 !important"
                      @click="showNotes(item, part)"
                      >mdi-comment-text-outline</v-icon
                    >
                  </template>
                  <div style="max-width: 500px" v-html="part.notes"></div>
                </v-tooltip>
              </div>
              <div
                style="
                  display: inline-flex;
                  position: absolute;
                  right: 97px;
                  top: -1px;
                "
              >
                <v-tooltip
                  bottom
                  v-if="
                    (part.hasComments || getPartHistory(part).length > 1) &&
                    data.document.state.docPartCommentAction
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :class="{
                        notesIcon: true,
                        notesEssential:
                          item.tmpl_part_metadata.uses_essential_flag,
                      }"
                      style="font-size: 20px; color: #7d93b4 !important"
                      v-bind="attrs"
                      v-on="on"
                      @click="showPartHistory(part, item, true)"
                      >mdi-timeline-text</v-icon
                    >
                  </template>
                  <span style="max-width: 500px">
                    <span
                      v-if="
                        part.hasComments && getPartHistory(part).length === 1
                      "
                      >{{ item.type }} comments</span
                    >
                    <span
                      v-else-if="
                        !part.hasComments && getPartHistory(part).length > 1
                      "
                      >{{ item.type }} activity</span
                    >
                    <span v-else>{{ item.type }} comments and activity</span>
                  </span>
                </v-tooltip>
              </div>
            </div>
            <div class="viewPartRowAdminActions" v-if="adminMode">
              <v-tooltip v-if="adminMode && pKey === 0" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="locked"
                    :color="
                      item.permissions_list.permissions.length > 0 ||
                      item.permissions_list.hierarchyPermissions.length > 0 ||
                      item.permissions_list.view_dependencies.length > 0
                        ? 'success'
                        : '#94979d7d'
                    "
                    small
                    style="padding-left: 3px"
                    @click="enableEditPartPermission(item, part)"
                    >mdi-security</v-icon
                  >
                </template>
                <span>{{
                  item.permissions_list.permissions.length > 0 ||
                  item.permissions_list.hierarchyPermissions.length > 0 ||
                  item.permissions_list.view_dependencies.length > 0
                    ? "Permission Controlled"
                    : "Add Permissions"
                }}</span>
              </v-tooltip>
              <v-tooltip v-if="adminMode && pKey === 0" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    style="
                      cursor: pointer;
                      padding-left: 3px;
                      position: relative;
                    "
                    @click="enableEditPart(item, part)"
                    color="#94979d7d"
                    >mdi-cog</v-icon
                  >
                </template>
                <span>Configure</span>
              </v-tooltip>
            </div>
          </div>
          <div
            v-if="
              !item.tmpl_part_metadata.contentPart &&
              part.childPartTypes &&
              part.childPartTypes.length > 0 &&
              part.expanded
            "
          >
            <!-- <div
              class="childPartTypeHolder"
              v-for="(cpt, cptIdx) in part.childPartTypes"
              :key="'cpt' + cpt.tmpl_part_id + '_' + cptIdx"
            > -->
              <ViewParts
                :partTypes="part.childPartTypes"
                :data="data"
                :disableEdit="disableEdit"
                :adminMode="adminMode"
                :viewLinkedContent="viewLinkedContent"
                :readOnly="readOnly"
                :lockSection="lockSection"
                :isDragging="isDragging"
                :spellcheckEnabled="spellcheckEnabled"
                :nonSuggestionsList="nonSuggestionsList"
                :sectionPermission="sectionPermission"
                :highlightedDocParts="highlightedDocParts"
                :displayMode="displayMode"
                :spellCheckOnLoad="spellCheckOnLoad"
                :childPartLevel="childPartLevel + 1"
                :parent="part"
                @editPart="$emit('editPart', $event)"
                @updatePart="$emit('updatePart', $event)"
                @editPartPermission="$emit('editPartPermission', $event)"
                @hierarchyUpdated="$emit('hierarchyUpdated', $event)"
                @triggerNotification="$emit('triggerNotification', $event)"
                @syncPosition="$emit('syncPosition', $event)"
                @updateRecentPart="$emit('updateRecentPart', $event)"
                @openSuggestions="$emit('openSuggestions', $event)"
                @changeOrder="$emit('changeOrder', $event)"
                @saveStart="$emit('saveStart', $event)"
                @saveEnd="$emit('saveEnd', $event)"
                @deletePart="$emit('deletePart', $event)"
                @showPartHistory="$emit('showPartHistory', $event)"
                @updateContentPart="$emit('updateContentPart', $event)"
                @processResults="$emit('processResults', $event)"
                @showDeletedPartHistory="$emit('showDeletedPartHistory', $event)"
                @suggestionDropEnd="$emit('suggestionDropEnd', $event)"
              />
              <!-- <draggable
                                        :list="cpt.parts"
                                        :handle="'.dragHandle'"
                                        ghost-class="ghost"
                                        :move="canMove"
                                        :group="{ name: `${data.document.doc_id}_pt` }"
                                        :disabled="!item.permissions_list.canEdit"
                                        @start="draggedParent = {
                                          partType: cpt,
                                          part: part,
                                        }"
                                        @end="dragging = false"
                                        @change="(e) => { return movePart(e, cpt, part);}"                           
                                    >
                                <div class="childPartRow" v-for="(cp,cpIdx) in cpt.parts" :key="'cp' + cp.doc_part_id + '_' + cpIdx" >

                                    <div class="childPartItem" :class="[cpt.tmpl_part_metadata.contentPart ? 'contentPart' : 'tmplPart', getHighlightStyle(cpt, cp), highlightedDocParts.includes(cp.doc_part_id) ? 'highlightedScorePart' : '']"
                                        @dragover.prevent 
                                        @dragenter="highlightDragTarget(cpt, cp)" 
                                        @drop.stop.prevent="dropItem($event, cpt, cp, part)">
                                            <div class="childPartRowGutter">
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"                                
                                                            v-if="!item.tmpl_part_metadata.contentPart && item.tmpl_part_metadata.why_text && hintsVisible && cpIdx === 0"
                                                            class="locked"
                                                            color="grey lighten-2"
                                                            @click="showHint(cpt, true)"
                                                            small style="padding-left:3px"
                                                        >mdi-lightbulb-outline</v-icon>
                                                    </template>
                                                    <span>View Insights</span>
                                                </v-tooltip>
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                            v-if="!item.tmpl_part_metadata.contentPart && item.tmpl_part_metadata.how_to_text && hintsVisible && cpIdx === 0"
                                                            class="locked"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="grey lighten-2"
                                                            small style="padding-left:3px"
                                                            @click="showHint(cpt, false)"
                                                        >mdi-help-circle-outline </v-icon>
                                                    </template>
                                                    <span>Guidance</span>
                                                </v-tooltip>  
                                            </div>
                                            <div class="childPartRowContent" :style="{ 'padding-left': cp.moveable ? '50px' : '0px' }" :class="[cpt.tmpl_part_metadata.contentPart ? 'contentPart' : 'tmplPart',getCssStyles(item.tmpl_part_metadata.layout)]" >
                                                <v-icon v-if="cpt.tmpl_part_metadata.moveable" style="display:inline-flex;" class="dragHandle">mdi-drag-variant</v-icon> 
												<template
                                          v-if="cpt.tmpl_part_metadata.attributes && cpt.tmpl_part_metadata.attributes.some(x => x.tag_type_id)">
                                        <v-row dense><v-col  v-for="(attr, aIdx) in cp.attributes" :key="idx + '_' + pKey + '_' + aIdx" :cols="getCols(attr.cols)">
											<TagAttribute v-if="attr.tag_type_id" style="width: 100%;"
											:attribute="attr"
											:editable="!readOnly && !attr.locked && !part.locked"
											@changed="
												updateDocPart(
												$event,
												attr,
												cpt,
												cp, false
												)
											"
											></TagAttribute>
                                        <TextInput 
                                            v-else
                                            :value="attr"
                                            :ref="idx + '_' + pKey + '_' + aIdx"
                                            :metadata="cpt.tmpl_part_metadata"
                                            :multiRow="multiRowPart(cpt)"
                                            :wordChecks="cpt.wordChecks"
                                            @focus="focus(cpt, attr, cp)"
                                            @blur="blur(cpt, attr, cp)"
                                            :doubleClickEdit=false
                                            :edit="attr.editing"
                                            :disable="!item.permissions_list.canEdit || cp.locked || attr.locked"
                                            :active="cpt.tmpl_part_metadata.active"
                                            :spellCheck="spellcheckEnabled && item.permissions_list.canEdit && !cpt.tmpl_part_metadata.exclusions.spell_check"
                                            :spellCheckOnLoad="spellCheckOnLoad"
                                            :spellCheckLanguageCode="data.document.spell_check_language_code"
                                            :controlOffset="textControlOffset"
                                            :textClass="display === 'sidebysidefull' ? 'comparePartTextFull' : cpt.tmpl_part_metadata.layout"
                                            :displayMode="display"
                                            :displayActions="aIdx === cp.attributes.length-1"
                                            @updateEssential="(data) => updateEssential(data, cp, cpt)"
                                            @confirm="(data) => updateDocPart(data, attr, cp, cpt)"
                                            @languageIssue="(data) => openLanguageMenu(data, cpt, cp, attr)"
                                        ></TextInput>
										</v-col></v-row>
										</template>
                                                <TextInput 
                                                    v-else-if="!cp.contentPart"
                                                    :value="cp"
                                                    :ref="idx + '_' + pKey + '_' + cpIdx"
                                                    :metadata="cpt.tmpl_part_metadata"
                                                    :wordChecks="cpt.wordChecks"
                                                    :multiRow="multiRowPart(cpt)"
                                                    @focus="focus(cpt,cp, part)"
                                                    @blur="blur(cpt, cp, part)"
                                                    :doubleClickEdit=false
                                                    :edit="cp.editing"
                                                    :disable="!item.permissions_list.canEdit"
                                                    :active=true
                                                    :spellCheck="spellcheckEnabled && item.permissions_list.canEdit && !item.tmpl_part_metadata.exclusions.spell_check"
                                                    :spellCheckOnLoad="spellCheckOnLoad"
                                                    :spellCheckLanguageCode="data.document.spell_check_language_code"
                                                    :controlOffset="textControlOffset"
                                                    :textClass="item.tmpl_part_metadata.layout"
                                                    :displayMode="display"
                                                    :displayActions=true
                                                    @updateEssential="(data) => updateEssential(data, cpt, item)"
                                                    @confirm="(data) => updateDocPart(data, null, cpt, cp)"
                                                    @languageIssue="(data) => openLanguageMenu(data, item, cpt, null)"
                                                ></TextInput>
                                                <ViewControl v-else 
                                                    :value="cp" 
                                                    :metadata="item.tmpl_part_metadata"
                                                    :disabled="!item.permissions_list.canEdit" 
                                                    @update="(data) => updateContentPart(data, null, item, part)" 
                                                />
                                            </div>
                                            <div class="childPartRowActions">  
                                                <div style="display:inline-flex;position: absolute; right: 40px; top: -5px;" v-if="!item.tmpl_part_metadata.contentPart">
                                                    <v-menu bottom left attach>
                                                        <template
                                                            v-slot:activator="{ on, attrs }"
                                                            ><v-btn
                                                            icon
                                                            v-if="!readOnly && !cpt.tmpl_part_metadata.tp_locked && !cp.locked"
                                                            small
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            >
                                                            <v-icon color="grey lighten-2" >mdi-dots-vertical</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list dense>
                                                            <v-list-item v-if="data.document.state.canEditDocParts && cp.canAdd && item.permissions_list.canEdit" @click="addRow(cp, cpt, part)">
                                                                <v-list-item-icon style="margin-right: 10px">
                                                                    <v-icon>mdi-plus</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-title>Add new</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item v-if="data.partsHistory.length && data.document.lifecycle_id && data.document.state.docPartCommentAction" @click="showPartHistory(cp, item, true)">
                                                                <v-list-item-icon style="margin-right: 10px">
                                                                    <v-icon>mdi-comment-text-outline</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-title>{{ data.document.state.docPartCommentAction.name }}</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item
                                                                v-if="data.partsHistory.length"
                                                                @click="showPartHistory(cp, item)"
                                                            >
                                                                <v-list-item-icon style="margin-right: 10px">
                                                                    <v-icon>mdi-history</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-title>History</v-list-item-title>
                                                            </v-list-item>  
                                                            <v-list-item v-if="data.document.state.canEditDocParts && cpt.tmpl_part_metadata.allow_edit_dialog" @click="showNotes(item, cp)">
                                                                <v-list-item-icon style="margin-right: 10px">
                                                                    <v-icon>mdi-square-edit-outline</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-title>Edit Notes</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item v-if="part.canLock">
                                                                <v-list-item-icon style="margin-right: 10px">
                                                                    <v-icon>{{ part.locked ? 'lock_open' :'lock' }}</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-title>
                                                                    {{ part.locked ? 'Un-Lock' : 'Lock' }}
                                                                </v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item v-if="data.document.state.canEditDocParts && cp.canDelete && item.permissions_list.canEdit" @click="deleteRow(cp, cpt, part)">
                                                                <v-list-item-icon style="margin-right: 10px">
                                                                    <v-icon>mdi-delete</v-icon>
                                                                </v-list-item-icon>
                                                                <v-list-item-title>Delete</v-list-item-title>
                                                            </v-list-item>                    
                                                        </v-list>
                                                    </v-menu>  
                                                </div>   
                                                <div style="display:inline-flex;position: absolute; right: 27px; top: 0px;">
                                                    <v-tooltip v-if="!item.permissions_list.canEdit" right>
                                                        <template
                                                            v-slot:activator="{ on, attrs }"
                                                        >
                                                            <v-icon
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                class="locked"
                                                                color="grey lighten-2"
                                                                small style="padding-left:3px"
                                                            >mdi-lock</v-icon>
                                                        </template>
                                                        <span>{{item.permissions_list.disableReason}}</span>
                                                    </v-tooltip> 
                                                </div>      
                                                <div style="display:inline-flex;position: absolute; right: 7px; top: -1px;">
                                                    <v-tooltip
                                                        bottom
                                                        v-if="
                                                            (cp.hasComments || getPartHistory(cp).length > 1) &&
                                                            data.document.state.docPartCommentAction
                                                        "
                                                        >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                            :class="{
                                                                notesIcon: true,
                                                                notesEssential:
                                                                item.tmpl_part_metadata.uses_essential_flag,
                                                            }" style="font-size:20px; color: #7d93b4 !important;"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="showPartHistory(part, item, true)"
                                                            >mdi-timeline-text</v-icon
                                                            >
                                                        </template>
                                                        <span style="max-width: 500px">
                                                            <span v-if="cp.hasComments && getPartHistory(cp).length === 1">{{ item.type }} comments</span>
                                                            <span v-else-if="!cp.hasComments && getPartHistory(cp).length > 1">{{ item.type }} activity</span>
                                                            <span v-else>{{ item.type }} comments and activity</span>
                                                        </span>
                                                    </v-tooltip>
                                                </div>   
                                            </div>
                                            <div class="childPartRowAdminActions" v-if="adminMode">    
                                                <v-tooltip v-if="adminMode" right>
                                                    <template
                                                        v-slot:activator="{ on, attrs }"
                                                    >
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="locked"
                                                            :color="item.permissions_list.permissions.length > 0 || item.permissions_list.hierarchyPermissions.length || item.permissions_list.view_dependencies.length > 0 > 0 ? 'success' : '#94979d7d'"
                                                            small style="padding-left:3px"
                                                            @click="enableEditPartPermission(item,part)"
                                                        >mdi-security</v-icon>
                                                    </template>
                                                    <span>{{item.permissions_list.permissions.length > 0 || item.permissions_list.hierarchyPermissions.length > 0 || item.permissions_list.view_dependencies.length > 0 ? 'Permission Controlled' : 'Add Permissions'}}</span>
                                                </v-tooltip>              
                                                <v-tooltip v-if="adminMode" right>
                                                    <template
                                                        v-slot:activator="{ on, attrs }"
                                                    >
                                                        <v-icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            small style="cursor:pointer; padding-left:3px; position:relative;"
                                                            @click="enableEditPart(item,part)"
                                                            color="#94979d7d"
                                                        >mdi-cog</v-icon>
                                                    </template>
                                                    <span>Configure</span>
                                                </v-tooltip>                                                  
                                            </div>
                                    </div>
                                </div>
                                </draggable> -->
          </div>
          <div
            class="viewPartLinked"
            v-if="viewLinkedContent && part.linked_content.length > 0"
          >
            <div
              v-for="(content, cIdx) in part.linked_content"
              :key="cIdx"
              style="display: inline-flex; width: 100%"
              :style="{
                'padding-left': item.tmpl_part_metadata.moveable
                  ? '85px'
                  : '50px',
                'padding-right': adminMode ? '90px' : '0px',
              }"
            >
              <ViewControl
                :value="content.parts[0].attributes[0]"
                :disabled="!content.permissions_list.canEdit"
                :metadata="content.tmpl_part_metadata"
                @update="
                  (data) =>
                    updateContentPart(
                      data,
                      content.parts[0].attributes[0],
                      part
                    )
                "
              />
            </div>
            <div class="viewPartLinkedAdminActions" v-if="adminMode">
              <v-tooltip v-if="adminMode" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="locked"
                    :color="
                      item.permissions_list.permissions.length > 0 ||
                      item.permissions_list.hierarchyPermissions.length > 0
                        ? 'success'
                        : '#94979d7d'
                    "
                    small
                    style="padding-left: 3px"
                    @click="enableEditPartPermission(item, part)"
                    >mdi-security</v-icon
                  >
                </template>
                <span>{{
                  item.permissions_list.permissions.length > 0 ||
                  item.permissions_list.hierarchyPermissions.length > 0
                    ? "Permission Controlled"
                    : "Add Permissions"
                }}</span>
              </v-tooltip>
              <v-tooltip v-if="adminMode" right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    style="
                      cursor: pointer;
                      padding-left: 3px;
                      position: relative;
                    "
                    @click="enableEditPart(item, part)"
                    color="#94979d7d"
                    >mdi-cog</v-icon
                  >
                </template>
                <span>Configure</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <v-menu
      bottom
      left
      attach
      v-model="externalContentMenu.show"
      :value="externalContentMenu.show"
      :close-on-content-click="false"
      :nudge-width="200"
      :top="externalContentMenu.lookupTop"
      :position-y="externalContentMenu.lookupPosY"
      :position-x="externalContentMenu.lookupPosX"
      :max-width="externalContentMenu.lookupWidth"
      :min-width="externalContentMenu.lookupWidth"
      max-height="300px"
      offset-x
      content-class="elevation-2 userPilotSuggestionsMenu"
    >
      <AIMenu :data="aiMenuData" @setValue="setValue"></AIMenu>
    </v-menu>
    <v-dialog v-model="hint.show" scrollable max-width="800px">
      <v-card>
        <v-card-title>
          <!-- <v-icon>{{ hint.icon }}</v-icon> -->
          <span class="subtitle-1">{{ hint.title }}</span>
          <v-spacer></v-spacer>
          <!-- {{ hint.subtitle }} -->
          <v-btn
            icon
            title="close"
            class="btn-background"
            @click="hint.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="max-height: 800px">
          <div v-html="hint.text"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="notesDialog.show"
      @keyup.esc="notesDialog.show = false"
      scrollable
      max-width="1000px"
    >
      <v-card v-if="notesDialog.item && notesDialog.part">
        <v-card-title>
          <!-- <v-icon>{{ hint.icon }}</v-icon> -->
          <span class="subtitle-1">{{ notesDialog.item.type }}</span>
          <v-spacer></v-spacer>
          <!-- {{ hint.subtitle }} -->
          <v-btn
            icon
            title="close"
            class="btn-background"
            @click="notesDialog.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="max-height: 800px">
          <v-row dense>
            <v-col>
              <div v-html="notesDialog.part.text"></div>
            </v-col>
          </v-row>
          <v-row dense style="padding-top: 15px">
            <v-col>
              <v-textarea
                ref="notes"
                outlined
                class="roundish"
                label="Notes"
                placeholder="Add your notes here..."
                rows="1"
                auto-grow
                v-model="notesDialog.original.notes"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex align-center">
              <v-select
                v-if="$loginState.canQA"
                outlined
                class="roundish"
                :menu-props="{
                  'offset-y': true,
                }"
                :return-object="false"
                :items="utils.qualities"
                label="Reusable Content Usage"
                item-text="text"
                item-value="value"
                v-model="notesDialog.quality"
                hide-details
              ></v-select>
            </v-col>
            <v-col class="d-flex align-end justify-end">
              <v-btn
                ref="save"
                large
                class="roundish px-10"
                color="primarylight"
                @click="saveNotes"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Notification :notification="notification" />
    <RoleFlex
      v-if="data.document"
      :open="roleFlex.show"
      label="text"
      :documentId="roleFlex.documentId"
      :docPart="roleFlex.docPart"
      :fixedResults="data.document.flex_fixed_results"
      @closed="roleFlex.show = false"
      @workingPattern="newWorkingPattern"
      @flexupdated="saveFlex"
    ></RoleFlex>
    <LanguageMenu
      :show="languageMenu.show"
      @close="closeLanguageMenu"
      :xCoord="languageMenu.x"
      :yCoord="languageMenu.y"
      :menu="languageMenu"
      @doSpellReplace="(data, source) => doSpellReplace(data)"
      @languageOptionsUpdate="(data) => languageOptionsUpdate(data)"
    ></LanguageMenu>
    <AIImpDialog :data="aiImpData" @setValue="setValue" @close="closeAiFunction"></AIImpDialog>
  </div>
</template>

<script>
import TextInput from "@/components/common/TextInput";
import LookupInput from "@/components/common/LookupInput";
import ViewControl from "@/components/document/view/View_Content";
import RoleFlex from "@/components/cRoleFlex"; // @ is an alias to /src
import draggable from "vuedraggable";
import viewUtils from "./utils";
import Notification from "@/components/common/SnackBar.vue";
import utils from "@/common/utils.js";
import LanguageMenu from "@/components/common/LanguageMenu";
import { mapState } from "vuex";
import AIMenu from "./AI_Function_Menu.vue";
import AIImpDialog from "./AI_Improvement_Dialogue.vue";
import DatePicker from "@/components/common/DatePicker";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
// import TagAttribute from "@/components/document/TagAttribute";
import TagAttribute from "@/components/document/TagAttributeDetail";

export default {
  name: "ViewParts",
  props: {
    partTypes: Array,
    data: null,
    disableEdit: Boolean,
    checkSummary: Object,
    adminMode: Boolean,
    viewLinkedContent: Boolean,
    readOnly: Boolean,
    lockSection: Boolean,
    isDragging: Boolean,
    spellcheckEnabled: Boolean,
    nonSuggestionsList: Array,
    sectionPermission: Object,
    highlightedDocParts: Array,
    displayMode: String,
    spellCheckOnLoad: Boolean,
    childPartLevel: { type: Number, default: 0 },
	parent: Object,
  },
  data: function () {
    return {
      docParts: [],
      disablePartEdit: false,
      display: this.displayMode ?? "doc",
      essentialFlags: [
        {
          value: true,
          text: "Essential",
        },
        {
          value: false,
          text: "Non Essential",
        },
      ],
      hintsVisible: true,
      notification: {
        text: "",
        type: "success",
      },
      roleFlex: {
        documentId: "",
        show: false,
        docPart: {},
      },
      flexPartId: null,
      flexFixedResults: false,
      dragging: false,
      draggedParent: null,
      contentDrag: false,
      dragTargetPart: null,
      languageMenu: {
        show: false,
        x: 0,
        y: 0,
        textStatus: null,
        element: null,
        match: null,
      },
      hint: { show: false, icon: "", title: "", subtitle: "", text: "" },
      notesDialog: {
        show: false,
        original: null,
        item: null,
        part: null,
        quality: 0,
        dirty: false,
      },
      utils: utils,
      triggerAutoAdd: false,
      lastAddedId: null,
      suggestionsLoading: false,
      lookupMenuShow: false,
      lookupMaxHeight: 300,
      lookupWidth: 500,
      lookupTop: false,
      lookupPosY: 0,
      lookupPosX: 0,
      lookupsToShow: 20,
      lookupsPageSize: 20,
      suggestionTpId: null,
      suggestionData: [],
      selectedSuggestionIndx: -1,
      externalContentMenu: {
        show: false,
        activeNode: false,
        loading: false,
        suggestionType: null,
        lookupMaxHeight: 300,
        lookupWidth: 500,
        lookupTop: false,
        lookupPosY: 0,
        lookupPosX: 0,
        lookupsToShow: 20,
        lookupsPageSize: 20,
        lookupData: [],
      },
      aiPart: null,
      aiPartType: null,
      aiAttr: null,
      aiMenuData: null,
      aiImpData: null,
      partActionMenu:
        this.$loginState.user &&
        this.$loginState.user.settings.some(
          (s) =>
            s.setting === "document_part_action" &&
            s.value.indexOf("menu") === 0
        ),
      hierarchy: null,
      useTreePicker: true,
      selectedHr: null,
      rerender: 0
    };
  },
  components: {
    TextInput,
    ViewControl,
    draggable,
    Notification,
    RoleFlex,
    LanguageMenu,
    LookupInput,
    AIMenu,
    AIImpDialog,
    DatePicker,
    HierarchyTreePicker,
    TagAttribute,
  },

  watch: {
    partTypes(val) {
      if (val) {
        this.init(val);
      }
    },
    isDragging(val) {
      this.contentDrag = val;
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.settings.config,
      canAddHierarchy: (state) => state.hierarchies.canAddHierarchy,
    }),
    textControlOffset() {
      return this.adminMode ? "40px" : "0px";
    },
    cssVars() {
      return {
        "--admin-actions-offset": this.textControlOffset,
        "--child-level": `${this.childPartLevel * 50}px`,
        "--action-Width": `${this.childPartLevel ? 100 : 130}px`,
      };
    },
    autoAddNewRow() {
      return !this.$loginState.user.settings.some(
        (s) =>
          s.setting === "document_suppress_autoaddnewrow" && s.value !== "false"
      );
    },
    allowSummarise() {
      return this.config.contentGenerationConfig?.enableSummarise || false;
    },
    useOpenAISuggestions() {
      return this.config.contentGenerationConfig?.enablePartSuggestions;
    },
    useAiImprovements() {
      return this.config.contentGenerationConfig?.enablePartImprovements;
    },
    improvementParts() {
      let docPartIds = [];
      
      if (this.useAiImprovements && this.checkSummary) {
        const fixableIssues = [ 'sentence_length', 'word_count', 'word_list', 'infCheck' ];
        docPartIds = this.checkSummary.parts
          ?.filter(p => p.status.errors.some(e => fixableIssues.includes(e.check))
            || p.status.warnings.some(w => fixableIssues.includes(w.check)))
          .map(p => p.doc_part_id)
          .flat() || [];
      }

      return docPartIds;
    },
    visibleDocParts() {
      return this.docParts.filter(
        (x) =>
          (x.permissions_list.viewPermissionOverride === null ||
            x.permissions_list.viewPermissionOverride === true) &&
          (x.permissions_list.displayPermissionOverride === null ||
            x.permissions_list.displayPermissionOverride === true)
      );
    },
    dbDragRules() {
      return this.config.settings?.find((s) => s.setting === "database_drag_splitRules")?.value;
    },
  },
  mounted() {},
  created() {
    if (this.partTypes) {
      this.init(this.partTypes);
    }
  },
  methods: {
    async toggleLockRow(part, item) {
      part.locked = !part.locked;
      await this.updateDocPart(part, null, item, part);
    },
    syncPositions(item) {
      this.$emit("syncPosition", item.type);
    },
    getCols(cols) {
      return this.displayMode === "sidebysidefull" ? 12 : cols;
    },
    newWorkingPattern(html, wordCount) {
      let newVal = utils.partToSubParts(html);
      let wpPartType = this.data.document.parttypes.find(
        (pt) => pt.type === "Working Pattern"
      );
      wpPartType.parts[0].subParts = newVal;
      wpPartType.parts[0].text = html;
      wpPartType.wordCount = wordCount;
      this.isLoading = true;
      this.refreshFlexScore();
    },
    async saveFlex(flexModel) {
      if (
        this.roleFlex.docPart &&
        this.roleFlex.docPart.parts &&
        this.roleFlex.docPart.parts.length !== 0
      ) {
        let flexPart = {
          ...this.roleFlex.docPart.parts[0],
          notes: flexModel.workingPatternNotes,
        };

        await this.updateDocPart(
          { text: flexModel.workingPatternHtml },
          null,
          this.roleFlex.docPart,
          flexPart,
          true
        );
      }
    },
    onLookupListScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (!this.suggestionTpId && scrollTop + clientHeight >= scrollHeight) {
        this.lookupsToShow += this.lookupsPageSize;
        this.filterLookup();
      }
    },
    async setValue(value, contentGenerationRequestId) {
      this.externalContentMenu.show = false;
      const item = this.aiPartType;
      const part = this.aiPart;
      const attr = this.aiAttr;

      if (
        part.text === "" ||
        (attr !== null && attr.text === "")
      ) {
        value = value.trim();
      }

      let resp = await this.updateDocPart(
        { text: value },
        attr,
        item,
        part,
        true,
        contentGenerationRequestId
      );
      //console.log(resp);

      this.closeAiFunction();
    },
    /*async setValue(part, item, value){
            let resp = await this.updateDocPart({text: value}, null, item, part, true);
            console.log(resp);
        },*/
    closeAiFunction() {
      this.externalContentMenu.show = false;
      this.aiImpData = null;
      this.aiPart = null;
      this.aiPartType = null;
      this.aiAttr = null;
    },
    doAiFunction(part, partType, functionType) {
      this.externalContentMenu.show = false;
      this.suggestionsLoading = true;
      let containerRect =
        this.$refs[`container_${part.doc_part_id}`][0].getBoundingClientRect();
      let container = this.$parent.$refs["docContent"];
      this.lookupWidth = containerRect.right - containerRect.left - 45; //containerRect.width - 150;
      this.lookupPosX = containerRect.right - containerRect.left - 45;
      //let offset = ev.clientY - containerRect.top;
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );

      //show below textara unless textarea is at bottom of page
      if (containerRect.bottom - 95 + this.lookupMaxHeight < vh) {
        this.lookupPosY = containerRect.bottom - 95 + container.scrollTop;
        this.lookupTop = false;
      } else {
        this.lookupPosY = containerRect.top - 95 + container.scrollTop;
        this.lookupTop = true;
      }

      this.aiPart = part;
      this.aiPartType = partType;
      this.aiAttr = partType.tmpl_part_metadata.attributes.length > 1 ?
          part.attributes.find((a) =>
              !a.restrict_to_lookup &&
              !a.restrict_to_date &&
              !a.locked &&
              !a.tag_type_id
          ) : null;

      if (functionType === "suggestions") {
        this.suggestionTpId = partType.tmpl_part_id;
      }

      if (functionType === 'improve') {
        this.aiImpData =  {
          function: functionType,
          language: "English", //todo pass in lang
          docId: this.data.document.doc_id,
          text: this.aiAttr?.text || part.text,
          docLanguage: this.data.docLanguage,
          spelling: this.data.document.spell_check_language_code,
          partType: partType
        };
      } else {
        this.externalContentMenu = {
          show: true,
          lookupTop: this.lookupTop,
          lookupPosY: this.lookupPosY,
          lookupPosX: this.lookupPosX,
          lookupWidth: this.lookupWidth,
        };

        this.aiMenuData = {
          function: functionType,
          language: "English", //todo pass in lang
          docId: this.data.document.doc_id,
          text: this.aiAttr?.text || part.text,
          docLanguage: this.data.docLanguage,
          spelling: this.data.document.spell_check_language_code,
          suggestionTpId: this.suggestionTpId,
        };
      }
    },
    getTableCss(css_styles) {
      if (!css_styles) return;
      let returnObj = {};
      css_styles.forEach((c) => {
        returnObj[c.key] = c.value;
      });
      return returnObj;
    },
    showDeletedPartHistory(pt) {
      this.$emit("showDeletedPartHistory", pt);
    },
    multiRowPart(item) {
      // return (item.cardinality.maxError > 1 || item.cardinality.minError > 1 || item.cardinality.maxWarn > 1 || item.cardinality.minWarn > 1);
      return item.tmpl_part_metadata.multi_row;
    },
    async syncDocPartAttr(data, parts, attr, part, item) {
      let relatedItemIdx = item.parts.findIndex(
        (x) => x.doc_part_id === part.doc_part_id
      );
      let relatedPartIdx = part.attributes.findIndex(
        (x) => x.dpa_id === data.dpa_id
      );
      if (relatedPartIdx > -1) {
        part.attributes.splice(relatedPartIdx, 1, data);
        item.parts.splice(relatedItemIdx, 1, parts);
        await this.updateDocPart(data, attr, item, parts);
      }
    },
    showNotes(pt, p) {
      this.notesDialog = {
        original: JSON.parse(JSON.stringify(p)),
        item: pt,
        part: p,
        show: true,
        quality: parseInt(p.quality),
      };
    },
    async saveNotes() {
      await this.updateDocPart(
        { text: this.notesDialog.original.text },
        null,
        this.notesDialog.item,
        this.notesDialog.original,
        true
      );
      this.notesDialog = {
        item: null,
        part: null,
        show: false,
        quality: 0,
      };
    },
    showHint(pt, why, text) {
      this.hint.text =
        text ||
        (why
          ? pt.tmpl_part_metadata.why_text
          : pt.tmpl_part_metadata.how_to_text);
      this.hint.icon = why ? "mdi-lightbulb-outline" : "mdi-help";
      this.hint.title = why ? "Insights" : "Guidance";
      this.hint.subtitle = pt ? pt.type : "Document";
      this.hint.show = true;
    },
    openLanguageMenu(data, item, part, attr) {
      let allIssues = [...data.status.errors, ...data.status.warnings];
      let issue = allIssues.find(
        (x) => x.short_description === data.element.title
      );
      this.languageMenu = {
        show: true,
        x: data.coords.x,
        y: data.coords.y,
        languageCode: this.data.document.spell_check_language_code,
        textStatus: data.status,
        element: issue,
        doc_id: this.data.document.doc_id,
        item: item,
        part: part,
        attr: attr,
        ...data,
      };
    },
    closeLanguageMenu() {
      this.languageMenu.show = false;
    },
    async languageOptionsUpdate(source) {
      console.log(source);
    },
    async doSpellReplace(data) {
      this.closeLanguageMenu();
      await this.updateDocPart(
        { text: data },
        this.languageMenu.attr,
        this.languageMenu.item,
        this.languageMenu.part,
        true
      );
    },
    getHighlightStyle(item, part) {
      if (
        !this.isDragging ||
        item.tmpl_part_metadata.contentPart ||
        !this.data.document.state.canEditDocParts ||
        (item.permissions_list && !item.permissions_list.canEdit)
      ) {
        return "";
      }

      let returnVal = "";

      if (
        !item.tmpl_part_metadata.exclusions ||
        !item.tmpl_part_metadata.exclusions.drag
      ) {
        returnVal = "highlightDragEnd";

        if (
          this.dragTargetPart &&
          this.dragTargetPart.doc_part_id === part.doc_part_id
        ) {
          returnVal = "highlightDragEndHover";
        }
      }

      return returnVal;
    },
    highlightDragTarget(item, part) {
      this.dragTargetPart = {
        tmpl_part_id: item.tmpl_part_id,
        doc_part_id: part.doc_part_id,
        sequence: part.sequence,
      };
    },
    async dropItem(event, item, part, parent) {
      if (
        !this.isDragging ||
        item.tmpl_part_metadata.contentPart ||
        !this.data.document.state.canEditDocParts ||
        (item.permissions_list && !item.permissions_list.canEdit)
      ) {
        return;
      }

      if (
        !item.tmpl_part_metadata.contentPart &&
        item.tmpl_part_metadata.exclusions &&
        item.tmpl_part_metadata.exclusions.drag
      ) {
        return;
      }

      try {
        if (event.dataTransfer.types.indexOf("array") > -1) {
          let dataObj = JSON.parse(event.dataTransfer.getData("array"));
          if (!dataObj) return;
          await this.processDropAction(dataObj, item, part, parent);
        } else if (event.dataTransfer.types.indexOf("object") > -1) {
          let dataObj = JSON.parse(event.dataTransfer.getData("object"));
          if (!dataObj) return;
          await this.processDropAction(dataObj, item, part, parent);
        }
      } catch (ex) {
        // console.log(ex);
      }
    },
    checkLastAutoAdded(part) {
      if (this.triggerAutoAdd && this.lastAddedId !== null) {
        this.triggerAutoAdd = part.doc_part_id === this.lastAddedId;
        if (!this.triggerAutoAdd) {
          this.lastAddedId = null;
          //check last added - if empty, remove?
        }
      }
    },
    async processDropAction(data, item, part, parent) {
      this.checkLastAutoAdded(part);
      // let multiPart = (item.cardinality.maxError > 1 || item.cardinality.minError > 1 || item.cardinality.maxWarn > 1 || item.cardinality.minWarn > 1);
      let multiPart = item.tmpl_part_metadata.multi_row;

      let isAttr = item.tmpl_part_metadata.attributes?.length >= 1;
      let attr = isAttr ? part.attributes[part.attributes.length - 1] : null;

      let currentSeq = item.parts.length === 1 ? 1 : item.parts.length + 1;
      if (Array.isArray(data)) {
        await Promise.all(
          data.map(async (d, idx) => {
            if (multiPart) {
              let empty = item.parts.find(
                (x) =>
                  x.text === "" ||
                  x.attributes[x.attributes.length - 1].text === ""
              );
              if (attr) {
                attr.text = d.varVal;
              }
              if (empty) {
                let result = await this.updateDocPart(
                  { text: d.varVal },
                  attr,
                  item,
                  empty,
                  false,
                );
                empty.attributes[empty.attributes.length - 1].text = d.varVal;
                return {
                  part: empty,
                  resp: result,
                };
              } else {
                return await this.addPart(
                  part,
                  item,
                  parent,
                  d.varVal,
                  false,
                  false,
                  attr,
                  currentSeq + idx
                );
              }
            } else {
              if (attr) {
                attr.text = d.varVal;
              }
              let result = await this.updateDocPart(
                { text: d.varVal },
                attr,
                item,
                part,
                false,
              );
              return {
                part: part,
                resp: result,
              };
            }
          })
        ).then((resps) => {
          this.$emit("processResults", resps);
          this.$emit("saveEnd");
          if(this.displayMode === "sidebysidefull"){
            this.$emit("suggestionDropEnd");
          }
        });
      } else {
        //drag & drop from DB view:
        if (multiPart) {
          let results = [];
          let cleaned = utils.cleanItems([data.text], this.dbDragRules)[0];

          if (data.html !== undefined) {
            results = utils.getSplitParts(data.html, this.dbDragRules);
          }

          results = results.length > 1 ? results : [cleaned];

          await Promise.all(
            results.map(async (d, idx) => {
              if (multiPart) {
                let empty = item.parts.find(
                  (x) =>
                    x.text === "" ||
                    (x.attributes.length &&
                      x.attributes[x.attributes.length - 1].text === "")
                );
                if (attr) {
                  attr.text = d;
                }
                if (empty) {
                  let result = await this.updateDocPart(
                    { text: d },
                    attr,
                    item,
                    empty,
                    false,
                    data.contentGenerationRequestId
                  );
                  if (empty.attributes.length)
                    empty.attributes[empty.attributes.length - 1].text = d;
                  return {
                    part: empty,
                    resp: result,
                  };
                } else {
                  let addedItem = await this.addPart(
                    part,
                    item,
                    parent,
                    d,
                    false,
                    false,
                    attr,
                    currentSeq + idx,
                    data.contentGenerationRequestId
                  );
                  return addedItem;
                }
              } else {
                if (attr) {
                  attr.text = d;
                }
                let result = await this.updateDocPart(
                  { text: d },
                  attr,
                  item,
                  part,
                  false,
                  data.contentGenerationRequestId
                );
                return {
                  part: part,
                  resp: result,
                };
              }
            })
          ).then((resps) => {
            item.parts = item.parts.sort(
              (a, b) => (a.sequence > b.sequence && 1) || -1
            );
            this.$emit("processResults", resps);
            this.$emit("saveEnd");
          });
        } else {
          let cleaned = utils.cleanItems([data.text], this.dbDragRules)[0];
          let result = await this.updateDocPart(
            { text: cleaned },
            attr,
            item,
            part,
            false,
            data.contentGenerationRequestId
          );
          this.$emit("processResults", [
            {
              part: part,
              resp: result,
            },
          ]);
          this.$emit("saveEnd");
        }
      }
    },
    getCssStyles(layout) {
      return viewUtils.mapCssStyles(layout);
    },
    getPartHistory(part) {
      let ph = this.data.partsHistory.find((pa) => pa.key === part.doc_part_id);
      return ph ? ph.reviewVersions : [];
    },
    // instantiateNewRow(partType, parent, attr = null, text = "", idx = null) {
    //     // let currentParts = (parent ? parent.childParts : partType.parts).filter(
    //     //     (x) => x.tmpl_part_id === partType.tmpl_part_id
    //     // );
    //     let currentParts = partType.parts;
    //     let sequence = idx !== null ? idx : currentParts.length
    //         ? currentParts.reduce(
    //             (pval, cval) => (pval > cval.sequence ? pval : cval.sequence),
    //             0
    //         ) + 1
    //         : 1;
    //     let ret = {
    //         Status: "",
    //         StatusList: [],
    //         WordCount: "",
    //         doc_part_id: 0,
    //         id: "",
    //         notes: "",
    //         isDirty: false,
    //         rowMessage: "",
    //         rowDirty: false,
    //         sequence: sequence,
    //         status: "",
    //         text: text,
    //         is_essential:
    // 			partType.tmpl_part_metadata.uses_essential_flag === 2
    // 				? this.tempEssentials
    // 				? null
    // 				: 1
    // 				: 0,
    //         attributes: [],
    //         quality: 0,
    //         type: partType.type,
    //         tmpl_part_id: partType.tmpl_part_id,
    //         parent_dp_id: parent ? parent.doc_part_id : null,
    //         highlightSection: false,
    //         editing: false,
    //         hover: false,
    //         moveable: partType.tmpl_part_metadata.moveable,
    //         expanded: false,
    // 		active: true,	// copied from formatDocPart
    // 		locked: partType.tmpl_part_metadata.tp_locked, // copied from formatDocPart, but based on parent there      linked_content: [],
    //         childPartTypes: []
    //     };
    //     if (partType.tmpl_part_metadata.attributes.length) {
    //         ret.attributes = partType.tmpl_part_metadata.attributes.map((a) => {
    //         return {
    //             ...a,
    //             dpa_id: 0,
    //             tpa_id: a.tpa_id,
    //             text: attr && a.tpa_id === attr.tpa_id ? text : "",
    //             editing: false,
    //             isDirty: true,
    //             active: true,
    //             cols: a.cols,
    //             restrict_to_lookup: a.restrict_to_lookup,
    //             rank_attribute: a.rank_attribute,
    //             placeholder: a.placeholder,
    //             restrict_to_date: a.restrict_to_date,
    //             locked: a.locked,
    //             css_class_name: a.css_class_name,
    //             allow_adding: a.allow_adding,
    //             lookup_type: a.lookup_type,
    //             lookup_key: a.lookup_key,
    //             restrict_to_htid: a.restrict_to_htid,
    //             restrict_to_ht_level: a.restrict_to_ht_level,
    //             multi_select: a.multi_select,
    //             multi_select_separator: a.multi_select_separator,
    //             tag_type_id: a.tag_type_id
    //         };
    //         });
    //     } else {
    //         ret.attributes.push({
    //             active:true,
    //             allow_adding:true,
    //             cols:12,
    //             css_class_name:"",
    //             default_text: partType.tmpl_part_metadata.default_text ?? "",
    //             editing: false,
    //             isDirty: true,
    //             include_in_part_val:true,
    //             locked: partType.tmpl_part_metadata.tp_locked === 1,
    //             lookup_dependency_tpa_source:null,
    //             lookup_key:null,
    //             lookup_type:null,
    //             lookup_v2_tpa_destination:null,
    //             lookup_v3_tpa_destination:null,
    //             position:1,
    //             prefix:null,
    //             required:null,
    //             restrict_to_date:null,
    //             restrict_to_lookup:null,
    //             rank_attribute:null,
    //             separator:null,
    //             suffix:null,
    //             text: "",
    //             title:null,
    //             tpa_id:null,
    //             restrict_to_htid: 0,
    //             restrict_to_ht_level: 0,
    //             tag_type_id: null,
    //         });
    //     }

    //     // ret.childParts = [];
    //     if (partType.childPartTypes && partType.childPartTypes.length) {
    //         // utils.setupChildPartTypes(
    //         // ret,
    //         // partType,
    //         // this.data.partsHistory,
    //         // this.data.docHierarchies,
    //         // this.data.wordChecks
    //         // );
    // 		ret.childPartTypes = JSON.parse(JSON.stringify(partType.childPartTypes));
    // 		ret.childPartTypes.forEach(cpt => cpt.childPartTypes = null);
    //     }
    //     utils.setDocPartStatus(
    //         ret,
    //         partType,
    //         this.data.wordChecks,
    //         this.data.docStatus,
    //         this.data.document
    //     );

    //     if (parent !== undefined && parent.temp === true) {
    //         ret.temp = true;
    //     }

    //     return ret;
    // },
    addRow(currentPart, partType) {
      this.addPart(currentPart, partType, this.parent, "", true, true, null, currentPart.sequence + 1);

      //if the only part present is the placeholder then add 2 parts by default
      //otherwise it looks as though we haven't actually added a row at all
      if (!partType.parts.some((p) => p.doc_part_id)) {
        this.addPart(currentPart, partType, this.parent);
      }
    },
    async addPart(
      currentPart,
      partType,
      parent,
      text = "",
      emit = true,
      autoAdd = true,
      attr = null,
      idx = null,
      contentGenerationRequestId = null
    ) {
      let part = viewUtils.instantiateNewRow(
        this.data,
        partType,
        parent,
        attr,
        text,
        idx
      );
      /*let curIndex = partType.parts.findIndex(
                (p) => p.doc_part_id === currentPart.doc_part_id
            );*/
      //let isLast = partType.parts.length === curIndex + 1;
      //partType.parts.splice(curIndex + 1, 0, part);
      //let callback = this.changeOrder;
      //console.log(isLast);
      part.is_essential =
        partType.tmpl_part_metadata.uses_essential_flag === 2
          ? null
          : part.is_essential;

      //let isAttr = partType.tmpl_part_metadata.attributes?.length >= 2;

      let obj = {
        ...part,
        text: part.text,
        doc_part_id: part.doc_part_id,
        doc_part_type: partType.type,
        doc_id: this.data.document.doc_id,
        system_number: this.data.document.system_number,
        tmpl_part_id: partType.tmpl_part_id,
        notes: null,
        parent_dp_id: parent ? parent.doc_part_id : null,
        is_essential: part.is_essential,
        quality: 0,
        sequence: part.sequence,
        insertOrder: part.sequence,
        /*attributes: isAttr ? part.attributes
                    .filter((a) => a.isDirty)
                    .map((a) => {
                        return { tpa_id: a.tpa_id, dpa_id: a.dpa_id, text: a.text };
                    })
                : []*/
      };

      let resp = await this.updateDocPart(
        { text: text },
        null,
        partType,
        obj,
        emit,
        contentGenerationRequestId
		);
		this.triggerAutoAdd = autoAdd && this.autoAddNewRow && part.sequence > partType.parts[partType.parts.length - 1].sequence;

      return {
        part: obj,
        resp: resp,
      };
    },
    blur(item, attr) {
      if (attr.text === "" && this.triggerAutoAdd) {
        this.triggerAutoAdd = false;
        this.lastAddedId = null;
      }
    },
    rFlex() {
      this.roleFlex.show = true;
      this.roleFlex.documentId = this.data.document.doc_id.toString();
      if (this.isFlexTemplate || this.flexPartId) this.roleFlex.docPart = null;
      else
        this.roleFlex.docPart = this.docParts.find(
          (t) => t.type === "Working Pattern"
        );
    },
    updateAttrID(attr, data, resp) {
      if (attr.content_type === "TABLE") {
        let target = attr.rows.find((x) => x.sequence === data.sequence);
        target.doc_view_content_id = resp.doc_view_content_id;
      } else {
        attr.doc_view_content_id = resp.doc_view_content_id;
      }

      return attr;
    },
    async updateContentPart(data, attr, part) {
      this.$emit("saveStart");

      let updateObj = {
        doc_id: this.data.document.doc_id,
        doc_view_content_id: data.doc_view_content_id,
        doc_part_id: data.dp_id,
        tmpl_view_section_part_id: data.tmpl_view_section_part_id,
        tmpl_view_section_id: attr.tmpl_view_section_id,
        sequence: data.sequence,
        value: data.value,
      };

      if (attr.parent_id && !data.doc_part_id) {
        updateObj.doc_part_id = part.doc_part_id;
      }

      await viewUtils.saveContentPart(updateObj).then((resp) => {
        if (data.doc_view_content_id === 0) {
          attr = this.updateAttrID(attr, updateObj, resp.data.data);
        }
        this.$emit("updateContentPart", { part: attr, response: resp });
        this.$emit("saveEnd", resp.data.audit);
        this.triggerNotification("Content Updated", "success");
      });
    },
    showPartHistory(part, type, collab) {
		this.$emit("showPartHistory", { part: part, partType: type, collab: !!collab });
    },
    triggerNotification(text, type) {
      if (this.display === "sidebysidefull") {
        this.$emit("triggerNotification", text, type);
        return;
      }
      this.notification = {
        text: text,
        type: type,
      };
    },
    async syncFromHierarchy(data, attr) {
      if (!data) return;
      let newHr = attr.hierarchy.values.find((x) => x.value === data);
      let newText = newHr ? newHr[`level${attr.restrict_to_ht_level}`] : null;
      attr.hierarchyText = newText;
      this.$emit("hierarchyUpdated", {
        ht_id: attr.restrict_to_htid,
        hr_id: newHr.value,
      });

      /*let all = this.visibleDocParts.filter(x => x.tmpl_part_metadata.attributes?.some(s => s.restrict_to_htid > 0));
            all.forEach(a => {
                
            })*/
        },
        async syncFromNewHierarchy(data, attr){
            if(!data || !data.hierarchy) return;
            let newText = data.hierarchy[`level${attr.restrict_to_ht_level}`];
            attr.hierarchyText = newText;
            this.$emit("hierarchyUpdated", {
                ht_id: attr.restrict_to_htid,
                hr_id: data.hierarchy.value
            });
        },
        checkPartReorder(item, part){
            let tpa = item.tmpl_part_metadata.attributes.find(x => x.auto_order);
            if(tpa){
                let value = part.attributes.find(x => x.tpa_id === tpa.tpa_id);
                if(value.text){
                    let lookupOrder = item.tmpl_part_metadata.lookup_list[0].children.map(x => {
                        return {
                            auto_order_position: x.auto_order_position,
                            value: x.value,
                            title: x.title
                        }
                    }).sort((a, b) => { return a.auto_order_position - b.auto_order_position });

                    item.parts.sort((a, b) => {
                        let attrType1 = a.attributes.find(x => x.tpa_id === tpa.tpa_id);
                        let attrType2 = b.attributes.find(x => x.tpa_id === tpa.tpa_id);
                        return lookupOrder.findIndex(order => order.value === attrType1.text) - lookupOrder.findIndex(order => order.value === attrType2.text)
                    }).forEach((p,pIdx) => {
                        p.sequence = (pIdx+1);
                    })
                    
                    this.rerender++;

                    this.$emit("changeOrder", item, true);
                }
            }    
        },
      async updateDocPart(data, attr, item, part, emit = true, contentGenerationRequestId = null){
            this.$emit("saveStart");

      let isAttr = item.tmpl_part_metadata.attributes?.length >= 1;

      if (isAttr && attr) {
        if (attr?.tag_type_id) {
          attr.currentTags = data.currentTags;
          attr.deletedTags = data.deletedTags;
          attr.isDirty =
            data.deletedTags.length > 0 ||
            data.currentTags.some((x) => x.change);
        } else {
          attr.isDirty = true;
          //attr.isDirty = data.text !== attr.text;
          attr.text = data.text;
          part.text = utils.attributesToText(
            item.tmpl_part_metadata.attributes,
            part.attributes
          );
          part.subParts = utils.partToSubParts(part.text);
        }
      } else {
        part.text = data.text;
        part.subParts = utils.partToSubParts(part.text);
        if (part.attributes.length === 1) {
          part.attributes[0].text = data.text;
        }
      }

      let obj = {
        text: part.text,
        doc_part_id: part.doc_part_id,
        doc_part_type: item.type,
        doc_id: this.data.document.doc_id,
        system_number: this.data.document.system_number,
        tmpl_part_id: item.tmpl_part_id,
        notes: part.notes,
        parent_dp_id: part.parent_dp_id ?? null,
        is_essential: part.is_essential,
        quality: part.quality,
        locked: part.locked,
        sequence: part.sequence,
        attributes: isAttr
          ? part.attributes
              .filter((a) => a.isDirty)
              .map((a) => {
                return {
                  tpa_id: a.tpa_id,
                  dpa_id: a.dpa_id,
                  text: a.text,
                  tag_type_id: a.tag_type_id,
                  currentTags: a.currentTags || [],
                  deletedTags: a.deletedTags || [],
                };
              })
          : [],
        content_generation_request_id: contentGenerationRequestId,
      };

      this.checkPartReorder(item, part); 
      
      let result = await viewUtils.updateDocPart(obj);

      if (this.triggerAutoAdd) {
        await this.checkAutoAdd(item, part);
      }
      if (result.result.Data.tagPart) {
        part.text = result.result.Data.tagPart.varVal;
        if (!part.doc_part_id && result.result.Data.tagPart.dp_id)
          part.doc_part_id = Number(result.result.Data.tagPart.dp_id);
        part.attributes.forEach((a) => {
          a.editing = false;
          a.isDirty = false;
          if (a.tag_type_id && result.result.Data.tagPart?.attributes) {
            // emit = false;
            let tagAttr = result.result.Data.tagPart.attributes.find(
              (x) => x.tpa_id === a.tpa_id
            );
            if (!a.dpa_id) a.dpa_id = tagAttr.dpa_id;
            a.currentTags
              .filter((x) => x.tag_value_id <= 0)
              .forEach((x) => {
                let newTag = tagAttr.tags.find(
                  (t) => t.value === x.value && t.tag_type_id === x.tag_type_id
                );
                if (newTag) {
                  this.$store.dispatch("hierarchies/addTag", newTag);
                }
              });
            a.currentTags = [];
            a.deletedTags = [];
            a.text = tagAttr?.text || "";
            a.tags = tagAttr?.tags || [];
            if (!emit) this.$emit("saveEnd");
          }
        });
      }

      if (emit) {
        this.triggerNotification(
          `${item.type} ${obj.doc_part_id === 0 ? "Added" : "Updated"}`,
          "success"
        );
        this.$emit("updatePart", { part: part, partType: item, response: result });
        this.$emit("saveEnd");
        return result;
      } else {
        return result;
      }
    },
    async checkAutoAdd(item, part) {
      if (
        this.data.document.state.canEditDocParts &&
        part.canAdd &&
        item.permissions_list?.canEdit &&
        part.text !== ""
      ) {
        if (this.lastAddedId) {
          let lastAdd = item.parts.find(
            (x) => x.doc_part_id === this.lastAddedId
          );
          if (
            lastAdd &&
            lastAdd.text === "" &&
            lastAdd.doc_part_id !== part.doc_part_id
          ) {
            return;
          }
        }
        let addedItem = await this.addPart(part, item, this.parent);
        this.lastAddedId = Number(
          addedItem.resp.result.Data.newRow.doc_part_id
        );
      }
    },
    processResults(resp, obj) {
      //todo - push up + reset single attr + update value
      let source = this.data.document.parttypes.find(
        (x) => x.tmpl_part_id === obj.tmpl_part_id
      );
      let targetPart = source.parts.find(
        (x) => x.doc_part_id === obj.doc_part_id
      );
      let idx = source.parts.findIndex(
        (x) => x.doc_part_id === obj.doc_part_id
      );

      if (idx > -1) {
        targetPart.text = resp.request.text;
        targetPart.subParts[0].text = resp.request.text;
      } else {
        source.parts.push(this.initialiseDocPart(obj));
      }

      source.parts = source.parts.sort(
        (a, b) => (a.sequence > b.sequence && 1) || -1
      );
    },
    handleDelete(row, partType) {
      //, parent, resp) {
      row.isDirty = false;
      // COMMENTED OUT AS CANT EVER HIT THIS based on calling proc - JW
      // let pts = (parent ? parent.childPartTypes : this.data.document.parttypes).find(
      //     (pt) => pt.type === partType.type
      // );
      // if (pts && pts.parts) {
      //     let pIndex = pts.parts.findIndex(
      //     (p) => p.doc_part_id === row.doc_part_id
      //     );
      //     if (pIndex >= 0) {
      //     // won't be present if moved
      //     pts.parts.splice(pIndex, 1);
      //     }
      // }
      // if (parent) {
      //     // clean up child list
      //     let cIndex = parent.childParts.findIndex(
      //     (p) => p.doc_part_id === row.doc_part_id
      //     );
      //     if (cIndex >= 0) parent.childParts.splice(cIndex, 1);
      //     if (!parent.childParts.length) {
      //     parent.expanded = false;
      //     }
      // }
      partType.parts.forEach((p) => {
        utils.setDocPartStatus(
          p,
          partType,
          this.wordChecks,
          this.docStatus,
          this.document
        );
      });

      // COMMENTED OUT AS CANT EVER HIT THIS based on calling proc - JW
      // let phIndex = this.partsHistory.findIndex(
      //     (ph) => ph.key === row.doc_part_id
      // );
      // if (phIndex >= 0) {
      //     if (this.history && this.history.key === row.doc_part_id) {
      //     this.history = null;
      //     }
      //     this.partsHistory.splice(phIndex, 1);
      // }
      // if (resp && resp.data && resp.data.Data && resp.data.Data.audit) {
      //     this.partsHistory.push(resp.data.Data.audit);
      //     if (this.ptDeleteHistory) {
      //     this.ptDeleteHistory.push(resp.data.Data.audit);
      //     }
      // }
      partType.hasDeletedParts = true;
      this.setCheckSummary();
    },
    async deleteRow(row, partType, parent) {
      this.$emit("saveStart");
      if (!partType) {
        this.data.document.parttypes.forEach((x) => {
          if (!partType && x.tmpl_part_id === row.tmpl_part_id) partType = x;
          if (!partType && x.childPartTypes) {
            partType = x.childPartTypes.find(
              (c) => c.tmpl_part_id === row.tmpl_part_id
            );
          }
        });
        if (!partType) alert("partType.type missing");
      }
      let data = {
        doc_part_id: row.doc_part_id,
        doc_part_type: partType.type,
        tmpl_part_id: row.tmpl_part_id,
      };

      if (!data.doc_part_id) {
        //doc part not yet saved so just remove locally.
        this.handleDelete(row, partType, parent);
      } else {
        await viewUtils.deletePart(data).then((resp) => {
          this.triggerNotification(`${partType.type} Deleted`, "success");
			this.$emit("deletePart", { row: row, partType: partType, parent: parent, response: resp });
          this.$emit("saveEnd");
        });
      }
    },
    async updateEssential(data, part, item) {
      this.$emit("saveStart");
      let obj = {
        text: part.text,
        doc_part_id: part.doc_part_id,
        doc_part_type: item.type,
        doc_id: this.data.document.doc_id,
        system_number: this.data.document.system_number,
        tmpl_part_id: item.tmpl_part_id,
        notes: null,
        parent_dp_id: null,
        is_essential: data ? 1 : 0,
        quality: 0,
        sequence: part.sequence,
      };
      part.is_essential = data ? true : false;
      await viewUtils.updateDocPart(obj).then((resp) => {
        this.$emit("saveEnd", resp.result.Data.audit);
        this.triggerNotification("Essential Flag Updated", "success");
        this.$emit("updatePart", { part: part, partType: item, response: resp });
        //this.processResults(resp, obj);
      });
      //this.$emit("updateEssential",data, part, item);
    },
    expandPart(part) {
      part.expanded = true;
      part.childPartTypes.forEach((cpt) => {
        if (cpt.parts.length === 0) {
          let newItem = viewUtils.instantiateNewRow(this.data, cpt, part);
          cpt.parts.push(newItem);
			if (!part.totalChildren)
				part.totalChildren = 1;
			else
				part.totalChildren++;
        }
      });
    },
    shrinkPart(part) {
      part.expanded = false;
    },
    canMove: function (evt) {
      if (
        this.readOnly ||
        !evt.relatedContext.element ||
        !evt.draggedContext.element.doc_part_id
      )
        return false;
      if (
        (evt.draggedContext.element.attributes?.length ||
          evt.relatedContext.element.attributes?.length) &&
        evt.draggedContext.element.tmpl_part_id !==
          evt.relatedContext.element.tmpl_part_id
      )
        return false;
      return (
        (evt.draggedContext.element.moveable === true &&
          evt.draggedContext.element.tmpl_part_id ===
          evt.relatedContext.element.tmpl_part_id &&
          evt.draggedContext.element.parent ===
            evt.relatedContext.element.parent)
      );
    },
    movePart: async function (source, destParttype, parent) {
      this.ddCount = 0;
      if (this.readOnly) return;
      let destIndex = null;
      let destBlockType = "";
      let destBlockParent = "";

      if (
        source.removed &&
        source.removed.element.childPartTypes &&
        source.removed.element.childPartTypes.some(x => x.parts.length)
      ) {
        destBlockType = source.removed.element.childPartTypes[0].type;
        destBlockParent = destParttype.type;
        let parentset = this.data.document.parttypes.find(
          (x) => x.tmpl_part_id === source.removed.element.tmpl_part_id
        );
        if (parentset)
          parentset.parts.splice(
            source.removed.oldIndex,
            0,
            source.removed.element
          );
        // check removed not still in childset - will be if dropped from above but will have fired "added" first if below
        this.data.document.parttypes.forEach((pt) => {
          pt.parts.forEach((p) => {
            p.childPartTypes.forEach((cpt) => {
              let dIndex = cpt.parts.findIndex(
                (x) => x.doc_part_id === source.removed.element.doc_part_id
              );
              if (dIndex >= 0) {
                cpt.parts.splice(dIndex, 1);
              }
            });
          });
        });
      } else if (
        parent &&
        source.added &&
        source.added.element.childPartTypes &&
        source.added.element.childPartTypes.some(x => x.parts.length)
      ) {
        destBlockType = destParttype.type;
        let ptype = null;
        this.data.document.parttypes.forEach((x) => {
          if (
            !ptype &&
            x.parts.some((p) => p.doc_part_id === parent.doc_part_id)
          )
            ptype = x.type;
          if (
            !ptype &&
            x.parts.some((xp) =>
              xp.childPartTypes.some((xc) =>
                xc.parts.some((xcp) => xcp.doc_part_id === parent.doc_part_id)
              )
            )
          )
            ptype = x.type;
        });
        let childset = parent.childPartTypes.find(
          (x) => x.tmpl_part_id === destParttype.tmpl_part_id
        );
        if (childset) {
          let csIndex = childset.parts.findIndex(
            (x) => x.doc_part_id === source.added.element.doc_part_id
          );
          if (csIndex >= 0) childset.parts.splice(csIndex, 1);
        }
      }
      if (destBlockType) {
        const childType = utils.pluralise(destBlockType);
        this.errorDialogue.message = `You cannot move this part to within a ${destBlockParent} as it contains ${childType}. Please remove it's ${childType} first.`;
        this.errorDialogue.show = true;
        return false;
      }

      if (source.removed) {
        // higher level dropped at lower level from "above" - need to find where dropped...
        let movedId = source.removed.element.doc_part_id;
        // find where dropped
        let dest = null;
        let destType = null;
        let destParent = null;
        this.data.document.parttypes.forEach((pt) => {
          if (!dest) {
            dest = pt.parts.find((p) => p.doc_part_id === movedId);
            if (dest) destType = pt;
          }
          if (!dest) {
            pt.parts.forEach((p) => {
              p.childPartTypes.forEach((cpt) => {
                if (!dest) {
                  let dIndex = cpt.parts.findIndex(
                    (x) => x.doc_part_id === movedId
                  );
                  if (dIndex >= 0) {
                    dest = cpt.parts[dIndex];
                    destType = cpt;
                    destParent = p;
                    destIndex = dIndex;
                  }
                }
              });
            });
          }
        });
        if (dest) {
          destParttype = destType;
          parent = destParent;
          await this.deleteRow(source.removed.element, destType, parent);
        } else {
          destParttype = null;
        }
        //source.added = source.removed;
      }
      if (!destParttype) return; // remove fired after added in some cases

      if (source.added) {
        let newPart = JSON.parse(JSON.stringify(source.added.element));
        newPart.tmpl_part_id = destParttype.tmpl_part_id;
        newPart.type = destParttype.type;
        newPart.doc_part_id = 0;
        newPart.status = "";
        newPart.newIndex = source.added.newIndex || destIndex;
        newPart.is_essential =
          destParttype.tmpl_part_metadata.uses_essential_flag === 2 ? 1 : 0;
        newPart.quality = 0;
        newPart.parent_dp_id = parent ? parent.doc_part_id : null;
		newPart.childPartTypes = [];
        if (destParttype.childPartTypes && destParttype.childPartTypes.length) {
			newPart.childPartTypes = JSON.parse(JSON.stringify(destParttype.childPartTypes));
			newPart.childPartTypes.forEach(cpt => cpt.childPartTypes = null);
        //   newPart.childParts = [];
        //   utils.setupChildPartTypes(
        //     newPart,
        //     destParttype,
        //     this.data.partsHistory,
        //     this.data.docHierarchies,
        //     this.data.wordChecks
        //   );
        }
        this.ddCount++;
        if (this.draggedParent) {
          await this.deleteRow(
            source.added.element,
            this.draggedParent.partType,
            this.draggedParent.part
          );
        } else {
          await this.deleteRow(source.added.element);
        }
        destParttype.parts = destParttype.parts.filter(
          (x) => x.doc_part_id !== source.added.element.doc_part_id
        );
        this.ddCount += 2;
        await this.addPart(
          newPart,
          destParttype,
          parent,
          newPart.text,
          true,
          false,
          null,
          null
        );
        this.$emit("changeOrder", destParttype, true);
      } else if (source.moved) {
        this.ddCount++;
        this.$emit("changeOrder", destParttype, true);
      }
    },
    focus(item, attr, part) {
      this.checkLastAutoAdded(part);
      if (
        attr.text === "" &&
        !this.nonSuggestionsList.some((v) =>
          item.type.toLowerCase().includes(v)
        )
      ) {
        this.$emit("openSuggestions", {
          ...item,
          attr_id: attr.tpa_id,
          dp_id: part.doc_part_id,
        });
      } else {
        this.$emit("updateRecentPart", {
          ...item,
          attr_id: attr.tpa_id,
          dp_id: part.doc_part_id,
        });
      }
    },
    deletePart(part, parentType, parent) {
      console.log(part);
      console.log(parentType);
      console.log(parent);
    },
    enableEditPart(item, part) {
      this.$emit("editPart", item, part);
    },
    enableEditPartPermission(item, part) {
      this.$emit("editPartPermission", item, part);
    },
    clone(item) {
      let newItem = Object.assign({}, item);
      delete newItem.doc_part_id;
      //delete newItem.highlightedText;
      delete newItem.text;
      delete newItem.subParts;
      delete newItem.previousValue;

      newItem = {
        ...newItem,
        doc_part_id: 0,
        text: "",
        editing: false,
        highlightedText: "",
        previousValue: "",
        subParts: [],
      };

      return newItem;
    },
    getPermissionInfo(x) {
      x.permissions_list.canEdit = true;
      x.permissions_list.disableReason = "";

      if (this.disableEdit) {
        x.permissions_list.disableReason = "Editing Disabled in Admin Mode";
        x.permissions_list.canEdit = false;
        return x;
      }

      if (
        x.tmpl_part_metadata.readonly === 1 ||
        x.tmpl_part_metadata.locked ||
        x.tmpl_part_metadata.tp_locked
      ) {
        x.permissions_list.disableReason = "Template Content Locked";
        x.permissions_list.canEdit = false;
        return x;
      }

      if (
        (!x.tmpl_part_metadata.contentPart &&
          !this.data.document.state.canEditDocParts) ||
        this.readOnly
      ) {
        x.permissions_list.disableReason = "No Edit Workflow Permissions";
        x.permissions_list.canEdit = false;
        return x;
      }

      if (
        x.tmpl_part_metadata.contentPart &&
        x.tmpl_part_metadata.readonly === 1
      ) {
        x.permissions_list.disableReason = "Template Content Readonly";
        x.permissions_list.canEdit = false;
        return x;
      }

      let canEdit = true;

      if (this.sectionPermission) {
        if (this.sectionPermission.editPermissionOverride !== null) {
          let partType = x.tmpl_part_metadata.contentPart ? "CONTENT" : "TMPL";
          let scopeIncluded =
            this.sectionPermission.editScope.includes(partType);
          let permType =
            this.sectionPermission.editPermissionOverride === false
              ? "RESTRICT"
              : "ALLOW";

          if (
            (permType === "RESTRICT" && scopeIncluded) ||
            (permType === "ALLOW" && !scopeIncluded)
          ) {
            x.permissions_list.disableReason = "Section Permission Locked";
            canEdit = false;
          }
        }
      } else {
        if (this.lockSection && !x.tmpl_part_metadata.contentPart) {
          x.permissions_list.disableReason = "Section Permission Locked";
          canEdit = false;
        }
      }

      if (x.permissions_list.editPermissionOverride !== null) {
        let partLocked = !x.permissions_list.editPermissionOverride;
        x.permissions_list.disableReason = partLocked
          ? "Part Permission Locked"
          : "";
        canEdit = !partLocked;
      }

      x.permissions_list.canEdit = canEdit;

      return x;
    },
    initialiseDocPart(x) {
      let partChecks = this.getWordChecksForPart(
        x.tmpl_part_id,
        this.data.wordChecks,
        this.data.docHierarchies
      );

      x.parts.forEach((p) => {
        if (p.subParts.length > 1) {
          p.text = utils.subpartsToPart(p.subParts);
        }
      });

      if (x.requireInit) {
        x.parts.forEach((p) => {
          if (p.attributes.length === 0) {
            p.attributes.push(viewUtils.dummyAttribute(p, x));
          }

        //   p.childParts = p.childParts.map((cp) => {
        //     let part = x.childPartTypes.find(
        //       (x) => x.tmpl_part_id === cp.tmpl_part_id
        //     );
        //     return {
        //       ...cp,
        //       tmpl_part_metadata: {
        //         ...part.tmpl_part_metadata,
        //       },
        //       active: true,
        //       locked: x.tmpl_part_metadata.tp_locked === 1,
        //     };
        //   });

          p.linked_content = [];
        });
      }

      x.tmpl_part_metadata = {
        ...x.tmpl_part_metadata,
      };

      x.parts = x.parts?.map((p) => {
        return {
          ...p,
          childPartTypes: p.childPartTypes?.map((cpt) => {
            let partChecks = this.getWordChecksForPart(
              cpt.tmpl_part_id,
              this.data.wordChecks,
              this.data.docHierarchies
            );
            cpt.parts.forEach(cp => {
              if(cp.attributes.length === 0){
                cp.attributes.push(this.dummyAttribute(cp, cpt));
              }
            })
            return {
              ...cpt,
              wordChecks: partChecks,
            };
          }),
          attributes: p.attributes?.map((a) => {
            let lookup = false;
            let dlookup = false;
            let hierarchy = null;
            let hierarchyTreePickerValue = null;
            let hierarchySelectedText = null;
            let hierarchyCanAdd = false;
            let sourceData = null;
            if (x.tmpl_part_metadata.attributes.length) {
              sourceData = x.tmpl_part_metadata.attributes.find(
                (ta) => ta.tpa_id === a.tpa_id
              );
              if (sourceData) {
                lookup = sourceData.isLookup;
                dlookup = sourceData.isDependentLookup;
              }
            } else {
              lookup = x.tmpl_part_metadata.restrict_to_lookup;
            }
            
            if (a.restrict_to_htid > 0) {
              hierarchy = this.$store.state.hierarchies.hierarchies.find(
                (ht) => ht.ht_id === a.restrict_to_htid
              );
              if (hierarchy) {
                let hr = this.data.document.hierarchies.find(
                  (dh) => dh.ht_id === a.restrict_to_htid
                );
                hierarchyCanAdd =
                  this.data.document.state.ht_id_can_add_node.some(
                    (h) => h === a.restrict_to_htid
                  );
                hierarchy.hr_id = hr ? hr.hr_id : null;
                let hItem = hierarchy.values.find(
                  (v) => v.value === hierarchy.hr_id
                );
                hierarchy.missing = hr ? false : true;
                hierarchy.allowAddLeafNode = hierarchyCanAdd;
                hierarchy.treePickerOptionsToUse =
                  hItem && !hItem.hr_active
                    ? hierarchy.treePickerOptions
                    : hierarchy.treePickerOptionsActive;
                hierarchySelectedText =
                  hr && hItem
                    ? hItem[`level${a.restrict_to_ht_level}`]
                    : hItem?.text;
                hierarchy.hierarchyTreePickerValue = hr?.hr_id;
                hierarchyTreePickerValue = hr?.hr_id;
                a.text = a.text === "" ? hierarchySelectedText : a.text;
              }
            }
            return {
              ...a,
              is_essential: p.is_essential,
              isLookup: lookup,
              isDependentLookup: dlookup,
              linked_parts: sourceData?.linked_parts ?? [],
              hierarchy: hierarchy,
              hierarchyTreePickerValue: hierarchyTreePickerValue,
              hierarchyText: hierarchySelectedText,
              hierarchyCanAdd: hierarchyCanAdd,
            };
          }),
        };
      });

      if (x.tmpl_part_metadata.linked_content.length > 0) {
        x.tmpl_part_metadata.linked_content =
          x.tmpl_part_metadata.linked_content.map((lc) => {
            return {
              ...this.getPermissionInfo(lc),
            };
          });

        x.parts.forEach((p) => {
          p.hover = false;
          p.linked_content = p.linked_content.map((lc) => {
            let lcType = x.tmpl_part_metadata.linked_content.find(
              (pt) =>
                pt.tmpl_view_section_part_id === lc.tmpl_view_section_part_id
            );
            return {
              ...lc,
              permissions_list: lcType.permissions_list,
            };
          });
        });
      }

      return {
        ...this.getPermissionInfo(x),
        wordChecks: partChecks,
      };
    },
    initialiseContentPart(x) {
      if (x.tmpl_part_metadata.content_type === "GROUP") {
        console.log("setup group");
        x.tmpl_part_metadata.content_metadata.rows.forEach((r) => {
          r.cells.forEach((c) => {
            if (c.cell_content) {
              c.cell_content = this.initialiseDocPart(c.cell_content);
            }
          });
        });
      }

      return {
        ...this.getPermissionInfo(x),
      };
    },
    initialisePart(x) {
      switch (x.tmpl_part_metadata.part_type) {
        case "docPart":
          return this.initialiseDocPart(x);
        case "contentPart":
          return this.initialiseContentPart(x);
      }
    },
    init(partTypes) {
      this.disablePartEdit = this.disableEdit;
      this.docParts = [];
      if (partTypes.length) {
        let initparts = partTypes.map((x) => {
          return this.initialisePart(x);
        });
        this.docParts = initparts;
      }
    },
    getWordChecksForPart(tpid, checks, hchys) {
      let tempChecks = checks.map((object) => ({ ...object }));
      let filtered = tempChecks.filter((w) =>
        w.tmpl_part_types.some(
          (tp) =>
            tp.tp_id === tpid &&
            (!tp.exclusions.length ||
              !hchys.some((h) =>
                tp.exclusions.some((e) => e.hr_id === h.hr_id)
              ))
        )
      );
      return utils.initialisePartChecks(filtered);
    },
    doubleClickTrigger(item) {
      if (item.editing) {
        return;
      }

      //let partChecks = this.getWordChecksForPart(item.tmpl_part_id, null, doc.docHierarchies, doc.tmpl_id);
      //item.wordChecks = partChecks;

      //item.previousValue = item.text;
      item.editing = !item.editing;
      /*if(this.isComparing && item.compareHtml !== ""){
                item.compareHtml = "";
            }      
            this.refreshDocs();*/
    },
  },
};
</script>

<style scoped lang="scss">
.viewPartHolder {
  * {
    font-family: "Manrope", sans-serif !important;
  }

  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #75838f;
  width: 100%;

  &.sbs {
    font-size: 11px !important;
    font-weight: 500;
    line-height: 24px;
    color: #eb2106 !important;
    width: 100%;
  }

  .viewPartRow {
    height: auto;
    min-height: 25px;
    margin-bottom: 5px;
    $gutterWidth: 65px;
    $contentMarginLeft: calc(#{$gutterWidth} + var(--child-level));
    $contentWidth: 100px;
    // $actionWidth: 130px;
    $actionWidth: var(--action-Width);
    $adminActionWidth: var(--admin-actions-offset);
    $mainContentInnerPaddingRight: 10px;
    border: solid 1px transparent !important;
    border-radius: 4px;
    box-sizing: border-box;

    .viewPartItem {
      width: 100%;
      display: flex;
      border-radius: 4px;
      border: solid 1px transparent !important;
      padding: 5px;

      &.tmplPart:hover {
        border: solid 1px rgba(138, 210, 252, 0.5) !important;
      }
    }

    .highlightedScorePart {
      background-color: lightgray;
    }

    .viewPartRowGutter {
      width: $gutterWidth;
      position: absolute;
      left: 0px;
      display: flex;
      align-items: flex-start;
      padding-top: 3px;
    }

    .viewPartRowContent {
      height: auto;
      min-height: 25px;
      width: calc(
        100% - #{$gutterWidth} - #{$actionWidth} - #{$adminActionWidth} - #{$mainContentInnerPaddingRight}
      );
      position: relative;
      margin-left: $contentMarginLeft;
      margin-right: calc(
        #{$actionWidth} + #{$adminActionWidth} + #{$mainContentInnerPaddingRight}
      );
      display: inline-flex;

      &.contentPart {
        width: calc(
          100% - #{$gutterWidth} - #{$adminActionWidth} - #{$mainContentInnerPaddingRight}
        ) !important;
        margin-right: calc(
          #{$adminActionWidth} + #{$mainContentInnerPaddingRight} + 40px
        ) !important;
      }

      &.central {
        width: 100%;
        text-align: center;
        margin-left: 60px;
        margin-right: 60px;
        justify-content: center !important;
      }

      &.bold {
        font-style: normal;
        font-weight: bolder !important;
        line-height: 24px !important;
        color: #3e3e3e !important;
      }

      &.header {
        font-size: 22px;
        line-height: 32px;
      }

      &.disabled {
        text-decoration: line-through;
        color: #00000026 !important;
      }
    }

    .viewPartRowActions {
      width: $actionWidth;
      position: absolute;
      right: $adminActionWidth;
      display: flex;
      align-items: flex-start;
      margin-top: 4px;
      height: 25px;
    }

    .viewPartRowAdminActions {
      width: $adminActionWidth;
      position: absolute;
      right: 3px;
      display: flex;
      align-items: flex-start;
      margin-top: 4px;
      height: 25px;
    }

    // .viewPartChildren {
    //   .childPartTypeHolder {
    //     padding-top: 15px;

    //     .childPartRow {
    //       height: auto;
    //       min-height: 25px;
    //       margin-bottom: 5px;
    //       $gutterWidth: 65px;
    //     $contentMarginLeft: $gutterWidth + 50px;
    //       $contentWidth: 100px;
    //       $actionWidth: 100px;
    //       $adminActionWidth: var(--admin-actions-offset);
    //       $mainContentInnerPaddingRight: 10px;
    //       border: solid 1px transparent !important;
    //       border-radius: 4px;
    //       box-sizing: border-box;
    //       padding: 0px;

    //       .childPartItem {
    //         display: flex;
    //         width: 100%;
    //         border-radius: 4px;
    //         border: solid 1px transparent !important;
    //         padding: 5px;

    //         &.tmplPart:hover {
    //           border: solid 1px rgba(138, 210, 252, 0.5) !important;
    //         }

    //         .childPartRowGutter {
    //           width: $gutterWidth;
    //           position: absolute;
    //           left: 0px;
    //           display: flex;
    //           align-items: flex-start;
    //           padding-top: 3px;
    //         }

    //         .childPartRowContent {
    //           height: auto;
    //           min-height: 25px;
    //           width: calc(
    //             100% - #{$gutterWidth} - #{$actionWidth} - #{$adminActionWidth} -
    //               #{$mainContentInnerPaddingRight}
    //           );
    //           position: relative;
    //           margin-left: $contentMarginLeft;
    //           margin-right: calc(
    //             #{$actionWidth} + #{$adminActionWidth} + #{$mainContentInnerPaddingRight}
    //           );
    //           display: inline-flex;
    //           border: solid 1px transparent !important;
    //           border-radius: 4px;
    //         }

    //         .childPartRowActions {
    //           width: $actionWidth;
    //           position: absolute;
    //           right: $adminActionWidth;
    //           display: flex;
    //           align-items: flex-start;
    //           margin-top: 4px;
    //           height: 25px;
    //         }

    //         .childPartRowAdminActions {
    //           width: $adminActionWidth;
    //           position: absolute;
    //           right: 3px;
    //           display: flex;
    //           align-items: flex-start;
    //           margin-top: 4px;
    //           height: 25px;
    //         }
    //       }
    //     }
    //   }
    // }

    .viewPartLinked {
      padding-top: 10px;

      .viewPartLinkedAdminActions {
        width: $adminActionWidth;
        position: absolute;
        right: 3px;
        display: inline-flex;
        align-items: flex-start;
        margin-top: 4px;
        height: 25px;
        padding-top: 10px;
      }
    }
  }
}

.v-application.theme--dark .viewPartHolder .viewPartRow {
  .highlightedScorePart {
    background-color: #2f2f2f !important;
  }
}

* {
  font-family: "Manrope" !important;
}

.dragHandle {
  font-size: 12px !important;
  display: inline-flex;
  padding-right: 10px;
  cursor: move;
  top: 6px;
  align-items: flex-start !important;
}

.docPartContainer {
  margin-bottom: 10px;
}

.contentHolder {
  border: solid 1px transparent !important;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0px;
  height: auto;
  min-height: 25px;
  display: inline-flex;
  width: 100%;
}

.partHolder {
  border: solid 1px transparent !important;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0px;
  height: auto;
  min-height: 25px;
  margin-bottom: 5px;
  display: block;
  width: 100%;
}

.childPartTextHolder {
  width: 100%;
  display: inline-flex;
  border: solid 1px transparent !important;
  border-radius: 4px;
  padding: 5px 0px 5px 0px;
  padding-left: 50px;

  &:hover {
    border: solid 1px rgba(151, 151, 151, 0.5) !important;
  }

  $gutterWidth: 50px;
  $actionsWidth: 50px;
  $adminActionsWidth: var(--admin-actions-offset);
  $contentWidth: calc(100% - #{$gutterWidth} - #{$actionsWidth});

  .childPartTextHolderGutter {
    width: $gutterWidth;
    display: inline-flex;
  }

  .childPartTextHolderMain {
    display: inline-flex;
    width: $contentWidth;
  }

  .childPartTextHolderActions {
    width: $actionsWidth;
    position: relative;
    right: var(--admin-actions-offset);
    top: 4px;
  }

  .childPartTextHolderAdminActions {
    width: 20px;
    position: relative;
    top: 4px;
  }
}

.partTextHolder.tmplPart:hover {
  border: solid 1px rgba(151, 151, 151, 0.5) !important;
}

.partTextHolder {
  width: 100%;
  display: inline-flex;
  border-radius: 4px;
  padding: 5px 0px 5px 0px;
  border: solid 1px transparent !important;

  $gutterWidth: 65px;
  $actionsWidth: 50px;
  $adminActionsWidth: var(--admin-actions-offset);
  $contentWidth: calc(100% - #{$gutterWidth} - #{$actionsWidth});

  &.contentPart {
    padding-top: 10px;
  }

  .partTextHolderGutter {
    width: $gutterWidth;
    display: inline-flex;
  }

  .partTextHolderMain {
    display: inline-flex;
    width: $contentWidth;
  }

  .partTextHolderActions {
    width: $actionsWidth;
    position: relative;
    right: var(--admin-actions-offset);
    top: 4px;
  }

  .partTextHolderAdminActions {
    width: 20px;
    position: relative;
    top: 4px;
  }
}

.docContent {
  padding-right: 3px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  border: 1px solid #4299e1;
}

::v-deep .lookupItem {
  cursor: pointer;
  &:hover,
  &.selected {
    &.theme--light {
      background-color: #eee;
    }
    &.theme--dark {
      background-color: #333;
    }
  }
  span {
    font-size: 14px;
    line-height: 1.5;
  }
  mark {
    color: inherit;
    border-radius: 5px;
    background-color: rgba(#0372ee, 0.3);
  }
}

table {
  border: 1px solid #ccc;
}
table td {
  padding: 15px;
  border: 1px solid #3a3838;
}
table td.selected {
  background-color: #aea8ff;
}

.groupTable {
  border: 1px solid;
  border-color: var(--table-border-color);
  border-collapse: collapse;
  border-left: 0;
  border-radius: 4px;
  border-spacing: 0px;

  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    border-collapse: collapse;
    background-color: var(--headrow-background-color);
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    background-color: var(--row-background-color);
  }
  th,
  td {
    padding: 5px 4px 6px 4px;
    text-align: left;
    vertical-align: top;
    border-left: 1px solid;
    border-color: var(--table-border-color);
  }
  td {
    border-top: 1px solid;
    border-color: var(--table-border-color);
  }
  thead:first-child tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
  }
  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
  }
}
</style>
