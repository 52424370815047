<template>
  <div>
    <v-dialog v-model="show" persistent width="500" class="rounded-xl">
      <v-card class="pt-5" v-if="item">
        <v-card-title
          ><v-row>
            <v-col v-if="!item.dpa_tag_id">Add {{ item.subjectType }}</v-col>
            <v-col v-else
              >{{ item.tag_value.value }}
              <div class="caption" v-text="item.tag_value.description"></div>
              <template v-if="item.tag_value.classifiers.length">
                <div
                  v-for="(c, ci) in item.tag_value.classifiers.filter(
                    (x) => x.value
                  )"
                  :key="'c' + ci"
                  class="subtitle-2"
                >
                  {{ c.name }}
                  <v-chip label small color="gray lighten-5">{{
                    c.value
                  }}</v-chip>
                </div></template
              ></v-col
            >
            <v-col cols="1"><v-icon @click="cancel">mdi-close</v-icon></v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-if="!item.dpa_tag_id"
            ><v-col>
              <v-autocomplete
                v-model="editedTag.tag_value_id"
                outlined
                :label="item.subjectType"
                required
                hide-details
                dense
                :items="item.tag_values"
                item-value="tag_value_id"
                item-text="value"
                :filter="tagFilter" :disabled="item.value_predefined"
              >
                <template v-slot:item="{ item }">
                  <v-row>
                    <v-col>
                      <v-chip small label outlined class="mr-4">
                        <span>{{ item.value }}</span>
                      </v-chip></v-col
                    >
                    <v-col>
                      <span v-if="item.description" style="font-size: 12px">
                        {{ item.description }}
                      </span></v-col
                    >
                    <v-col v-if="item.classifiers.length">
                      <div
                        v-for="(c, ci) in item.classifiers.filter(
                          (x) => x.value
                        )"
                        :key="'cv' + ci"
                        style="font-size: 12px"
                      >
                        <span>
                          {{
                            c.name
                          }}:
                        </span>
                        <span style="font-weight: bold;">{{ c.value }}</span>
                      </div></v-col
                    >
                  </v-row>
                </template></v-autocomplete
              >
            </v-col>
          </v-row>
          <v-row v-if="item.showRating"
            ><v-col>
              <v-select
                v-model="editedTag.tag_type_rating_id"
                outlined
                label="Proficiency"
                required
                hide-details
                clearable
                dense
                :items="ratings"
                item-value="tag_type_rating_id"
                item-text="name"
              >
                <template v-slot:item="{ item }">
                  <v-row>
                    <v-col cols="4">
                      <v-chip small label outlined class="mr-4">
                        <span>{{ item.name }}</span>
                      </v-chip></v-col
                    >
                    <v-col>
                      <span v-if="item.description" style="font-size: 12px">
                        {{ item.description }}
                      </span></v-col
                    >
                  </v-row>
                </template></v-select
              >
            </v-col>
          </v-row>
          <v-row v-if="item.showGrading"
            ><v-col>
              <v-select
                v-model="editedTag.tag_grading_type_value_id"
                outlined
                :label="item.gradingLabel"
                required
                hide-details
                clearable
                dense
                :items="item.gradings"
                item-value="tag_grading_type_value_id"
                item-text="name"
              >
                <template v-slot:item="{ item }">
                  <v-row>
                    <v-col cols="4">
                      <v-chip small label outlined class="mr-4">
                        <span>{{ item.name }}</span>
                      </v-chip></v-col
                    >
                    <v-col>
                      <span v-if="item.description" style="font-size: 12px">
                        {{ item.description }}
                      </span></v-col
                    >
                  </v-row>
                </template></v-select>
            </v-col>
          </v-row>
          <!-- <v-row dense>
            <v-col cols="11">
              <v-textarea
                v-model="editedTag.description"
                outlined
                label="Description"
                :readonly="!editedTag.active"
              ></v-textarea>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="removeTagValue"
            v-if="item.dpa_tag_id"
            >Remove {{ item.subjectType }}</v-btn
          >
          <v-checkbox
            v-else-if="!item.value_predefined"
            v-model="addAgain"
            label="Add another?"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
          <v-btn
            v-if="editedTag.active"
            color="blue darken-1"
            text
            @click="saveTagValue"
            :disabled="
              !editedTag.tag_value_id ||
              (editedTag.dpa_tag_id &&
                editedTag.tag_type_rating_id === item.tag_type_rating_id &&
                editedTag.tag_grading_type_value_id ===
                  item.tag_grading_type_value_id)
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
  </div>
</template>
  
  <script>
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
// import utils from "@/common/utils.js";
import { mapState } from "vuex";

export default {
  name: "TagValueAttribute",
  components: {
    ResponseHandler,
  },
  props: {
    item: Object,
  },
  data: function () {
    return {
      response: null,
      editedTag: {
        description: "",
        tag_type_rating_id: null,
        tag_grading_type_value_id: null,
        tag_value_id: null,
        dpa_tag_id: null,
        active: 1,
        error_message: "",
      },
      usesNotes: false,
      isLoading: false,
      show: false,
      tagType: null,
      addAgain: false,
    };
  },
  watch: {
    item(val) {
      if (val) this.editTag();
    },
  },
  computed: {
    ...mapState({
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagPermissions: (state) => state.hierarchies.tagPermissions,
    }),
    ratings() {
      let ratings = [];
      if (this.item.tag_value && this.item.tag_value.ratings.length)
        ratings = this.item.tag_value.ratings;
      else if (!this.item.tag_value && this.editedTag.tag_value_id)
        ratings = this.item.tag_values.find(x => x.tag_value_id === this.editedTag.tag_value_id)?.ratings || [];
      return ratings.length ? ratings : this.item.ratings
    }
  },
  created() {},
  methods: {
    setup() {
      this.allowAdd = this.tagPermissions.addTag;
      this.allowEditTag = this.tagPermissions.editTag;
      this.usesNotes = this.$loginState.user.settings.some(
        (s) => s.setting === "tagging_notes_used" && s.value !== "false"
      );
    },
    editTag() {
      this.setup();

      this.show = true;
      this.editedTag.tag_value_id = this.item.tag_value?.tag_value_id;
      this.editedTag.dpa_tag_id = this.item.dpa_tag_id;
      this.editedTag.tag_type_rating_id = this.item.tag_type_rating_id;
      this.editedTag.tag_grading_type_value_id =
        this.item.tag_grading_type_value_id;
      this.editedTag.error_message = "";
    },
    cancel() {
      this.show = false;
      this.$emit("cancel");
    },
    saveTagValue() {
      this.$emit("saved", this.editedTag, this.addAgain);
      this.show = false;
    },
    removeTagValue() {
      this.$emit("removed", this.editedTag);
      this.show = false;
    },
    tagFilter(item, queryText, itemText) {
      const words = queryText
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      const _itemText = itemText.toLowerCase();
      return words.every((w) => _itemText.indexOf(w) >= 0);
    },
  },
};
</script>
  <style scoped>
</style>
  