<template>
  <div class="chartHolder" :style="{ width: width ?`${width}px` : '100%', height: height ? `${height}px` : '100%' }">
    <Doughnut
      :data="chartJSData"
      :options="chartOptions"
      :style="chartStyles"
      ></Doughnut>
    <div class="centralTitle" v-if="centralLabel" v-html="centralLabel">
    </div>
  </div>
</template>
<script>
//import utils from "@/common/utils.js";
import { Doughnut } from 'vue-chartjs';

export default {
  name: "DoughnutChart",
  components: {
    Doughnut
  },
  props: {
    chartData: { type: Array },
    height: { type: Number, default: 0 },
    width: { type: Number, default: 0 },
    showLegend: { type: Boolean, default: false },
    centralLabel: { type: String, default: ''}
  },
  data: function () {
    return {
      colours: [
        "#2d99ff", // Blue
        "#2cd9c5", // Green
        "#FF4069", // Red
        "#FF9020", // Orange
        "#FFED6F", // Yellow
        "#BC80BD", // Purple
        "#8DD3C7", // Turquoise
        "#CCEBC5", // Pale Blue
        "#FFFFB3", // Pale Yellow
        "#BEBADA", // Lavender
        "#FCCDE5", // Pink
        "#D9D9D9", // Grey,
      ],
    };
  },
  updated() {
  },
  mounted() {
  },
  watch: {},
  computed: {
    chartJSData() {
      return {
        labels: this.chartData.map(d => d.label),
        datasets: [{
          data: this.chartData.map(d => d.value),
          backgroundColor: this.chartData.map(d => d.colour),
          hoverOffset: 4,
        }]
      }
    },
    chartOptions() {
      return {
        responsive: !this.height || !this.width,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: this.showLegend,
            position: 'right',
            maxWidth: this.width > 100 ? this.width - 50 : undefined,
            labels: {
              usePointStyle: true,
            }
          },
        }
      };
    },
    chartStyles() {
      return {
        height: this.height ? `${this.height}px` : undefined,
        width: this.width ? `${this.width}px` : undefined,
      }
    },
  },
  methods: {
  }
};
</script>
<style scoped lang="scss">
.chartHolder {
  position: relative;
  .centralTitle {
    text-align: center;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
</style>