<template>
  <table v-if="DocumentPartAttribute.tagValues?.length">
    <!-- <caption class="HeaderPart">
                      {{ DocumentPartAttribute.tagValues[0].tag_type_name }}
                    </caption> -->
    <thead>
      <tr>
        <th colspan="2">
          {{ DocumentPartAttribute.tagValues[0].tag_type_name }}
        </th>
        <th v-if="DocumentPartAttribute.tagRules.showRating">Proficiency</th>
        <th v-if="DocumentPartAttribute.tagRules.showRatingDescriptor">
          Descriptor
        </th>
        <th v-if="DocumentPartAttribute.tagRules.showGrading">
          {{ DocumentPartAttribute.tagValues[0].grading_type }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(t, ti) in DocumentPartAttribute.tagValues"
        :key="DocumentPartAttribute.DocumentPartAttribute_id + 't' + ti"
      >
        <td>{{ t.sequence }}</td>
        <td>
          {{ t.value }}
          <v-tooltip top v-if="t.description">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on">mdi-information</v-icon>
            </template>
            <div style="max-width: 300px;">{{ t.description }}</div>
          </v-tooltip>
        </td>
        <td v-if="DocumentPartAttribute.tagRules.showRating">
          {{ t.rating_name }}
        </td>
        <td v-if="DocumentPartAttribute.tagRules.showRatingDescriptor">
          {{ t.rating_description }}
        </td>
        <td v-if="DocumentPartAttribute.tagRules.showGrading">
          {{ t.grading_name }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
  
  <script>
// import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
// import utils from "@/common/utils.js";
import { mapState } from "vuex";

export default {
  name: "DocumentTagSummary",
  components: {
    // ResponseHandler,
  },
  props: {
    DocumentPartAttribute: Object,
  },
  data: function () {
    return {};
  },
  watch: {},
  computed: {
    ...mapState({
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagClassifierTypes: (state) => state.hierarchies.tagClassifierTypes,
      tagPermissions: (state) => state.hierarchies.tagPermissions,
    }),
  },
  created() {},
  methods: {},
};
</script>
  <style scoped>
caption {
  caption-side: top;
  text-align: left;
  padding-bottom: 5px;
}
th {
  text-align: left;
  font-weight: bold;
  padding-right: 10px;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}
td {
  padding-right: 10px;
  vertical-align: top;
}
</style>
  