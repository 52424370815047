<template>
  <div>
    <h3 class="mb-4" v-if="prompt">{{ prompt }}</h3>
    <v-row>
      <template v-if="settings.chartType === 'tagDistribution'">
        <v-col v-for="(ct, cti) in activeTagClassifierTypes" :key="ct.tag_classifier_type_id" style="max-width: 400px;">
          <v-autocomplete
            :items="ct.values"
            v-model="tagClassifierTypeIds[cti]"
            :label="`${settings.tagTypeName} ${ct.name}`"
            item-text="value"
            item-value="tag_classifier_type_value_id"
            clearable
            outlined
            dense
            class="roundish"
          ></v-autocomplete>
        </v-col>
      </template>
      <v-col v-if="settings.chartType === 'tagDistribution'" style="max-width: 400px;">
        <v-autocomplete
          :items="tagValues"
          v-model="tagValueId"
          :label="settings.tagTypeName"
          item-text="value"
          item-value="tag_value_id"
          clearable
          outlined
          dense
          class="roundish"
        ></v-autocomplete>
      </v-col>
      <template>
        <v-col v-for="(h, hi) in hierarchies"
          :key="'h' + hi"
          style="max-width: 400px;">
          <HierarchyTreePicker
            v-if="useTreePicker"
            v-model="h.treePickerValue"
            :options="h.treePickerOptionsActive"
            :fulldetail="true"
            :label="h.label"
            :placeholder="'Choose a ' + h.label"
            :clearable="true"
            :hierarchyType="h"
            :multiple="true"
            :limit="1"
            dense
            @changeNodeIds="onHryNodeIdSelect(h, $event)"
          >
          </HierarchyTreePicker>
          <v-autocomplete
            v-else-if="!h.selectNew"
            dense
            outlined
            clearable
            class="roundish"
            auto-select-first
            :ref="'hcb' + hi"
            :return-object="false"
            :items="h.values"
            item-value="text"
            item-text="text"
            :label="h.label"
            multiple
            v-model="h.hierarchy_text"
            @focus="focusHTID = hi"
            @keyup="searchText($event, h)"
            @keyup.enter.exact="pickValue(h)"
            @keyup.ctrl.enter="pickValue(h, true)"
            :filter="utils.comboFilterPicker"
            hide-details
            :append-icon="h.icon"
          >
            
            <template v-slot:prepend-item>
              <div
                v-if="h.showNewPrompt"
                style="padding: 0px 0px 10px 10px; font-size: 14px"
              >
                <div>
                  Press <kbd>Enter</kbd> to pick the highlighted item
                </div>
              </div>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ h.not_in_list_message }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </template>
    </v-row>
    <v-row :class="`my-6 mx-2 dt_${displayType}`" v-if="charts.length">
      <div class="yLabel" v-if="displayType === 'bars'">
        {{ this.measure }}
      </div>
      <div class="chartHolder">
        <template v-if="displayType === 'bars'">
          <Bar
            v-for="(chart, ci) in charts" :key="'bar_' + ci"
            :data="chart.data"
            :options="chartOptions"
            :style="chartStyles"
            ></Bar>
        </template>
        <template v-else-if="displayType === 'pieset'">
          <div class="pieHolder" v-for="(chart, ci) in charts" :key="'pie_' + ci">
            <Doughnut 
              :data="chart.data"
              :options="chartOptions"
              :style="chartStyles"
              ></Doughnut>
            <div class="centralTitle" v-if="displayType === 'pieset'">
              <h4>{{ chart.data.datasets[0].data.reduce((cum, curr) => cum += curr, 0) }}</h4>
              <p>{{ measure }}</p>
            </div>
            <h3 class="bottomTitle" v-if="displayType === 'pieset'">{{ chart.title }}</h3>
          </div>
        </template>
      </div>
      <div class="chartLegend">
        <v-select
          label="Sort Chart"
          outlined
          class="roundish"
          dense
          :items="sortOptions"
          v-model="selectedSortOption"
          ></v-select>
        <div class="ml-2">
          <h3>{{ dimensionTitle }}</h3>
          <v-checkbox
            v-for="(d, di) in dimensions"
            v-model="selectedDimensions"
            :key="'d' + di"
            :label="d.label"
            :value="d">
            <template v-slot:label>
              <v-icon :color="d.colour" size="20" class="mr-2">circle</v-icon>{{ d.label }}
            </template>
          </v-checkbox>
          </div>
      </div>
      <div class="xLabel" v-if="displayType === 'bars'">
        {{ this.category }}
      </div>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import utils from "@/common/utils.js";
import HierarchyTreePicker from "@/components/cHierarchyTreePicker";
import { mapState } from "vuex";
import { Bar, Doughnut } from 'vue-chartjs';

export default {
  name: "landingChart",
  components: {
    HierarchyTreePicker,
    Bar,
    Doughnut
  },
  data: function () {
    return {
      utils: utils,
      isLoading: false,
      tagClassifierTypeIds: [],
      tagValueId: null,
      category: "Job",
      dimension: "Rating",
      measure: "Number of Jobs",
      groupedValues: null,
      visibleGroupsCount: 0,
      dimensions: [],
      selectedDimensions: [],
      charts: [],
      colours: [
        "#50B3CB", // Blue
        "#FFCB00", // Yellow
        "#2cd946", // Green
        "#ED742D", // Orange
        "#EA3785", // Pink
        "#5E2872", // Purple
        "#8DD3C7", // Turquoise
        "#CCEBC5", // Pale Blue
        "#FFFFB3", // Pale Yellow
        "#BEBADA", // Lavender
        "#FCCDE5", // Pink
        "#D9D9D9", // Grey,
      ],
      hierarchies: [],
      selectedHierarchies: [],
      sortOptions: [
        'Alphabetically',
        'Reverse Alphabetically',
        'High to Low',
        'Low to High'
      ],
      selectedSortOption: 'High to Low',
    };
  },
  props: {
    definition: { type: Object },
    qaActions: { type: Array },
    editMode: { type: Boolean },
  },
  mounted() {
    this.tagValueId = this.settings.initialTagValueId;
    this.setupHierarchies();
  },
  watch: {
    hierarchiesLoading() {
      this.setupHierarchies();
    },
    'settings.initialTagValueId'(val) {
      if (val)
        this.tagValueId = val;
    },
    'settings.chartType'() {
      this.fetchData();
    },
    'settings.chartClassifierTypeId'() {
      this.fetchData();
    },
    displayType() {
      this.fetchData();
    },
    tagValueId() {
      this.fetchData();
    },
    docTypeIds() {
      this.fetchData();
    },
    selectedDimensions() {
      this.drawCharts();
    },
    selectedSortOption() {
      this.drawCharts();
    }
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      tagTypes: (state) => state.hierarchies.tagTypes,
      tagClassifierTypes: (state) => state.hierarchies.tagClassifierTypes,
      tagGradingTypes: (state) => state.hierarchies.tagGradingTypes,
      docTypes: (state) => state.hierarchies.docTypes,
      lifecycleStatuses: (state) => state.hierarchies.lifecycleStatuses,
      config: (state) => state.settings.config,
    }),
    settings() {
      return this.definition;
    },
    displayType() {
      return this.settings?.chartDisplayType || 'bars';
    },
    docTypeIds() {
      const chartDocTypes = this.settings?.docTypes || [];
      if (chartDocTypes.length)
        return this.docTypes.filter(dt => chartDocTypes.includes(dt.tmpl_name)).map(dt => dt.tmpl_id);
      else
        return [];
    },
    activeTagClassifierTypes() {
      let tagClassifierTypes = this.tagClassifierTypes;
      const setting = this.settings?.chartClassifierFilters;

      if (setting && setting.length)
        tagClassifierTypes = tagClassifierTypes.filter(ct => setting.includes(ct.tag_classifier_type_id));

      return tagClassifierTypes;
    },
    prompt() {
      switch (this.settings.chartType) {
        case "tagDistribution":
          return `Choose a ${this.singularize(this.settings.tagTypeName) }`;
        case "tagRequirements":
          return `Choose a Job`;
        default:
          return "";
      }
    },
    dimensionTitle() {
      return `${this.singularize(this.settings.tagTypeName)} ${this.dimension}`;
    },
    tagType() {
      return this.tagTypes?.find(tt => tt.tag_type_name === this.settings.tagTypeName) || null;
    },
    tagValues() {
      let values = this.tagType?.values || [];

      this.activeTagClassifierTypes.forEach((ct, cti) => {
        const selected = this.tagClassifierTypeIds[cti];
        if (selected) {
          values = values.filter(v => v.classifiers.some(c => c.tag_classifier_type_value_id === selected));
        }
      });
      
      return values;
    },
    chartStyles() {
      if (this.displayType === 'pieset')
        return {
          height: '120px',
          width: '120px',
        }
      else
        return {
          height: `${this.chartHeight}px`,
          width: this.chartWidth,
          position: 'relative',
        }
    },
    chartWidth() {
      const width = this.visibleGroupsCount * 50 + 200;
      if (width < (window.innerWidth - 400))
        return '100%';
      else
        return `${width}px`;
    },
    chartOptions() {
      if (this.displayType === 'pieset')
        return {
          responsive: false,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false
            }
          }
        };
      else
        return {
          responsive: this.chartWidth === '100%',
          maintainAspectRatio: false,
          interaction: {
            intersect: true,
            mode: 'index',
          },
          plugins: {
            legend: {
              display: false
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              ticks: { precision: 0 },
              stacked: true
            }
          }
        };
    },
    chartHeight() {
      return this.settings.height || 400;
    },
    useTreePicker() {
      return !!this.config.settings.find(
        (s) => s.setting === "hierarchy_tree_picker" && s.value === "true"
      );
    }
  },
  created() {
    if (this.settings?.chartFilterOptional) this.fetchData();
  },
  methods: {
    setupHierarchies() {
      if (
        !this.$store.state.hierarchies.loading &&
        this.$store.state.hierarchies.hierarchies.length
      ) {
        this.hierarchies = this.$store.state.hierarchies.hierarchies.map(
          (h) => {
            let hry = { ...h };
            switch (hry.label.toLowerCase()) {
              case "geography":
              case "location":
                h.icon = "mdi-map-marker-outline";
                break;
              default:
                h.icon = "mdi-menu-down";
                break;
            }
            return hry;
          }
        );
        this.tagClassifierTypeIds.length = this.activeTagClassifierTypes.length;
      } else {
        this.resetTreePicker();
      }
    },
    resetTreePicker() {
      if (this.hierarchies.length) {
        this.hierarchies.forEach((h) => (h.treePickerValue = []));
      }
    },
    onHryNodeIdSelect(ht, selNodeIds) {
      let sht = this.selectedHierarchies.find(s => s.ht_id === ht.ht_id);
      if (!sht) {
        this.selectedHierarchies.push({ ht_id: ht.ht_id, hr_node_ids: selNodeIds });
      } else {
        sht.hr_node_ids = selNodeIds;
      }

      this.fetchData();
    },
    fetchData() {
      let dataEndpoint = "report/tagDashboardData";
      let data = null;
      let randomAssignedColourIndex = 0;
      let assignDimensionColour = () => {
        return this.colours[randomAssignedColourIndex++];
      }
      switch (this.settings.chartType) {
        case "tagDistribution":
          if (this.tagValueId) {
            data = {
              tag_type_id: this.tagType.tag_type_id,
              categoryField: 'Job',
              dimentionType: 'Proficiency',
              measureField: 'Number of Jobs',
              tag_value_id: this.tagValueId,
              selectedHierarchies: this.selectedHierarchies,
              docTypeIds: this.docTypeIds,
            }
          }
          break;
        case "tagProficiencies":
          if (this.settings.chartFilterOptional || this.selectedHierarchies.some(h => h.hr_node_ids.length)) {
            data = {
              tag_type_id: this.tagType.tag_type_id,
              categoryField: 'Skill',
              dimentionType: 'Proficiency',
              measureField: 'Number of Jobs',
              tag_value_id: this.tagValueId,
              selectedHierarchies: this.selectedHierarchies,
              docTypeIds: this.docTypeIds,
            }
          }
          break;
        case "jobTagClassifiers":
          if (this.settings.chartFilterOptional || this.selectedHierarchies.some(h => h.hr_node_ids.length)) {
            data = {
              tag_type_id: this.tagType.tag_type_id,
              categoryField: 'Job',
              dimentionType: 'Classifier',
              classifierTypeId: this.settings.chartClassifierTypeId,
              measureField: 'Tags',
              tag_value_id: this.tagValueId,
              selectedHierarchies: this.selectedHierarchies,
              docTypeIds: this.docTypeIds,
            }
            const classifierType = this.tagClassifierTypes.find(ct => ct.tag_classifier_type_id === this.settings.chartClassifierTypeId);
            assignDimensionColour = (d) => {
              const classifierValue = classifierType.values.find(cv => cv.value === d);
              if (classifierValue && classifierValue.colour)
                return classifierValue.colour;
              else
                return this.colours[randomAssignedColourIndex++];
            }
          }
          break;
      }
      
      this.charts.splice(0);

      if (!data) {
        return;
      }
      data.chartFilterOptional = this.settings.chartFilterOptional;

      let possibleError = false;
      this.isLoading = true;
      axios
        .post(dataEndpoint, data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            const data = resp.data.Data;
            this.category = data.categoryField;
            this.dimension = data.dimensionField;
            this.measure = data.measureField;
            this.maxGroupedCount = data.maxGroupedCount;
            this.flatValues = data.flatValues;
            this.groupedValues = Object.groupBy(data.flatValues, (v) => v[this.category]);
            this.dimensions = [...new Set(data.flatValues.map(v => v[this.dimension]))]
              .map((d) => {
                return {
                  label: d,
                  colour: assignDimensionColour(d)
                }
              });
            this.selectedDimensions = [...this.dimensions];

            this.drawCharts();
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    drawCharts() {
      let chart;
      let selectedDimensionLabels = this.selectedDimensions.map(d => d.label);
      let groups = Object.keys(this.groupedValues)
        .map(g => {
            return {
              label: g,
              total: this.groupedValues[g].reduce((cum, curr) => cum += selectedDimensionLabels.includes(curr[this.dimension]) ? parseInt(curr[this.measure]) : 0, 0)
          }
        })
        .filter(g => g.total);
      
      if (this.visibleGroupsCount !== groups.length)
        this.charts.splice(0); //force redraw

      this.$nextTick(() => {
        this.visibleGroupsCount = groups.length;
        
        switch (this.selectedSortOption) {
          case 'Alphabetically':
          case 'Reverse Alphabetically':
            groups.sort((a, b) => a.label.localeCompare(b.label));
            break;
          case 'High to Low':
          case 'Low to High':
            groups.sort((a, b) => b.total - a.total);
            break;
        }
        groups = groups.map(g => g.label);
        if (['Reverse Alphabetically', 'Low to High'].includes(this.selectedSortOption))
          groups = groups.reverse();

        switch (this.displayType) {
          case "pieset":
            this.charts.splice(0);
            this.charts.push(...groups.map((c) => {
              return {
                title: c,
                data: {
                  labels: selectedDimensionLabels,
                  datasets: [{
                    data: selectedDimensionLabels.map(d => {
                      const val = this.groupedValues[c].find(v => v[this.dimension] === d);
                      return val ? parseInt(val[this.measure]) : 0;
                    }),
                    backgroundColor: this.selectedDimensions.map((d) => d.colour),
                  }]
                },
              }
            }));
            break;
          default:
            if (this.charts.length === 0) {
              chart = { data: null };
              this.charts.push(chart);
            } else {
              chart = this.charts[0];
            }
            chart.data = {
              labels: groups,
              datasets: this.selectedDimensions.map((d) => {
                return {
                  label: d.label,
                  data: groups.map(c => {
                    const val = this.groupedValues[c].find(v => v[this.dimension] === d.label);
                    return val ? parseInt(val[this.measure]) : 0;
                  }),
                  backgroundColor: d.colour
                }
              })
            }
            break;
        }
      })
    },
    singularize(word) {
      const endings = {
          ves: 'fe',
          ies: 'y',
          i: 'us',
          zes: 'ze',
          ses: 's',
          es: 'e',
          s: ''
      };
      return word.replace(
          new RegExp(`(${Object.keys(endings).join('|')})$`), 
          r => endings[r]
      );
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";

.chartHolder {
  width: calc(100% - 230px);
  overflow-x: auto;
}

.chartLegend {
  width: 230px;
  padding: 0 15px;
}

.dt_pieset {
  .chartHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .pieHolder {
      padding: 0;
      margin: 0 10px;
      width: 120px;
      position: relative;
      .centralTitle {
        text-align: center;
        position: absolute;
        top: 42px;
        left: 0;
        width: 100%;
        > h4 {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
        }
        > p {
          font-size: 12px;
          margin-top: -5px;
        }
      }
      .bottomTitle {
        text-align: center;
        width: 100%;
        white-space: wrap;
        font-size: 12px;
      }
    }
  }
}

.dt_bars {
  .chartHolder {
    width: calc(100% - 250px);
  }
  
  .xLabel {
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
  }

  .yLabel {
    width: 20px;
    text-align: center;
    font-weight: bold;
    writing-mode: tb-rl;
    transform: rotate(-180deg);
  }
}

</style>