var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{class:['mt-2', _vm.density],attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":_vm.sortColumn,"sort-desc":"","dense":_vm.density == 'dense',"page":_vm.page,"hide-default-footer":"","items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',_vm._l((_vm.headers),function(col,coli){return _c('td',{key:'col' + coli,on:{"click":function($event){return _vm.handleClick(item, col.value)}}},[(col.value === 'doc_checkbox')?_c('div',{staticClass:"text-right"},[_c('v-checkbox',{attrs:{"value":"","input-value":_vm.selectedDocs.includes(item.doc_id)},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.handleClick(item, col.value)}}})],1):(col.value === 'export_menu')?_c('div',[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openContextMenu($event, item)}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1):(col.value === 'system_number')?_c('div',{staticClass:"d-flex align-center"},[_c('span',{domProps:{"innerHTML":_vm._s(item.system_number)}})]):(col.value === 'doc_status_text')?_c('div',[_c('DocStatus',{attrs:{"document":item}})],1):(col.value === 'comment_count')?_c('div',[(item.comment_count != 0)?_c('CommentsCount',{attrs:{"document":item},on:{"openDocument":_vm.handleClick}}):_vm._e()],1):(col.value === 'overall_score')?_c('div',[(item.overall_score !== undefined && item.overall_score !== null)?_c('v-progress-circular',{staticClass:"ma-1",attrs:{"rotate":90,"size":_vm.density == 'sparse' ? 40 : _vm.density == 'dense' ? 25 : 35,"width":_vm.density == 'sparse' ? 4 : _vm.density == 'dense' ? 2 : 3,"value":item.overall_score,"color":item.overall_score_color}},[_c('span',{style:(_vm.density == 'dense' ? 'font-size: 12px' : '')},[_vm._v(_vm._s(item.overall_score))])]):_vm._e()],1):(
              col.value === 'listview3' ||
              col.value === 'recruiter' ||
              col.value === 'job_updated_at' ||
              col.value === 'job_created_at' ||
              col.value === 'created_by' ||
              col.value.startsWith('classifier_')
            )?_c('div',[_c('span',{staticClass:"small",domProps:{"innerHTML":_vm._s(item[col.value])}})]):(col.value === 'add_doc_button')?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("add_circle")])],1)],1):(col.value === 'docQuestionnaireStatus')?_c('div',[_c('v-chip',{staticClass:"status-chip",attrs:{"dense":"","small":"","label":"","outlined":"","color":item[col.sourceColour]}},[_vm._v(_vm._s(item[col.source]))])],1):(col.value === 'questionnaireStatuses')?_c('div',[(
                item[col.source] &&
                item[col.source].length &&
                item[col.source][0].status !== 'Unassigned'
              )?_c('div',[(item[col.source].length)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.showQuestionnaireSummary(item, col)}}},'div',attrs,false),on),[_vm._l((item[col.source]),function(rs,rsi){return _c('div',{key:'col' + coli + 'rs' + rsi,staticClass:"responder-stat",style:({
                        width: (80 * rs.count) / rs.total + '%',
                        backgroundColor: rs.colour,
                      })},[_vm._v(" "+_vm._s(rs.count)+" ")])}),_c('div',{staticStyle:{"display":"inline-flex","padding-left":"10px"}},[_c('v-badge',{attrs:{"content":item[col.source][0].total,"color":"blue lighten-3"}})],1)],2)]}}],null,true)},[_c('div',[_vm._v(_vm._s(item[col.source][0].total)+" in Total")]),_vm._l((item[col.source]),function(rs,rsi){return _c('div',{key:'col' + coli + 'rs' + rsi},[_c('div',{staticClass:"responder-stat",style:({
                      width: rs.count * 20 + 'px',
                      height: '20px',
                      fontSize: '14px',
                      backgroundColor: rs.colour,
                    })},[_vm._v(" "+_vm._s(rs.count)+" ")]),_c('div',{staticStyle:{"display":"inline-flex","padding-left":"6px","padding-top":"1px"}},[_vm._v(" "+_vm._s(rs.status)+" ")])])})],2):_vm._e()],1):_vm._e()]):(col.value === 'docQuestionnaireScore')?_c('div',[(item[col.source])?_c('v-chip',{staticClass:"status-chip",attrs:{"dense":"","small":"","label":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.showQuestionnaireSummary(item, col)}}},[_vm._v(_vm._s(item[col.source]))]):_vm._e()],1):(col.value === 'tisTimeToComplete')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"timeInStatus"},_vm._l((_vm.timeInStatusCols.filter(st => Math.round(100 * (item[st.value] || 0) / item['tisTimeToComplete']))),function(st){return _c('div',{key:st.value,style:({
                        width: `${Math.round(100 * (item[st.value] || 0) / item['tisTimeToComplete'])}%`,
                        backgroundColor: st.colour,
                      })})}),0)])]}}],null,true)},[(!item['tisCompleted'])?_c('div',[_vm._v("Lifetime: "+_vm._s(Math.round(item['tisTimeToComplete'] / (24 * 60)))+" days")]):_c('div',[_vm._v("Time to "+_vm._s(item.doc_status_text)+": "+_vm._s(Math.round(item['tisTimeToComplete'] / (24 * 60)))+" days")]),_vm._l((_vm.timeInStatusCols.filter(st => item[st.value])),function(st){return _c('div',{key:st.value},[_c('v-icon',{attrs:{"small":"","color":st.colour}},[_vm._v("circle")]),_vm._v(" "+_vm._s(st.name)+": "+_vm._s(Math.round(item[st.value] / (24 * 60)))+" days ( "+_vm._s(Math.round(100 * item[st.value] / item['tisTimeToComplete']))+" % ) ")],1)})],2)],1):(col.value === 'remove_doc_button')?_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("remove_circle")])],1)],1):_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(item[col.value])}})])])}),0)]}}])}),_c('v-dialog',{model:{value:(_vm.questionnaireSummary.show),callback:function ($$v) {_vm.$set(_vm.questionnaireSummary, "show", $$v)},expression:"questionnaireSummary.show"}},[_c('v-card',[_c('v-card-title',[_c('v-chip',{staticClass:"mr-4",attrs:{"large":"","outlined":"","label":""}},[_vm._v(_vm._s(_vm.questionnaireSummary.docTitle))]),_vm._v(" "+_vm._s(_vm.questionnaireSummary.type)+" "),_c('v-spacer'),_c('v-btn',{on:{"click":_vm.hideQuestionnaireSummary}},[_vm._v("Close")])],1),(_vm.questionnaireSummary.show)?_c('v-card-text',[_c('v-divider'),_c('DataCaptureQuestionnaireSummary',{attrs:{"identifier":_vm.questionnaireSummary.identifier,"isWithinDialogue":""}})],1):_vm._e()],1)],1),_c('DocumentActivities',{attrs:{"open":_vm.docActivities.open,"doc_id":_vm.docActivities.doc_id,"showEditBtn":true},on:{"closed":function($event){_vm.docActivities.open = false},"openDocument":function($event){return _vm.$emit('openDocument', $event)},"copyDocument":function($event){return _vm.$emit('copyDocument', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }