import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios';
import Loading from "./components/cLoading";
import moment from 'moment'
import "leaflet/dist/leaflet.css";
import store from './store';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import Zendesk from './common/zendesk.js';
import VueGtm from '@gtm-support/vue2-gtm';
import vueDebounce from 'vue-debounce';
import EventGatewayPlugin from './plugins/events.js'
import VueColumnsResizable from 'vue-columns-resizable';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale);

const getInitials = function (input) {
	if (!input) return "";

	const names = input.split(" ");
	let initials = names[0].substring(0, 1).toUpperCase();

	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}
	return initials;
}

Vue.prototype.$loginState = {
	isAuthenticated: false, user: null, reEnterPassword: false, sso: false, canQA: false, readOnly: false, canEditDocParts: false, canDemo: false,
	rmapiAccessToken: null,
	permittedRoutes: [],
	defaultRoute: "",
	setUser: function (u) {
		u.impersonatedInitials = getInitials(u.name);
		u.userInitials = getInitials(u.impersonator_name || u.name);
		this.user = u;
		this.impersonating = u && !!u.impersonator_name;
		this.clientUser = u.clientUser;
		this.noAccess = u.noAccess;
		this.canQA = u.canQA;
		this.canDemo = u.canDemo;
		this.canEditUsers = u.canEditUsers;
		this.onDemand = u.onDemand;
		this.canAdmin = u.canAdmin;
		this.canImpersonate = u.canImpersonate;
		this.readOnly = u.readOnly;
		this.canEditDocParts = u.canEditDocParts;
		this.isInternal = u.client_type === 'INTERNAL' || u.impersonator_client_type === 'INTERNAL';
		localStorage.setItem("RMTAGKEY", JSON.stringify({ email: u.email, name: u.name, impersonator_name: u.impersonator_name, client: u.client, groups: u.permission_groups }));
	},
	saveSetting: function (key, value) {
		let user_id = this.user ? this.user.user_id : '';
		localStorage.setItem(`${key}|${user_id}`, JSON.stringify(value));
	},
	getSetting: function (key, defaultValue) {
		let user_id = this.user ? this.user.user_id : 0;
		let raw = localStorage.getItem(`${key}|${user_id}`);
		if (raw === "[object Object]")
			return defaultValue || {};
		else
			return JSON.parse(raw) || defaultValue || {};
	},
	getUserSetting: function (key) {
		if (!this.user || !this.user.userSettings) return null;
		const setting = this.user.userSettings.find(s => s.us_type == key);
		if (!setting) return null;
		return setting.value;
	},
	saveUserSetting(key, value) {
		if (!this.user) return;
		if (!this.user.userSettings) this.user.userSettings = [];

		axios
			.post("user/updateSetting/", { type: key, value: value })
			.then((resp) => {
				if (resp.data.Status === "OK") {
					const setting = this.user.userSettings.find(s => s.us_type == key);
					if (setting) setting.value = value;
					else this.user.userSettings.push({ us_type: key, value: value });
				}
			})
			.catch((err) => {
				if (err.response && err.response.status === 401) {
					this.sessionExpired(err);
				} else {
					console.log(err);
					this.response = err.response
						? err.response.data
						: { message: "Unexpected Error" };
				}
			});
	},
	getLandingConfig(route) {
		return this.user?.landingPageConfigs?.find(c => c.page_route === route) || {};
	},
	updateLandingDefinition(id, definition) {
		if (this.user && this.user.landingPageConfigs) {
			const config = this.user.landingPageConfigs.find(lp => lp.lpd_id === id);
			if (config)
				config.definition = definition;
		}
	}
};

Vue.prototype.$passedParams = {
	params: {
		view: "",
		docTypes: [],
		filterString: "",
		statuses: [],
		section: "",
		title: "",
		sourcePath: "",
		sourceView: "",
		sourceTitle: "",
	},
	get() {
		const val = { ...this.params };
		this.params.view = "";
		this.params.docTypes = [];
		this.params.filterString = "";
		this.params.statuses = [];
		this.params.section = "";
		this.params.title = "";
		this.params.sourcePath = "";
		this.params.sourceView = "";
		this.params.sourceTitle = "";
		return val;
	},
	set(val) {
		this.params.view = val.view || "";
		this.params.docTypes = val.docTypes && Array.isArray(val.docTypes) ? val.docTypes : [];
		this.params.filterString = val.filterString || "";
		this.params.statuses = val.statuses && Array.isArray(val.statuses) ? val.statuses : [];
		this.params.section = val.section || "";
		this.params.title = val.title || "";
		this.params.sourcePath = val.sourcePath || "";
		this.params.sourceView = val.sourceView || "";
		this.params.sourceTitle = val.sourceTitle || "";
	}
}

Vue.config.productionTip = false
Vue.filter('dateDoMMMYY', function (value) {
	if (value) {
		return moment(new Date(value)).format('Do MMM YYYY')
	}
});
Vue.filter('dateDoMMMYYHHmm', function (value) {
	if (value) {
		return moment(new Date(value)).format('Do MMM YYYY HH:mm')
	}
});
Vue.use(PerfectScrollbar);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Loading', Loading);
Vue.use(vueDebounce);
Vue.use(VueColumnsResizable);
Vue.use(EventGatewayPlugin, {});

const zenDeskKey = process.env.VUE_APP_ZENDESK_KEY;
Vue.use(Zendesk, {
	key: zenDeskKey,
	disabled: true,
	hideOnLoad: false,
	settings: {
		webWidget: {
			offset: {
				horizontal: '5px',
				vertical: '45px'
			},
			zIndex: 300
		},
	}
})

window.showSupport = function () {
	vue.$zendesk.show();
}
window.toggleSupport = function () {
	vue.$zendesk.toggle();
}
window.hideSupport = function () {
	vue.$zendesk.hide();
}

// Load GTM from env if set, not sure if $config still wants setting??
if (process.env.VUE_APP_GTM_ID) {
	Vue.use(VueGtm, {
		id: process.env.VUE_APP_GTM_ID, //"GTM-TK7SRMT", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
		// queryParams: {
		// 	// Add url query string when load gtm.js with GTM ID (optional)
		// 	// gtm_auth: "aWQ9R1RNLVRLN1NSTVQmZW52PTEmYXV0aD1mMlVKU0pHaVdDWjYzZkRRYVFDa01B",
		// 	gtm_auth: config.GTM_AUTH, //"AB7cDEf3GHIjkl-MnOP8qr",
		// 	gtm_preview: config.GTM_PREVIEW, //"env-4",
		// 	gtm_cookies_win: config.GTM_COOKIES_WIN, //"x",
		// },
		defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
		enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
		debug: true, // Whether or not display console logs debugs (optional)
		loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
		vueRouter: router, // Pass the router instance to automatically sync with router (optional)
		ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
		trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
	});

	// is this needed? if not remove, left for consistancy after change to rmconfig removal
	Vue.prototype.$config = {
		GTM_AUTH: process.env.VUE_APP_GTM_AUTH,
		GTM_COOKIES_WIN: process.env.VUE_APP_GTM_COOKIES_WIN,
		GTM_ID: process.env.VUE_APP_GTM_ID,
		GTM_PREVIEW: process.env.VUE_APP_GTM_PREVIEW
	};
}

// bootstrap
const vue = new Vue({
	vuetify: vuetify,
	router,
	store,
	//components: { Loading },
	provide: {
		loginState: Vue.prototype.$loginState,
		store, router
	},	
	render: h => h(App)
}).$mount('#app');
