<template>
  <v-container>
    <v-row class="sectionTitle" v-if="section.label || section.section">
      <v-col class="pt-0">
        <h2>{{ section.label || section.section }}</h2>
      </v-col>
    </v-row>
    <v-row class="chartRow">
      <DoughnutChart
        :chartData="chartData"
        :showLegend="true"
        :height="150">
      </DoughnutChart>
    </v-row>
  </v-container>
</template>
<script>
import utils from "@/common/utils.js";
import DoughnutChart from "./common/DoughnutChart.vue";
export default {
  name: "DocumentDashboardPieChart",
  components: {
    DoughnutChart
  },
  props: {
    section: Object,
    settings: Object
  },
  data: function () {
    return {
      dimension: "key",
      measure: "",
      colours: [
        "#2d99ff", // Blue
        "#2cd9c5", // Green
        "#FF4069", // Red
        "#FF9020", // Orange
        "#FFED6F", // Yellow
        "#BC80BD", // Purple
        "#8DD3C7", // Turquoise
        "#CCEBC5", // Pale Blue
        "#FFFFB3", // Pale Yellow
        "#BEBADA", // Lavender
        "#FCCDE5", // Pink
        "#D9D9D9", // Grey,
      ],
    };
  },
  updated() {
  },
  mounted() {
    this.measure = this.getMeasures()[0].measureColumn;
  },
  watch: {},
  computed: {
    chartData() {
      const dimensionColours = this.section.view?.dimensionColours || [];
      const data = this.section.view?.data || [];
      let getColour = (v) => {
        let col = this.section.view.dimensionColours.find(
          (dc) => dc.value === v
        );
        if (!col) {
          col = { value: v, colour: this.colours[dimensionColours.length] };
          dimensionColours.push(col);
        }
        return col.colour;
      };
      const total = data.reduce((acc, curr) => acc += curr[this.measure], 0)
      return data.map(d => {
        return {
          label: `${d[this.dimension]} (${(100 * d[this.measure] / total).toFixed(0)}%)`,
          value: d[this.measure],
          colour: getColour(d[this.dimension])
        }
      });
    }
  },
  methods: {
    getMeasures() {
      let d = this.settings.measures.filter((x) =>
        this.settings.activeMeasures.some((m) => m === x.name)
      );
      d.forEach((m) => {
        let sort =
          this.settings.sortBy && this.settings.sortBy.measure === m.name
            ? this.settings.sortBy
            : null;
        m.sort = sort ? (sort.desc ? "desc" : "asc") : "";
      });
      return d;
    },
    exportToCSV() {
      let data = `"${this.section.section}",`;
      let cols = this.getMeasures();
      data += cols.map((c) => `"${c.name}"`).join(",") + "\n";
      this.section.view.data.forEach((d) => {
        //utils.removeTags(d[h.measureColumn])
        data += `${utils.csvEscape(d.key)},`;
        data += cols.map((c) => utils.csvEscape(d[c.measureColumn])).join(",") + "\n";
      });
      utils.downloadFile(
        data,
        `${this.section.section}.csv`,
        "text/csv;encoding:utf-8"
      );
    },
  }
};
</script>
<style scoped lang="scss">
.container {
  padding: 6px;
}
.sectionTitle {
  min-height: 56px;
}
.chart-container {
  cursor: pointer;
}
h2 {
  margin: 0px;
}
.chartRow {
  position: relative;
  .centralTitle {
    position: absolute;
    top: calc(50% - 30px);
    left: 0;
    width: 100%;
    height: 100%;
    > div {
      text-align: center;
    }
  }
}
</style>