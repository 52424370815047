<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th
            v-for="(h, hi) in listColumns"
            :key="'lc' + hi"
            :style="getTHStyle(listColumns, hi)"
            class="th text-left"
          >
            <h3 @click="setSort(h)">
              {{ h.text }}
              <v-icon v-if="h === sortColumn.column">{{
                sortColumn.descending
                  ? "mdi-arrow-down-thin"
                  : "mdi-arrow-up-thin"
              }}</v-icon>
              <v-menu
                v-if="h.filterSettings"
                v-model="h.filterSettings.show"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
                @input="openlistViewFilter(h)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="blue" small v-bind="attrs" v-on="on" icon>
                    <v-icon small>
                      {{
                        h.filterSettings.isActive
                          ? "mdi-filter-settings"
                          : "mdi-filter-outline"
                      }}</v-icon
                    >
                  </v-btn>
                </template>

                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ h.text }} Filters</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn small @click="filterClear(h.filterSettings)">
                          Clear
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn small @click="h.filterSettings.show = false">
                          Close
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn small @click="filterHeading(h.filterSettings)">
                          Apply
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><v-text-field
                            placeholder="type to filter values"
                            hide-details
                            append-icon="mdi-check-all"
                            @click:append="filterSearchAll(h.filterSettings)"
                            @input="filterSearch(h.filterSettings)"
                            dense
                            clearable
                            v-model="h.filterSettings.searchText"
                          ></v-text-field
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action v-if="h.filterSettings.pages > 1">
                        <v-pagination
                          v-model="h.filterSettings.page"
                          :length="h.filterSettings.pages"
                          :total-visible="7"
                        ></v-pagination>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list style="max-height: 100px; overflow: auto">
                    <v-list-item
                      dense
                      v-for="(v, vi) in h.filterSettings.values.filter(
                        (x) => !x.visible && x.selected
                      )"
                      :key="'head' + hi + 'vs' + vi"
                    >
                      <v-list-item-content>
                        <v-switch
                          v-model="v.selected"
                          color="blue"
                          :label="`${v.text} (${v.count})`"
                        ></v-switch>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-list
                    style="max-height: 400px; overflow: none"
                    @wheel.stop="filterScroll($event, h.filterSettings)"
                  >
                    <v-list-item
                      dense
                      v-for="(v, vi) in h.filterSettings.values.filter(
                        (x) => x.visible && x.page === h.filterSettings.page
                      )"
                      :key="'head' + hi + 'v' + vi"
                    >
                      <v-list-item-content>
                        <v-switch
                          v-model="v.selected"
                          color="blue"
                          :label="`${v.text} (${v.count})`"
                        ></v-switch>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
              <v-icon
                small
                title="Remove FIlter"
                v-if="h.filterSettings && h.filterSettings.isActive"
                @click="filterClear(h.filterSettings)"
                >mdi-filter-off</v-icon
              >
            </h3>
          </th>
        </tr>
      </thead>
      <tbody @wheel.stop="pageScroll($event)">
        <tr
          v-for="(item, index) in pageRows"
          :key="'r' + index"
          :style="index % 2 === 0 ? 'background-color: #f7f9fb;' : ''"
        >
          <td
            class="admin-cell"
            style="border-bottom: none"
            :style="{
              backgroundColor: col.column === 'colour' ? item[col.column] : '',
            }"
            v-for="(col, ci) in listColumns"
            :key="'r' + index + 'c' + ci"
            :class="!item.active ? 'inactive-row' : ''"
            :title="!item.active ? 'Value is inactive' : null"
            @click.stop="col.editable ? cellEdit(item, col) : rowClick(item)"
          >
            <template v-if="col.columnObject && item[col.columnObject] && item[col.columnObject].edit">
              <v-textarea
                v-model="item[col.columnObject][col.column]"
                auto-grow
                hide-details
                outlined
                clearable
                style="font-size: 12px"
                row-height="3"
                rows="3"
                @click.stop=""
              ></v-textarea
              ><v-btn
                small
                @click.stop="cellEditSave(item, col)"
                class="ml-2 float-right"
                >Save</v-btn
              ><v-btn
                small
                @click.stop="cellEditCancel(item, col)"
                class="float-right"
                >Cancel</v-btn
              ></template
            >
            <template v-else-if="col.columnObject">
              {{ item[col.columnObject] ? item[col.columnObject][col.column] : '' }}
              <v-icon v-if="col.editable && item[col.columnObject]" small class="float-right"
                >mdi-pencil</v-icon
              >
            </template>
            <template v-else-if="col.column">
              {{ item[col.column] }}
              <v-icon v-if="!item.active && col.column === 'value'" color="red"
                >mdi-emoticon-dead-outline</v-icon
              ></template
            >
            <template v-if="col.actions && col.actions.length">
              <br v-if="col.column" />
              <v-icon
                v-for="(a, ai) in col.actions"
                :small="!!col.column"
                class="mr-3"
                :key="'r' + index + 'c' + ci + 'a' + ai"
                color="grey lighten-1"
                @click.stop="$emit('rowAction', a, item)"
                >{{ a.icon }}</v-icon
              >
            </template>
            <template v-else-if="col.values">
              <v-row
                dense
                v-for="(v, vi) in col.values.filter((x) => item[x.column])"
                :key="'r' + index + 'c' + ci + 'v' + vi"
                ><v-col
                  ><span class="admin-cell-title">{{ v.name }}:</span
                  ><span class="admin-cell ml-2">{{
                    item[v.column]
                  }}</span></v-col
                >
              </v-row></template
            >
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th :colspan="listColumns.length" scope="row" class="tf">
            <TablePager
              :totalItems="listData.length"
              :pageSize="paging.pageSize"
              :resetPage="paging.reset"
              :gotoPage="paging.goto"
              dense
              @pageChanged="pageChanged"
              @exportToCSV="exportToCSV"
            />
          </th>
        </tr>
      </tfoot>
    </template>
  </v-simple-table>
</template>
	
	<script>
import utils from "@/common/utils.js";
import TablePager from "@/components/common/TablePager";
let Table_Raw_Data = [];
export default {
  name: "RMTable",
  components: {
    TablePager,
  },
  props: {
    columns: [],
    rows: [],
    presetFilters: [],
    maxHeight: Number,
    heightAdjustment: Number,
    refresh: Number,
  },
  data: function () {
    return {
      utils: utils,
      filterList: [],
      listColumns: [],
      listData: [],
      sortColumn: { descending: false, column: null },
      paging: { pageSize: 20, reset: 1, goto: 0, currentRange: null },
      pageRows: [],
      initialised: false,
      rowHeight: 49,
      index: -1,
    };
  },
  created: function () {
    // this.$nextTick(() => this.setTableHeight());
  },
  mounted() {
    this.setup();
  },
  computed: {},
  watch: {
    rows(val) {
      if (val) this.setup(true);
    },
    refresh(val) {
      if (val) this.setup(true);
    },
  },
  methods: {
    setTableHeight() {
      let height = window.innerHeight - (this.filterList.length ? 40 : 0);
      if (this.heightAdjustment) height -= this.heightAdjustment;
      if (this.maxHeight && height > this.maxHeight) height = this.maxHeight;
      this.paging.pageSize = Math.floor(height / this.rowHeight);
    },
    prepData() {
      if (this.index < 0) this.index = Table_Raw_Data.length;
      Table_Raw_Data[this.index] = JSON.parse(JSON.stringify(this.rows)).map(
        (r, ri) => {
          r.row_key = ri;
          return r;
        }
      );
    },
    prepColumns() {
      let cols = JSON.parse(JSON.stringify(this.columns));
      if (cols.some((c) => c.column && c.actions?.length)) this.rowHeight = 65;
      cols
        .filter((c) => c.column && !c.hideFilter)
        .forEach((c) => {
          c.filterSettings = {
            show: false,
            values: [],
            active: false,
            searchText: "",
            page: 1,
            pages: 1,
          };
        });
      return cols;
    },
    setup(rowUpdated) {
      this.prepData();
      let cols = this.listColumns.length === this.columns.length && this.columns.every((c, ci) => this.listColumns[ci].column === c.column && this.listColumns[ci].text === c.text) ? this.listColumns : this.prepColumns();
      cols
        .filter((c) => c.filterSettings)
        .forEach((c) => {
          let vals = this.distinctValues(Table_Raw_Data[this.index], [c]).map(
            (x, xi) => {
              let col = c.column;
              let text =
                col === "active" ? (x.active ? "Active" : "Inactive") : x[col];
              if (text === undefined || text === null) {
                text = "";
              }
              if (typeof text === "number") c.isNumber = true;
              return {
                text: text,
                value: col === "active" ? x[col] : text,
                selected: false,
                visible: true,
                count: x._count,
                searchValue: c.isNumber ? text : text.toLowerCase(),
                page: parseInt(xi / 7) + 1,
              };
            }
          );
          if (c.filterSettings.values.length) {
            vals.forEach((v) => {
              let prev = c.filterSettings.values.find(
                (x) => x.value === v.value
              );
              if (prev) {
                v.selected = prev.selected;
                v.visible = prev.visible;
              }
            });
          }
          vals.sort((a, b) =>
            c.isNumber
              ? Number(a.text) > Number(b.text)
                ? 1
                : Number(a.text) < Number(b.text)
                ? -1
                : 0
              : a.searchValue > b.searchValue
              ? 1
              : a.searchValue < b.searchValue
              ? -1
              : 0
          );
          c.filterSettings.values = vals;
          c.filterSettings.pages =
            parseInt((c.filterSettings.values.length - 1) / 7) + 1;
          if (!rowUpdated && c.defaultSort) {
            this.sortColumn.column = c;
            this.sortColumn.isNumber = c.isNumber;
            this.sortColumn.descending = false;
          }
        });
      this.listColumns = cols;
      this.filterData(rowUpdated);
      this.initialised = true;
    },
    getValue(item, def) {
      return (def.columnObject
        ? (item[def.columnObject] ? item[def.columnObject][def.column] : "")
        : (item[def.column])) || (def.isNumber ? 0 : "");
    },
    distinctValues(arr, keys) {
      let list = [];
      arr.forEach((item) => {
        let row = list.filter((li) => {
          let match = true;
          keys.forEach((k) => {
            if (li[k.column] !== this.getValue(item, k)) match = false;
          });
          return match;
        })[0];
        if (!row) {
          row = { _count: 1 };
          keys.forEach((k) => {
            row[k.column] = this.getValue(item, k);
          });
          list.push(row);
        } else {
          row._count++;
        }
      });
      return list;
    },
    openlistViewFilter(column) {
      let filterSettings = column.filterSettings;
      if (!filterSettings.show) {
        filterSettings.values
          .filter((v) => v.selected && !v.applied)
          .forEach((v) => (v.selected = false));
        filterSettings.values
          .filter((v) => !v.selected && v.applied)
          .forEach((v) => (v.selected = true));
        return;
      }
      filterSettings.values
        .filter((v) => !v.selected && v.applied)
        .forEach((v) => (v.applied = false));
      if (filterSettings.mostRecent) return;
      let usedValues = this.distinctValues(this.listData, [column]).map(
        (x) => x[column.column]
      );
      let valueCount = 0;
      if (
        usedValues.length === filterSettings.values.length ||
        (this.activeFilterCount === 1 && filterSettings.isActive)
      ) {
        valueCount = filterSettings.values.length;
        filterSettings.values.forEach((x, xi) => {
          x.visible = true;
          x.page = parseInt(xi / 7) + 1;
        });
      } else {
        filterSettings.values.forEach((v) => {
          v.visible = usedValues.some((uv) => uv === v.text);
          v.page = v.visible ? parseInt(valueCount / 7) + 1 : 0;
          if (v.visible) valueCount++;
        });
      }
      filterSettings.pages = parseInt((valueCount - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    removeFilter(f) {
      let lvfilter = this.listColumns.find((x) => x.column === f.column);
      if (lvfilter) {
        lvfilter.filterSettings.values.forEach((x) => {
          x.selected = false;
          x.applied = false;
        });
        lvfilter.filterSettings.isActive = false;
        this.filterData();
      }
    },
    filterScroll(data, filterSettings) {
      if (data) {
        let page = filterSettings.page + (data.deltaY >= 0 ? 1 : -1);
        if (page > filterSettings.pages || page < 1) return;
        filterSettings.page = page;
      }
    },
    setFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.column];
        column.filterSettings.values.forEach((x) => {
          x.selected = x.column === value;
        });
        this.filterHeading(column.filterSettings);
      }
    },
    removeFilterValue(item, column) {
      if (column && column.filterSettings) {
        let value = item[column.column];
        column.filterSettings.values
          .filter((x) => x.value === value)
          .forEach((x) => (x.selected = false));
        this.filterHeading(column.filterSettings);
      }
    },
    filterHeading(filterSettings) {
      filterSettings.values
        .filter((v) => v.selected)
        .forEach((v) => (v.applied = true));
      filterSettings.isActive = filterSettings.values.some((x) => x.selected);
      filterSettings.show = false;
      let activeCount = 0;
      this.listColumns
        .filter((c) => c.filterSettings)
        .forEach((c) => {
          c.filterSettings.mostRecent = false;
          if (c.filterSettings.isActive) activeCount++;
        });
      filterSettings.mostRecent = filterSettings.isActive;
      this.activeFilterCount = activeCount;
      this.filterData();
    },
    filterSearchAll(filterSettings) {
      if (filterSettings.searchText) {
        let newVal = !filterSettings.values.every(
          (x) => !x.visible || x.selected
        );
        filterSettings.values.forEach((x) => {
          if (x.visible || !newVal) x.selected = newVal;
        });
      } else {
        let newVal = !filterSettings.values.every((x) => x.selected);
        filterSettings.values.forEach((x) => {
          x.selected = newVal;
        });
      }
    },
    filterSearch(filterSettings) {
      let index = 0;
      let search = (filterSettings.searchText || "")
        .toLowerCase()
        .split(" ")
        .filter((x) => x);
      filterSettings.values.forEach((x) => {
        const visible =
          !search.length || search.every((s) => x.searchValue.indexOf(s) >= 0);
        x.visible = visible;
        if (visible) {
          x.page = parseInt(index / 7) + 1;
          index++;
        } else {
          x.page = 0;
        }
      });
      filterSettings.pages = parseInt((index - 1) / 7) + 1;
      filterSettings.page = 1;
    },
    filterClear(filterSettings) {
      filterSettings.searchText = "";
      filterSettings.values.forEach((x, xi) => {
        x.visible = true;
        x.selected = false;
        x.applied = false;
        x.page = parseInt(xi / 7) + 1;
      });
      filterSettings.pages =
        parseInt((filterSettings.values.length - 1) / 7) + 1;
      this.filterHeading(filterSettings);
    },
    filterData(rowUpdated) {
      let filters = this.listColumns
        .filter((c) => c.filterSettings?.isActive)
        .map((c) => {
          return {
            name: c.text,
            column: c.column,
            columnObject: c.columnObject,
            values: c.filterSettings.values
              .filter((v) => v.selected)
              .map((v) => v.value),
          };
        });
      let data = !filters.length
        ? Table_Raw_Data[this.index]
        : Table_Raw_Data[this.index].filter((x) => {
            return filters.every((f) =>
              f.values.some((v) => this.getValue(x, f) === v)
            );
          });
      this.listData = data;
      this.filterList = filters.map((f) => {
        return {
          title: `${f.name} = [${f.values.join(", ")}]`,
          name: f.name,
          column: f.column,
        };
      });
      this.setTableHeight();
      this.doSort(rowUpdated);
    },
    exportToCSV() {
      let cols = this.listColumns;
      let data = cols.map((c) => '"' + c.text + '"').join(",");
      data += "\n";

      let raw = this.listData || [];
      raw.forEach((d) => {
        data += cols
          .map((c) =>
            utils.csvEscape(utils.removeTags(d[c.column]).replace(/\n/g, "|"))
          )
          .join(",");
        data += "\n";
      });

      utils.downloadFile(data, `list export.csv`, "text/csv;encoding:utf-8");
    },
    cellEdit(item, col) {
      if (col.columnObject && item[col.columnObject]) {
        if (item[col.columnObject].edit) this.cellEditCancel(item, col);
        else {
          item[col.columnObject].edit = true;
          item[col.columnObject].original = item[col.columnObject][col.column];
        }
      }
    },
    cellEditCancel(item, col) {
      if (col.columnObject && item[col.columnObject]) {
        item[col.columnObject].edit = false;
        item[col.columnObject][col.column] = item[col.columnObject].original;
      }
    },
    cellEditSave(item, col) {
      if (col.columnObject && item[col.columnObject]) {
        this.$emit("saveCell", item[col.columnObject]);
        // item[col.columnObject].edit = false;
      }
    },
    getTHStyle(headerColumns, index) {
      return {
        width: headerColumns[index].width,
      };
    },
    setSort(column) {
      if (this.sortColumn.column !== column) {
        this.sortColumn.column = column;
        this.sortColumn.isNumber = column.isNumber;
        this.sortColumn.descending = false;
      } else {
        this.sortColumn.descending = !this.sortColumn.descending;
      }
      this.doSort();
    },
    doSort(rowUpdated) {
      if (this.sortColumn.column) {
        const col = this.sortColumn.column;
        const high = this.sortColumn.descending ? -1 : 1;
        const low = this.sortColumn.descending ? 1 : -1;
        if (this.sortColumn.isNumber)
          this.listData.sort((a, b) => {
				const aVal = (this.getValue(a, col) || 0);
				const bVal = (this.getValue(b, col) || 0);
				return aVal > bVal ? high : aVal < bVal ? low : 0
			}
          );
        else
			this.listData.sort((a, b) => {
				const aVal = (this.getValue(a, col) || "").toLowerCase();
				const bVal = (this.getValue(b, col) || "").toLowerCase();
				return aVal > bVal ? high : aVal < bVal ? low : 0
			}
          );
      }
      if (rowUpdated) this.pageChanged(this.paging.currentRange);
      else this.paging.reset++;
    },
    pageChanged(range) {
      if (!range || range.start < 0)
        range = { start: 0, end: this.paging.pageSize, pageNo: 1 };
      this.paging.currentRange = range;
      this.pageRows = this.listData.slice(range.start, range.end);
    },
    pageScroll(data) {
      if (data) {
        let page =
          this.paging.currentRange.pageNo + (data.deltaY >= 0 ? 1 : -1);
        this.paging.goto = page;
      }
    },
    rowClick(item) {
      this.$emit("rowClick", item);
    },
  },
};
</script>
	
	<style scoped lang="scss">
::v-deep .v-data-table tbody td {
  cursor: pointer !important;
}
.inactive-row {
  background-color: #ebe8e8;
}
div.admin-cell,
td.admin-cell,
span.admin-cell {
  font-family: sans-serif;
  font-weight: 400 !important;
  border-bottom: none;
  font-size: 14px;
}
div.admin-cell-title,
span.admin-cell-title {
  font-weight: 800 !important;
}
th.th:first-child {
  border-top-left-radius: 20px;
}
th.th:last-child {
  border-top-right-radius: 20px;
}
th.th,
th.tf {
  border: 0px gray #eeeeee;
  background-color: #eeeeee !important;
}
th.th {
  font-weight: bold;
  text-transform: uppercase;
}
th.tf {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
tfoot tr {
  position: sticky;
  bottom: 0;
}
</style>