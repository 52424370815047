<template>
  <div>
    <div
      :class="{
        'flex-container': true,
        fixedFooter: fullScreen && !height.length,
      }"
    >
      <v-container fluid>
        <v-row>
          <v-col>
            <h1 class="title">
              {{ title }}
              <v-btn
                class="ml-3"
                outlined
                color="info"
                v-if="passedParams.sourceView"
                @click="returnToSource"
                ><v-icon>mdi-arrow-left</v-icon>Back to
                {{ passedParams.sourceTitle }}</v-btn
              >
            </h1>
          </v-col>
        </v-row>
        <v-row
          ><v-col>
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col cols="3"
                    ><v-select
                      :items="views"
                      label="Reports Available:"
                      item-text="title"
                      item-value="name"
                      outlined
                      hide-details
                      v-model="selectedView"
                      @change="changeView"
                    ></v-select
                  ></v-col>
                  <v-col>
                    <v-card v-if="selectedViewData"
                      ><v-card-subtitle>
                        <v-row>
                          <v-col
                            ><span
                              style="cursor: pointer; font-weight: bold"
                              @click="showHideDefinition"
                              ><v-icon class="pr-4">mdi-information</v-icon
                              >{{ getDescription() }}
                              <v-icon>{{
                                showDefinition
                                  ? "mdi-chevron-up"
                                  : "mdi-cog-outline"
                              }}</v-icon></span
                            ></v-col
                          ><v-col
                            v-if="
                              selectedViewData &&
                              (showDefinition || selectedViewData.isDirty)
                            "
                            cols="3"
                            class="text-right"
                            ><v-btn
                              small
                              class="mr-3"
                              v-if="selectedViewData.restricted"
                              @click="deleteDefinition"
                              >Delete</v-btn
                            ><v-btn
                              small
                              class="mr-3"
                              :disabled="!selectedViewData.isDirty"
                              @click="saveDefinitionAs"
                              >Save As</v-btn
                            ><v-btn
                              small
                              :disabled="
                                !selectedViewData.isDirty ||
                                selectedView === getNewTitle()
                              "
                              @click="saveDefinition()"
                              >Save</v-btn
                            ></v-col
                          >
                        </v-row>
                      </v-card-subtitle>
                      <v-card-text v-if="showDefinition || filterList.length">
                        <v-row v-if="showDefinition"
                          ><v-col cols="3"
                            ><v-select
                              v-if="selectedViewData.definition.linked_doc_view"
                              :items="documentTypes"
                              label="Document Types:"
                              item-text="docType"
                              item-value="tmpl_id"
                              outlined
                              multiple
                              hide-details
                              dense
                              v-model="selectedViewData.definition.templates"
                              @change="setDirty('changeDocType')"
                            ></v-select
                            ><v-select
                              v-else
                              :items="documentTypes"
                              label="Document Type:"
                              item-text="docType"
                              item-value="tmpl_id"
                              outlined
                              hide-details
                              dense
                              v-model="selectedViewData.definition.tmpl_id"
                              @change="setDirty('changeDocType')"
                            ></v-select
                          ></v-col>
                          <v-col cols="3">
                            <v-select
                              v-model="selectedViewData.definition.statuses"
                              :items="docStatusList"
                              item-text="doc_status_text"
                              item-value="doc_status_text"
                              label="Document Statuses"
                              multiple
                              outlined
                              hide-details
                              :menu-props="{
                                closeOnContentClick: false,
                                maxHeight: '400',
                                'offset-y': true,
                              }"
                              dense
                              @change="setDirty('filterMatches')"
                            >
                              <!-- <template v-slot:selection="{ item, index }">
                  <span>{{ getDocStatusFilterText(item, index) }}</span>
                </template> -->
                            </v-select></v-col
                          >
                          <v-col cols="3">
                            <v-select
                              v-model="selectedViewData.definition.matchLevels"
                              :items="matchLevels"
                              item-text="name"
                              item-value="name"
                              label="Match Levels"
                              multiple
                              outlined
                              hide-details
                              :menu-props="{
                                closeOnContentClick: false,
                                maxHeight: '400',
                                'offset-y': true,
                              }"
                              dense
                              clearable
                              @change="setDirty('filterMatches')"
                            >
                              <!-- <template v-slot:selection="{ item, index }">
                  <span>{{ getDocStatusFilterText(item, index) }}</span>
                </template> -->
                            </v-select></v-col
                          >
                          <v-col cols="3">
                            <v-select
                              v-model="
                                selectedViewData.definition.columnsVisible
                              "
                              :items="detailColumns"
                              item-text="text"
                              item-value="value"
                              label="Columns Visible"
                              multiple
                              outlined
                              hide-details
                              :menu-props="{
                                closeOnContentClick: false,
                                maxHeight: '400',
                                'offset-y': true,
                              }"
                              dense
                              @change="setDirty('setColumns')"
                            >
                              <!-- <template v-slot:selection="{ item, index }">
                  <span>{{ getDocStatusFilterText(item, index) }}</span>
                </template> -->
                            </v-select></v-col
                          ></v-row
                        >
                        <v-row
                          v-if="
                            showDefinition &&
                            !selectedViewData.definition.linked_doc_view
                          "
                        >
                          <v-col cols="6"
                            ><v-radio-group
                              row
                              v-model="selectedViewData.definition.name_match"
                              @change="setDirty('filterMatches')"
                              ><v-radio
                                v-for="c in similarityColumnsName"
                                :key="c.column"
                                :label="c.label"
                                :value="c.column"
                              ></v-radio></v-radio-group
                          ></v-col>
                          <v-col cols="6"
                            ><v-radio-group
                              row
                              v-model="
                                selectedViewData.definition.content_match
                              "
                              @change="setDirty('filterMatches')"
                              ><v-radio
                                v-for="c in similarityColumnsContent"
                                :key="c.column"
                                :label="c.label"
                                :value="c.column"
                              ></v-radio></v-radio-group
                          ></v-col>
                        </v-row>
                        <v-row v-if="filterList.length"
                          ><v-col>
                            <v-chip
                              small
                              v-for="(f, fi) in filterList"
                              :key="'f' + fi"
                              close
                              @click:close="removeColumnFilter(f)"
                            >
                              {{ f.title }}</v-chip
                            ></v-col
                          ></v-row
                        ></v-card-text
                      >
                    </v-card>
                  </v-col>
                </v-row></v-card-title
              >
              <v-card-text v-if="selectedViewData">
                <v-simple-table :height="tableHeight" fixed-header>
                  <template v-slot:default>
                    <TableColumnHeaders
                      :dataColumns="detailColumnsUsed"
                      :preColumns="['']"
                      :postColumns="
                        ['Action', 'Review Status'].filter(
                          (x) => x === 'Action' || !hideReviewColumn
                        )
                      "
                      :rows="documents"
                      :stateKey="stateKey"
                      :presetFilters="selectedViewData.definition.columnFilters"
                      @sortby="setSort($event)"
                      @filterApplied="applyColumnFilters($event)"
                    ></TableColumnHeaders>
                    <SimilarityTableRow
                      :rows="pageRows"
                      :columns="detailColumnsUsed"
                      :hierarchical="
                        selectedViewData.definition.linked_doc_view
                      "
                      @openDocument="openDocument"
                      @copyDocument="$emit('copyDocument', $event)"
                      @compareDocDetailsFull="(x) => compareDocDetailsFull(x)"
                      @compareDocDetails="(x) => compareDocDetails(x)"
                    ></SimilarityTableRow>
                  </template>
                </v-simple-table>
                <TablePagination
                  :totalItems="documents.length"
                  :pageSize="paging.pageSize"
                  :resetPage="paging.changeKey"
                  @pageChanged="pageChanged"
                  @exportToCSV="exportToCSV"
                ></TablePagination>
              </v-card-text>
            </v-card> </v-col
        ></v-row>
      </v-container>
    </div>
    <v-dialog v-model="sideBySide.show">
      <DocumentSideBySide
        v-if="sideBySide.show"
        :items="sideBySide.items"
        :isDialogue="true"
        @close="sideBySide.show = false"
        @openContext="openContextMenu"
      ></DocumentSideBySide>
    </v-dialog>
    <v-dialog v-model="fullCompareView.show" style="background-color: white">
      <v-card>
        <v-card-title>
          Document Comparison
          <v-spacer></v-spacer>
          <v-switch
            v-model="fullCompareView.highlightChanges"
            label="Highlight Differences"
            class="mr-4"
          ></v-switch>
          <v-btn
            icon
            large
            class="btn-background"
            @click="fullCompareView.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <DocumentVersionCompare
            class="pt-6 px-2"
            style="height: 100%"
            v-if="fullCompareView.show"
            :compareList="fullCompareView.items"
            :highlightChanges="fullCompareView.highlightChanges"
            hideCompareVersion
            multiDoc
            showWorkflowActions
            @openDocument="openDocument"
            @copyDocument="$emit('copyDocument', $event)"
          >
          </DocumentVersionCompare>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="saveAsDialog.show" max-width="600px">
      <v-card>
        <v-card-title>Save Report As </v-card-title>
        <v-card-text>
          <v-row
            ><v-col
              ><v-text-field
                v-model="saveAsDialog.name"
                label="Report Name"
              ></v-text-field></v-col
          ></v-row>
          <v-row
            ><v-col
              ><v-checkbox
                v-model="saveAsDialog.restricted"
                label="Visible to me only?"
              ></v-checkbox></v-col
          ></v-row>
        </v-card-text>
        <v-card-actions
          ><v-spacer></v-spacer
          ><v-btn
            @click="saveDefinition()"
            :disabled="!saveAsDialog.name || !saveAsDialog.name.trim()"
            >Save</v-btn
          >
          <v-btn @click="saveAsDialog.show = false"
            >Cancel</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="reviewDialog.show" max-width="600px">
      <v-card v-if="reviewDialog.item">
        <v-card-title
          >Review Details: {{ reviewDialog.item.type }}</v-card-title
        >
        <v-card-text>
          <v-row
            ><v-col>
              <v-select
                v-model="reviewDialog.item.status"
                :items="['Under Review', 'Completed']"
                outlined
                label="Review Status"
              ></v-select>
              <v-select
                v-model="reviewDialog.item.users"
                :items="users"
                multiple
                outlined
                label="Reviewers:"
              ></v-select> </v-col
          ></v-row>
          <v-row
            ><v-col
              ><v-textarea
                outlined
                v-model="reviewDialog.item.notes"
                label="Review Notes"
              ></v-textarea></v-col
          ></v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="!reviewDialog.new" @click="deleteReview">Delete</v-btn
          ><v-spacer></v-spacer
          ><v-btn @click="reviewDialog.show = false">Save</v-btn>
          <v-btn @click="closeReview">Cancel</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>

    <DocActionsMenu
      :context="docActionsMenuContext"
      @openDocument="openDocument"
      @copyDocument="$emit('copyDocument', $event)"
    ></DocActionsMenu>

    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <Loading :isVisible="isLoading" />
  </div>
</template>
  
<script>
import axios from "axios";
import ResponseHandler from "@/components/ResponseHandler"; // @ is an alias to /src
// import Pagination from "@/components/cPagination";
import TablePagination from "@/components/common/TablePager";
// import DocStatus from "@/components/common/DocStatus";
import DocumentVersionCompare from "@/components/audit/cDocumentVersionCompare";
import DocumentSideBySide from "@/components/cDocumentSideBySide.vue";
import TableColumnHeaders from "@/components/common/TableColumnHeaders";
// import DocSummary from "@/components/common/DocViewPopUp";
import SimilarityTableRow from "@/components/jobArchitecture/similarityTableRow";
import DocActionsMenu from "../common/DocActionsMenu.vue";
import utils from "@/common/utils.js";
import dayjs from "dayjs";
import { mapState } from "vuex";

let _matchDocs = [];
let _relatedDocs = [];
let _docStatuss = [];
const _page = "similarityAnalysis";
const _newTitle = "< New >";
export default {
  name: _page,
  components: {
    ResponseHandler,
    // Pagination,
    // PageDescription,
    // DocStatus,
    DocumentVersionCompare,
    DocumentSideBySide,
    TableColumnHeaders,
    TablePagination,
    // DocSummary,
    SimilarityTableRow,
    DocActionsMenu,
  },
  props: {
    fullScreen: { type: Boolean, required: false, default: true },
    height: { type: String, required: false, default: "" },
  },
  data: function () {
    return {
      documents: [],
      views: [],
      similarityColumnsName: [
        { label: "Same Title", column: "same_name" },
        { label: "Similar Title", column: "similar_name" },
        { label: "Different Title", column: "different_name" },
      ],
      similarityColumnsContent: [
        { label: "Same Content", column: "same_content" },
        { label: "Similar Content", column: "similar_content" },
        { label: "Different Content", column: "different_content" },
      ],
      stateKey: 1,
      selectedView: null,
      selectedViewData: null,
      showDefinition: false,
      docStatusList: [],
      matchLevels: [],
      //   detailColumns: [],
      detailColumnsUsed: [],
      sideBySide: { show: false, items: [] },
      fullCompareView: { show: false, highlightChanges: false, items: [] },
      saveAsDialog: { show: false, name: "", restricted: false, isNew: false },
      reviewDialog: { show: false, item: null, new: false },
      reviews: [],
      passedParams: {},
      sortColumn: { name: null, descending: false, isNumber: false },
      paging: { pageSize: 20, changeKey: 1, currentPage: 1 },
      tableHeight: "700px",
      pageRows: [],
      filterList: [],
      hideReviewColumn: true,
      users: [],
      utils: utils,
      response: null,
      title: "Similarity Analysis",
      isDirty: false,
      docTypeIncluded: [],
      itemStatusIncluded: [],
      tempFilterText: "",
      filterText: "",
      showSearch: false,
      selDoc: "",
      contextMenu: false,
      csvHeaders: [],
      dialog: false,
      defaultStatusFilterList: "approved,pre-draft,pending,draft",
      filteredItems: [],
      maxCards: 24,
      drawerHover: false,
      currentTask: {},
      searchTimeout: null,
      rules: {
        required: (value) => !!value || "Required.",
      },
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      currentFilterKey: "",
      newFilterKey: "",
      isFavFilter: false,
      selectedDocument: null,
      tableHeightTimeout: null,
      posX: null,
      posY: null,
      docActionsMenuContext: {
        show: false,
        busy: false,
        document: null,
        posX: 0,
        posY: 0,
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    isLoading(val) {
      if (!val) this.fetchData();
    },
  },
  computed: {
    rowClickAction() {
      return "opendoc";
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    ...mapState({
      isLoading: (state) => state.docs.loading,
      hierarchyTypes: (state) => state.docs.hierarchyTypes,
      documentTypes: (state) =>
        state.docs.docTypeSummary.filter((x) => x.tmpl_id),
      docStatusSummary: (state) => state.docs.docStatusSummary,
      detailColumns: (state) =>
        state.docs.docListColumns.filter((c) => c.showInListView),
    }),
  },
  created() {
    this.passedParams = this.$passedParams.get();

    this.fetchData();
    this.$nextTick(() => this.setTableHeight());
  },
  methods: {
    onPaneResize() {
      if (this.tableHeightTimeout) {
        clearTimeout(this.tableHeightTimeout);
        this.tableHeightTimeout = null;
      }
      this.tableHeightTimeout = setTimeout(() => this.setTableHeight(), 50);
    },
    setTableHeight() {
      let prevPageSize = this.paging.pageSize;
      let height =
        window.innerHeight -
        (300 +
          (this.showDefinition ? 110 : 0) +
          (this.filterList.length ? 40 : 0));
      this.tableHeight = `${height}px`;
      this.paging.pageSize = Math.floor(height / 49);
      if (prevPageSize !== this.paging.pageSize) {
        //   let range = null;
        //   if (this.paging.currentPage > 1) {
        // 	range = { }
        //   }
        // this.pageChanged();
      }
    },
    documentChanged(doc) {
      this.$store.dispatch("docs/updateDoc", doc);
      this.filterMatches();
    },
    formatDate(value) {
      return value ? dayjs(value).format("D MMM YYYY") : "";
    },
    parseDate(date) {
      if (!date) return null;
      return dayjs(date).format("YYYY-MM-DD");
    },
    fetchData() {
      if (this.isLoading) return;
      this.$store
        .dispatch("settings/getViewDefinitions", _page)
        .then((resp) => {
          this.prepareDocs();
          this.setUpViews(resp.data.Data);
          this.changeView();
        }),
        (error) => {
          console.error(error);
        };
    },
    prepareDocs() {
      let allDocs = JSON.parse(
        JSON.stringify(this.$store.getters["docs/docsList"])
      );
      let matchColumn;
      let matchLevels = [];
      if (allDocs.length && allDocs[0].job_code !== undefined) {
        matchColumn = "job_code";
        matchLevels.push({
          name: "Same Job Code",
          title: "Job Code",
          matchColumn: matchColumn,
          matchFunction: (d, dm, column) => dm.detail[column] === d[column],
        });
      }
      this.hierarchyTypes.forEach((ht) => {
        let prev = "";
        for (let l = 1; l <= ht.linklevel; l++) {
          prev += (prev.length ? " - " : "") + ht[`level${l}_name`];
          matchColumn = `ht${ht.ht_id}l${l}`;
          matchLevels.push({
            name: `Same ${prev}`,
            title: prev,
            matchColumn: matchColumn,
            matchFunction: (d, dm, column) => dm.detail[column] === d[column],
          });
          matchLevels.push({
            name: `Different ${prev}`,
            title: prev,
            matchColumn: matchColumn,
            matchFunction: (d, dm, column) => dm.detail[column] !== d[column],
          });
        }
      });
      this.matchLevels = matchLevels;
      let stats = [];
      allDocs.forEach((d) => {
        if (
          !stats.some(
            (s) =>
              s.tmpl_id === d.tmpl_id && s.doc_status_text === d.doc_status_text
          )
        ) {
          stats.push({
            tmpl_id: d.tmpl_id,
            doc_status_text: d.doc_status_text,
            doc_status_type: d.doc_status,
          });
        }
        this.hierarchyTypes.forEach((ht) => {
          let dh = d.hierarchies.find((x) => x.ht_id === ht.ht_id);
          if (dh) {
            let prev = "";
            let targetIndex = 1;
            for (let li = ht.linklevel; li >= 1; li--) {
              d[`ht${ht.ht_id}l${targetIndex}`] = prev + dh[`hierarchy${li}`];
              prev = dh[`hierarchy${li}`] + "|";
              targetIndex++;
            }
          }
        });
        // temporary for now for demo
        d.reviews = [];
        if (!this.users.some((x) => x === d.created_by))
          this.users.push(d.created_by);
      });
      let randomMatchPcts_oood = [95, 97, 96, 98, 99, 93, 92, 91, 90, 96];
      let randomMatchPcts_ok = [77, 85, 82, 66, 79, 81, 74, 69, 67, 83];
      let randomMatchPcts_bad = [45, 38, 33, 49, 29, 44, 48, 39, 47, 40];
      let similarity = (s1, s2) => {
        let longer = s1;
        let shorter = s2;
        if (s1.length < s2.length) {
          longer = s2;
          shorter = s1;
        }
        let longerLength = longer.length;
        if (longerLength === 0) {
          return 1.0;
        }
        return (
          (longerLength - editDistance(longer, shorter)) /
          parseFloat(longerLength)
        );
      };

      let editDistance = (s1, s2) => {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        let costs = new Array();
        for (let i = 0; i <= s1.length; i++) {
          var lastValue = i;
          for (let j = 0; j <= s2.length; j++) {
            if (i == 0) costs[j] = j;
            else {
              if (j > 0) {
                let newValue = costs[j - 1];
                if (s1.charAt(i - 1) != s2.charAt(j - 1))
                  newValue =
                    Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                costs[j - 1] = lastValue;
                lastValue = newValue;
              }
            }
          }
          if (i > 0) costs[s2.length] = lastValue;
        }
        return costs[s2.length];
      };

      allDocs.forEach((d) => {
        d.matches.forEach(
          (dm) => (dm.detail = allDocs.find((x) => x.doc_id === dm.doc_id))
        );
        d.matches = d.matches.filter((x) => x.detail);
        if (d.relationships) {
          let parent = d.advert_job_title || "";
          d.relationships.forEach((dr, di) => {
            dr.detail = allDocs.find((x) => x.doc_id === dr.doc_id);
            let child = dr.detail ? dr.detail.advert_job_title || "" : "";
            let pct = Math.round(similarity(parent, child) * 100) / 1;
            if (pct === 100) pct = randomMatchPcts_oood[di % 10];
            else if (pct < 50) pct = randomMatchPcts_bad[di % 10];
            else pct = randomMatchPcts_ok[di % 10];
            dr.match_pct_content = pct; // randomMatchPcts[di % 10];
          });
          d.relationships = d.relationships.filter((x) => x.detail);
        }
        d.expanded = false;
      });
      _matchDocs = allDocs.filter((d) => d.matches?.length);
      _relatedDocs = allDocs.filter((d) => d.relationships?.length);
      _docStatuss = stats;
    },
    setUpViews(views) {
      let defaultViews = [
        {
          name: "Job Title Analysis – Similarity",
          name_match: "different_name",
          content_match: "similar_content",
        },
        {
          name: "Job Title Analysis – Differences",
          name_match: "same_name",
          content_match: "different_content",
        },
        {
          name: "Job Title Analysis – Duplicates",
          name_match: "same_name",
          content_match: "similar_content",
        },
        {
          name: _newTitle,
          name_match: "same_name",
          content_match: "similar_content",
        },
        {
          name: "Linked Documents",
          name_match: null,
          content_match: null,
          linked_doc_view: true,
        },
      ];
      this.detailColumns.push({
        value: "match_pct_content_max",
        text: "Max Match %",
        type: "Number",
      });
      //   if (views.length < defaultViews.length) {
      let defaultTempsForLinked = this.documentTypes
        .filter((x) => x.docType.toLowerCase().indexOf("prof") > 0)
        .map((t) => t.tmpl_id);
      let tmpl = this.documentTypes.find(
        (x) => x.docType.toLowerCase().indexOf("desc") > 0
      );
      if (!tmpl)
        tmpl = this.documentTypes.find(
          (x) => x.docType.toLowerCase().indexOf("prof") > 0
        );
      if (!tmpl) tmpl = this.documentTypes[0];
      if (!defaultTempsForLinked.length)
        defaultTempsForLinked.push(tmpl.tmpl_id);
      let defaultStatuss = _docStatuss
        .filter(
          (x) => x.tmpl_id === tmpl.tmpl_id && x.doc_status_type === "APPROVED"
        )
        .map((x) => x.doc_status_text);
      let defaultColumns = [
        "doc_name",
        "doc_status_text",
        "advert_job_title",
        ...this.detailColumns
          .filter((c) => c.value.indexOf("hry") === 0)
          .map((c) => c.value),
      ];
      //check for defaults
      defaultViews
        .filter((x) => !views.some((v) => v.name === x.name))
        .forEach((x) => {
          let view = {
            view_definition_id: null,
            view_type: _page,
            name: x.name,
            restricted: false,
            definition: {
              tmpl_id: tmpl.tmpl_id,
              templates: x.linked_doc_view ? defaultTempsForLinked : [],
              statuses: defaultStatuss,
              columnsVisible: defaultColumns,
              matchLevels: [],
              name_match: x.name_match,
              content_match: x.content_match,
              linked_doc_view: !!x.linked_doc_view,
              columnFilters: [],
            },
          };
          if (
            x.linked_doc_view &&
            !view.definition.columnsVisible.some((x) => x === "doc_type")
          )
            view.definition.columnsVisible.push("doc_type");
          views.push(view);
        });
      //   }
      views.forEach((v) => {
        v.isDirty = false;
        v.canSave = v.name !== _newTitle;
        this.setViewTitle(v);
      });
      this.views = views;
      this.sortViews();
    },
    changeView() {
      if (!this.selectedView) {
        let view = (this.selectedView = this.views.find(
          (v) => v.name === this.passedParams?.view
        ));
        if (view) {
          this.selectedView = view.name;
          this.title = this.passedParams?.title || this.passedParams?.view;
        } else {
          this.selectedView = this.views.filter(
            (v) => v.name !== _newTitle
          )[0].name;
        }
      }
      let selectedViewData = this.views.find(
        (x) => x.name === this.selectedView
      );
      this.selectedViewData = JSON.parse(JSON.stringify(selectedViewData));
      this.changeDocType();
      this.setColumns();
    },
    getDescription() {
      if (!this.selectedViewData) return "Please select a report";
      let def = this.selectedViewData.definition;
      let docType = def.linked_doc_view
        ? def.templates
            .map(
              (t) =>
                this.documentTypes.find((dt) => dt.tmpl_id === t).docType + "s"
            )
            .join(", ")
        : this.documentTypes.find((t) => t.tmpl_id === def.tmpl_id).docType +
          "s";
      let desc = `${docType} in ${def.statuses
        .map(
          (s, si) =>
            s +
            (def.statuses.length > 1 && si < def.statuses.length - 1
              ? si === def.statuses.length - 2
                ? " or "
                : ", "
              : "")
        )
        .join("")} status with ${
        this.similarityColumnsName.find((c) => c.column === def.name_match)
          ?.label || "linked documents"
      }  ${def.linked_doc_view ? "" : "and"}  ${
        this.similarityColumnsContent.find(
          (c) => c.column === def.content_match
        )?.label || ""
      }`;
      if (def.matchLevels.length) {
        desc += ` matching on ${def.matchLevels
          .map(
            (s, si) =>
              s +
              (def.matchLevels.length > 1 && si < def.matchLevels.length - 1
                ? si === def.matchLevels.length - 2
                  ? " and "
                  : ", "
                : "")
          )
          .join("")}`;
      }
      return desc;
    },
    setDirty(changeType) {
      this.selectedViewData.isDirty = true;
      switch (changeType) {
        case "changeDocType":
          this.changeDocType();
          break;
        case "filterMatches":
          this.filterMatches();
          break;
        case "setColumns":
          this.setColumns();
          break;
      }
    },
    changeDocType() {
      if (this.selectedViewData.definition.linked_doc_view) {
        let docStatusList = [];
        _docStatuss.forEach((s) => {
          if (
            this.selectedViewData.definition.templates.some(
              (t) => t === s.tmpl_id
            ) &&
            !docStatusList.some((l) => l.doc_status_text === s.doc_status_text)
          )
            docStatusList.push(s);
        });
        this.docStatusList = docStatusList;
      } else {
        this.docStatusList = _docStatuss.filter(
          (s) => s.tmpl_id === this.selectedViewData.definition.tmpl_id
        );
      }
      let currentStats = this.selectedViewData.definition.statuses.map(
        (s) => s
      );
      this.selectedViewData.definition.statuses = this.docStatusList
        .filter((s) => currentStats.some((x) => x === s.doc_status_text))
        .map((s) => s.doc_status_text);
      if (this.selectedViewData.definition.statuses.length === 0) {
        this.selectedViewData.definition.statuses = this.docStatusList
          .filter((s) => s.doc_status_type === "APPROVED")
          .map((s) => s.doc_status_text);
      }
      if (this.selectedViewData.definition.statuses.length === 0) {
        this.selectedViewData.definition.statuses = this.docStatusList
          .filter((s) => s.doc_status_type === "PENDING")
          .map((s) => s.doc_status_text);
      }
      if (this.selectedViewData.definition.statuses.length === 0) {
        this.selectedViewData.definition.statuses = this.docStatusList
          .filter((s) => s.doc_status_type === "DRAFT")
          .map((s) => s.doc_status_text);
      }
      this.filterMatches();
    },
    filterMatches(sameState) {
      if (!sameState) this.stateKey++;
      let matchLevelsUsed = this.matchLevels.filter((m) =>
        this.selectedViewData.definition.matchLevels.some((i) => i === m.name)
      );
      let view = this.selectedViewData.definition;
      let statuses = view.statuses;
      let sourceDocs = view.linked_doc_view ? _relatedDocs : _matchDocs;
      let columnFilters = view.columnFilters
        ? view.columnFilters
            .filter((f) => f.filterSettings.isActive)
            .map((f) => {
              let cf = {
                column: f.value,
                name: f.text,
                values: f.filterSettings.values
                  .filter((v) => v.selected)
                  .map((v) => v.value),
              };
              cf.title = `${cf.name} = [${cf.values.join(", ")}]`;
              return cf;
            })
        : [];
      this.filterList = JSON.parse(JSON.stringify(columnFilters));
      let maxPctFilterIndex = columnFilters.findIndex(
        (f) => f.column === "match_pct_content_max"
      );
      let maxPctFilter =
        maxPctFilterIndex >= 0 ? columnFilters[maxPctFilterIndex] : null;
      if (columnFilters.length) {
        if (maxPctFilterIndex >= 0) {
          // can't apply this filter until value is derived below
          columnFilters.splice(maxPctFilterIndex, 1);
        }
        sourceDocs = sourceDocs.filter((x) => {
          return columnFilters.every((f) =>
            f.values.some((v) => x[f.column] === v)
          );
        });
      }
      let documents;
      if (view.linked_doc_view) {
        documents = sourceDocs.filter(
          (d) =>
            view.templates.some((t) => t === d.tmpl_id) &&
            statuses.some((s) => s === d.doc_status_text)
        );
      } else {
        documents = sourceDocs.filter(
          (d) =>
            d.tmpl_id === view.tmpl_id &&
            statuses.some((s) => s === d.doc_status_text)
        );
      }
      let listColumn = view.linked_doc_view ? "relationships" : "matches";
      documents.forEach((d) => {
        if (d[listColumn]) {
          d.filteredItems = d[listColumn].filter(
            (dm) =>
              (view.linked_doc_view ||
                statuses.some((s) => s === dm.detail.doc_status_text)) &&
              this.reportFilter(view, matchLevelsUsed, d, dm)
          );
          let match_pct_content_max = Number(
            d.filteredItems.reduce((p, c) => {
              return p < c.match_pct_content ? c.match_pct_content : p;
            }, 0)
          );
          d.match_pct_content_max = match_pct_content_max.toFixed();
          if (view.linked_doc_view) {
            const setLinkedDocs = (docs) => {
              docs.forEach((ld) => {
                ld.filteredItems = ld.detail[listColumn].filter((dm) =>
                  this.reportFilter(view, matchLevelsUsed, ld.detail, dm)
                );
                setLinkedDocs(ld.filteredItems);
              });
            };
            setLinkedDocs(d.filteredItems);
          }
        }
        d.expanded = false;
      });
      this.documents = documents.filter((d) => d.filteredItems.length);
      if (maxPctFilter)
        this.documents = documents.filter((d) =>
          maxPctFilter.values.some((v) => d[maxPctFilter.column] === v)
        );
      this.sort();
      this.setTableHeight();
      this.pageChanged();
    },
    reportFilter(view, matchLevelsUsed, d, dm) {
      if (!view.linked_doc_view) {
        if (!dm[view.name_match]) return false;
        if (!dm[view.content_match]) return false;
      }
      if (matchLevelsUsed.length === 0) return true;
      else
        return matchLevelsUsed.every((x) =>
          x.matchFunction(d, dm, x.matchColumn)
        );
    },
    sort() {
      if (this.sortColumn.name) {
        const col = this.sortColumn.name;
        const dir = this.sortColumn.descending ? -1 : 1;
        if (!this.sortColumn.type === "Number") {
          this.documents.sort((a, b) => {
            const at = a[col]?.toLowerCase().trim() || "";
            const bt = b[col]?.toLowerCase().trim() || "";
            return dir * (at > bt ? 1 : at < bt ? -1 : 0);
          });
        } else {
          this.documents.sort((a, b) => {
            const at = a[col] || 0;
            const bt = b[col] || 0;
            return dir * (at > bt ? 1 : at < bt ? -1 : 0);
          });
        }
      }
    },
    setSort(column) {
      this.sortColumn = column;
      this.sort();
      this.paging.changeKey++;
      this.pageChanged();
    },
    applyColumnFilters(filters) {
      this.selectedViewData.definition.columnFilters = filters;
      this.selectedViewData.isDirty = true;
      this.filterMatches(filters.some((f) => f.filterSettings.isActive));
    },
    removeColumnFilter(f) {
      let lvfilter = this.selectedViewData.definition.columnFilters.find(
        (x) => x.value === f.column
      );
      if (lvfilter) {
        lvfilter.filterSettings.values.forEach((x) => {
          x.selected = false;
          x.applied = false;
        });
        lvfilter.filterSettings.isActive = false;
        this.selectedViewData.isDirty = true;
        this.filterMatches();
      }
    },
    toggleShow(doc) {
      doc.expanded = !doc.expanded;
    },
    getNewTitle() {
      return _newTitle;
    },
    showHideDefinition() {
      this.showDefinition = !this.showDefinition;
      this.setTableHeight();
    },
    saveDefinitionAs() {
      this.saveAsDialog.show = true;
      this.saveAsDialog.isNew = true;
      this.saveAsDialog.restricted = true;
      this.saveAsDialog.name = this.selectedView;
    },
    deleteDefinition() {
      this.saveDefinition(true);
    },
    setViewTitle(view) {
      view.title = view.name + (view.restricted ? " (My report)" : "");
    },
    saveDefinition(makeInactive) {
      let possibleError = false;
      let self = this;
      let data = {
        view_type: _page,
        active: makeInactive ? false : true,
        restricted: this.saveAsDialog.isNew
          ? this.saveAsDialog.restricted
          : this.selectedViewData.restricted,
        view_definition_id: this.saveAsDialog.isNew
          ? null
          : this.selectedViewData.view_definition_id,
        name: this.saveAsDialog.isNew
          ? this.saveAsDialog.name.trim()
          : this.selectedView,
        definition: this.selectedViewData.definition,
        description: this.getDescription(),
      };
      this.setViewTitle(data);
      axios
        .post("user/saveViewDefinition/", data)
        .then((resp) => {
          possibleError = true;
          if (resp.data.Status === "OK") {
            if (resp.data.Data) {
              if (resp.data.Data.isDuplicateName) {
                resp.data.Status = "Error";
                resp.data.Message =
                  "Name already exists. Please choose another name.";
              } else {
                if (!data.view_definition_id) {
                  data.view_definition_id =
                    resp.data.Data.view.view_definition_id;
                }
                if (self.saveAsDialog.isNew) {
                  self.views.push(data);
                  self.sortViews();
                  self.selectedView = data.name;
                  self.changeView();
                } else {
                  let ind = self.views.findIndex(
                    (x) => x.name === self.selectedView
                  );
                  if (makeInactive) {
                    self.views.splice(ind, 1);
                    self.selectedView = null;
                    self.changeView();
                    self.showDefinition = false;
                    resp.data.Message = "Report removed.";
                  } else {
                    self.views.splice(ind, 1, self.selectedViewData);
                  }
                }
                self.saveAsDialog.show = false;
              }
            }
          }
          this.response = resp.data;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
        });
    },
    sortViews() {
      this.views.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    },
    pageChanged(range) {
      if (!range)
        range = { start: 0, end: this.paging.pageSize - 1, pageNo: 1 };
      //   this.paging.currentPage = range.pageNo;
      this.pageRows = this.documents.slice(range.start, range.end);
    },
    setColumns() {
      this.detailColumnsUsed = this.detailColumns.filter((x) =>
        this.selectedViewData.definition.columnsVisible.some(
          (c) => c === x.value
        )
      );
      this.stateKey++;
    },
    createReview(doc) {
      let review = {
        doc_id: doc.doc_id,
        status: "Under Review",
        type: this.selectedView,
        notes: "",
        users: [],
      };
      this.reviews.push(review);
      this.reviewDialog.item = review;
      this.reviewDialog.new = true;
      this.reviewDialog.show = true;
      doc.reviews = this.reviews.filter((x) => x.doc_id === doc.doc_id);
    },
    closeReview() {
      if (this.reviewDialog.new) this.deleteReview();
      else this.reviewDialog.show = false;
    },
    deleteReview() {
      let doc = this.documents.find(
        (d) => d.doc_id === this.reviewDialog.item.doc_id
      );
      let ind = doc.reviews.findIndex((r) => r.type === this.selectedView);
      if (ind >= 0) doc.reviews.splice(ind, 1);
      ind = this.reviews.findIndex(
        (r) =>
          r.type === this.selectedView &&
          r.doc_id === this.reviewDialog.item.doc_id
      );
      if (ind >= 0) this.reviews.splice(ind, 1);
      this.reviewDialog.show = false;
    },
    openReview(review) {
      this.reviewDialog.item = review;
      this.reviewDialog.new = false;
      this.reviewDialog.show = true;
    },
    compareDocDetails(doc) {
      this.sideBySide.show = true;
      this.sideBySide.items = doc.detail.filteredItems.map((dm) => {
        return {
          doc_id: dm.doc_id || dm.detail.doc_id,
          system_number: dm.detail.system_number,
          doc_name: dm.doc_name || dm.detail.doc_name,
        };
      });
      this.sideBySide.items.unshift({
        doc_id: doc.doc_id || doc.detail.doc_id,
        doc_name: doc.doc_name || doc.detail.doc_name,
        system_number: doc.system_number || doc.detail.system_number,
      });
    },
    compareDocDetailsFull(doc) {
      this.fullCompareView.show = true;
      this.fullCompareView.items = doc.detail.filteredItems.map((dm) => {
        return {
          doc_id: dm.doc_id || dm.detail.doc_id,
          doc_name: dm.doc_name || dm.detail.doc_name,
          match_pct_name: dm.match_pct_name,
          match_pct_content: dm.match_pct_content,
        };
      });
      this.fullCompareView.items.unshift({
        doc_id: doc.doc_id || doc.detail.doc_id,
        doc_name: doc.doc_name || doc.detail.doc_name,
        match_pct_name: 100,
        match_pct_content: 100,
      });
    },
    getDocTypeFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.docTypeIncluded.length === this.docTypeSummary.length)
        return this.docTypeSummary.length > 0 ? "All" : "None";

      if (this.docTypeIncluded.length > 2)
        return `${this.docTypeIncluded.length} items`;

      return this.docTypeIncluded.map((i) => i.docType).join(", ");
    },
    getStatusFilterText(item, index) {
      if (item && index > 0) return "";
      if (this.docStatusIncluded.length === this.docStatusSummary.length) {
        return this.docStatusSummary.length > 0 ? "All" : "None";
      } else {
        let defList = this.defaultStatusFilterList.split(",");
        if (
          defList.every((s) =>
            this.docStatusIncluded.some((si) => si.status.toLowerCase() === s)
          )
        ) {
          let text = "";
          text = "Default";
          if (defList.length < this.docStatusIncluded.length) {
            text += ` (+ ${this.docStatusIncluded.length - defList.length})`;
          }
          return text;
        } else {
          return this.docStatusIncluded.reduce((prev, curr) => {
            return prev + (prev ? ", " : "") + curr.status;
          }, "");
        }
      }
    },
    selectRow(event, item) {
      if (this.rowClickAction === "menu") {
        this.openContextMenu(event, item);
      } else {
        this.openDocument(item);
      }
    },
    openDocument(item) {
      this.contextMenu = false;
      this.$emit("openDocument", item);
    },
    exportToCSV() {
      this.csvHeaders = JSON.parse(
        JSON.stringify(
          this.detailColumnsUsed.filter(
            (x) => x.value !== "match_pct_content_max"
          )
        )
      );
      let childType = this.selectedViewData.definition.linked_doc_view
        ? "LINKED"
        : "MATCHED";
      let data =
        '"Relationship","Match Group","Match Group Row",' +
        this.csvHeaders.map((h) => '"' + h.text + '"').join(",") +
        (this.selectedViewData.definition.linked_doc_view
          ? ""
          : ',"Match Percent"') +
        "\n";
      this.documents.forEach((d, di) => {
        data +=
          '"MAIN DOCUMENT","' +
          (di + 1) +
          '","0",' +
          this.csvHeaders
            .map((h) => utils.csvEscape(utils.removeTags(d[h.value])))
            .join(",") +
          (this.selectedViewData.definition.linked_doc_view ? "" : ",") +
          "\n";
        d.filteredItems.forEach((i, ii) => {
          data +=
            '"' +
            childType +
            ' DOCUMENT","' +
            (di + 1) +
            '","' +
            (ii + 1) +
            '",' +
            this.csvHeaders
              .map((h) => utils.csvEscape(utils.removeTags(i.detail[h.value])))
              .join(",") +
            (this.selectedViewData.definition.linked_doc_view
              ? ""
              : "," + Number.parseFloat(i.match_pct_content).toFixed() + "") +
            "\n";
        });
      });
      utils.downloadFile(
        data,
        `${this.selectedView}.csv`,
        "text/csv;encoding:utf-8"
      );
    },
    openContextMenu(event, document) {
      event.preventDefault();
      let doc = { doc_id: document.doc_id };
      this.docActionsMenuContext.document = doc;
      this.docActionsMenuContext.posX = event.clientX;
      this.docActionsMenuContext.posY = event.clientY;
      this.docActionsMenuContext.show = false;
      this.$nextTick(() => {
        this.docActionsMenuContext.show = true;
      });
    },
    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },
    returnToSource() {
      let params = { view: this.passedParams.sourceView };
      this.$passedParams.set(params);
      this.$router.push({ path: this.passedParams.sourcePath });
    },
  },
};
</script>
  <style lang="scss" scoped>
@import "@/assets/styles/vars";

.flex-container {
  display: flex;

  .left-col {
    z-index: 3;
    flex: 0 0 400px;
    width: 400px !important;
    height: calc(100vh - 48px) !important;
    position: fixed;
    top: 48px !important;
    overflow: visible !important;
    &.collapsed {
      left: 20px;
    }
  }

  .right-col {
    padding: 12px 0;
    transition: padding 200ms;
    &.fullscreen {
      padding: 12px;
    }
    &.hierarchiesCollapsed {
      padding-left: 30px;
    }
    &.hierarchiesPinned {
      padding-left: 410px;
    }
  }

  @media (max-width: 600px) {
    .right-col {
      padding-left: 0;
    }
  }
}

.filter-col {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 50%;
  @media (min-width: 960px) {
    max-width: 25%;
  }
  @media (min-width: 1264px) {
    max-width: 14%;
  }
}

.header-buttons {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
}

.btn-background {
  height: 44px;
  ::v-deep .v-input__slot {
    background: transparent !important;
  }
}

.headerCount {
  display: flex;
  align-items: center;
  padding: 0 60px 0 0;
  margin: -45px 0 15px 0;
  span {
    margin: 0 20px;
  }
}

.mainPane > .v-card {
  border-radius: 10px;
}

::v-deep mark {
  color: inherit;
  border-radius: 5px;
  background-color: rgba(#0372ee, 0.3);
}

::v-deep .v-select__selections {
  overflow: hidden;
  white-space: nowrap;
  flex-flow: nowrap;
}

.fixedFooter {
  @media (max-width: 600px) {
    .docList .footer-actions {
      padding-left: 15px;
    }
  }

  .left-col {
    height: calc(100vh - 103px) !important;
  }
  .mainPane {
    padding-bottom: 56px;
  }

  .docList {
    .footer-actions {
      width: 100%;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 4;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.multipane {
  .multipane-resizer {
    margin: 0;
    left: 0;
    position: relative;
    height: 20px;
    &:before {
      display: block;
      content: "";
      width: 40%;

      position: absolute;
      left: 30%;
      margin-top: 5px;
      margin-left: -20px;
      border: 2px solid #ccc;
      border-radius: 2px;
    }
    &:hover {
      &:before {
        border-color: #999;
      }
    }
  }

  .mainPane {
    padding-top: 12px;
    padding-right: 12px;
    min-height: 100px;
    max-height: 100%;
    &.fixedHeader {
      padding-right: 0;
      overflow: hidden;
    }
  }
  .previewPane {
    width: 100%;
    height: 100px;
    display: flex;
    flex-grow: 1;
    position: relative;
    .actions {
      position: absolute;
      top: 0;
      right: 25px;
      width: 44px;
      button {
        z-index: 1;
        opacity: 0.8;
      }
    }

    .docPreview {
      width: 100%;
      min-height: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 12px 85px 0 85px;
      //height: calc(50vh - 35px);
      //overflow-y: auto;
    }
  }
  &.hasPreview {
    height: calc(100vh - 105px);
    .mainPane {
      height: 100%;
      overflow-x: hidden;
      padding-bottom: 12px;
    }
  }
  // &:not(.hasPreview) {
  //   .mainPane {
  //     height: 100%;
  //     overflow: unset;
  //   }
  // }
}

.v-application.theme--light {
  .previewPane {
    ::v-deep .docPreview {
      background-color: $background-color-light;
      > .v-card {
        box-shadow: 0 0 4px 0 #b5b5b5;
      }
    }
  }
}

.v-application.theme--dark {
  .previewPane {
    ::v-deep .docPreview {
      > .v-card {
        border: 1px solid #b5b5b5;
      }
    }
  }
}

.docList {
  .footer-actions {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 55px;

    div {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
    }
  }

  .v-card.doc-card {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .v-card.on-hover.theme--light .v-list {
    background-color: #f7f7f7;
  }

  .v-card.on-hover.theme--dark .v-list {
    background-color: #272727;
  }

  .job-description {
    font-size: 0.75rem;
    overflow: hidden;
    display: flow-root;
    position: relative;
    height: 12em;
    line-height: 1.8;
  }

  .job-description:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 1.2em;
    background: linear-gradient(to right, rgba(#fff, 0), rgba(#fff, 1) 50%);
  }

  .v-card.theme--dark {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#1e1e1e, 0),
        rgba(#1e1e1e, 1) 50%
      );
    }
  }

  .v-card.on-hover {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#f7f7f7, 0),
        rgba(#f7f7f7, 1) 50%
      );
    }
  }

  .v-card.on-hover.theme--dark {
    .job-description:after {
      background: linear-gradient(
        to right,
        rgba(#272727, 0),
        rgba(#272727, 1) 50%
      );
    }
  }

  .data-content {
    margin-top: -5px;
    padding-top: 0;
  }
  .data-content-2 {
    margin-top: -7px;
    min-height: auto !important;
    max-height: auto;
    padding-bottom: 0;
  }
  .data-label {
    font-size: 0.6rem;
    padding: 0 5px 0 16px;
    color: darkgray;
  }
  .data-value {
    font-size: 0.9rem;
  }
  .data-value-2 {
    font-size: 0.75rem;

    padding-bottom: 10px;
  }

  .doc-card .status-chip {
    background-color: transparent !important;
    :before {
      content: "\2022";
      margin-right: 0.5em;
    }
  }

  ::v-deep .v-data-table {
    tbody > tr,
    tbody > tr span {
      cursor: pointer;
    }
    tr td:last-child {
      padding: 0 10px 0 0;
    }
    .status-chip {
      height: 2.35em;
      min-width: 7.2em;
      display: inline-flex;
      justify-content: center;
      font-size: 0.5625rem;
    }
  }
}

::v-deep {
  .customFlag {
    transform: scale(0.35) !important;
    -ms-transform: scale(0.35) !important;
    -webkit-transform: scale(0.35) !important;
    -moz-transform: scale(0.35) !important;
  }
}

.recruiterSelect::v-deep {
  input {
    cursor: pointer;
  }
}

.recruiterChip.theme--light {
  background-color: #f4f9fd !important;
  .v-icon {
    color: #7d8592;
  }
}

.recruitersMenu {
  min-height: 150px;
  .v-chip-group {
    min-height: 30px;
  }
  ::v-deep .v-tabs-slider-wrapper {
    left: auto !important;
    right: 0;
  }
}

.primary-document {
  font-weight: bold;
  border-top: 1px solid lightgray;
}
.match-document-row {
  margin-left: 10px;
  margin-bottom: 1px;
}
.match-document-col-action {
  padding-top: 5px;
  padding-bottom: 5px;
}
.match-document-col {
  background-color: #f4f3f3;
  padding-top: 5px;
  padding-bottom: 5px;
}
.match-document-col-1 {
  margin-right: -20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.match-document-col-last {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.vertical-line,
.vertical-line-linked,
.vertical-line-1,
.vertical-line-2 {
  display: inline-block;
  float: left;
  width: 2px;
  background-color: lightgray;
  margin-left: 11px;
  margin-top: -40px;
  height: calc(50% + 25px);
  //   margin-top: -40px;
  //   height: calc(100% + 14px);
  z-index: -1;
}
.horizontal-line {
  display: inline-block;
  float: left;
  width: calc(100% + 6px);
  height: 2px;
  background-color: lightgray;
  //   margin-top: 10px;
  margin-left: 11px;
  //   margin-right: -12px;
}
.vertical-line-1,
.vertical-line-2 {
  margin-top: -34px;
  height: calc(50% + 15px);
}
.vertical-line-2 {
  margin-left: calc(7px + 100% / -12);
}
</style>
  