<template>
  <div>
    <v-container fluid>
      <v-card flat v-if="dataLoaded">
        <v-row no-gutters>
          <v-col cols="12" class="actionButtons">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="uploadRules" v-bind="attrs" v-on="on">
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </template>
              <span>Restore Rules from Uploaded Definition</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="downloadRules" v-bind="attrs" v-on="on">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download Rules</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3" class="checkMatrix flex-grow-1 flex-shrink-0">
            <v-simple-table v-if="selectedTemplate && !isUploading" dense class="">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>
                    </th>
                    <th
                      class="slanted-text"
                      v-for="ct in selectedTemplateCheckTypes"
                      :key="ct"
                    >
                      <div>
                        <span>{{ ct }}</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="tp in selectedTemplate.parts" :key="tp.tp_id">
                    <td>
                      {{ tp.tp_name }}
                      <v-btn
                        icon
                        color="primary"
                        class="float-right"
                        x-small
                        @click="addNewRule(tp)"
                      >
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </td>
                    <td
                      v-for="ct in selectedTemplateCheckTypes"
                      :key="ct"
                      class="data"
                    >
                      <v-tooltip
                        v-for="tpct in tp[ct]"
                        :key="tpct.tptc_id"
                        bottom
                      >
                        <template v-slot:activator="{ on: tooltip }">
                          <v-chip
                            small
                            outlined
                            :color="tpct.is_error ? 'red' : 'orange'"
                            v-on="{ ...tooltip }"
                            @click="editRule(tpct)"
                          >
                            {{ buildShortDescription(tpct) }}
                            <v-icon small v-if="tpct.exclusions.length">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-chip>
                        </template>
                        <span v-html="buildCheckDescription(tpct)" />
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col
            cols="9"
            v-if="noData"
            class="d-flex align-end justify-center"
          >
            <v-alert color="primary" type="warning"
              >No rules found for this template</v-alert
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-dialog v-model="dialog" persistent max-width="950px">
      <v-card>
        <v-toolbar flat class="mb-4">
          <v-toolbar-title>
            {{ editingItem.tpct_id ? "Edit" : "New" }} Rule -
            {{ editingItem.tp_name }}
            {{ editingItem.tpct_id ? `/ ${editingItem.tc_type}` : "" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-form class="newRuleForm">
            <v-row class="align-center">
              <v-label>What</v-label>
              <v-select
                v-if="!editingItem.tpct_id"
                v-model="editingItem.tc_type"
                :items="checkTypes"
                item-text="tc_type"
                item-value="tc_type"
                label="Check Type"
                style="max-width: 15em"
              />
              <v-chip-group
                v-if="!selectedSeverity"
                active-class="primary--text"
                v-model="editingItem.is_error"
                mandatory
                row
                align="center"
              >
                <v-chip small>Warning</v-chip>
                <v-chip small>Error</v-chip>
              </v-chip-group>
              <v-spacer></v-spacer>
              <span class="ml-12">Colour</span>
              <v-menu v-model="colourPickerMenu" top nudge-bottom="130" nudge-right="115" :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    v-model="editingItem.error_colour_override"
                    readonly
                    :placeholder="defaultColour"
                    style="max-width: 10em">
                    <template v-slot:append>
                      <div :style="swatchStyle" v-on="on" />
                    </template>
                  </v-text-field>
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="pickerColour" flat />
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" outlined small @click="pickerColour = editingItemColour; colourPickerMenu = false;">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined small @click="editingItem.error_colour_override = null; pickerColour = editingItemColour; colourPickerMenu = false;">
                      Use Default
                    </v-btn>
                    <v-btn color="primary" small @click="editingItem.error_colour_override = pickerColour; colourPickerMenu = false;">Set Override</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-row>

            <v-row class="align-center">
              <v-label>When</v-label>
              <span>Section</span>
              <v-select
                v-model="editingItem.include"
                :items="inclusions"
                item-text="text"
                item-value="value"
                style="max-width: 10em"
              />
              <span v-if="newRuleJoiner">{{ newRuleJoiner }}</span>
              <v-text-field
                v-if="newRuleJoiner"
                v-model="editingItem.check_value"
                type="number"
                style="max-width: 2.5em"
              />
              <span v-if="editingItem.check_function === 'skills_type'">&percnt;</span>
              <v-select
                v-if="editingItem.check_function === 'skills_type'"
                v-model="editingItem.check_classifier_value"
                :items="tagClassifierValues"
                item-text="title"
                item-value="tag_classifier_type_value_id"
                style="max-width: 8em"
              >
                <template v-slot:item="{item, attrs, on}">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
              <v-select
                v-model="editingItem.check_function"
                :items="functions"
                item-text="ending"
                item-value="name"
                style="max-width: 10em"
              />
            </v-row>

            <v-row class="align-center">
              <v-label>Excluding</v-label>
              <v-select
                v-model="selectedHierarchyType"
                return-object
                :items="hierarchies"
                label="Hierarchy Type"
                item-text="ht_name"
                item-value="ht_id"
                style="max-width: 10em"
              >
              </v-select>
              <v-autocomplete
                v-model="selectedHierarchy"
                return-object
                :items="filteredHierarchies"
                label="Hierarchy"
                item-text="hierarchy_text"
                item-value="hr_id"
                @mousedown.stop.prevent
                @touchstart.stop.prevent
              >
              </v-autocomplete>
              <v-btn depressed tile color="primary" small @click="addExclusion()">
                Add
              </v-btn>
            </v-row>

            <v-simple-table dense v-if="editingItem.exclusions.length">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Hierarchy</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="tpcte in editingItem.exclusions"
                    :key="tpcte.tpcte_id"
                  >
                    <td>
                      {{ tpcte.ht_name }}
                    </td>
                    <td>
                      {{ tpcte.hierarchy_text }}
                    </td>
                    <td class="text-right">
                      <v-btn icon small color="primary">
                        <v-icon @click="removeExclusion(tpcte)">
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="secondary" text outlined @click="dialog = false"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="this.editingItem.tpct_id"
            color="red"
            text
            outlined
            @click="deleteRule()"
            >Delete Rule</v-btn
          >
          <v-btn
            v-if="this.editingItem.tpct_id"
            color="primary"
            text
            outlined
            @click="updateRule()"
            >Update Rule</v-btn
          >
          <v-btn
            v-if="!this.editingItem.tpct_id"
            color="primary"
            text
            outlined
            @click="createRule()"
            >Add Rule</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showErrorSnack"
      :timeout="snackTimeout"
      :color="snackColor"
      :multi-line="errorText.length > 50"
      top
    >
      {{ errorText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <Loading :isVisible="isLoading || isUploading" />
    <ResponseHandler :serviceResponse="response"></ResponseHandler>
    <v-file-input v-show="false" accept=".json" ref="inputFile" dense @change="uploadRulesFromFile" label="Select File"></v-file-input>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ResponseHandler from "@/components/ResponseHandler";
import utils from "@/common/utils.js";

export default {
  name: "adminTemplateValidation",
  components: {
    ResponseHandler,
  },
  props: {
    value: { type: Number, required: true },
  },
  data: function () {
    return {
      dataLoaded: false,
      isLoading: false,
      isUploading: false,
      response: null,
      altsearch: "",
      errorText: "",
      showErrorSnack: false,
      snackColor: "error",
      snackTimeout: 4000,
      contextMenu: null,
      colourPickerMenu: false,
      pickerColour: null,
      x: 0,
      y: 0,
      dialog: null,
      checkTypes: [],
      inclusions: [
        { text: "has", value: 0 },
        { text: "does not have", value: 1 },
      ],
      functions: [
        {
          name: "word_list",
          short: "InList",
          ending: "words from list",
        },
        {
          name: "row_count",
          short: "R",
          pos: "at least",
          neg: "more than",
          ending: "rows",
        },
        {
          name: "char_count",
          short: "C",
          pos: "at least",
          neg: "more than",
          ending: "characters",
        },
        {
          name: "word_count",
          short: "W",
          pos: "at least",
          neg: "more than",
          ending: "words",
        },
        {
          name: "sentence_length",
          short: "S",
          pos: "at least",
          neg: "more than",
          ending: "words in a sentence",
        },
        {
          name: "infCheck",
          short: "IsVerb",
          ending: "a verb at the start",
        },
        {
          name: "ingCheck",
          short: "IsGerund",
          ending: "a gerund (..ing) at the start",
        },
        {
          name: "flex",
          short: "F",
          pos: "at least",
          neg: "more than",
          ending: "score",
        },
        {
          name: "flex_completion",
          short: "FC",
          ending: "flex completed",
        },
        {
          name: "flag_count",
          short: "flag",
          ending: "essential flag",
        },
        {
          name: "skills_type",
          short: "SK",
          pos: "at least",
          neg: "more than",
          ending: "skills",
        },
      ],

      selectedTemplate: null,
      selectedTemplatePartId: null,
      selectedCheckType: null,
      selectedSeverity: null,

      selectedHierarchyType: null,
      selectedHierarchy: null,

      editingItem: {
        tpct_id: 0,
        tp_id: null,
        tc_type: null,
        is_error: 0,
        error_colour_override: null,
        include: 0,
        check_value: 0,
        check_function: "word_list",
        check_classifier_value: null,
        exclusions: [],
      },
    };
  },
  watch: {
    value(val) {
      if (val)
        this.loadData();
    }
  },
  computed: {
    ...mapState({
      hierarchiesLoading: (state) => state.hierarchies.loading,
      hierarchies: (state) => state.hierarchies.hierarchies,
      tagClassifierTypes: (state) => state.hierarchies.tagClassifierTypes,
    }),
    tagClassifierValues() {
      return this.tagClassifierTypes.map(ct => ct.values.map(tv => {
        return {
          tag_classifier_type_value_id: tv.tag_classifier_type_value_id,
          title: tv.value.toLowerCase(),
          subtitle: ct.name
        }
      })).flat();
    },
    noData: function () {
      return (
        !this.selectedTemplate ||
        !this.selectedTemplate.usedCheckTypes ||
        this.selectedTemplate.usedCheckTypes.length == 0
      );
    },
    filteredHierarchies: function () {
      return this.selectedHierarchyType?.values || [];
    },

    selectedTemplateCheckTypes: function () {
      if (!this.selectedTemplate) return [];

      const checkTypes = this.selectedTemplate.usedCheckTypes;
      return checkTypes.sort();
    },

    newRuleJoiner: function () {
      return this.ruleJoiner(this.editingItem);
    },

    defaultColour: function() {
      const currentType = this.checkTypes.find(ct => ct.tc_type === this.editingItem.tc_type);
      return currentType?.colour;
    },

    editingItemColour: function() {
      const colour = this.editingItem.error_colour_override || this.defaultColour || "";
      if (colour.startsWith("#")) {
        return colour;
      } else {
        const ctx = document.createElement('canvas').getContext('2d');
        ctx.fillStyle = colour;
        return ctx.fillStyle || "#ffa500";
      }
    },

    swatchStyle() {
      return {
        backgroundColor: this.editingItemColour,
        cursor: 'pointer',
        height: '28px',
        width: '28px',
        borderRadius: this.colourPickerMenu ? '50%' : '5px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },
  mounted() {},
  created() {
    this.loadData();
  },
  methods: {
    ruleJoiner(rule) {
      let checkFunction = this.functions.find(
        (func) => func.name == rule.check_function
      );
      return this.buildRuleJoiner(checkFunction, rule.include);
    },

    buildRuleJoiner(checkFunction, include) {
      if (!checkFunction) return "";
      if (include) return checkFunction.pos || "";
      else return checkFunction.neg || "";
    },

    buildShortDescription(check) {
      const checkFuncName = check.check_function;
      const checkFunction = this.functions.find(
        (func) => func.name == checkFuncName
      );
      const checkVal = check.check_value;

      if (!checkFunction.neg)
        return `${check.include ? "! " : ""}${checkFunction.short}`;
      else
        return `${checkFunction.short}${
          check.include ? " < " : " > "
        }${checkVal}`;
    },

    buildCheckDescription(check) {
      const checkFuncName = check.check_function;
      const checkFunction = this.functions.find(
        (func) => func.name == checkFuncName
      );
      const checkVal = check.check_value;
      const what = check.is_error ? "error" : "warning";
      const incl = check.include ? "does <b>not</b> have" : "has";
      let joiner = this.buildRuleJoiner(checkFunction, check.include);
      if (joiner) joiner += ` <b>${checkVal}</b>`;
      if (checkFuncName === 'skills_type')
        joiner += ` ${this.tagClassifierValues.find(cv => cv.tag_classifier_type_value_id === check.check_classifier_value)?.title || ''}`;

      let exclusions = "";
      if (check.exclusions && check.exclusions.length)
        exclusions =
          "<br /> except where hierarchy in: <br />&nbsp;&nbsp;" +
          check.exclusions
            .map((tpcte) => {
              return tpcte.hierarchy_text;
            })
            .join("<br />&nbsp;&nbsp;");

      return `Show <b>${what}</b> when section ${incl} ${joiner} ${checkFunction.ending} ${exclusions}`;
    },

    loadData() {
      let possibleError = false;
      this.isLoading = true;
      axios
        .get("admin/validation/loadData/" + this.value)
        .then((resp) => {
          this.checkTypes = resp.data.Data.checkTypes;
          this.selectedTemplate = resp.data.Data.templates.length ? resp.data.Data.templates[0] : null;
          this.isLoading = false;
          this.dataLoaded = true;
        })
        .catch((err) => {
          if (possibleError) {
            alert("Code Error");
          } else if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            alert(err.response ? err.response.data.message : err);
          }
          console.log(err);
          this.isLoading = false;
        });
    },

    triggerNotification(text, type) {
      this.errorText = text;
      this.snackColor = type;
      this.showErrorSnack = true;
    },

    addNewRule(tp) {
      this.editingItem.tpct_id = 0;
      this.editingItem.tp_id = tp.tp_id;
      this.editingItem.tp_name = tp.tp_name;
      this.editingItem.exclusions = [];
      this.editingItem.error_colour_override = null;
      this.dialog = true;
    },

    editRule(tpct) {
      this.editingItem.tpct_id = tpct.tpct_id;
      this.editingItem.tp_id = tpct.tp_id;
      this.editingItem.tp_name = tpct.tp_name;
      this.editingItem.tc_type = tpct.tc_type;
      this.editingItem.is_error = tpct.is_error;
      this.editingItem.error_colour_override = tpct.error_colour_override;
      this.editingItem.include = tpct.include;
      this.editingItem.check_function = tpct.check_function;
      this.editingItem.check_value = tpct.check_value;
      this.editingItem.check_classifier_value = tpct.check_classifier_value;
      this.editingItem.exclusions = [...tpct.exclusions];
      this.pickerColour = this.editingItemColour;
      this.dialog = true;
    },

    async deleteRule(rule) {
      const item = rule || this.editingItem;
      if (!item.tpct_id) return;
      this.isLoading = true;

      await axios
        .post("admin/validation/DeleteRule", {
          tpct_id: item.tpct_id,
        })
        .then((resp) => {
          if (resp.data.Status === "OK") {
            let tp = this.selectedTemplate.parts.find(
              (tp) => tp.tp_id == item.tp_id
            );
            if (tp && tp[item.tc_type]) {
              let idx = tp[item.tc_type].findIndex(
                (tpct) => tpct.tpct_id == item.tpct_id
              );
              if (idx > -1) tp[item.tc_type].splice(idx, 1);

              // this.triggerNotification(
              //   `Rule ${item.tpct_id} for ${item.tp_name}/${item.tc_type} deleted`,
              //   "success"
              // );
            }
          }
          this.isLoading = false;
          this.dialog = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
          this.dialog = false;
        });
    },

    buildUpdateRequest(rule) {
      const templatePartId = rule.tp_id;
      const checkType = rule.tc_type;
      if (
        !rule ||
        !this.selectedTemplate ||
        !templatePartId ||
        !checkType
      )
        return null;

      return {
        tpct_id: rule.tpct_id,
        tmpl_id: this.selectedTemplate.tmpl_id,
        tp_id: templatePartId,
        tc_type: checkType,
        is_error: rule.is_error,
        error_colour_override: rule.error_colour_override,
        include: rule.include ? 1 : 0,
        avoid: rule.include ? 0 : 1,
        check_function: rule.check_function,
        check_value: this.ruleJoiner(rule) ? rule.check_value : 0,
        check_classifier_value: rule.check_function === 'skills_type' ? rule.check_classifier_value : null,
        exclusions: rule.exclusions.map((e) => {
          return {
            ht_id: e.ht_id,
            hr_id: e.hr_id,
          };
        }),
      };
    },

    async updateRule(rule) {
      rule = rule || this.editingItem;
      const request = this.buildUpdateRequest(rule);
      if (!request) return;
      this.isLoading = true;

      await axios
        .post("admin/validation/addOrUpdateRule", request)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            rule.isDirty = false;
            rule.isNew = false;
            rule.fromUpload = false;
            let tp = this.selectedTemplate.parts.find(
              (tp) => tp.tp_id == rule.tp_id
            );
            if (tp && tp[rule.tc_type]) {
              let tpct = tp[rule.tc_type].find(
                (tpct) => tpct.tpct_id == rule.tpct_id
              );

              if (tpct) {
                tpct.tp_id = rule.tp_id;
                tpct.tc_type = rule.tc_type;
                tpct.is_error = rule.is_error;
                tpct.error_colour_override = rule.error_colour_override;
                tpct.tp_name = rule.tp_name;
                tpct.include = rule.include;
                tpct.check_function = rule.check_function;
                tpct.check_value = rule.check_value;
                tpct.check_classifier_value = rule.check_classifier_value;
                tpct.exclusions = [...rule.exclusions];

                // this.triggerNotification(
                //   `Rule ${tpct.tpct_id} for ${tpct.tp_name}/${tpct.tc_type} updated`,
                //   "success"
                // );
              }
            }
          }
          this.isLoading = false;
          this.dialog = false;
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
          this.dialog = false;
        });
    },

    async createRule(rule) {
      rule = rule || this.editingItem;
      const request = this.buildUpdateRequest(rule);
      if (!request) return;
      this.isLoading = true;

      await axios
        .post("admin/validation/addOrUpdateRule", request)
        .then((resp) => {
          if (resp.data.Status === "OK") {
            const newItem = { ...rule };
            newItem.isDirty = false;
            newItem.isNew = false;
            newItem.fromUpload = false;
            newItem.tpct_id = resp.data.Data;
            let tp = this.selectedTemplate.parts.find(
              (tp) => tp.tp_id == newItem.tp_id
            );
            if (tp) {
              if (
                !this.selectedTemplate.usedCheckTypes.includes(
                  newItem.tc_type
                )
              )
                this.selectedTemplate.usedCheckTypes.push(newItem.tc_type);
              if (!tp[newItem.tc_type]) {
                this.$set(tp, newItem.tc_type, []);
              }
              tp[newItem.tc_type].push(newItem);
            }
            // this.triggerNotification(
            //   `Rule ${newItem.tpct_id} for ${newItem.tp_name}/${newItem.tc_type} added`,
            //   "success"
            // );
            this.isLoading = false;
            this.dialog = false;
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$emit("sessionExpired", err);
          } else {
            console.log(err);
            this.response = err.response
              ? err.response.data
              : { message: "Unexpected Error" };
          }
          this.isLoading = false;
          this.dialog = false;
        });
    },

    addExclusion(hierarchyType, hierarchy) {
      hierarchyType = hierarchyType || this.selectedHierarchyType;
      hierarchy = hierarchy || this.selectedHierarchy;
      let newExclusion;
      if (hierarchyType && hierarchy) {
        newExclusion = {
          ht_id: hierarchyType.ht_id,
          ht_name: hierarchyType.ht_name,
          hierarchy_text: hierarchy.hierarchy_text,
          hr_id: hierarchy.value,
          hr_active: true
        };
        this.editingItem.exclusions.push(newExclusion);
        this.selectedHierarchy = null;
      }
      return newExclusion;
    },

    removeExclusion(tpcte) {
      const idx = this.editingItem.exclusions.indexOf(tpcte);
      if (idx > -1) this.editingItem.exclusions.splice(idx, 1);
    },
    
    downloadRules() {
      const definitionJson = JSON.stringify({
        template: this.selectedTemplate,
        hierarchies: this.hierarchies.map(h => { return { ht_id: h.ht_id, ht_name: h.ht_name }}),
      });
      const fileName =  `${this.selectedTemplate.tmpl_name.replaceAll(" ","_")}_Validation.json`;
      utils.downloadFile(
        definitionJson,
        fileName,
        "text/json"
      );
    },

    uploadRules() {
      if (confirm("Update rules based on uploaded definition?\nAll rules will be overwritten!"))
        this.$refs.inputFile.$refs.input.click();
    },

    uploadRulesFromFile(file) {
      if (file) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          const uploadedDefinition = JSON.parse(reader.result);
          this.updateRulesFromUpload(uploadedDefinition);
        }, false);
        reader.readAsText(file, "UTF-8");
        this.$refs.inputFile.$refs.input.value = null;
      }
    },

    async updateRulesFromUpload(uploadedDefinition) {

      const checkCols = [
        { name: "tc_type" },
        { name: "is_error" },
        { name: "check_value" },
        { name: "check_function" },
        { name: "avoid" },
        { name: "include" },
      ];
      const updateInstance = (rule, cols, localInstance, uploadedInstance) => {
        rule.fromUpload = true;
        cols.forEach(c => {
          if (localInstance[c.name] !== uploadedInstance[c.name]) {
            rule.isDirty = true;
            localInstance[c.name] = uploadedInstance[c.name];
          }
        })
      }
      const dirty = [];
      const toDelete = [];
      const uploadCheckTypes = uploadedDefinition.template.usedCheckTypes;
      this.isUploading = true;

      try {
        uploadedDefinition.template.parts.forEach(p => {
          const localPart = this.selectedTemplate.parts.find(lp => !lp.fromUpload && lp.tp_name === p.tp_name);
          if (localPart) {
            localPart.fromUpload = true;
            uploadCheckTypes.forEach(ct => {
              const checks = localPart[ct] || [];
              p[ct].forEach(rule => {
                const localRule = checks.find(lc => lc.check_function === rule.check_function && lc.is_error === rule.is_error)
                  || {
                    tp_id: localPart.tp_id,
                    isNew: true,
                    isDirty: true,
                    exclusions: []
                  };
                updateInstance(localRule, checkCols, localRule, rule);

                if (localRule.exclusions && localRule.exclusions.length) {
                  localRule.isDirty = true;
                  localRule.exclusions.splice(0);
                }

                if (rule.exclusions && rule.exclusions.length) {
                  rule.exclusions.forEach(e => {
                    const localHT = this.hierarchies.find(ht => ht.ht_name === e.ht_name);
                    const localHry = localHT?.values.find(h => h.hierarchy_text === e.hierarchy_text);
                    if (localHT && localHry) {
                      localRule.isDirty = true;
                      localRule.exclusions.push(this.addExclusion(localHT, localHry));
                    }
                  })
                }
                
                if (localRule.isDirty)
                  dirty.push(localRule);
              })
              toDelete.push(...checks.filter(lc => !lc.fromUpload));
            })
          }
        });

        this.selectedTemplate.usedCheckTypes
          .filter(ct => !uploadCheckTypes.includes(ct))
          .forEach((ct) => {
            const idx = this.selectedTemplate.usedCheckTypes.indexOf(ct);
            this.selectedTemplate.usedCheckTypes.splice(idx, 1);
            this.selectedTemplate.parts
              .filter(p => p[ct] && p[ct].length)
              .forEach(p => {
                toDelete.push(...p[ct]);
              });
          });

        await Promise.all([
          ...dirty.map(rule => {
            if (rule.isNew)
              return this.createRule(rule);
            else
              return this.updateRule(rule);
          }),
          ...toDelete.map(rule => this.deleteRule(rule))
        ]);

        this.isUploading = false;
        
      } catch(e) {
        this.isUploading = false;
        alert("Unexpected Error");
        console.error(e);
      }
    }
  },
};
</script>

<style scoped lang="scss">

div.actionButtons {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  margin-top: 4px;
  padding-right: 4px !important;
  margin-bottom: -40px;
}

th.slanted-text {
  height: 140px !important;
  min-width: 50px;
  position: relative;
  vertical-align: bottom;
  padding: 0 !important;
  font-size: 14px;
  line-height: 0.8;
}

th.slanted-text > div {
  position: relative;
  top: 0px;
  left: -70px;
  height: 100%;
  -ms-transform: skew(45deg, 0deg);
  -moz-transform: skew(45deg, 0deg);
  -webkit-transform: skew(45deg, 0deg);
  -o-transform: skew(45deg, 0deg);
  transform: skew(45deg, 0deg);
  overflow: hidden;
}

th.slanted-text > div > span {
  -ms-transform: skew(-45deg, 0deg) rotate(45deg);
  -moz-transform: skew(-45deg, 0deg) rotate(45deg);
  -webkit-transform: skew(-45deg, 0deg) rotate(45deg);
  -o-transform: skew(-45deg, 0deg) rotate(45deg);
  transform: skew(-45deg, 0deg) rotate(45deg);
  position: absolute;
  bottom: 60px;
  left: -40px;
  display: inline-block;
  width: 140px;
  text-align: right;
}

/* .checkMatrix th > div > span {
  writing-mode: vertical-rl;
  text-orientation: mixed;
} */

.checkMatrix {
  max-width: 100%;
}

.checkMatrix thead > tr {
  height: 140px;
}

.checkMatrix tr > td:first-child {
  width: 200px;
}

.checkMatrix td.data {
  padding: 0;
  text-align: center;
}

.checkMatrix tr:hover {
  background-color: transparent !important;
}

.checkMatrix thead > tr th:nth-of-type(even) div,
.checkMatrix tbody > tr td:nth-of-type(even) {
  background-color: #eee;
}


.v-application.theme--dark {
  .checkMatrix thead > tr th:nth-of-type(even) div,
  .checkMatrix tbody > tr td:nth-of-type(even) {
    background-color: #424242;
  }
}

.checkMatrix .v-chip {
  margin: 2px;
}

.newRuleForm .row {
  padding-left: 10px;
}

.newRuleForm .row > .v-input,
.newRuleForm .row > span,
.newRuleForm .row > .v-text-field {
  margin-right: 1em;
  font-size: 16px;
}

.newRuleForm .row > .v-label {
  padding-right: 2em;
  min-width: 7em;
}
</style>